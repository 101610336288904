import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export const NotFound: FunctionComponent = () => (
  <span>
    <br />
    The page you're looking for could not be found. Perhaps you'd
    like to go to the{" "}
    <Link to="/admin">
      Dashboard
    </Link>?
  </span>
);
