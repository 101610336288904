import { IFormErrorHandler } from "../../../common/Form/FormError";
import { IApiError409, IApiError422 } from "../../api/types";

const INVALID_TOKEN = 'invalid_token_request';
const COMPLEXITY = 'password_complexity_not_met';
const REUSE = 'password_previously_used';

const PASSWORD_ERROR_CODES = [
  INVALID_TOKEN, 
  COMPLEXITY,
  REUSE
];

const PASSWORD_HINTS = {
  'allowed_characters': 'Password contains invalid characters',
  'min_length': 'Password is too short (10 characters minimum)',
  'max_length': 'Password is too long',
  'max_consecutive_character_repetition': 'Password contains too many repeated characters',
  'required_character_classes': 'Password must contain an uppercase letter, lowercase letter, special character and a number',
  'commonness': 'That password is either a commonly-used password or has been found in a public data breach, please choose another password'
};

export const passwordErrorHandler: IFormErrorHandler = {
  match: (error: IApiError409 | IApiError422) => PASSWORD_ERROR_CODES.indexOf(error.code) > -1,
  describe: (error: IApiError409 | IApiError422) => {
    if (error.code === INVALID_TOKEN) {
      return 'Invalid or expired token';
    } else if(error.code === REUSE) {
      return 'That password has already been used - please use a new password';
    } else {
      return PASSWORD_HINTS[error.meta.hint];
    }
  }
};
