import { formatDateField } from '../date';
import { IAutomatedInterventionResolutionType, IIssueStatus } from '../../types/resources';
import { getApiUrl } from '../url';
import { AUTOMATED_INTERVENTION_RESOLUTION_TYPE, ISSUE_STATUS } from '../../types/enums';

export const getResolvedText = (isIssueOpen: boolean): string => isIssueOpen ? 'Unresolved' : 'Resolved';

export const getIssueOpenColor = (isIssueOpen: boolean) => isIssueOpen ? 'red' : 'green'

export const getStatusEnumerationNumber = (
  isIssueOpen: boolean,
  interventionResolutionOptions: IAutomatedInterventionResolutionType | null,
): number => {
  if (!isIssueOpen) {
    return 0;
  } else if (!interventionResolutionOptions || interventionResolutionOptions === AUTOMATED_INTERVENTION_RESOLUTION_TYPE.view_only) {
    return 1;
  } else {
    return 2;
  }
}

export const getGuidancePreviewLink = (
  surveyTemplateId: string,
  interventionId: string,
  interventionTemplateId: string,
  authToken: string
): string =>
  `${getApiUrl('admin')}/survey_templates/${surveyTemplateId}/automated_intervention_templates/${
    interventionTemplateId
  }?automated_intervention_id=${interventionId}&jwt_token=${authToken}`;

export const solutionGuidanceTypes: {[key in IAutomatedInterventionResolutionType]: string} = {
  resolve_only: 'Automatically resolves issue on viewing',
  resolve_escalate_defer: 'User may resolve issue on viewing',
  view_only: 'Informational only',
}

export const getGuidanceViewedInfo = (interventionViewedAt: string) => `Viewed on ${formatDateField(interventionViewedAt)}`;

export const getIssueStatusText = (issueStatus: IIssueStatus): string => {
  if (issueStatus === ISSUE_STATUS.open) {
    return 'Open';
  } else if (issueStatus === ISSUE_STATUS.escalated) {
    return 'Escalated';
  } else if (issueStatus === ISSUE_STATUS.escalated_by_user) {
    return 'Escalated (By User)';
  } else if (issueStatus === ISSUE_STATUS.auto_closed) {
    return 'Auto-Closed';
  } else if (issueStatus === ISSUE_STATUS.closed_on_user_archival) {
    return 'Closed (On User Archival)';
  } else if (issueStatus === ISSUE_STATUS.resolved) {
    return 'Resolved';
  } else {
    return '';
  }
}
