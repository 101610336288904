import React, { FunctionComponent, ReactElement } from "react";
import Checkbox from "../Forms/Checkbox";
import { CheckboxFilterProps } from "./types";

type ArchivedFilterKey = "archived" | "userArchived"

const ArchivedFilter: FunctionComponent<CheckboxFilterProps<ArchivedFilterKey>> = ({ filterKey, value, onChange }): ReactElement => {
  return (
    <Checkbox
      id='archived'
      fieldLabel="Archived"
      label="include archived users"
      value={value}
      onChange={() => onChange([{ key: filterKey, value: value ? [false] : [true, false] }])}
    />
  );
};

export default ArchivedFilter;
