import { useMemo } from 'react';
import { IFormConfig } from '../../../common/Form';
import { IFormInput } from '../../../common/Form/FormField';

export const useUserFormConfig = (updateRiskyFields?: boolean) => useMemo<IFormConfig>(() => ({
  segments: [
    {
      key: 'default',
      inputs: [
        {
          key: 'firstName',
          type: 'input',
          inputProps: {
            label: 'First Name',
            placeholder: 'First Name',
          },
          validation: [
            { key: 'required', attributes: null },
            { key: 'isType', attributes: 'string' },
            { key: 'lengthBetween', attributes: [1, 50] },
          ],
        },
        {
          key: 'lastName',
          type: 'input',
          inputProps: {
            label: 'Last Name',
            placeholder: 'Last Name',
          },
          validation: [
            { key: 'required', attributes: null },
            { key: 'isType', attributes: 'string' },
            { key: 'lengthBetween', attributes: [1, 50] },
          ],
        },
        {
          key: 'emailAddress',
          type: 'input',
          inputProps: {
            label: 'Email',
            placeholder: 'Email'
          },
          validation: [
            { key: 'required', attributes: null },
            { key: 'isType', attributes: 'string' },
            { key: 'email', attributes: null },
            { key: 'lengthBetween', attributes: [1, 100] },
          ]
        },
        {
          key: 'jobTitle',
          type: 'input',
          inputProps: {
            label: 'Job Title',
            placeholder: 'Job Title',
          },
          validation: [
            { key: 'isType', attributes: 'string' },
            { key: 'lengthBetween', attributes: [1, 100] },
          ],
        },
        {
          key: 'department',
          type: 'input',
          inputProps: {
            label: 'Department',
            placeholder: 'Department',
          },
          validation: [
            { key: 'isType', attributes: 'string' },
            { key: 'lengthBetween', attributes: [1, 100] },
          ],
        },
        {
          key: 'location',
          type: 'input',
          inputProps: {
            label: 'Location',
            placeholder: 'Location',
          },
          validation: [
            { key: 'isType', attributes: 'string' },
            { key: 'lengthBetween', attributes: [1, 100] },
          ],
        },
        ...(updateRiskyFields ? [
          {
            key: 'archived',
            type: 'checkbox',
            inputProps: {
              label: 'Archived',
            },
          },
          {
            key: 'userIsAdministrator',
            type: 'checkbox',
            inputProps: {
              label: 'Has Administrator Access',
            },
          }
        ] as IFormInput[] : [])
      ],
    },
  ],
}), [updateRiskyFields]);
