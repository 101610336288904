import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import { SaveButtonProps } from "../types";

export const StyledCheckbox = styled.div`
  margin-bottom: 16px;
  padding: 0 18px 0 5px;
  width: 100%;
`;

export const StyledInput = styled.div`
  padding: 0 18px;
  height: 78px;
  width: 100%;
`

const StyledSaveButtonRow = styled.div`
  display: flex;
  flex: 1 0 100%;
  justify-content: flex-end;
`;

export const SaveButton: FunctionComponent<SaveButtonProps> = ({ disabled, onClick, label, theme, icon }): ReactElement => (
  <StyledSaveButtonRow>
    <Button disabled={disabled} label={label ?? "Save changes"} icon={icon ?? "ok" } theme={theme ?? "SUCCESS_PRIMARY"} onClick={onClick} />
  </StyledSaveButtonRow>
)
