import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Accent from "../../../../common/Modal/Accent";
import FollowUpForm from "../../../../forms/FollowUpForm/FollowUpForm";
import { getManualAssessmentStatus } from "../../../../utils/surveyResponse";
import { FollowUpFormWrapperProps } from "./types";

const StyledIntro = styled.div`
  margin-bottom: 18px;
`;

const StyledFollowUpFormWrapper = styled.div<{showContactForm: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ showContactForm }) => showContactForm ? '860px' : '540px'};
  padding: 30px 30px 50px;
`;

const FollowUpFormWrapper: FunctionComponent<FollowUpFormWrapperProps> = ({ assessment, initialValues, onCancel, onStatusChange, onSubmit, onToggleContact, openIssues }): ReactElement => {
  const currentStatus = getManualAssessmentStatus(assessment.manualAssessment?.status).toLowerCase();

  return (
    <StyledFollowUpFormWrapper showContactForm={initialValues.sendEmail}>
      <StyledIntro>Change follow-up status from <Accent color="red">{currentStatus}</Accent> to:</StyledIntro>
      <FollowUpForm 
        currentStatus={assessment.manualAssessment?.status}
        initialValues={initialValues}
        isActive={!!assessment.manualAssessment?.meta?.isActive}
        onCancel={onCancel}
        onStatusChange={onStatusChange}
        onSubmit={onSubmit}
        onToggleContact={onToggleContact}
        openIssues={openIssues}
      />
    </StyledFollowUpFormWrapper>
  );
};

export default FollowUpFormWrapper;

