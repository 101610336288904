import React, { FunctionComponent, ReactElement, useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../../common/Box/Box";
import { Table, TBody, Thead, THr, Tr } from "../../../common/DataTable/DataTable";
import {
  ActionsCell,
  AssessmentCell,
  CheckboxCell,
  CheckboxHeaderCell,
  IssueStatusCell,
  LinkCell,
  MultilineTextCell,
  RiskCell,
  TextCell,
  TextHeaderCell,
  UserResolvableCell,
} from "../../../common/DataTable/Cells";
import { IIssue } from "../../../types/resources";
import { useApiRequest, useFilterCallbacks, usePaginationCallbacks, useSortByCallbacks } from "../../../utils/api/hooks";
import { useFetchQuestionResponseIssueListApi } from "../../../utils/api/options/questionResponseIssue";
import { usePageFetchWithFilters } from "../../../utils/dashboard/filters";
import MiniActionButton from "../../../common/Buttons/MiniActionButton";
import Pagination from "../../../common/Pagination/Pagination";
import StyledCardsAndPagination from "../common/StyledCardsAndPagination";
import IssueCards from "./IssueCards";
import IssueFilters from "./IssueFilters";
import { useIssueListAutoFilters, useIssueListFilters } from "../../../utils/table/filters/issueList";
import { useFetchIssueCategoryListings, useFetchSurveyTemplateListings, useFetchUserDepartmentListings, useFetchUserLocationListings } from "../../../utils/filterHelpers";
import useSelect from "../../../common/Forms/useSelect";
import { useModal } from "../../../common/Modal/hooks";
import { canBeUpdated, isResolvableByUser } from "../../../utils/issue";
import ExportIssuesModal from "../modals/ExportIssuesModal";
import UpdateIssuesModal from "../modals/UpdateIssuesModal";
import UpdatesHistoryModal from "../modals/UpdatesHistoryModal";
import { isArchived } from "../../../utils/user";
import { archivedUserTooltip } from "../../../common/Tooltip/utils";
import { GuidancePreviewLink } from "../common/GuidancePreviewLink";
import { getAnswerString } from "../../../utils/questionResponseIssue/questonAnswer";
import { getAnswerValue } from "../../../utils/assessment";

const IssuesList: FunctionComponent = (): ReactElement => {
  const { TABLE } = useContext(ThemeContext);
  const { close: closeExportIssuesModal, data: exportIssuesModalData, isOpen: isExportIssuesModalOpen, open: openExportIssuesModal } = useModal<IIssue[]>();
  const { close: closeUpdateIssuesModal, data: updateIssuesModalData, isOpen: isUpdateIssuesModalOpen, open: openUpdateIssuesModal } = useModal<IIssue[]>();
  const { close: closeUpdatesHistoryModal, data: updatesHistoryModalData, isOpen: isUpdatesHistoryModalOpen, open: openUpdatesHistoryModal } = useModal<IIssue>();

  const fetchIssueListApi = useFetchQuestionResponseIssueListApi();
  const {
    fetch: fetchIssues,
    data: issues,
    _filters,
    _pagination,
    _sortBy,
  } = useApiRequest<never, IIssue[]>(fetchIssueListApi);
  const {
    select: selectIssue,
    selectAll: selectAllIssues,
    selected: selectedIssues,
    areAllItemsSelected: areAllIssuesSelected,
    isSelected: isIssueSelected,
  } = useSelect(issues);
  const { currentPage, totalItemCount, itemsPerPage } = _pagination.current;

  const { setFilters, clearFilters } = useFilterCallbacks(_filters, fetchIssues, _pagination);
  const { sortBy } = useSortByCallbacks(_sortBy, fetchIssues, _pagination);
  usePageFetchWithFilters(setFilters, clearFilters);
  const paginationCallbacks = usePaginationCallbacks(_pagination, fetchIssues);

  const { surveyTemplates } = useFetchSurveyTemplateListings();
  const { userDepartments } = useFetchUserDepartmentListings();
  const { userLocations } = useFetchUserLocationListings();
  const { issueCategories } = useFetchIssueCategoryListings();

  const filterConfig = useIssueListFilters(issueCategories, userDepartments, userLocations, surveyTemplates);
  const filterAutoConfig = useIssueListAutoFilters();

  const handleIssuesUpdate = () => {
    fetchIssues();
    closeExportIssuesModal();
    closeUpdateIssuesModal();
    closeUpdatesHistoryModal();
  };

  return (
    <>
      <IssueFilters
        config={filterConfig}
        quickFiltersConfig={filterAutoConfig}
        filters={_filters}
        onChange={setFilters}
        onClear={clearFilters}
      />
      <StyledCardsAndPagination>
        <IssueCards
          onOpenExportIssuesModal={() => openExportIssuesModal(selectedIssues)}
          onOpenUpdateIssuesModal={() => openUpdateIssuesModal(selectedIssues)}
          selectedIssues={selectedIssues || []}
        />
        <Pagination
          itemName="issue"
          currentPage={currentPage}
          total={totalItemCount || 0}
          itemsPerPage={itemsPerPage}
          onPageChange={paginationCallbacks.goToPage}
          select
          onFilterChange={paginationCallbacks.setItemsPerPage}
        />
      </StyledCardsAndPagination>
      <Box noPadding shadow>
        <Table>
          <Thead>
            <THr>
              <CheckboxHeaderCell id='all-issues' value={areAllIssuesSelected} onChange={selectAllIssues} />
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='userName' onSort={sortBy}>User</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='issueType' onSort={sortBy}>Issue</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='status' onSort={sortBy}>Status</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='riskFactor' onSort={sortBy} align="center">Risk level</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='userResolvable' onSort={sortBy} align="center">User resolvable</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='surveyTemplate' onSort={sortBy} align="center">Assessment</TextHeaderCell>
              <TextHeaderCell>Question</TextHeaderCell>
              <TextHeaderCell>Answer</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='solutionSent' onSort={sortBy} align="center">Guidance given</TextHeaderCell>
              <TextHeaderCell currentSort={_sortBy.current} sortableKey='updatesCount' onSort={sortBy} align="center">Notes</TextHeaderCell>
              <TextHeaderCell align="center">Action</TextHeaderCell>
            </THr>
          </Thead>
          <TBody>
          { issues && issues.map(issue => {
            const isSelected = isIssueSelected(issue.id);
            const user = issue.user
            const userName = `${user.firstName} ${user.lastName}`;
            const answer = getAnswerValue(issue.questionResponse.data);
            const displayAnswer = getAnswerString(answer);
            const notes = `${issue.updatesCount} note${issue.updatesCount !== 1 ? 's' : ''}`;
            const isResolvable = isResolvableByUser(issue)
            return (
              <Tr key={issue.id}>
                <CheckboxCell id={issue.id} value={isSelected} onChange={selectIssue} />
                <TextCell
                  text={userName}
                  icon={isArchived(user) ? 'padlock' : undefined}
                  iconColor={TABLE.PADLOCK}
                  iconTooltip={archivedUserTooltip}
                />
                <TextCell text={issue.issueTemplate.name} />
                <IssueStatusCell isOpen={issue.meta.isOpen} />
                <RiskCell value={issue.meta.riskCategory.data.id} />
                <UserResolvableCell isResolvable={isResolvable} />
                <AssessmentCell 
                  id={issue.questionResponse.surveyResponse.id} 
                  name={issue.questionResponse.surveyResponse.surveyTemplate.name} 
                  date={issue.questionResponse.surveyResponse.createdAt} 
                  center 
                />
                <MultilineTextCell text={issue.questionResponse.surveyQuestion.displayText} />
                <MultilineTextCell text={displayAnswer} />
                {issue.automatedIntervention ? <GuidancePreviewLink issue={issue} /> : <LinkCell id={issue.id} text={"-"} disabled={true} /> }
                <LinkCell id={issue.id} text={notes} onClick={() => openUpdatesHistoryModal(issue)} />
                <ActionsCell>
                    <MiniActionButton
                      id={issue.id}
                      disabled={!canBeUpdated(issue)}
                      name="updateFile"
                      tooltip="Update follow-up status"
                      onClick={(id) => openUpdateIssuesModal([issue])}
                    />
                  </ActionsCell>
              </Tr>
            );
          })}
          </TBody>
        </Table>
      </Box>
      {updateIssuesModalData && (
        <UpdateIssuesModal
          isOpen={isUpdateIssuesModalOpen}
          issues={updateIssuesModalData}
          onCancel={closeUpdateIssuesModal}
          onOk={handleIssuesUpdate}
        />
      )}
      {exportIssuesModalData && (
        <ExportIssuesModal
          isOpen={isExportIssuesModalOpen}
          onCancel={closeExportIssuesModal}
          selectedIssues={exportIssuesModalData}
          pageIssues={issues || []}
          allIssuesCount={_pagination.current.totalItemCount || 0}
        />
      )}
      {updatesHistoryModalData && (
        <UpdatesHistoryModal
          isOpen={isUpdatesHistoryModalOpen}
          issue={updatesHistoryModalData}
          onCancel={closeUpdatesHistoryModal}
          onOk={handleIssuesUpdate}
        />
      )}
    </>
  );
};

export default IssuesList;

