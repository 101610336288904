import React, { FunctionComponent, ReactElement } from "react";
import Button from "../../../common/Buttons/Button";
import ActionCard from "../../../common/ActionCard/ActionCard";
import { IssueCardsProps } from "./types";
import { canBeUpdated } from "../../../utils/issue";
import { CardsRow } from "../../../common/Card/Card";

const IssueCards: FunctionComponent<IssueCardsProps> = ({ selectedIssues, onOpenExportIssuesModal, onOpenUpdateIssuesModal }): ReactElement => {
  const canUpdate = selectedIssues.filter(canBeUpdated).length > 0;

  return (
    <CardsRow>
      <ActionCard
        cardWidth="318px"
        heading="Bulk actions"
        info="You can carry out bulk functions for issues that you have selected."
      >
        <Button disabled={!canUpdate} label="Update issues" theme="SUCCESS_PRIMARY" icon="updateFile" onClick={onOpenUpdateIssuesModal} />
        <Button label="Export" theme="SUCCESS_SECONDARY" icon="download" onClick={onOpenExportIssuesModal} />
      </ActionCard>
    </CardsRow>
  );
};

export default IssueCards;
