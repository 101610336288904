import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import MiniActionButton from "../Buttons/MiniActionButton";
import { PaginationButtonProps } from "./types";

const StyledPaginationButton = styled.div`
  margin-right: 8px;
  user-select: none;

  &:last-child {
    margin-right: 0;
  }
`;

const PaginationButton: FunctionComponent<PaginationButtonProps> = (props): ReactElement => {
  return (
    <StyledPaginationButton>
      <MiniActionButton size={28} {...props} />
    </StyledPaginationButton>
  );
};

export default PaginationButton;
