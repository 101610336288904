import React, { FunctionComponent, ReactElement, useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import Checkbox from "../../common/Forms/Checkbox"
import useFormField from "../../common/Forms/useFormField";
import InputText from "../../common/Forms/InputText";
import Textarea from "../../common/Forms/Textarea";
import { lengthBetweenValidation, requiredValidation, urlValidation, x509CertifcateValidation } from "../../common/Forms/validation";
import { SamlIdentityProviderFormProps } from "./types";
import Icon from "../../common/Icons/Icon";
import { ISamlIdentityProvider } from "../../types/resources";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding-inline: 12px;
  padding-bottom: 12px;
`;

const StyledInput = styled.div`
  height: 74px;
`;

const ValidationInfo = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  padding-inline: 12px;
  margin-top: 12px;
`

const jitTooltip = "Enable just-in-time user provisioning. Users logging in via your SAML identity provider that do not exist in Ergofy or exist but do not have login permissions assigned will be automatically created and/or assigned login permissions."

const SamlIdentityProviderForm: FunctionComponent<SamlIdentityProviderFormProps> = ({ initialValues, onDirty, setIsValid, locked, showJitProvisioning, testLoginUrl }): ReactElement => {
  const { COLORS } = useContext(ThemeContext);
  const [issuerId, setIssuerId, clearIssuerId, validateIssuerId, issuerIdError, issuerIsDirty] = useFormField<string>(initialValues.entityId, [requiredValidation, lengthBetweenValidation(8, 200)]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [ssoUrl, setSsoUrl, clearSsoUrl, validateSsoUrl, ssoUrlError, ssoUrlIsDirty] = useFormField<string>(initialValues.ssoUrl, [requiredValidation, urlValidation]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [certificate, setCertificate, clearCertificate, validateCertificate, certificateError, certificateIsDirty] = useFormField<string>(initialValues.x509Certificate, [requiredValidation, x509CertifcateValidation]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [jitProvisioning, setJitProvisioning, clearJitProvisioning, validateJitProvisioining, jitProvisioningError, jitProvisioningIsDirty] = useFormField<boolean>(initialValues.jitProvisioning); // eslint-disable-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    validateCertificate();
    validateIssuerId();
    validateSsoUrl();
  }, [validateCertificate, validateIssuerId, validateSsoUrl])

  useEffect(() => {
    if (issuerIdError || ssoUrlError || certificateError)
      setIsValid(false)
    else
      setIsValid(true)
  }, [issuerIdError, ssoUrlError, certificateError, setIsValid])

  useEffect(() => {
    const newValues: ISamlIdentityProvider = {
      id: initialValues.id,
      ssoUrl: ssoUrl,
      x509Certificate: certificate,
      entityId: issuerId,
      jitProvisioning: jitProvisioning,
      validated: initialValues.validated,
    }
    onDirty(newValues) // This shouldn't change but adding it to the deps causes an endless loop. The fix is a mission so this hack works for now.
  }, [ssoUrl, certificate, issuerId, jitProvisioning, initialValues.id, initialValues.validated]) // eslint-disable-line react-hooks/exhaustive-deps

  const setIssuerIdCallback = (value) => {
    setIssuerId(value);
  }

  const setSsoUrlCallback = (value) => {
    setSsoUrl(value);
  }

  const setCertificateCallback = (value) => {
    setCertificate(value);
  }

  const setJitProvisioningCallback = (value) => {
    setJitProvisioning(value);
  }

  let validationStatus: ReactElement;

  if (initialValues.id) {
    if (initialValues.validated) {
      if(locked) {
        validationStatus = (
          <>
            <ValidationInfo>
              <Icon name="okCircle" color={COLORS.GREEN} size={36} />
              <p>This configuration has been validated as working.</p>
            </ValidationInfo>
            <ValidationInfo>
              <p>In order to edit this configuration, please enable an alternate (backup) login method first to prevent accidental account lockout.</p>
            </ValidationInfo>
          </>
        )
      } else {
        validationStatus = (
          <ValidationInfo>
            <Icon name="okCircle" color={COLORS.GREEN} size={36} />
            <p>This configuration has been validated as working.</p>
          </ValidationInfo>
        )
      }
    } else {
      validationStatus = (
        <>
          <ValidationInfo>
            <Icon name="info" color={COLORS.RED} size={48} />
            <p>This configuration has not been validated since it was created/last updated. Please perform a test login to validate it.</p>
          </ValidationInfo>
          <ValidationInfo>
            <a href={testLoginUrl || ssoUrl} target="_blank" rel="noopener noreferrer" >Click here to test SSO login.</a>
          </ValidationInfo>
        </>
      )
    }
  } else {
    validationStatus = <></>;
  }

  return (
    <StyledForm>
      <StyledInput>
        <InputText placeholder="The unique identifier for the IdP" label="Issuer ID" required value={issuerId} onChange={setIssuerIdCallback} error={issuerIdError} disabled={locked} />
      </StyledInput>
      <StyledInput>
        <InputText placeholder="SP-initiated sign-on URL" label="SSO URL" required value={ssoUrl} onChange={setSsoUrlCallback} error={ssoUrlError} disabled={locked} />
      </StyledInput>
      <Textarea placeholder="X509 certificate" label="Certificate" required value={certificate} onChange={setCertificateCallback} error={certificateError} disabled={locked} />
      {showJitProvisioning && <Checkbox
        id="enable-jit"
        value={jitProvisioning}
        onChange={() => setJitProvisioningCallback(!jitProvisioning)}
        label="Enable JIT user provisioning"
        tooltip={jitTooltip}
        disabled={locked}
      />}

      { validationStatus }
  </StyledForm>
  );
};

export default SamlIdentityProviderForm;
