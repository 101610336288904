import { useEffect } from 'react';
import { IUserDepartmentListItem, IUserLocationListItem, ISurveyTemplate, IIssueCategory } from '../types/resources';

import { useUserDepartmentListApi, useUserLocationListApi } from './api/options/users';
import { useApiRequest } from './api/hooks';
import { useSurveyTemplateListApi } from './api/options/assessments';
import { useIssueCategoryListApi } from './api/options/questionResponseIssue/issueCategory';


export const useFetchUserDepartmentListings = () => {
  const fetchUserDepartmentListApi = useUserDepartmentListApi();
  const { fetch, data } = useApiRequest<never, IUserDepartmentListItem[]>(fetchUserDepartmentListApi);
  useEffect(() => { fetch(); }, [fetch]);

  return {
    userDepartments: data ?? [],
    fetchUserDepartments: fetch,
  };
};

export const useFetchUserLocationListings = () => {
  const fetchUserLocationListApi = useUserLocationListApi();
  const { fetch, data } = useApiRequest<never, IUserLocationListItem[]>(fetchUserLocationListApi);
  useEffect(() => { fetch(); }, [fetch]);

  return {
    userLocations: data ?? [],
    fetchUserLocations: fetch,
  };
};

export const useFetchSurveyTemplateListings = () => {
  const fetchSurveyTemplateListApi = useSurveyTemplateListApi();
  const { fetch, data } = useApiRequest<never, ISurveyTemplate[]>(fetchSurveyTemplateListApi);
  useEffect(() => { fetch(); }, [fetch]);

  return {
    surveyTemplates: data ?? [],
    fetchSurveyTemplates: fetch,
  };
};

export const useFetchIssueCategoryListings = () => {
  const fetchIssueCategoryListApi = useIssueCategoryListApi();
  const { fetch, data } = useApiRequest<never, IIssueCategory[]>(fetchIssueCategoryListApi);
  useEffect(() => { fetch(); }, [fetch]);

  return {
    issueCategories: data ?? [],
    fetchIssueCategories: fetch,
  };
};
