import { useMemo } from "react";
import { IFilterConfig, IAutoFilterConfig } from "../../../common/Table/Context/TableFilterConfigContext";
import { getCheckboxOptions } from "./options/checkboxOptions";
import { getListedStringOptions } from "./options/listedStringOptions";
import { getSurveyTemplateOptions } from "./options/surveyTemplateOptions";
import { ISurveyTemplate, IUserLocationListItem, IUserDepartmentListItem } from "../../../types/resources";

export const useUserListFilters = (
  departmentOptions: IUserDepartmentListItem[],
  locationOptions: IUserLocationListItem[],
  surveyTemplates: ISurveyTemplate[],
) => useMemo<IFilterConfig[]>(() => [
  {
    label: 'Due For Assessment',
    key: 'assessmentDue',
    type: 'select',
    options: getSurveyTemplateOptions(surveyTemplates),
  },
  {
    label: 'User Name Search',
    key: 'userNameLike',
    type: 'text',
  },
  {
    label: 'Location',
    key: 'location',
    type: 'multiSelect',
    options: getListedStringOptions(locationOptions),
  },
  {
    label: 'Department',
    key: 'department',
    type: 'multiSelect',
    options: getListedStringOptions(departmentOptions),
  },
  {
    label: 'Job Title Search',
    key: 'jobTitleLike',
    type: 'text',
  },
  {
    label: 'User Archived',
    key: 'archived',
    type: 'multiSelect',
    options: getCheckboxOptions('Archived', 'Non-Archived'),
  },
], [departmentOptions, locationOptions, surveyTemplates]);

export const useUserListAutoFilters = (surveyTemplates: ISurveyTemplate[]) => useMemo<IAutoFilterConfig[]>(() => 
  surveyTemplates.map((template) => ({
    label: `Due for ${template.name} Assessment`,
    values: [
      { key: 'assessmentDue', value: template.id },
      { key: 'archived', value: [false] }
    ]
  })), [surveyTemplates]);
