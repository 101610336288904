import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import AccordionItem from "../../../common/Accordion/AccordionItem";
import { getAnswerValue } from "../../../utils/assessment";
import AssessmentQuestionAnswers from "./AssessmentQuestionAnswers";
import { QuestionAnswer, AssessmentQACategoryProps, IQuestionCategory } from "./types";

const StyledAnswers = styled.div`
  background: ${({ theme }) => theme.QA.BG};
  display: flex;
  flex-direction: column;
  padding: 0 54px 14px;
`;

const isMatrix = (questionCategory: IQuestionCategory) => !!questionCategory.answers[0].surveyQuestion.meta.matrixQuestionText

const getAnswers = (questionCategory: IQuestionCategory): QuestionAnswer[] => {
  return isMatrix(questionCategory) 
    ? [{
      id: questionCategory.answers[0].id,
      question: questionCategory.answers[0].surveyQuestion.meta.matrixQuestionText || '',
      answer: questionCategory.answers.map(answer => [answer.surveyQuestion.displayText, `${getAnswerValue(answer.data)}`]) }] 
    : questionCategory.answers.map(answer => ({
      id: answer.id,
      question: answer.surveyQuestion.displayText,
      answer: getAnswerValue(answer.data)
    }))
}

const AssessmentQACategory: FunctionComponent<AssessmentQACategoryProps> = ({ questionCategory }): ReactElement => {
  const answers = getAnswers(questionCategory)

  return (
    <AccordionItem id={questionCategory.id} title={questionCategory.name}>
      <StyledAnswers>
        {answers.map(questionAnswer => <AssessmentQuestionAnswers key={questionAnswer.id} questionAnswer={questionAnswer} />)}
      </StyledAnswers>
    </AccordionItem>
  );
};

export default AssessmentQACategory;
