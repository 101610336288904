import React, { FunctionComponent } from 'react';

import { SubmitUsersUpload } from './SubmitUsersUpload';
import { ClearUsersUpload } from './ClearUsersUpload';
import { ErrorNotification } from './ErrorNotification';

export const UploadUserTableActions: FunctionComponent = () => (
  <>
  <ErrorNotification />
  <SubmitUsersUpload />
  <ClearUsersUpload />
  </>
);
