import React, { FunctionComponent, ReactElement } from "react";
import AssessmentStatus from "../../../../common/AssessmentStatus/AssessmentStatus";
import Card from "../../../../common/Card/Card";
import RiskTag from "../../../../common/RiskTag/RiskTag";
import { SelfAssessmentCardProps } from "../types";
import { StyledCardContent, StyledEntry, StyledEntryLabel, StyledEntryValue, StyledRow, StyledTitle } from "./StyledAssessementCard";

const SelfAssessmentCard: FunctionComponent<SelfAssessmentCardProps> = ({ assessmentDetails }): ReactElement => {
  const { finishedAt, status, surveyTemplate } = assessmentDetails
  const risk = assessmentDetails.meta.riskCategory.data.id

  return (
    <Card width="33%">
      <StyledCardContent>
        <StyledRow>
          <StyledTitle>Online self-assessment</StyledTitle>
        </StyledRow>
        <StyledRow>
          <StyledEntry>
            <StyledEntryLabel>Type</StyledEntryLabel>
            <StyledEntryValue>{surveyTemplate.name}</StyledEntryValue>
          </StyledEntry>
          <StyledEntry>
            <StyledEntryLabel>Status</StyledEntryLabel>
            <AssessmentStatus date={finishedAt} status={status} />
          </StyledEntry>
          <StyledEntry center>
            <StyledEntryLabel>Current Risk</StyledEntryLabel>
            <StyledEntryValue><RiskTag value={risk} /></StyledEntryValue>
          </StyledEntry>
        </StyledRow>
      </StyledCardContent>
    </Card>
  );
};

export default SelfAssessmentCard;
