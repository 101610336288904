import React, { useMemo, useState, FunctionComponent, ReactElement } from "react";
import { debounce } from "lodash";
import InputText from "../Forms/InputText";
import { InputTextFilterProps } from "./types";
import { useEffect } from "react";

type JobTitleFilterKey = "jobTitleLike"

const JobTitleFilter: FunctionComponent<InputTextFilterProps<JobTitleFilterKey>> = ({ filterKey, value, onChange }): ReactElement => {
  const [ jobTitleLike, setJobTitleLike ] = useState(value || '');
  const debuncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleOnChange = (value: string) => {
    setJobTitleLike(value);
    debuncedOnChange([{ key: filterKey, value }]);
  };

  useEffect(() => {
    if(value === '') {
      setJobTitleLike(value);
    }
  }, [value]);

  return (
    <InputText value={jobTitleLike} onChange={handleOnChange} placeholder="User job title" label="Job title" />
  );
};

export default JobTitleFilter;
