import React, { FunctionComponent, ReactElement, useCallback, useMemo, useState } from "react";
import Modal from "../../../../common/Modal/Modal";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useResendAssessmentLinkApi } from "../../../../utils/api/options/assessments";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import { ResendInviteModalProps, ResendInviteModalStep } from "./types";
import { getModalTitle } from "./utils";

const ResendInviteModal: FunctionComponent<ResendInviteModalProps> = ({ assessment, isOpen, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<ResendInviteModalStep>('confirm');
  const resendLinkApi = useResendAssessmentLinkApi();
  const { fetch: resendLink } = useApiRequest(resendLinkApi);
  const title = getModalTitle(step);

  const resendInvite = useCallback(async () => {
    setStep('processing');
    const payload = [{ id: assessment.id }]
    const [result, error] = await resendLink(payload); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }, [assessment, resendLink]);

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} title={title}>
      {step === 'confirm' && <Confirm onOk={resendInvite} onCancel={onCancel}/>}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text="The invitation link was successfuly resent." />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default ResendInviteModal;
