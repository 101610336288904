import React, { FunctionComponent, ReactElement } from "react";
import BarChart from "../Charts/BarChart/BarChart";
import { ActiveAssessmentChartCardProps, BarChartItem } from "../types";
import DashboardCard from "./DashboardCard";

const ActiveAssessmentChartCard: FunctionComponent<ActiveAssessmentChartCardProps> = ({ selfAssessmentRiskSummary }): ReactElement => {
  const { activeHighRisk, activeMediumRisk, activeLowRisk } = selfAssessmentRiskSummary
  const high = activeHighRisk ?? 0
  const medium = activeMediumRisk ?? 0
  const low = activeLowRisk ?? 0
  const total = high + medium + low

  const data: BarChartItem[] = [
    { color: 'red', label: 'High risk', value: high, riskId: 'high', total },
    { color: 'yellow', label: 'Medium risk', value: medium, riskId: 'medium', total },
    { color: 'green', label: 'Low risk', value: low, riskId: 'low', total },
  ]

  return (
    <DashboardCard info="Break down of active assessment" title="Break down of active assessment">
      <BarChart data={data} />
    </DashboardCard>
  );
};

export default ActiveAssessmentChartCard;
