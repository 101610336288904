import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { riskLabels } from "../../utils/risk";
import { RiskTagProps } from "./types";
import { getRiskColor } from "./utils";

const StyledRiskTag = styled.div`
  border: 1px solid ${({ color }) => color};
  border-radius: 13px;
  color: ${({ color }) => color};
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  padding: 2px 6px;
  white-space: nowrap;
`;

const RiskTag: FunctionComponent<RiskTagProps> = ({ value }): ReactElement => {
  return (
    <StyledRiskTag color={getRiskColor(value)}>
      {riskLabels[value]}
    </StyledRiskTag>
  );
};

export default RiskTag;
