import React, { FunctionComponent } from "react";
import Checkbox from "../../Forms/Checkbox";
import { CheckboxProps } from "../../Forms/types";
import Td from "./Td";

const CheckboxHeaderCell: FunctionComponent<CheckboxProps> = ({ id, value, onChange }) => (
  <Td><Checkbox id={id} value={value} onChange={onChange} /></Td>
);

export default CheckboxHeaderCell;
