import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Icon from "../../Icons/Icon";
import { IconCellProps } from "../types";
import Td from "./Td";

const StyledIconCell = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TextCell: FunctionComponent<IconCellProps> = ({ icon, iconColor }) => {
  return (
    <Td>
      <StyledIconCell color={iconColor}>
        { icon && iconColor && <Icon name={icon} color={iconColor} size={24} /> }
      </StyledIconCell>
    </Td>
  );
};

export default TextCell;
