import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { BoxProps } from "./types";

export const StyledBox = styled.div<BoxProps>`
  background-color: ${({ theme }) => theme.BOX.BG};
  border: ${({ border, theme }) => border ? `1px solid ${theme.BOX.BORDER}` : 'none' };
  border-radius: 14px;
  box-shadow: 0px 4px 15px ${({ shadow, theme }) => shadow ? `${theme.BOX.SHADOW}` : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.FONT};
  padding: ${({ noPadding }) => noPadding ? 0 : '18px' };
  min-height: 32px;
  min-width: 32px;
`;

const Box: FunctionComponent<BoxProps> = (props): ReactElement => {
  const { children } = props;

  return <StyledBox {...props}>{ children }</StyledBox>;
};

export default Box;

