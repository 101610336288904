import { useCallback, useEffect, useState } from "react"

export default function useFrontendPagination<ItemType>(items: ItemType[], itemsPerPage: number) {
  const [page, setPage] = useState<number>(1)
  const [pageItems, setPageItems] = useState<ItemType[]>([])

  const handlePageChange = useCallback((newPage: number) => {
    const start = (newPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageItems(items.slice(start, end))
    setPage(newPage)
  }, [items, itemsPerPage])

  useEffect(() => {
    handlePageChange(1)
  }, [handlePageChange])

  return {
    handlePageChange,
    itemsPerPage,
    page,
    pageItems,
  }
}
