import { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { IFilterValue, IFilterCallbacks } from '../api/types';

// TODO: update `key: string` to have some kind of a enumeration (each page should have enumerated filter keys)
export interface IFilterQueryItem {
  key: string;
  value: IFilterValue | IFilterValue[];
}

const FILTER_DELIMITER=';';
const FILTER_KEY_VALUE_DELIMITER = ':';
const FILTER_VALUE_DELIMITER = ',';

const formatFilterValue = (filterValue: IFilterValue | IFilterValue[]): string =>
  Array.isArray(filterValue) ? `[${filterValue.join(FILTER_VALUE_DELIMITER)}]` : `${filterValue}`;

const formatFilter = (filterKey: string, filterValue: IFilterValue | IFilterValue[]): string =>
  `${filterKey}${FILTER_KEY_VALUE_DELIMITER}${formatFilterValue(filterValue)}`;

export const createFilterQueryString = (filters: IFilterQueryItem[]): string => 
  filters.reduce(
    (acc, cur) =>
      acc ? `${acc}${FILTER_DELIMITER}${formatFilter(cur.key, cur.value)}` : formatFilter(cur.key, cur.value),
    ''
  );

const parseValue = (value: string): IFilterValue | IFilterValue[] =>
  value.startsWith('[') && value.endsWith(']') ?
    value.substr(1, value.length - 2).split(FILTER_VALUE_DELIMITER) :
    value.replace(/^\//, '').replace(/\/$/, '');

// TODO: it might throw (and break the app) if invalid string is passed
export const parseFilterQueryString = (queryString: string): { key: string; value: IFilterValue | IFilterValue[] }[] =>
  queryString.split(FILTER_DELIMITER).map((filter) => {
    const [key, value] = filter.split(FILTER_KEY_VALUE_DELIMITER);

    return { key, value: parseValue(value) };
  });

export const usePageFetchWithFilters = (
  setFilters: IFilterCallbacks['setFilters'],
  clearFilters: IFilterCallbacks['clearFilters'],
) => {
  const [{ filters }] = useQueryParams({ filters: StringParam });

  useEffect(() => {
    if (filters) {
      // clear other filters
      clearFilters(undefined, true);
      const parsedFilters = parseFilterQueryString(filters);
      setFilters(parsedFilters);
    } else {
      clearFilters();
    }
  }, [clearFilters, filters, setFilters]);
};
