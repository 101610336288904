import { get } from "lodash";
import React, { FunctionComponent, ReactElement } from "react";
import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Select from "../Forms/Select";
import { Option } from "../Forms/types";
import Icon from "../Icons/Icon";
import { IAutoFilterConfig } from "../Table/Context/TableFilterConfigContext";
import { ChangeFilters, QuickFiltersProps } from "./types";

const StyledQuickFilters = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;

  label {
    font-family: ${({ theme }) => theme.FONT};
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.FORMS.QUICK_FILTERS};
    line-height: 19px;
    margin: 0 0 2px 2px;
  }
`;

function getQuickFiltersOptions(quickFiltersConfig: IAutoFilterConfig[]): Option[] {
  return quickFiltersConfig.map((config, index) => ({ label: config.label, value: index.toString() }))
}

function optionToQuickFilterValue(config: IAutoFilterConfig[], option: Option): ChangeFilters[]  {
  const quickFilterConfigOption = get<IAutoFilterConfig>(config, +option.value)

  const filterValues: ChangeFilters[] = quickFilterConfigOption.values
  return filterValues
}

const QuickFilters: FunctionComponent<QuickFiltersProps> = ({ config, onChange, value }): ReactElement => {
  const { FORMS: { QUICK_FILTERS } } = useContext(ThemeContext);
  const options = getQuickFiltersOptions(config)

  const handleOnChange = (selectedOption: Option[]) => {
    const filter = selectedOption[0] === null ? [] : optionToQuickFilterValue(config, selectedOption[0])
    onChange(selectedOption, filter)
  }

  return (
    <StyledQuickFilters>
      <StyledLabel>
        <Icon name="filter" size={16} color={QUICK_FILTERS} />
        <label>Quick filters</label>
      </StyledLabel>
      <Select
        value={value}
        options={options}
        placeholder="–"
        onChange={handleOnChange}
        isClearable
      />
    </StyledQuickFilters>
    
  );
};

export default QuickFilters;
