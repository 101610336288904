import styled from "styled-components";

const Th = styled.th`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.TABLE.HEADER_COLOR};
  padding: ${({ theme }) => theme.TABLE.CELL_PADDING};
`;

export default Th;
