import React, { useCallback, useContext, FunctionComponent } from "react";
import { ThemeContext } from "styled-components";
import Icon from '../Icons/Icon';
import Error from "./Error";
import Label from "./Label";
import { IconWrapper, InputRow, StyledInput, StyledInputText } from "./StyledInput";
import { InputNumberProps } from "./types";

const InputNumber: FunctionComponent<InputNumberProps> = (props) => {
  const { disabled, error, label, max, min, onChange, value } = props;
  const { FORMS } = useContext(ThemeContext);

  const handleChange = useCallback((event) => {
    onChange(+event.target.value);
  }, [onChange]);

  return (
    <StyledInputText>
      { label && <Label {...props} />}
      <InputRow>
        <StyledInput
          type="number"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          min={min}
          max={max}
        />
        { error &&
          <IconWrapper>
            <Icon name="warning" size={16} color={FORMS.ERROR_COLOR} />
          </IconWrapper>
        }
      </InputRow>
      { error && <Error msg={error} />}
    </StyledInputText>
  );
};

export default InputNumber;
