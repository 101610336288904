import { UserFormData } from "../../../../forms/UserForm/types";
import { IUpdateUser } from "../../../../types/resources";

export function updateUserPayload(id: string, data: UserFormData): IUpdateUser {
  return {
    id,
    firstName: data.firstName,
    lastName: data.lastName,
    emailAddress: data.email,
    jobTitle: data.jobTitle,
    department: data.department,
    location: data.location,
  }
}
