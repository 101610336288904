import React, { FunctionComponent } from "react";
import { formatDateField } from "../../../utils/date";
import { DateCellProps } from "../types";
import Td from "./Td";

const DateCell: FunctionComponent<DateCellProps> = ({ date }) => (
  <Td>
    {formatDateField(date)}
  </Td>
);

export default DateCell;
