import React, { FunctionComponent, ReactElement, useEffect, useMemo } from "react";
import InputText from "../../common/Forms/InputText";
import useFormField from "../../common/Forms/useFormField";
import { emailValidation, lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";
import { StyledInput } from "../../pages/admin/AccountSettings/Tabs/Common";
import { SettingsContactFormProps } from "./types";

const SettingsContactForm: FunctionComponent<SettingsContactFormProps> = ({ initialValues, onChange, onValidate }): ReactElement => {
  const [name, setName, clearName, validateName, nameError] = useFormField<string>(initialValues.name, [lengthBetweenValidation(1, 50)]) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [emailAddress, setEmailAddress, clearEmailAddress, validateEmailAddress, emailAddressError] = useFormField<string>(initialValues.emailAddress, [requiredValidation, emailValidation]) // eslint-disable-line @typescript-eslint/no-unused-vars
  const formKey = initialValues.contactRole.name

  const isValid = useMemo(() => !nameError && !emailAddressError, [emailAddressError, nameError])

  useEffect(() => {
    validateName()
    validateEmailAddress()
  }, [validateEmailAddress, validateName])

  useEffect(() => {
    onChange(formKey, name, emailAddress);
  }, [name, emailAddress, formKey, onChange])

  useEffect(() => {
    onValidate(formKey, !isValid);
  }, [formKey, isValid, onValidate])

  return (
    <>
      <StyledInput>
        <InputText
          error={nameError}
          label="Name"
          placeholder="Name"
          value={name}
          onChange={setName}
        />
      </StyledInput>
      <StyledInput>
        <InputText
          error={emailAddressError}
          label="Email address"
          placeholder="Email address"
          value={emailAddress}
          onChange={setEmailAddress}
        />
      </StyledInput>
    </>
  );
};

export default SettingsContactForm;
