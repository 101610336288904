import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type FollowUpStatusFilterKey = "manualAssessmentStatus";

const FollowUpStatusFilter: FunctionComponent<SelectFilterProps<FollowUpStatusFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Follow-up status"
      options={options}
      placeholder="Follow-up status"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default FollowUpStatusFilter;
