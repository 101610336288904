import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box, { StyledBox } from "../../../../common/Box/Box";
import { AccountSettingsTabProps } from "../types";

const StyledAccountSettingsTab = styled.div`
  align-items: flex-start;
  display: flex;
  margin-left: 16px;
  width: 100%;

  >${StyledBox} {
    flex-direction: column;
    padding-top: 0;
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 90px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
  width: 100%;
`;

const StyledTabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const AccountSettingsTab: FunctionComponent<AccountSettingsTabProps> = ({ children, title }): ReactElement => {
  return (
    <StyledAccountSettingsTab>
      <Box shadow>
        <StyledTitle>{title}</StyledTitle>
        <StyledTabContent>
          {children}
        </StyledTabContent>
      </Box>
    </StyledAccountSettingsTab>
  );
};

export default AccountSettingsTab;
