import React, { useCallback, useContext, FunctionComponent } from "react";
import styled, { ThemeContext } from 'styled-components';
import THEME from '../../theme';
import Icon from '../Icons/Icon';
import { StyledSvg } from "../Icons/Icons";
import { ButtonProps } from "./types";

const { FILL_CLASSNAME } = THEME;

type ButtonColors = {
  BG: string;
  BORDER: string;
  COLOR: string;
  HOVER_BG: string;
  HOVER_BORDER: string;
  HOVER_COLOR: string;
  ACTIVE_BG: string;
  ACTIVE_BORDER: string;
  ACTIVE_COLOR: string;
  DISABLED_BG: string;
  DISABLED_BORDER: string;
  DISABLED_COLOR: string;
  ICON: string;
  HOVER_ICON: string;
  ACTIVE_ICON: string;
  DISABLED_ICON: string;
  SHADOW: string;
}

export const StyledButton = styled.button<{colors: ButtonColors}>`
  align-items: center;
  background-color: ${({ colors }) => colors.BG};
  border: 1px solid ${({ colors }) => colors.BORDER};
  border-radius: 20px;
  color: ${({ colors }) => colors.COLOR};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.FONT};
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  max-width: 166px;
  padding: 10px;
  width: 166px;
  user-select: none;
  white-space: nowrap;
  &:hover:enabled {
    background-color: ${({ colors }) => colors.HOVER_BG};
    border: 1px solid ${({ colors }) => colors.HOVER_BORDER};
    color: ${({ colors }) => colors.HOVER_COLOR};
    box-shadow: 0px 9px 12px -5px ${({ colors }) => colors.SHADOW};
    ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ colors }) => colors.HOVER_ICON};
      }
    }
  }
  &:active:enabled {
    background-color: ${({ colors }) => colors.ACTIVE_BG};
    border: 1px solid ${({ colors }) => colors.ACTIVE_BORDER};
    color: ${({ colors }) => colors.ACTIVE_COLOR};
    ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ colors }) => colors.ACTIVE_ICON};
      }
    }
  }
  &[disabled] {
    background-color: ${({ colors }) => colors.DISABLED_BG};
    border: 1px solid ${({ colors }) => colors.DISABLED_BORDER};
    color: ${({ colors }) => colors.DISABLED_COLOR};
    ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ colors }) => colors.DISABLED_ICON};
      }
    }  
  }
  ${StyledSvg} {
    margin-right: 8px;
  }
`;

const Button: FunctionComponent<ButtonProps> = ({ disabled, icon, label, onClick, theme }) => {
  const { BUTTON_COLORS } = useContext(ThemeContext);
  const colors = BUTTON_COLORS[theme];

  const handleClick = useCallback(() => {
    if(onClick) { onClick(); }
  }, [onClick]);

  return (
    <StyledButton disabled={disabled} colors={colors} onClick={handleClick}>
      { icon && <Icon name={icon} size={24} color={colors.ICON} />}
      { label }
    </StyledButton>
  );
};

export default Button;
