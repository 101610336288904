import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 80%;
  text-align: center;
  margin-top: 30px;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ onCancel, onOk }): ReactElement => {
  return (
    <StyledConfirm>
      <StyledIntro>Are you sure you want to <Accent>resend an invite link</Accent> to this user?</StyledIntro>
      <StyledButtons>
        <Button label="No, thanks" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Yes, resend" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
