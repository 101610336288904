import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Icon from "../../Icons/Icon";
import { StyledSvg } from "../../Icons/Icons";
import Tooltip from "../../Tooltip/Tooltip";
import { TextCellProps } from "../types";
import Td from "./Td";

const StyledTextCell = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ color, theme }) => color || theme.TABLE.COLOR};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  ${StyledSvg} {
    left: -20px;
    position: absolute;
  }
`;

const TextCell: FunctionComponent<TextCellProps> = ({ id, icon, iconColor, iconTooltip, color, text, onClick }) => {
  const handleClick = () => {
    if (onClick && id) {
      onClick(id);
    }
  };

  return (
    <Td>
      <StyledTextCell color={color} onClick={onClick ? handleClick : undefined}>
        { icon && iconColor && (
          iconTooltip 
            ? <Tooltip text={iconTooltip}><Icon name={icon} color={iconColor} size={16} /></Tooltip>
            : <Icon name={icon} color={iconColor} size={16} />
        )}
        {text}
      </StyledTextCell>
    </Td>
  );
};

export default TextCell;
