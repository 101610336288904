import React, { FunctionComponent } from 'react'
import { IUser } from '../../../types/resources'
import { useUnarchiveUserApi } from '../../../utils/api/options/accounts';
import { useApiRequest } from '../../../utils/api/hooks';
import { Button } from 'semantic-ui-react';
import { useOpenConfirmationModal } from '../../../common/modals/ConfirmationModal/ConfirmationModalContext';
import { useOnUserUpdate } from './UserListTable/contexts/UserUpdateContext';

export const UnarchiveUserButton: FunctionComponent<{ value: IUser }> = ({ value }) => {
    const apiConfig = useUnarchiveUserApi(value.id);
    const { fetch: runUpdate } = useApiRequest(apiConfig);
    const openConfirmationModal = useOpenConfirmationModal();
    const onUserUpdate = useOnUserUpdate();

    const unarchiveUser = async () => {
        openConfirmationModal(
            async () => {
              await runUpdate?.()
              onUserUpdate();
            },
            async () => {
            },
            'Are you sure you want to change unarchive this user?'
        );
    }

    if (value.archived) {
        return(
            <Button onClick={unarchiveUser} color={'green'}>
                Unarchive user
           </Button>
        )
    } else {
        return null;
    }
}