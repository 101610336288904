import { MANUAL_ASSESSMENT_STATUS_TYPE } from "../../types/enums";
import { IIssue, IManualAssessmentStatusType } from "../../types/resources";
import { ContactFormData } from "../ContactForm/types";

export type FollowUpFormData = {
  status: IManualAssessmentStatusType | null;
  sendEmail: boolean;
  closeIssues: boolean;
  closeIssuesMessage: string;
  contact: ContactFormData;
}

export type FollowUpFormProps = {
  currentStatus?: IManualAssessmentStatusType;
  initialValues: FollowUpFormData;
  isActive: boolean;
  onCancel: () => void,
  onStatusChange: (status: IManualAssessmentStatusType) => void,
  onSubmit: (data: FollowUpFormData) => void,
  onToggleContact: (showContactForm: boolean) => void;
  openIssues?: IIssue[];
}

export const isManualAssessmentStatusType = (status: any): status is IManualAssessmentStatusType => {
  return typeof status === 'string' && MANUAL_ASSESSMENT_STATUS_TYPE[status]
}
