import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { UpdateIssuesModalStep } from "./types";

const STEP_TITLE_MAP_SINGLE: { [key in UpdateIssuesModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Update an issue',
  processing: 'Updating issue',
};

const STEP_TITLE_MAP_MULTI: { [key in UpdateIssuesModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Update issues',
  processing: 'Updating issues',
};

export function getModalTitle(step: UpdateIssuesModalStep, multi: boolean) {
  return multi ? STEP_TITLE_MAP_MULTI[step] : STEP_TITLE_MAP_SINGLE[step];
}
