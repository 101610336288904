import React, { FunctionComponent } from "react";
import { useFetchQuestionResponseIssueListApi } from "../../../../utils/api/options/questionResponseIssue";
import { useIssueListExportOptions } from "../../../../utils/export/options/issueList";
import ExportModal from "../ExportModal";

import { ExportIssuesModalProps } from "./types";

const ExportAssessmentIssuesModal: FunctionComponent<ExportIssuesModalProps> = ({ isOpen, onCancel, selectedIssues, pageIssues, allIssuesCount }) => {
  const fetchIssueListApi = useFetchQuestionResponseIssueListApi();
  const exportOptions = useIssueListExportOptions();

  return (
    <ExportModal
      allItemsCount={allIssuesCount}
      exportOptions={exportOptions}
      fetchItemListApi={fetchIssueListApi}
      fileName="Issue list"
      isOpen={isOpen}
      onCancel={onCancel}
      pageItems={pageIssues}
      selectedItems={selectedIssues}
    />
  );
};

export default ExportAssessmentIssuesModal;
