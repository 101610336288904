import { useState, useCallback, useMemo, useRef, useEffect, MutableRefObject } from 'react';
import {
  IApiRequestOptions,
  IApiError,
  IPagination,
  IApiResponseMeta,
  ISortByItem,
  IFilterValue,
  IFilters,
  IPaginationCallbacks,
  IFilterCallbacks,
  ISortByCallbacks,
  IApiRequest,
} from './types';

import { getCancelToken, useApiInstanceRequest } from './request';
import { parseSortQuery, getDefaultSuccessMessageByMethod } from './helpers';
import { showMessage } from '../toast';
import { useSession } from '../../pages/auth/AuthContext/SessionContext';

const usePagination = (defaultPagination?: Partial<Pick<IPagination, 'currentPage' | 'itemsPerPage'>>) => useRef<IPagination>({
  currentPage: defaultPagination?.currentPage || 1,
  itemsPerPage: defaultPagination?.itemsPerPage || 10,
});

export const usePaginationCallbacks = (
  _pagination: MutableRefObject<IPagination>,
  fetch: () => Promise<unknown>
): IPaginationCallbacks => {
  const canNextPage = useCallback(() => _pagination.current.currentPage < (_pagination.current.totalPageCount || 0), [_pagination]);
  const canPrevPage = useCallback(() => _pagination.current.currentPage > 1, [_pagination]);

  const nextPage = useCallback(async (skipApi: boolean = false) => {
    if (canNextPage()) {
      _pagination.current.currentPage += 1;

      if (!skipApi) {
        await fetch();
      }
    }
  }, [_pagination, canNextPage, fetch]);
  
  const prevPage = useCallback(async (skipApi: boolean = false) => {
    if (canPrevPage()) {
      _pagination.current.currentPage -= 1;

      if (!skipApi) {
        await fetch();
      }
    }
  }, [_pagination, canPrevPage, fetch]);
  
  const lastPage = useCallback(async (skipApi: boolean = false) => {
    _pagination.current.currentPage = _pagination.current.totalPageCount || 1;

    if (!skipApi) {
      await fetch();
    }
  }, [_pagination, fetch]);

  const firstPage = useCallback(async (skipApi: boolean = false) => {
    _pagination.current.currentPage = 1;

    if (!skipApi) {
      await fetch();
    }
  }, [_pagination, fetch]);

  const goToPage = useCallback(async (page: number, skipApi: boolean = false) => {
    _pagination.current.currentPage = page;

    if (!skipApi) {
      await fetch();
    }
  }, [_pagination, fetch]);

  const setItemsPerPage = useCallback(async (itemsPerPage: number, skipApi: boolean = false) => {
    _pagination.current.itemsPerPage = itemsPerPage;
    _pagination.current.currentPage = 1;

    if (!skipApi) {
      await fetch();
    }
  }, [_pagination, fetch]);

  return { nextPage, prevPage, lastPage, firstPage, canNextPage, canPrevPage, goToPage, setItemsPerPage };
}

const useFilters = (defaultFilters?: IFilters) => useRef<IFilters>(defaultFilters ?? {});

export const useFilterCallbacks = <TResponse = any>(
  _filters: MutableRefObject<IFilters>,
  fetch: () => Promise<TResponse | null>,
  _pagination?: MutableRefObject<IPagination>,
): IFilterCallbacks => {
  const defaultPagination = useMemo(() => ({ current: { currentPage: 1, itemsPerPage: 10 } }), []);
  const { goToPage } = usePaginationCallbacks(_pagination ?? defaultPagination, fetch);

  const setFilters = useCallback(async (
    filters: { key: string; value: IFilterValue | IFilterValue[] | null } | { key: string; value: IFilterValue | IFilterValue[] | null }[],
    skipApi: boolean = false
  ) => {
    (Array.isArray(filters) ? filters : [filters]).forEach((filter) => _filters.current[filter.key] = filter.value);
    goToPage(1, true);

    if (!skipApi) {
      await fetch();
    }
  }, [_filters, fetch, goToPage]);
  const clearFilters = useCallback(async (keys?: string | string[], skipApi: boolean = false) => {
    if (keys) {
      (Array.isArray(keys) ? keys : [keys]).forEach((key) => {
        delete _filters.current[key];
      });
    } else {
      _filters.current = {};
    }
    goToPage(1, true);

    if (!skipApi) {
      await fetch();
    }
  }, [_filters, fetch, goToPage]);

  return { setFilters, clearFilters };
}

const useSortBy = (defaultSort?: ISortByItem[]) => useRef<ISortByItem[]>(defaultSort ?? []);

export const useSortByCallbacks = (
  _sort: MutableRefObject<ISortByItem[]>,
  fetch: () => Promise<unknown>,
  _pagination?: MutableRefObject<IPagination>,
): ISortByCallbacks => {
  const defaultPagination = useMemo(() => ({ current: { currentPage: 1, itemsPerPage: 10 } }), []);
  const { goToPage } = usePaginationCallbacks(_pagination ?? defaultPagination, fetch);

  const sortBy = useCallback(async (sortKeys: ISortByItem | ISortByItem[], skipApi: boolean = false) => {
    _sort.current = Array.isArray(sortKeys) ? sortKeys : [sortKeys];
    await goToPage(1, true);

    if (!skipApi) {
      await fetch();
    }
  }, [_sort, fetch, goToPage]);

  return { sortBy };
};

export const useApiRequest = <TPayload = any, TResponse = any>(
  requestInfo: IApiRequestOptions
) => {
  const apiRequest = useApiInstanceRequest();
  const { handle401 } = useSession();

  const unmounted = useRef(false);
  const cancelToken = useRef(getCancelToken());
  // Cancel any ongoing requests if the component that owns this request is unmounted
  useEffect(() => () => { 
    unmounted.current = true;
    cancelToken.current.cancel();
  }, [unmounted]);
  
  const [data, setData] = useState<TResponse | null>(null);
  const [meta, setMeta] = useState<IApiResponseMeta | null>(null);
  const [error, setError] = useState<IApiError[] | null>(null);
  const [throwable, setThrowable] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  
  // using ref, since we don't need re-rendering when api updates this
  const _pagination = usePagination(requestInfo.defaultPagination);
  const _filters = useFilters(requestInfo.defaultFilter);
  const _sortBy = useSortBy(requestInfo.defaultSort);

  const fetch: IApiRequest<TPayload, TResponse> = useCallback(async (payload): Promise<[TResponse | null, IApiError[] | null]> => {
    setLoading(true);
    try {
      const [data, meta] = await apiRequest<TPayload, TResponse>(
        requestInfo,
        cancelToken.current.token,
        payload,
        _pagination.current,
        _filters.current,
        _sortBy.current,
      ) || null;
            
      if (!unmounted.current) {
        setData(data);
        setMeta(meta);
        setError(null);
      }

      if (requestInfo.method && requestInfo.method !== 'get' && requestInfo.successMessage !== null) {
        showMessage(requestInfo.successMessage || getDefaultSuccessMessageByMethod(requestInfo.method));
      }

      return [data, null];
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (!unmounted.current) {
          setData(null);
          setMeta(null);
        }

        if (requestInfo.passBackErrorStatus?.find((passStatus) => passStatus === error.response.status)) {
          if (!unmounted.current) {
            setError(error.response.data.errors);
          }
          return [null, error.response.data.errors];
        } else if (error.response.status === 401) {
          if (!unmounted.current) {
            handle401(requestInfo.uri);
          }
        } else {
          if (!unmounted.current) {
            setThrowable(error);
          }
        }
      } else {
        // manually checking if request was canceled (no better way?)
        if (!error.__CANCEL__) {
          // Something happened in setting up the request that triggered an Error
          if (!unmounted.current) {
            setThrowable(error);
          }
        }
      }

      return [null, null];
    } finally {
      if (!unmounted.current) {
        setLoading(false);
      }
    }
  }, [_filters, _pagination, _sortBy, apiRequest, requestInfo, handle401]);

  useEffect(() => {
    if (throwable) {
      throw throwable;
    }
  }, [throwable]);

  // manually reset filters and pagination based on api response
  useEffect(() => {
    if (meta?.appliedFilters) {
      _filters.current = meta.appliedFilters;
    }
    if (meta?.pagination) {
      _pagination.current.currentPage = meta.pagination.currentPage;
      _pagination.current.totalItemCount = meta.pagination.totalItems;
      _pagination.current.totalPageCount = meta.pagination.totalPages;
    }
    if (meta?.appliedSort) {
      _sortBy.current = parseSortQuery(meta.appliedSort);
    }
  }, [_filters, _pagination, _sortBy, meta]);
  
  return {
    fetch,
    data,
    error,
    meta,
    loading,
    _pagination,
    _filters,
    _sortBy,
  };
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useApiRequestOptions = (opts: IApiRequestOptions, deps: any[]) => useMemo(() => opts, deps);
