import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button, { StyledButton } from "../../common/Buttons/Button";
import LinkButton from "../../pages/admin/DashboardPage/LinkButton";
import InputText from "../../common/Forms/InputText";
import useFormField from "../../common/Forms/useFormField";
import { emailValidation, lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";
import { ForgotPasswordFormProps } from "./types";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const StyledInput = styled.div`
  height: 74px;
`;

const StyledButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 34px;

  ${StyledButton} {
    width: 166px;
    max-width: none;
  }
`;

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({ initialValues, onSubmit }): ReactElement => {
  const history = useHistory();
  const [email, setEmail, clearEmail, validateEmail, emailError] = useFormField<string>(initialValues.email, [requiredValidation, emailValidation, lengthBetweenValidation(1, 60)]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const handleSubmit = () => {
    const errors = [validateEmail()];

    if (errors.filter(e => !!e).length === 0) {
      onSubmit({ email });
    }
  };

  return (
    <>
      <StyledForm>
        <StyledInput>
          <InputText placeholder="Email" label="Your email" value={email} onChange={(v) => setEmail(v.toLocaleLowerCase().trim())} error={emailError} />
        </StyledInput>
      </StyledForm>
      <StyledButtons>
        <LinkButton label="Go back to login" onClick={() => history.push('/admin/login')} theme="gray" />
        <Button label="Submit" theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
    </>
  )
}

export default ForgotPasswordForm
