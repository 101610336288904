import React, { ReactElement, useContext } from "react";
import Icon from '../Icons/Icon'
import styled, { ThemeContext } from "styled-components";
import { AccordionMenuContext } from "./AccordionMenuContext";
import { AccordionMenuCategoryProps } from "./types";
import { IconName } from "../types";

const StyledAccordionMenuCategory = styled.div`
  display: flex;
  flex: 0 0 50px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const StyledHeading = styled.div<{ isActive: boolean }>`
  background-color: ${({ theme }) => theme.ACCORDION_MENU.BG};
  border-bottom: 1px solid ${({ theme }) => theme.ACCORDION_MENU.BORDER};
  border-left: ${({ isActive, theme }) => isActive ? '2px solid ' + theme.ACCORDION_MENU.ACTIVE : 'none'};
  cursor: pointer;
  display: flex;
  flex: 0 0 50px;
  flex-direction: column;
  font-size: 14;
  font-weight: 600;
  justify-content: center;
  padding-left: ${({ isActive }) => isActive ? '8px' : '10px'};
  user-select: none;
`

const StyledToggle = styled.div`
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 13px;
  user-select: none;
`;

const AccordionMenuCategory = <TabType extends string>(props: AccordionMenuCategoryProps<TabType>): ReactElement<AccordionMenuCategoryProps<TabType>> => {
  const { children, label, tab } = props
  const { activeTab, expanded, onTabChange, toggle } = useContext(AccordionMenuContext)
  const { ACCORDION_MENU } = useContext(ThemeContext)
  const isActive = !!tab && tab === activeTab
  const isExpanded = expanded.indexOf(label) > -1
  const iconName: IconName = isExpanded ? 'arrowDown' : 'arrowRight'

  const handleTabChange = () => {
    if(tab) {
      onTabChange(tab)
    } else {
      toggle(label)
    }
  }

  return (
    <StyledAccordionMenuCategory>
      <StyledHeading onClick={handleTabChange} isActive={isActive}>{label}</StyledHeading>
      {!tab && <StyledToggle onClick={handleTabChange}><Icon size={24} color={ACCORDION_MENU.TOGGLE} name={iconName} /></StyledToggle>}
      {isExpanded && children}
    </StyledAccordionMenuCategory>
  );
};

export default AccordionMenuCategory;
