import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import Box, { StyledBox } from "../../../../common/Box/Box";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import Icon from "../../../../common/Icons/Icon";
import Accent from "../../../../common/Modal/Accent";
import { ASSESSMENT_ICON_MAP } from "../../../../types/enums";
import { SelectAssessmentTypeProps } from "./types";

const StyledSelectAssessmentType = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  width: 860px;
`;

const StyledIntro = styled.div`
  line-height: 24px;
  max-width: 50%;
  text-align: center;
  margin: 16px 0 36px;
`;

const StyledInfo = styled.div`
  text-align: center;
  height: 55px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const StyledAssessmentCards = styled.div`
  display: flex;
  margin-bottom: 34px;
  height: 306px;

  ${StyledBox} {
    border: 1px solid ${({ theme }) => theme.ASSESMENT_CARD.BORDER};
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
    margin-right: 16px;
    padding: 18px 18px 34px;
  }

  ${StyledBox}:last-child {
    margin-right: 0;
  }

  ${StyledButton} {
    margin-top: 8px;
  }
`;

const StyledAssessmentType = styled.div`
  color: ${({ theme }) => theme.ASSESMENT_CARD.TYPE};
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  height: 57px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 18px;
`;

const SelectAssessmentType: FunctionComponent<SelectAssessmentTypeProps> = ({ onCancel, onOk, users, surveyTemplates, newUser }): ReactElement => {
  const { ASSESMENT_CARD } = useContext(ThemeContext);

  const userText = users.length > 1 
    ? 'selected users' 
    : newUser 
      ? 'them' 
      : 'this user'


  return (
    <StyledSelectAssessmentType>
      <StyledIntro>
        {newUser && <div>New user successfuly added.</div> }
        <div>Would you like to invite <Accent>{ userText }</Accent> for assessment?</div>
      </StyledIntro>
      <StyledAssessmentCards>
        {surveyTemplates.map((survey) => (
          <Box key={survey.id}>
            <Icon name={ASSESSMENT_ICON_MAP[survey.assessmentType]} size={70} color={ASSESMENT_CARD.ICON} />
            <StyledAssessmentType>{survey.name}</StyledAssessmentType>
            <StyledInfo>I want to invite user to {survey.name} assessement</StyledInfo>
            <Button icon="userAdd" label="Invite" theme="SUCCESS_PRIMARY" onClick={() => onOk(survey)} />
          </Box>
        ))}
      </StyledAssessmentCards>
      <Button label="No, thanks" theme="SUCCESS_SECONDARY" onClick={onCancel} />
    </StyledSelectAssessmentType>
  );
};

export default SelectAssessmentType;
