import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import { LinkButtonProps, LinkButtonTheme } from "./types";

const StyledLinkButton = styled.div<{ buttonColor: string }>`
  background: transparent;
  color: ${({ buttonColor }) => buttonColor};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;
  text-decoration: underline;
`;

const getColor = (theme: DefaultTheme, buttonTheme: LinkButtonTheme): string => {
  const { DASHBOARD: { LINK_BUTTON: { GRAY, GREEN, RED } } } = theme
  switch(buttonTheme) {
    case 'gray': return GRAY;
    case 'green': return GREEN;
    case 'red': return RED;
  }
}

const LinkButton: FunctionComponent<LinkButtonProps> = ({ label, onClick, theme }): ReactElement => {
  const appTheme = useContext(ThemeContext)
  const buttonColor = getColor(appTheme, theme)

  return (
    <StyledLinkButton buttonColor={buttonColor} onClick={onClick}>{label}</StyledLinkButton>
  );
};

export default LinkButton;
