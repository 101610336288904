import React, { FunctionComponent } from "react";
import { AppLayout } from "../../shared/AppLayout";
import BackendNavbar from "./BackendNavbar";

export const ΒackendLayout: FunctionComponent = ({ children }) => {
  return (
    <AppLayout topMenu={BackendNavbar}>
      {children}
    </AppLayout>
  );
};
