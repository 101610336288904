import { useApiRequestOptions } from "../../hooks";

export const useFetchRemindersApi = () => useApiRequestOptions({
  uri: 'reminder_schedules'
}, []);

export const useUpdateRemindersApi = () => useApiRequestOptions({
  uri: 'reminder_schedules',
  method: 'patch',
  passBackErrorStatus: [422],
  successMessage: 'Reminder schedule settings saved',
  serialization: {
    type: 'reminder_schedule',
    options: {
      attributes: ['enabled', 'interval', 'intervalType', 'maxReminders', 'reminderType'],
    }
  }
}, [])