import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Box, { StyledBox } from "../../../../common/Box/Box";
import Icon from '../../../../common/Icons/Icon';
import Tooltip from "../../../../common/Tooltip/Tooltip";
import { DashboardCardProps } from "../types";

const StyledCard = styled.div`
  padding: 0 9px;

  > ${StyledBox} {
    padding: 15px 18px 18px;
  }
`;

const StyledDashobardCardContent = styled.div`
  width: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const StyledHeading = styled.div`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

const DashboardCard: FunctionComponent<DashboardCardProps> = ({ children, info, title }): ReactElement => {
  const { INFO_BADGE } = useContext(ThemeContext)

  return (
    <StyledCard>
      <Box shadow>
        <StyledDashobardCardContent>
          <StyledHeader>
            <StyledHeading>{title}</StyledHeading>
            <Tooltip text={info} icon trigger="click">
              <Icon name="info" size={16} color={INFO_BADGE.COLOR} />
            </Tooltip>
          </StyledHeader>
          {children}
        </StyledDashobardCardContent>
      </Box>
    </StyledCard>
  );
};

export default DashboardCard;
