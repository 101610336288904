import React, { FunctionComponent, useMemo, useCallback, useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { useTableFilterConfig } from '../../Context/TableFilterConfigContext';
import { useTableFilter } from '../../Context/TableFilterContext';
import { useApiRequest, useFilterCallbacks } from '../../../../utils/api/hooks';
import { IApiRequestOptions, IFilterValue } from '../../../../utils/api/types';

export const AutoFilter: FunctionComponent = () => {
  const { filterAutoConfig } = useTableFilterConfig();
  const { filter, setFilters, clearFilters } = useTableFilter();

  const [value, setValue] = useState('');
  const [apiRequestOptions, setApiRequestOptions] = useState<IApiRequestOptions>({ uri: '' });
  const [cachedValues, setCachedValues] = useState<{ value: IFilterValue | IFilterValue[]; key: string }[]>([]);

  const { fetch: fetchAutoFilterEndpoint, data: autoFilterEndpointData, _filters: _autoFilterFilters } = useApiRequest(apiRequestOptions);
  const { clearFilters: clearAutoFilterFilters } = useFilterCallbacks(_autoFilterFilters, fetchAutoFilterEndpoint);

  const options = useMemo(() => filterAutoConfig?.map((item) => ({ text: item.label, value: item.label })) ?? [], [filterAutoConfig]);

  const selectAppropriateFilters = useCallback((filters: { value: IFilterValue | IFilterValue[] | null; key: string }[]) => {
    // clear all filters before setting values for concrete auto-filter (skip api on clearing filters)
    clearFilters(undefined, true);
    setFilters([...filters, ...cachedValues]);
  }, [cachedValues, clearFilters, setFilters]);

  const handleOnChange = useCallback((e: unknown, { value }) => {
    const selectedItem = filterAutoConfig?.find((item) => item.label === value);

    if (selectedItem) {
      if (selectedItem.apiOptions) {
        setApiRequestOptions({ ...selectedItem.apiOptions });
        setCachedValues(selectedItem.values);
      } else {
        setCachedValues([]);
        selectAppropriateFilters(selectedItem.values);
      }

      setValue(selectedItem.label);
    }
  }, [filterAutoConfig, selectAppropriateFilters]);

  // fetch auto-filter url once apiRequestOptions changes
  useEffect(() => {
    if (apiRequestOptions.uri) {
      clearAutoFilterFilters(undefined);
    }
  }, [apiRequestOptions, clearAutoFilterFilters]);

  // when fetch of the auto-filter changes, use the filters from given response and set it to main fetch
  useEffect(() => {
    if (autoFilterEndpointData && Object.keys(_autoFilterFilters.current).length) {
      selectAppropriateFilters(Object.keys(_autoFilterFilters.current).map((key: string) => ({
        key,
        value: _autoFilterFilters.current[key] === null ? '' : (
          !Array.isArray(_autoFilterFilters.current[key]) ?
            _autoFilterFilters.current[key] :
            (_autoFilterFilters.current[key] as IFilterValue[]).map((value) => value ?? '')
        )
      })));
    }
  }, [_autoFilterFilters.current, autoFilterEndpointData, selectAppropriateFilters]);  // eslint-disable-line

  // reset selected value when filters are changed
  useEffect(() => {
    setValue('');
  }, [filter?.current]); // eslint-disable-line

  return (
    <Dropdown
      text="Quick filters"
      icon="filter"
      labeled
      button
      floating
      pointing="top"
      direction="left"
      selectOnBlur={false}
      className="green icon"
      options={options}
      value={value}
      onChange={handleOnChange}
    />
  )
};