import React, { createContext, useContext, ReactNode, ReactElement, FunctionComponent } from 'react';
import { TableHeaderCellProps } from 'semantic-ui-react';

export interface ITableConfig<TData extends { id: string }> {
  header: string;
  Cell: FunctionComponent<{ value: TData }>;
  sortKey?: string | string[];
  cellProps?: TableHeaderCellProps;
}

export interface ITableConfigProps<TData extends { id: string }> {
  config: ITableConfig<TData>[];
}

export const TableConfigContext = createContext<ITableConfig<any>[]>([]);

export const TableConfigProvider = <TConfig extends { id: string }>(
  { config, children }: ITableConfigProps<TConfig> & { children: ReactNode }
): ReactElement => (
  <TableConfigContext.Provider value={config}>
    {children}
  </TableConfigContext.Provider>
);

export const useTableConfig = () => useContext(TableConfigContext);
