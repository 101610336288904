import React, { useCallback, useContext, FunctionComponent } from "react";
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';
import THEME from '../../theme';
import Icon from "../Icons/Icon";
import { StyledSvg } from "../Icons/Icons";
import { MiniActionButtonProps } from "./types";
import Tooltip from "../Tooltip/Tooltip";

const { FILL_CLASSNAME } = THEME;

const StyledMiniActionButton = styled.div<{size?: number}>`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.DISABLED};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 0;
  height: ${({ size }) => size || 30}px;
  width: ${({ size }) => size || 30}px;
  &.is-active {
    background-color: ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.BG};
    border: 1px solid ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.ACTIVE};
    cursor: pointer;
  }
  &.is-active:hover {
    background-color: ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.HOVER_ACTIVE};
    border-color: ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.HOVER_ACTIVE};
    box-shadow: 0px 9px 12px -5px ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.SHADOW};
    ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ theme }) => theme.MINI_ACTION_BUTTON_COLORS.ACTIVE_ICON};
      }
    }
  }
`;

const MiniActionButton: FunctionComponent<MiniActionButtonProps> = ({ id, name, onClick, disabled, size, tooltip }) => {
  const { MINI_ACTION_BUTTON_COLORS } = useContext(ThemeContext);

  const color = disabled ? MINI_ACTION_BUTTON_COLORS.DISABLED : MINI_ACTION_BUTTON_COLORS.ACTIVE;

  const handleClick = useCallback(() => {
    if(!disabled && onClick) {
      onClick(id ?? null);
    }
  }, [id, disabled, onClick]);

  return (
    <Tooltip text={tooltip}>
      <StyledMiniActionButton
        className={disabled ? '' : 'is-active'}
        onClick={handleClick}
        size={size}
      >
          <Icon color={color} name={name} size={20} />
      </StyledMiniActionButton>
    </Tooltip>
  );
};

export default MiniActionButton;
