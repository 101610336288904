import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Td from "./Td";

const StyledActionsCell = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActionsCell: FunctionComponent = ({ children }) => (
  <Td>
    <StyledActionsCell>
      { children }
    </StyledActionsCell>
  </Td>
);

export default ActionsCell;
