import React, { FunctionComponent, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Loader } from 'semantic-ui-react';
import { useSession } from '../AuthContext/SessionContext';

export const Logout: FunctionComponent<{ redirectTo: string }> = ({ redirectTo }) => {
    const { state, logout } = useSession();

    useEffect(() => {
        logout();
    }, [logout]);

    if (state.type === 'loggedOut') {
        return <Redirect to={redirectTo} />;
    }

    return <Loader active />;
};
