import { IIssueRiskId } from "../../types/resources";
import { ISSUE_RISK_ID } from "../../types/enums";

export const getRiskText = (riskId: IIssueRiskId): string => {
  if (riskId === ISSUE_RISK_ID.none) {
    return 'No Risk';
  } else if (riskId === ISSUE_RISK_ID.low) {
    return 'Low Risk';
  } else if (riskId === ISSUE_RISK_ID.medium) {
    return 'Medium Risk';
  } else {
    return 'High Risk';
  }
};

export const getRiskColor = (riskId: IIssueRiskId, isIssueOpen: boolean) => {
  if (!isIssueOpen) {
    return;
  } else if (riskId === ISSUE_RISK_ID.high) {
    return 'red';
  } else if (riskId === ISSUE_RISK_ID.medium) {
    return 'orange';
  } else if (riskId === ISSUE_RISK_ID.low) {
    return 'green';
  } else {
    return;
  }
}

export const getRiskEnumerationNumber = (riskId: IIssueRiskId): number => {
  if (riskId === ISSUE_RISK_ID.high) {
    return 0;
  } else if (riskId === ISSUE_RISK_ID.medium) {
    return 1;
  } else if (riskId === ISSUE_RISK_ID.low) {
    return 2;
  } else {
    return 3;
  }
}