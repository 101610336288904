import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Accent from "../../../../common/Modal/Accent";
import CloseIssuesForm from "../../../../forms/CloseIssuesForm/CloseIssuesForm";
import { CloseIssuesFormWrapperProps } from "./types";

const StyledContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  padding: 30px 95px 50px;
`;

const StyledIntro = styled.div`
  margin-bottom: 30px;
`;

const CloseIssuesFormWrapper: FunctionComponent<CloseIssuesFormWrapperProps> = ({ items, initialValues, onCancel, onOk }): ReactElement => {
  return (
    <StyledContactFormWrapper>
      <StyledIntro>Are you sure you want to <Accent color="red">close</Accent> all issues?</StyledIntro>
      <CloseIssuesForm
        cancelLabel="No, go back"
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={onOk}
        submitLabel="Yes, close"
      />
    </StyledContactFormWrapper>
  );
};

export default CloseIssuesFormWrapper;
