import React, { FunctionComponent, useMemo } from 'react';
import { Table } from '../../../../common/Table';

import { useMockPagination } from '../../../../utils/table/pagination';
import { useUploadedUserListTable } from '../../../../utils/table/columns/simpleUserList';

import { ITableContextProps } from '../../../../common/Table/Context';
import { ICreateUserWithId } from '../types';

import { UploadUserTableActions } from './actions';
import { UserUploadDataProvider } from './contexts/UserUploadDataContext';
import { UserUploadHasErrorProvider } from './contexts/UserUploadHasErrorContext';
import { UserUpdateProvider } from './contexts/UserUpdateContext';

export interface IUploadUserTableProps {
  duplicatedEmails: string[];
  users: ICreateUserWithId[];
  setUsers: (users: ICreateUserWithId[]) => void;
  isFileValid: boolean;
}

export const UploadUserTable: FunctionComponent<IUploadUserTableProps> = ({ duplicatedEmails, users, setUsers, isFileValid }) => {
  const tableConfig = useUploadedUserListTable(duplicatedEmails);

  const { data, fetch, pagination } = useMockPagination<ICreateUserWithId>(users);

  const tableContextProps = useMemo<ITableContextProps<ICreateUserWithId>>(() => ({
    config: tableConfig,
    data,
    fetch,
    pagination,
    loading: false,
  }), [data, fetch, pagination, tableConfig]);

  // data provider must go before has error context (depends on data)
  return (
      <UserUploadDataProvider users={users} setUsers={setUsers} duplicatedEmails={duplicatedEmails} isFileValid={isFileValid}>
        <UserUploadHasErrorProvider>
          <UserUpdateProvider>
            <Table<ICreateUserWithId>
              contextProps={tableContextProps}
              ActionButtons={UploadUserTableActions}
              height="40vh"
              paginateable
              sticky
            />
          </UserUpdateProvider>
        </UserUploadHasErrorProvider>
      </UserUploadDataProvider>
  );
};
