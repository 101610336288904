import { useApiRequestOptions } from "../../hooks";
import { IApiRequestOptions } from "../../types";

export const useFetchQuestionResponseIssueApi = (issueId: string) => useApiRequestOptions(({
  uri: `/question_response_issues/${issueId}`,
  query: { 
    includes: [
      'user',
      'resolvingUser',
      'issueTemplate.issueCategory',
      'automatedIntervention.automatedInterventionTemplate',
      'questionResponse.surveyQuestion.questionGroup',
      'questionResponse.surveyResponse.surveyTemplate',
      'administratorNotes.administrator',
      'administratorClosures.administrator',
      'administratorUserArchivalClosures.administrator',
      'administratorReopenings.administrator',
      'accountPolicyAutoClosures',
      'userGuidanceEscalations',
      'userGuidanceAutoResolutions',
      'userGuidanceResolutions'
    ],
  },
}), [issueId]);

export const useFetchQuestionResponseIssueListApi = () => useApiRequestOptions(({
  uri: `/question_response_issues`,
  query: {
    includes: [
      'user',
      'issueTemplate.issueCategory',
      'automatedIntervention.automatedInterventionTemplate',
      'questionResponse.surveyQuestion.questionGroup',
      'questionResponse.surveyResponse.surveyTemplate',
    ],
  },
}), []);

export const getRequiringActionFilterApi = (): IApiRequestOptions => ({
  uri: 'question_response_issues/requiring_action',
});

