import React, { FunctionComponent, useEffect } from 'react';
import { IUser } from '../../../types/resources';
import { useFetchUserListApi } from '../../../utils/api/options/accounts';
import { useApiRequest } from '../../../utils/api/hooks';
import { UserListTable } from './UserListTable';

export const UserList: FunctionComponent = () => {
  const fetchUserListApi = useFetchUserListApi();

  const {
    fetch: fetchUsers,
    loading,
    data: users,
    _filters,
  } = useApiRequest<never, IUser[]>(fetchUserListApi);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <UserListTable
      users={users}
      fetchUsers={fetchUsers}
      loading={loading}
      _filters={_filters}
    />
  );
};
