import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import { weeksSince } from "../../../../utils/date";
import { ROUTES } from "../../../../routing/AdminRouter";
import { AddOrUpdateUsersCardProps, DashboardCardBoxTheme } from "../types";
import DashboardCard from "./DashboardCard";
import DashboardCardBox from "./DashboardCardBox";
import CardStats from "./CardStats";
import { createFilterQueryString, IFilterQueryItem } from "../../../../utils/dashboard/filters";
import { IEmailDeliveryStatus } from "../../../../types/resources";

const StyledFixButton = styled.div`
  ${StyledButton} {
    height: 26px;
    width: 75px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  > div {
    &:first-child {
      margin-right: 18px;
    }
    flex: 1;
  }
`;

const StyledEmailStatsRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  height: 26px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 19px;
`;

const AddOrUpdateUsersCard: FunctionComponent<AddOrUpdateUsersCardProps> = ({ onOpenAddNewUserModal, usersSummary }): ReactElement => {
  const history = useHistory();
  const { lastUserUpdated, totalActiveUsers, totalHardBouncingEmails } = usersSummary
  const invalidEmailsCount = totalHardBouncingEmails ?? 0
  const weeksSinceLastUpdate = lastUserUpdated ? weeksSince(lastUserUpdated) : 0
  const invalidEmailAddressTheme: DashboardCardBoxTheme = invalidEmailsCount === 0 ? 'default' : 'error'
  const weeksSinceLastUpdateTheme: DashboardCardBoxTheme = weeksSinceLastUpdate === 0 ? 'default' : weeksSinceLastUpdate <= 2 ? 'warning' : 'error'

  const viewUsersWithInvalidEmails = () => {
    const emailDeliveryStatus: IEmailDeliveryStatus[] = ['hard_bouncing']
    const filters: IFilterQueryItem[] = [
      {
        key: 'emailDeliveryStatus',
        value: emailDeliveryStatus
      },
      {
        key: 'archived',
        value: [false]
      }
    ];

    history.push(`${ROUTES.usersList}?filters=${createFilterQueryString(filters)}`)
  }

  return (
    <DashboardCard info="Add/update users" title="Add/update users">
      <DashboardCardBox theme={invalidEmailAddressTheme} title="Invalid email address">
        <StyledEmailStatsRow>
          <CardStats label={`user${invalidEmailsCount !== 1 ? 's' : ''}`} value={invalidEmailsCount} />
          {invalidEmailsCount > 0 && (
            <StyledFixButton><Button label="Fix" theme="DANGER_SECONDARY" onClick={viewUsersWithInvalidEmails} /></StyledFixButton>
          )}
        </StyledEmailStatsRow>
      </DashboardCardBox>
      <StyledRow>
        <DashboardCardBox icon="clock" theme={weeksSinceLastUpdateTheme} title="Last user update">
          <CardStats label={`week${weeksSinceLastUpdate !== 1 ? 's' : ''} ago`} value={weeksSinceLastUpdate} />
        </DashboardCardBox>
        <DashboardCardBox icon="users" title="Total active users">
          <CardStats value={totalActiveUsers ?? 0} />
        </DashboardCardBox>
      </StyledRow>
      <StyledButtons>
        <Button icon="userAdd" label="Add new" theme="SUCCESS_PRIMARY" onClick={onOpenAddNewUserModal} />
        <Button icon="upload" label="Upload users list" theme="SUCCESS_SECONDARY" onClick={() => history.push(ROUTES.usersUpload)} />
      </StyledButtons>
    </DashboardCard>
  );
};

export default AddOrUpdateUsersCard;
