import React, { FunctionComponent } from 'react';
import { ModalProvider as NewModalProvider } from 'styled-react-modal';
import ModalOverlay from "../../../common/Modal/ModalOverlay";
import { ConfirmationModalProvider } from '../../../common/modals/ConfirmationModal/ConfirmationModalContext';
import { UserFormModalProvider } from './UserFormModal/UserFormModalContext';

export const ModalProvider: FunctionComponent = ({ children }) => (
  <NewModalProvider backgroundComponent={ModalOverlay}>
    <ConfirmationModalProvider>
      <UserFormModalProvider>
        {children}
      </UserFormModalProvider>
    </ConfirmationModalProvider>
  </NewModalProvider>
);
