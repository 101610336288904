import React, { FunctionComponent, useMemo, MutableRefObject, useEffect, useState, useCallback } from 'react';
import { Table } from '../../../../common/Table';
import { IDataUpload } from '../../../../types/resources';
import { ITableContextProps } from '../../../../common/Table/Context';

import { useDataUploadsTable } from '../../../../utils/table/columns/dataUploads';
import { IPagination, IApiRequest } from '../../../../utils/api/types';
import { Message } from 'semantic-ui-react';


export interface IDataUploadsTableProps {
  data: IDataUpload[] | null;
  fetch: IApiRequest<never, IDataUpload[]>;
  loading: boolean;
  pagination: MutableRefObject<IPagination>;
  showMessage: 'active' | 'active_resolved' | null;
}

export const DataUploadsTable: FunctionComponent<IDataUploadsTableProps> = ({ data, fetch, loading, pagination, showMessage }) => {
  const [messageHidden, setMessageHidden] = useState<boolean>(false);

  useEffect(() => {
    if (showMessage) {
      setMessageHidden(false);
    }
  }, [showMessage, setMessageHidden]);

  const handleDismiss = useCallback(() => {
    setMessageHidden(true);
  }, [setMessageHidden]);

  const tableConfig = useDataUploadsTable();

  const tableContextProps = useMemo<ITableContextProps<IDataUpload>>(() => ({
    config: tableConfig,
    data,
    fetch,
    loading,
    pagination,
  }), [data, fetch, loading, pagination, tableConfig]);

  return (
    <>
    {showMessage && !messageHidden && (
      <Message info onDismiss={showMessage === 'active_resolved' ? handleDismiss : undefined}>
        {showMessage === 'active' ? 
          'An upload is currently in progress...' :
          'Upload complete!'
        }
      </Message>
    )}
    <Table<IDataUpload>
      contextProps={tableContextProps}
      height="40vh"
      paginateable
      sticky
    />
    </>
  );
};
