import { useApiRequestOptions } from "../../hooks";

export const useFetchContactsApi = () => useApiRequestOptions({
  uri: 'contacts',
  query: {
    includes: ['contactRole'],
  }
}, []);

export const useUpdateContactsApi = () => useApiRequestOptions({
  uri: 'contacts',
  method: 'patch',
  passBackErrorStatus: [422],
  successMessage: 'Contact settings saved',
  serialization: {
    type: 'contact',
    options: {
      attributes: ['name', 'emailAddress'],
    },
  },
}, []);
