import { useCallback, useMemo } from 'react';
import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import { IApiRequestOptions, IApiResponseMeta, IPagination, ISortByItem, IApiHeaders } from "./types";

import { getApiUrl } from '../url';
import { createQueryObject, serializePayload, deserializeResponse, extractResponseMeta } from './helpers';
import { useActiveApplication } from '../../pages/auth/AuthContext/ApplicationContext';

const CSRF_HEADER = 'X-CSRF-TOKEN';
const CSRF_STORAGE_KEY = "ERGOFY_API_CSRF_TOKEN"

export const getCancelToken = () => axios.CancelToken.source();

const getCsrfTokenFromResponse = (response: AxiosResponse) => {
  return response.headers[CSRF_HEADER.toLocaleLowerCase()] as string;
};

const readCsrfTokenFromStorage = () => {
  return localStorage.getItem(CSRF_STORAGE_KEY) || '';
}

const setCsrfTokenInStorage = (token: string) => {
  return localStorage.setItem(CSRF_STORAGE_KEY, token);
}

export const useApiInstanceRequest = () => {
  const { applicationKey } = useActiveApplication();

  const api = useMemo(() => axios.create({
    withCredentials: true,
    baseURL: `${getApiUrl(applicationKey)}/api/v1/`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
    },
  }), [applicationKey]);

  return useCallback(async <TPayload = any, TResponse = any>(
    requestInfo: IApiRequestOptions,
    cancelToken: CancelToken,
    payload?: TPayload | null,
    pagination?: IPagination,
    filters?: any,
    sort?: ISortByItem[],
  ): Promise<[TResponse | null, IApiResponseMeta | null, IApiHeaders | null]> => {

    try {
      const headers = {};
      headers[CSRF_HEADER] = readCsrfTokenFromStorage();
      const response = await api.request<TResponse>({
        url: requestInfo.uri,
        baseURL: requestInfo.noAppKeyInBaseUrl ? `${getApiUrl(null)}/api/v1` : undefined,
        method: requestInfo.method,
        params: createQueryObject(requestInfo, pagination, filters, sort),
        data: serializePayload<TPayload>(requestInfo, payload),
        headers: headers,
        cancelToken,
      });

      const newCsrfToken = getCsrfTokenFromResponse(response);
      if (newCsrfToken) {
        setCsrfTokenInStorage(newCsrfToken);
      }

      const deserializedData = await deserializeResponse<TResponse>(response);
      const metaData = extractResponseMeta(response);

      return [deserializedData, metaData, response?.headers ?? null];
    } catch (error) {
      const castError = error as AxiosError;
      const newCsrfToken = getCsrfTokenFromResponse(castError.response as AxiosResponse);
      if (newCsrfToken) {
        setCsrfTokenInStorage(newCsrfToken);
      }

      throw error;
    }
  }, [api]);
};
