import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import Modal from "../../../../common/Modal/Modal";
import { FollowUpFormData } from "../../../../forms/FollowUpForm/types";
import { getFollowUpFormDataInitialValues } from "../../../../forms/FollowUpForm/utils";
import { IIssue, IManualAssessment, IManualAssessmentStatusType } from "../../../../types/resources";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useFetchAssessmentIssuesApi } from "../../../../utils/api/options/assessments";
import { useCreateManualAssessmentApi, useUpdateManualAssessmentApi } from "../../../../utils/api/options/assessments/manualAssessment";
import { useSendEmailApi } from "../../../../utils/api/options/outboundEmails";
import { useCreateAdministratorClosureApi } from "../../../../utils/api/options/questionResponseIssue/administratorNotes";
import { decorateIssues, isOpen as isIssueOpen } from "../../../../utils/issue";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import FollowUpFormWrapper from "./FollowUpFormWrapper";
import { FollowUpModalProps, FollowUpModalStep } from "./types";
import { getModalTitle } from "./utils";

const FollowUpModal: FunctionComponent<FollowUpModalProps> = ({ assessment, isOpen, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<FollowUpModalStep>();
  const [formData, setFormData] = useState<FollowUpFormData>(getFollowUpFormDataInitialValues());
  const title = getModalTitle(step || 'form');
  const createNewAssessmentApi = useCreateManualAssessmentApi(assessment.id);
  const updateAssessmentApi = useUpdateManualAssessmentApi(assessment.id);
  const assessmentIssuesApi = useFetchAssessmentIssuesApi(assessment.id);
  const sendEmailApi = useSendEmailApi();
  const addClosureApi = useCreateAdministratorClosureApi();
  const user = assessment.user

  const { fetch: createNewAssessment } = useApiRequest<any, IManualAssessment>(createNewAssessmentApi);
  const { fetch: updateAssessment } = useApiRequest<any, IManualAssessment>(updateAssessmentApi);
  const { fetch: sendEmail } = useApiRequest(sendEmailApi);
  const { fetch: fetchAssessmentIssues, data: assessmentIssues } = useApiRequest<never, IIssue[]>(assessmentIssuesApi);
  const { fetch: addClosure } = useApiRequest(addClosureApi);

  const handleToggleContact = (showContactForm: boolean) => {
    setFormData({ ...formData, sendEmail: showContactForm })
  }

  const handleStatusChange = (status: IManualAssessmentStatusType) => {
    setFormData({ ...formData, status })
  }

  const handleSubmit = (data: FollowUpFormData) => {
    setFormData(data)
    setStep('confirm')
  }

  const closeIssuesRequest = async () => {
    const results = await Promise.all(openIssues.map((issue) => addClosure({
      note: formData.closeIssuesMessage,
      questionResponseIssue: { id: issue.id }
    })));

    const errors = results.filter(result => !!result[1]);

    if(errors.length > 0) {
      throw new Error('Close issues error')
    }
  }

  const followUpRequest = async (): Promise<string> => {
    let result: IManualAssessment | null;
    let error: any;

    const manualAssessment = assessment.manualAssessment

    if (!manualAssessment || !manualAssessment.meta.isActive) {
      [result, error] = await createNewAssessment({ status: formData.status });
    } else {
      [result, error] = await updateAssessment({ id: manualAssessment.id, status: formData.status });
    }

    if (error || !result) {
      throw new Error('Update follow up error')
    }

    return result.id
  }

  const sendEmailRequest = async (id: string) => {
    const [emailResult, error] = await sendEmail({ // eslint-disable-line @typescript-eslint/no-unused-vars
      subject: formData.contact.subject,
      message: formData.contact.message,
      recipient: { id: assessment.user.id, type: 'user' }, relatedItem: { id, type: 'manualAssessment' }
    });

    if (error) {
      throw new Error('Send email error')
    }
  }

  const changeStatus = async () => {
    setStep('processing');
    const { closeIssues, sendEmail } = formData

    try {
      if (closeIssues) {
        await closeIssuesRequest()
      }

      const assessmentId = await followUpRequest()

      if (sendEmail) {
        await sendEmailRequest(assessmentId)
      }
    } catch (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }

  useEffect(() => {
    fetchAssessmentIssues()
    setStep('form')
  }, [fetchAssessmentIssues])

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  const openIssues = useMemo(() => {
    const issues = decorateIssues(assessmentIssues || [], user)
    return issues.filter(isIssueOpen)
  }, [user, assessmentIssues])

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} title={title} step={step}>
      { step === 'form' && (
        <FollowUpFormWrapper 
          assessment={assessment}
          openIssues={openIssues}
          initialValues={formData}
          onCancel={onCancel}
          onStatusChange={handleStatusChange}
          onSubmit={handleSubmit}
          onToggleContact={handleToggleContact}
        />
      )}
      { step === 'confirm' && formData.status && (
        <Confirm
          closeIssues={formData.closeIssues}
          onOk={changeStatus}
          onCancel={() => setStep('form')}
          sendEmail={formData.sendEmail}
          status={formData.status}
        />
      )}
      { step === 'processing' && <Processing />}
      { step === 'success' && <Success text="Status successfuly changed" onOk={onOk} />}
      { step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default FollowUpModal;
