import React, { FunctionComponent } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AdminRouter, BASE_PATH as ADCON_BASE_PATH, ROUTES as AdminRoutes } from './AdminRouter';
import { BackendRouter, BASE_PATH as BACKEND_BASE_PATH } from './BackendRouter';
import THEME from '../theme';

const RedirectWithQuery = ({ from, to }) => {
  const location = useLocation();
  const newPath = `${to}${location.search}`;
  
  return (
    <Redirect exact from={from} to={newPath} />
  );
};

export const Router: FunctionComponent = () => (
  <BrowserRouter>
    <ThemeProvider theme={THEME}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Redirect from="/forgot-password" to={AdminRoutes.forgotPassword}/>
          <RedirectWithQuery from="/password-reset" to={AdminRoutes.passwordReset}/>
          <Route path={`/${ADCON_BASE_PATH}*`}><AdminRouter/></Route>
          <Route path={`/${BACKEND_BASE_PATH}*`}><BackendRouter/></Route>
          <Route path="*"><Redirect to={`/${ADCON_BASE_PATH}`}/></Route>
        </Switch>
      </QueryParamProvider>
    </ThemeProvider>
  </BrowserRouter>
);
