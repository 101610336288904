import { useApiRequestOptions } from "../../hooks";

export const useFetchUserListApi = (defaultUserName?: string) => useApiRequestOptions({
  uri: 'users',
  query: {
    includes: [
      'lastCompletedSurveyResponse.surveyTemplate',
      'activeSurveyResponse.surveyTemplate'
    ],
  },
  defaultFilter: defaultUserName ? { userNameLike: defaultUserName } :  { archived: [false] },
}, [defaultUserName]);

export const useCreateUserApi = () => useApiRequestOptions({
  uri: 'users',
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: 'User created',
  serialization: {
    type: 'user',
    options: {
      attributes: ['firstName', 'lastName', 'emailAddress', 'jobTitle', 'department', 'location'],
    },
  },
}, []);

export const useUpdateUserApi = (id: string) => useApiRequestOptions({
  uri: `users/${id}`,
  method: 'patch',
  passBackErrorStatus: [422],
  successMessage: 'User updated',
  serialization: {
    type: 'user',
    options: {
      attributes: ['firstName', 'lastName', 'emailAddress', 'jobTitle', 'department', 'location', 'archived', 'userIsAdministrator'],
    },
  },
}, [id]);

export const useCreateUserUploadApi = () => useApiRequestOptions({
  uri: 'user_list_uploads',
  method: 'post',
  successMessage: null,
  serialization: {
    type: 'data_upload',
    options: {
      attributes: ['name', 'description', 'createNewUsers', 'updateExistingUsers'],
    }
  }
}, []);

export const useAddRowsToUserUploadApi = (id: string) => useApiRequestOptions({
  uri: `user_list_uploads/${id}/rows`,
  method: 'post',
  successMessage: null,
  serialization: {
    type: 'user',
    options: {
      attributes: ['firstName', 'lastName', 'emailAddress', 'jobTitle', 'department', 'location'],
    },
  },
}, [id]);

export const useUserDepartmentListApi = () => useApiRequestOptions({
  uri: '/users/departments',
  defaultPagination: {
    itemsPerPage: 1000,
  },
}, []);

export const useUserLocationListApi = () => useApiRequestOptions({
  uri: '/users/locations',
  defaultPagination: {
    itemsPerPage: 1000,
  },
}, []);
