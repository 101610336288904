import React, { FunctionComponent } from "react";
import { AppLayout } from "../../shared/AppLayout";
import AdminNavbar from "./AdminNavbar";

export const AdminLayout: FunctionComponent = ({ children }) => {
  return (
    <AppLayout topMenu={AdminNavbar}>
      {children}
    </AppLayout>
  );
};
