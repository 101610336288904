import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { createFilterQueryString } from "../../../../../utils/dashboard/filters";
import { ROUTES } from "../../../../../routing/AdminRouter";
import LinkButton from "../../LinkButton";
import { BarChartProps } from "../../types";
import Bar from "./Bar";
import { IIssueRiskId } from "../../../../../types/resources";

const StyledRow = styled.div`
  margin-bottom: 12px;

  :last-child {
    margin-bottom: 3px;
  }
`;

const StyledMetaRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledValue = styled.div`
  color: ${({ theme }) => theme.CHARTS.VALUE};
  font-size: 14px;
  font-weight: 600;
`;

const percent = (value: number, total: number): number => total !== 0 
  ? Math.round((value / total) * 100)
  : 0;

const BarChart: FunctionComponent<BarChartProps> = ({ data }): ReactElement => {
  const history = useHistory();

  const viewFilteredAssessments = (riskId: IIssueRiskId) => {
    const filters = [{ key: 'currentRisk', value: [riskId] }];
    history.push(`${ROUTES.assessmentsList}?filters=${createFilterQueryString(filters)}`)
  }

  return (
    <div>
      { data.map(item => (
        <StyledRow key={item.riskId}>
          <Bar barTheme={item.color} value={percent(item.value, item.total)} />
          <StyledMetaRow>
            <LinkButton label={item.label} theme="gray" onClick={() => viewFilteredAssessments(item.riskId)} />
            <StyledValue>{item.value}</StyledValue>
          </StyledMetaRow>
        </StyledRow>
      ))}
    </div>
  );
};

export default BarChart;
