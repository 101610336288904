import React, { ReactNode, ReactElement } from 'react';
import { ITableSelectionProps, TableSelectionProvider } from './TableSelectionContext';
import { ITablePaginationProps, TablePaginationProvider } from './TablePaginationContext';
import { ITableFilterProps, TableFilterProvider } from './TableFilterContext';
import { ITableDataProps, TableDataProvider } from './TableDataContext';
import { ITableApiInfoProps, TableApiInfoProvider } from './TableApiInfoContext';
import { ITableConfigProps, TableConfigProvider } from './TableConfigContext';
import { ITableSortByProps, TableSortByProvider } from './TableSortByContext';
import { ITableFilterConfigProps, TableFilterConfigProvider } from './TableFilterConfigContext';
import { ITableFlags, TableFlagProvider } from './TableFlagsContext';

export interface ITableContextProps<TData extends { id: string }> extends ITableFlags,
  ITableSelectionProps,
  ITableSortByProps,
  ITablePaginationProps,
  ITableFilterProps,
  ITableFilterConfigProps,
  ITableDataProps<TData>,
  ITableConfigProps<TData>,
  ITableApiInfoProps<TData> {}

// Component merging together all of the table related providers in one place
// Order of the providers matter, because some of the providers are depending on the other context already set
export const TableProvider = <TData extends { id: string }>({
  fetch,
  loading,
  config,
  data,
  filterConfig,
  filterAutoConfig,
  filter,
  pagination,
  sort,
  selectable,
  sortable,
  filterable,
  paginateable,
  children,
}: ITableContextProps<TData> & { children: ReactNode }
): ReactElement => (
  <TableConfigProvider config={config}>
    <TableFilterConfigProvider filterConfig={filterConfig} filterAutoConfig={filterAutoConfig}>
      <TableDataProvider data={data}>
        <TableApiInfoProvider fetch={fetch} loading={loading}>
          <TableFlagProvider selectable={selectable} sortable={sortable} filterable={filterable} paginateable={paginateable}>
            <TableSelectionProvider>
              <TablePaginationProvider pagination={pagination}>
                <TableSortByProvider sort={sort}>
                  <TableFilterProvider filter={filter}>
                    {children}
                  </TableFilterProvider>
                </TableSortByProvider>
              </TablePaginationProvider>
            </TableSelectionProvider>
          </TableFlagProvider>
        </TableApiInfoProvider>
      </TableDataProvider>
    </TableFilterConfigProvider>
  </TableConfigProvider>
);
