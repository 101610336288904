import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { IApiError422 } from '../../../utils/api/types';
import { useApiRequest } from '../../../utils/api/hooks';
import { useSubmitForgottenPasswordApi } from '../../../utils/api/options/auth';
import { useNonLayoutTitle } from '../../../layouts/NonAuth/NonLayoutTitleContext';
import ForgotPasswordForm from '../../../forms/ForgotPasswordForm/ForgotPasswordForm';
import { getForgotPasswordFormDataInitialValues } from '../../../forms/ForgotPasswordForm/utils';
import { ForgotPasswordFormData } from '../../../forms/ForgotPasswordForm/types';
import styled from 'styled-components';
import LinkButton from '../../admin/DashboardPage/LinkButton';
import { useHistory } from 'react-router';
import { NonAuthLayout } from '../../../layouts/NonAuth';

const StyledMessage = styled.div`
  color: ${({ theme }) => theme.MODAL.HEADER};
  margin-bottom: 24px;
`;

const StyledLinkButton = styled.div`
  div {
    margin-right: 0;
  }
`;

export const ForgotPasswordPage: FunctionComponent = () => {
  const history = useHistory();
  const [linkSent, setLinkSent] = useState(false);

  const { setTitle } = useNonLayoutTitle();
  useEffect(() => {
    setTitle('Reset Password');
  }, [setTitle]);

  const loginApi = useSubmitForgottenPasswordApi();
  const { fetch: submitForgottenPassword } = useApiRequest<{ emailAddress: string }>(loginApi);

  const handleForgotPasswordClick = useCallback(async (data: ForgotPasswordFormData) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [result, error] = await submitForgottenPassword({ emailAddress: data.email.toLowerCase().trim() });

    if (error) {
      return error as IApiError422[];
    }

    setLinkSent(true);
  }, [submitForgottenPassword]);

  return (
    <NonAuthLayout title="Forgot password">
      { linkSent ? (
        <>
          <StyledMessage>
            A password reset link has been emailed to you.
          </StyledMessage>
          <StyledLinkButton>
            <LinkButton label="Go back to login" onClick={() => history.push('/admin/login')} theme="gray" />
          </StyledLinkButton>
        </>
        ) : <ForgotPasswordForm initialValues={getForgotPasswordFormDataInitialValues()} onSubmit={handleForgotPasswordClick} />
      }
    </NonAuthLayout>
  )
};
