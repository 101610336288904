import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type IssueStatusFilterKey = 'active'

const IssueStatusFilter: FunctionComponent<SelectFilterProps<IssueStatusFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Issue status"
      options={options}
      placeholder="All statuses"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey))}
      isClearable
    />
  );
};

export default IssueStatusFilter;
