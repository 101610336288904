import { useState, useCallback } from 'react';
import { ISurveyResponseStatusType, IManualAssessmentStatusType } from '../types/resources';
import { formatDateField } from './date';
import { SURVEY_RESPONSE_STATUS_TYPE, MANUAL_ASSESSMENT_STATUS_TYPE } from '../types/enums';
import { useOpenConfirmationModal } from '../common/modals/ConfirmationModal/ConfirmationModalContext';
import { useResendAssessmentLinkApi, useCancelAssessmentApi } from './api/options/assessments';
import { useApiRequest } from './api/hooks';

export const getSurveyResponseStatus = (status?: ISurveyResponseStatusType, finishedAt?: string | null): string => {
  if (status === SURVEY_RESPONSE_STATUS_TYPE.not_started) {
    return 'Invited';
  } else if (status === SURVEY_RESPONSE_STATUS_TYPE.cancelled || status === SURVEY_RESPONSE_STATUS_TYPE.cancelled_by_user_archival) {
    return 'Cancelled';
  } else if (status === SURVEY_RESPONSE_STATUS_TYPE.in_progress) {
    return 'In Progress';
  } else if (status === SURVEY_RESPONSE_STATUS_TYPE.finished) {
    return `Completed${finishedAt ? ' - ' + formatDateField(finishedAt) : ''}`;
  } else {
    return '';
  }
};

export const getManualAssessmentStatus = (status?: IManualAssessmentStatusType, updatedAt?: string | null): string => {
  if (status === MANUAL_ASSESSMENT_STATUS_TYPE.invited) {
    return 'Invited';
  } else if (status === MANUAL_ASSESSMENT_STATUS_TYPE.scheduled) {
    return 'Scheduled';
  } else if (status === MANUAL_ASSESSMENT_STATUS_TYPE.cancelled) {
    return 'Cancelled';
  } else if (status === MANUAL_ASSESSMENT_STATUS_TYPE.declined) {
    return 'Declined';
  } else if (status === MANUAL_ASSESSMENT_STATUS_TYPE.completed) {
    return `Completed${updatedAt ? ' - ' + formatDateField(updatedAt) : ''}`;
  } else {
    return 'No follow up scheduled';
  }
};

export const useResendCancelCallbacks = (data: { id: string }[], onAssessmentUpdate?: () => void) => {
  const openConfirmationModal = useOpenConfirmationModal();

  const resendLinkApi = useResendAssessmentLinkApi();
  const cancelAssessmentApi = useCancelAssessmentApi();

  const { fetch: resendLink } = useApiRequest(resendLinkApi);
  const { fetch: cancelAssessment } = useApiRequest(cancelAssessmentApi);

  const [inviteLinkResent, setInviteLinkResent] = useState(false);

  const handleLinkResend = useCallback(async () => {
    setInviteLinkResent(true);
    await resendLink(data);

    onAssessmentUpdate?.();
  }, [data, onAssessmentUpdate, resendLink]);
  const handleCancel = useCallback(async () => {
    await cancelAssessment(data);

    onAssessmentUpdate?.();
  }, [cancelAssessment, data, onAssessmentUpdate]);
  const handleCancelPress = useCallback(() => {
    openConfirmationModal(handleCancel, undefined, undefined, 'red');
  }, [handleCancel, openConfirmationModal]);

  return {
    handleLinkResend,
    handleCancel: handleCancelPress,
    inviteLinkResent,
  };
};
