import React, { FunctionComponent, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import THEME from '../theme';

import AccountSettings from '../pages/admin/AccountSettings/AccountSettings';
import Assessment from '../pages/admin/Assessment/Assessment';
import AssessmentsList from '../pages/admin/AssessmentsList/AssessmentsList';
import DashboardPage from '../pages/admin/DashboardPage/DashboardPage';
import UsersList from '../pages/admin/UsersList/UsersList';
import IssuesList from '../pages/admin/IssuesList/IssuesList';
import { ForgotPasswordPage } from '../pages/auth/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/auth/ResetPasswordPage';

import 'react-toastify/dist/ReactToastify.css';

import { applicationPathTo, ApplicationRoute, ApplicationRouter } from './ApplicationRouter';

import { ListAccountsModalProvider } from '../pages/auth/AuthenticationPage/ListAccountsModal/ListAccountModalContext';
import { ILoginComponent, LoginPage } from '../pages/auth/LoginPage';
import { NotFound } from '../pages/admin/NotFound';
import { UserUpload } from '../pages/admin/UserUpload';
import { AdminLayout } from '../layouts/admin/Default';
import { ListAccountsModal } from '../pages/auth/AuthenticationPage/ListAccountsModal';
import { IApplication } from '../pages/auth/AuthContext/ApplicationContext';
import { Logout } from '../pages/auth/Logout';
import { SamlRelayPage } from '../pages/auth/SamlRelayPage';
import { ModalProvider } from '../pages/admin/modals';

export const BASE_PATH = 'admin';
const pathTo = applicationPathTo(BASE_PATH);
export const ROUTES = {
  login: pathTo('login'),
  samlRelay: pathTo("saml/relay"),
  logout: pathTo('logout'),
  root: pathTo(null),
  home: pathTo('/'),
  dashboard: pathTo('/dashboard'),
  usersList: pathTo('users'),
  usersUpload: pathTo('users/upload'),
  assessmentsList: pathTo('assessments'),
  assessmentDetails: pathTo('assessments/:id'),
  issuessList: pathTo('issues'),
  accountSettings: pathTo('settings'),
  forgotPassword: pathTo('forgot-password'),
  passwordReset: pathTo('password-reset'),
  notFound: pathTo('*'),
};

export const getAssessmentDetailsRoute = (id: string) => ROUTES.assessmentDetails.replace(':id', id)

const ChooseLoginUser: ILoginComponent = ({ users, loginToAccount }) => {
  return (
    <ListAccountsModal
      accounts={users}
      loginToAccount={loginToAccount}
      isOpen
    />
  );
};

export const AdminRouter: FunctionComponent = () => {
  const adminApplication = useMemo<IApplication>(() => ({
    applicationKey: BASE_PATH,
    homePath: ROUTES.home,
    loginPath: ROUTES.login,
  }), []);

  return (
    <ApplicationRouter application={adminApplication} defaultLayout={AdminLayout}>
      <ThemeProvider theme={THEME}>
        <ModalProvider>
          <ListAccountsModalProvider>
            <Switch>
              <ApplicationRoute path={ROUTES.login} layout={null} unauthenticated>
                <LoginPage homeUri={ROUTES.home} loginComponent={ChooseLoginUser}/>
              </ApplicationRoute>

              <ApplicationRoute path={ROUTES.samlRelay} layout={null} unauthenticated component={SamlRelayPage} />

              <ApplicationRoute path={ROUTES.logout} layout={null} unauthenticated>
                <Logout redirectTo={ROUTES.login}/>
              </ApplicationRoute>

              <ApplicationRoute path={[ROUTES.home, ROUTES.root]} exact>
                <Redirect to={ROUTES.dashboard}/>
              </ApplicationRoute>
              <ApplicationRoute path={ROUTES.dashboard} component={DashboardPage}/>
              <ApplicationRoute path={ROUTES.usersUpload} component={UserUpload}/>
              <ApplicationRoute path={ROUTES.usersList} component={UsersList}/>
              <ApplicationRoute path={ROUTES.assessmentDetails} component={Assessment}/>
              <ApplicationRoute path={ROUTES.assessmentsList} component={AssessmentsList}/>
              <ApplicationRoute path={ROUTES.issuessList} component={IssuesList}/>
              <ApplicationRoute path={ROUTES.accountSettings} component={AccountSettings}/>

              <ApplicationRoute path={ROUTES.forgotPassword} layout={null} component={ForgotPasswordPage} unauthenticated/>
              <ApplicationRoute path={ROUTES.passwordReset} layout={null} component={ResetPasswordPage} unauthenticated/>

              <ApplicationRoute path={ROUTES.notFound} component={NotFound} layout={null} unauthenticated/>
            </Switch>
          </ListAccountsModalProvider>
        </ModalProvider>
      </ThemeProvider>
    </ApplicationRouter>
  );
};
