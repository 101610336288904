import React, { FunctionComponent } from 'react';
import { TableHeaderCellProps, Table } from 'semantic-ui-react';
import styled from 'styled-components';

export interface ITableHeaderCellWrapperProps extends TableHeaderCellProps {
  sticky?: boolean;
  sortable?: boolean;
}

const StickyHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: 0;
  z-index: 2;
  &&&&&:hover {
    background-color: #f2f2f2;
  }
`;

const NonSortableHeaderCell = styled(Table.Header)`
  &&&&& {
    cursor: auto;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const NonSortableStickyHeaderCell = styled(StickyHeaderCell)`
  &&&&& {
    cursor: auto;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

// TODO: think about, and fix more than 200 components issue 
export const TableHeaderCellWrapper: FunctionComponent<ITableHeaderCellWrapperProps> = ({ sticky, sortable, children, ...cellProps }) => {
  const HeaderCell = !sortable ?
    (sticky ? NonSortableStickyHeaderCell : NonSortableHeaderCell) :
    (sticky ? StickyHeaderCell : Table.HeaderCell);

  return (
    <HeaderCell {...cellProps}>
      {children}
    </HeaderCell>
  );
};
