import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { WarningProps } from "./types";

const StyledWarning = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 28px 24px 0;
  width: 540px;

  p {
    text-align: center;
    margin-bottom: 18px;
    padding: 0 16px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 50px 0;

  > * {
    margin-right: 16px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Warning: FunctionComponent<WarningProps> = ({ onCancel, onOk }): ReactElement => {
  return (
    <StyledWarning>
      <p>You have selected users who <Accent color="red">already have an active assessment</Accent>.</p>
      <p>Do you want to invite <Accent>only those users</Accent> from the selected group who <Accent>do not have an active assessment</Accent>?</p>
      <StyledButtons>
        <Button label="No, go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button label="Yes, invite" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledWarning>
  );
};

export default Warning;
