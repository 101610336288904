import React, { useContext, FunctionComponent, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { HeaderCellProps, TextAlignment } from "../types";
import Th from "./Th";
import Icon from "../../Icons/Icon";
import Tooltip from "../../Tooltip/Tooltip";

const StyledTextHeaderCell = styled.div<{ align?: TextAlignment; sortable: boolean}>`
  align-items: center;
  cursor: ${({ sortable }) => sortable ? 'pointer' : 'normal'};
  display: flex;
  justify-content: ${({ align }) => align || 'flex-start'};
  user-select: none;
`;

const StyledSort = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  div {
    display: flex;
  }
`;

const Sort: FunctionComponent<{ direction?: 'asc' | 'desc' }> = ({ direction }) => {
  const { TABLE } = useContext(ThemeContext);
  const { SORT_ACTIVE, SORT_INACTIVE } = TABLE;

  return (
    <StyledSort>
      <div><Icon name="triangleBigUp" color={direction === 'asc' ? SORT_ACTIVE : SORT_INACTIVE} size={11} /></div>
      <div><Icon name="triangleBigDown" color={direction === 'desc' ? SORT_ACTIVE : SORT_INACTIVE} size={11} /></div>
    </StyledSort>
  );
};

const TextHeaderCell: FunctionComponent<HeaderCellProps> = (props) => {
  const { align, children, currentSort, onSort, sortableKey, tooltip } = props;

  const currentSortDirection = useMemo(() => {
    return currentSort?.find(sort => sort.key === sortableKey)?.direction
  }, [currentSort, sortableKey])

  const handleSort = () => {
    if(onSort && sortableKey) {
      onSort({ key: sortableKey, direction: currentSortDirection === 'asc' ? 'desc' : 'asc' });
    }
  }

  return (
    <Th>
      
      {tooltip ? (
        <Tooltip text={tooltip}>
          <StyledTextHeaderCell 
            align={align} 
            onClick={sortableKey ? handleSort : undefined}
            sortable={!!sortableKey && !!onSort}
          >
            { children }{ sortableKey && onSort && <Sort direction={currentSortDirection} /> }
          </StyledTextHeaderCell>
        </Tooltip>
      ) : (
        <StyledTextHeaderCell 
          align={align} 
          onClick={sortableKey ? handleSort : undefined}
          sortable={!!sortableKey && !!onSort}
        >
          { children }{ sortableKey && onSort && <Sort direction={currentSortDirection} /> }
        </StyledTextHeaderCell>
      )}

    </Th>
  );
};

export default TextHeaderCell;
