import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 60%;
  text-align: center;
  margin-top: 30px;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ assessmentsCount, sendEmail, status, onCancel, onOk }): ReactElement => {
  const email = sendEmail ? ` and send the user${assessmentsCount !== 1 ? 's' : ''} an email` : ''

  return (
    <StyledConfirm>
      <StyledIntro>Are you sure you want to change status of <Accent color="red">{assessmentsCount} high risk assessments</Accent> to <Accent>{status}</Accent>{email}?</StyledIntro>
      <StyledButtons>
        <Button label="Go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Change" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
