import { useApiRequestOptions, } from '../../hooks';
import { IApiRequestOptions } from '../../types';

export const useFetchAssessmentListApi = () => useApiRequestOptions({
  uri: 'survey_responses',
  query: {
    includes: [
      'user',
      'surveyTemplate',
      'manualAssessment'
    ]
  }
}, []);

export const useFetchUserAssessmentListApi = (userId: string) => useApiRequestOptions({
  uri: 'survey_responses',
  query: {
    includes: [
      'user',
      'surveyTemplate',
      'manualAssessment'
    ]
  },
  defaultPagination: {
    itemsPerPage: 5
  },
  defaultFilter: {
    userId
  }
}, []);

export const useInviteUserToAssessmentApi = () => useApiRequestOptions({
  uri: 'survey_responses',
  method: 'post',
  successMessage: null,  // disabled because it might get called in a loop
  passBackErrorStatus: [422],
  serialization: {
    type: 'surveyResponse',
    options: {
      attributes: ['surveyTemplate', 'user'],
      surveyTemplate: {
        ref: 'id',
        type: 'surveyTemplate',
        included: false,
      },
      user: {
        ref: 'id',
        type: 'user',
        included: false,
      },
    },
  }
}, []);

export const useBulkInviteApi = (surveyTemplateId: string) => useApiRequestOptions({
  uri: `survey_responses/invite_users_due/${surveyTemplateId}`,
  method: 'post',
  successMessage: 'Users invited',
}, []);

export const useSurveyTemplateListApi = () => useApiRequestOptions({
  uri: 'survey_templates',
}, []);

export const useFetchAssessmentDetailsApi = (surveyResponseId: string) => useApiRequestOptions({
  uri: `survey_responses/${surveyResponseId}`,
  query: {
    includes: [
      'user',
      'surveyTemplate',
      'manualAssessment'
    ],
  },
}, [surveyResponseId]);

export const useFetchAssessmentIssuesApi = (surveyResponseId: string) => useApiRequestOptions({
  uri: `survey_responses/${surveyResponseId}/question_response_issues`,
}, [surveyResponseId]);

export const useFetchAssessmentQuestionsApi = (surveyResponseId: string) => useApiRequestOptions({
  uri: `survey_responses/${surveyResponseId}/question_responses`,
}, [surveyResponseId]);

export const useResendAssessmentLinkApi = () => useApiRequestOptions({
  uri: 'survey_responses/send_reminder_email',
  method: 'patch',
  successMessage: 'Reminder email(s) sent',
  serialization: {
    type: 'surveyResponse',
    options: {},
  }
}, []);

export const useCancelAssessmentApi = () => useApiRequestOptions({
  uri: 'survey_responses/cancel',
  method: 'patch',
  successMessage: 'Assessment(s) cancelled',
  serialization: {
    type: 'surveyResponse',
    options: {},
  }
}, []);

// Used in auto-filters
export const getRequiringReviewFilterApi = (): IApiRequestOptions => ({
  // has to be a hack like this, since `defaultFilter` wouldn't work (not used from the beginning of the useApiRequest)
  uri: 'survey_responses/requiring_action',
});
