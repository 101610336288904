import React, { FunctionComponent } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export interface IUserFormModalHeaderProps {
  mode: 'create' | 'update';
}

export const UserFormModalHeader: FunctionComponent<IUserFormModalHeaderProps> = ({ mode }) => (
  <Header as="h1" textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Icon color="green" name="edit" />
    <Header.Content>
      {mode === 'create' ? 'Create' : 'Edit'} user
    </Header.Content>
  </Header>
);
