import React, { useContext } from "react";
import ReactSelect from "react-select";
import styled, { ThemeContext } from "styled-components";
import Icon from '../Icons/Icon';
import Error from "./Error";
import Label from "./Label";
import { SelectProps } from "./types";

const CLASSNAME_PREFIX = 'react-select';

const StyledReactSelect = styled(ReactSelect)`
  .${CLASSNAME_PREFIX} {
    &__control {
      border: 1px solid ${({ theme }) => theme.FORMS.DROPDOWN.BORDER_COLOR};
      border-radius: 10px;
      font-family: ${({ theme }) => theme.FONT};
      font-weight: 400;
      font-size: 14px;
      box-shadow: none;
      min-height: 36px;
      height: 36px;
 
      &:hover {
        border-color: ${({ theme }) => theme.FORMS.DROPDOWN.BORDER_COLOR};
      }
  
      &--menu-is-open {
        border-radius: 10px 10px 0 0;
      }

      &--is-disabled {
        background-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BG};
        border-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BORDER};
      }
    }

    &__value-container {
      padding-left: 12px;
      
      &--is-multi {
        overflow-y: auto;
        height: 34px;
      }
    }

    &__placeholder {
      margin: 0;
    }

    &__single-value {
      color: ${({ theme }) => theme.FORMS.DROPDOWN.COLOR};
      margin: 0;

      &--is-disabled {
        color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_COLOR};
      }
    }

    &__indicator-separator {
      background-color: ${({ theme }) => theme.FORMS.DROPDOWN.BORDER_COLOR};
      margin: 0;
    }

    &__menu {
      border: 1px solid ${({ theme }) => theme.FORMS.DROPDOWN.BORDER_COLOR};
      border-top: none;
      border-radius: 0 0 10px 10px;
      box-shadow: none;
      font-family: ${({ theme }) => theme.FONT};
      margin: 0;
      z-index: 3;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      background-color: ${({ theme }) => theme.FORMS.DROPDOWN.OPTION_BG};
      color: ${({ theme }) => theme.FORMS.DROPDOWN.OPTION_COLOR};

      &--is-selected {
        background-color: ${({ theme }) => theme.FORMS.DROPDOWN.SELECTED_OPTION_BG};
        color: ${({ theme }) => theme.FORMS.DROPDOWN.SELECTED_OPTION_COLOR};
      }

      &:hover {
        background-color: ${({ theme }) => theme.FORMS.DROPDOWN.HOVER_OPTION_BG};
        color: ${({ theme }) => theme.FORMS.DROPDOWN.HOVER_OPTION_COLOR};
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
`;

const StyledIndicator = styled.div`
  display: flex;
  justify-content: center;
  width: 36px;
`;

const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
`;

const DropdownIndicator = () => {
  const { FORMS } = useContext(ThemeContext);
  const color = FORMS.DROPDOWN.INDICATOR_COLOR;

  return (
    <StyledIndicator>
      <Icon name="triangleBigDown" size={10} color={color} />
    </StyledIndicator>
  );
};

const ClearIndicator = ({ innerProps }) => {
  const { FORMS } = useContext(ThemeContext);
  const color = FORMS.DROPDOWN.INDICATOR_COLOR;

  return (
    <StyledIndicator {...innerProps}>
      <Icon name="cancel" size={16} color={color} />
    </StyledIndicator>
  );
};

const Select = <ValueType extends string | number | boolean>({ disabled, error, label, isClearable, isMulti, options, onChange, placeholder, value }: SelectProps<ValueType>) => {
  return (
    <StyledSelect>
      { label && <Label label={label} disabled={disabled} />}
      <StyledReactSelect
        classNamePrefix={CLASSNAME_PREFIX}
        components={{ ClearIndicator, DropdownIndicator }}
        isSearchable={false}
        options={options}
        placeholder={placeholder}
        onChange={(value) => isMulti ? onChange(value as any) : onChange([value as any])}
        value={value}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
      />
      { error && <Error msg={error} />}
    </StyledSelect>
  );
};

export default Select;
