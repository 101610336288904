import React, { FunctionComponent } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../../routing/AdminRouter';
import { NonAuthLayout } from '../../../layouts/NonAuth';

enum SamlErrorCode {
  SAML_ERROR = 'saml_error',
  REQUEST_ERROR = 'request_error',
  SERVER_ERROR = 'server_error',
  ADMIN_NOT_FOUND = 'user_not_found',
}

export const SamlRelayPage: FunctionComponent = () => {
  const [uuid] = useQueryParam('uuid', StringParam);
  const [errorCode] = useQueryParam('error', StringParam);
  let errorMessage: string | null = null;

  switch (errorCode) {
    case SamlErrorCode.SAML_ERROR :
    case SamlErrorCode.REQUEST_ERROR :
    case SamlErrorCode.SERVER_ERROR :
      errorMessage = `An error occurred while logging you in. Please contact your organisation’s IT support. Your error reference is ${uuid}.`;
      break;
    case SamlErrorCode.ADMIN_NOT_FOUND :
      errorMessage =  `You do not have administrative privileges on any Ergofy account. Please contact your organisation’s Ergofy administrator or Ergofy support. Your error reference is ${uuid}.`
      break;
  }

  return errorMessage ? (
    <NonAuthLayout title="Error" errors={[errorMessage]} />
  ) : (
    <Redirect to={ROUTES.login}/>
  );
};
