import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type AssessmentFilterKey = "surveyTemplateId" | "assessmentDue"

const AssessmentTypeFilter: FunctionComponent<SelectFilterProps<AssessmentFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label={'Due for assessment'}
      options={options}
      placeholder={'Assessment type'}
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey))}
      isClearable
    />
  );
};

export default AssessmentTypeFilter;
