import React, { FunctionComponent, ReactElement } from "react";
import { canBeCancelled, canBeContacted, canBeReminded } from "../../../utils/assessment";
import Button from "../../../common/Buttons/Button";
import { AssessmentCardsProps } from "./types";
import ActionCard from "../../../common/ActionCard/ActionCard";
import { CardsRow } from "../../../common/Card/Card";

const AssessmentCards: FunctionComponent<AssessmentCardsProps> = ({ 
  selectedAssessments,
  onOpenCancelAssessmentsModal,
  onOpenContactModal,
  onOpenExportAssessmentsModal,
  onOpenSendReminderModal
}): ReactElement => {
  const canContact = selectedAssessments.filter(canBeContacted).length > 0;
  const canCancel = selectedAssessments.filter(canBeCancelled).length > 0;
  const canRemind = selectedAssessments.filter(canBeReminded).length > 0;

  return (
    <CardsRow>
      <ActionCard
        cardWidth="522px"
        heading="Bulk actions"
        info="You can carry out bulk functions for assessments that you have selected."
      >
        <Button label="Contact" theme="SUCCESS_PRIMARY" icon="envelope" disabled={!canContact} onClick={onOpenContactModal} />
        <Button label="Remind" theme="SUCCESS_PRIMARY" icon="bell" disabled={!canRemind} onClick={onOpenSendReminderModal} />
        <Button label="Cancel" theme="DANGER_SECONDARY" icon="cancel" disabled={!canCancel} onClick={onOpenCancelAssessmentsModal} />
        <Button label="Export" theme="SUCCESS_SECONDARY" icon="download" onClick={onOpenExportAssessmentsModal} />
      </ActionCard>
    </CardsRow>
  );
};

export default AssessmentCards;
