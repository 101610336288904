import { IIssue } from "../../types/resources";
import { getRiskEnumerationNumber } from "./riskCategory";
import { getStatusEnumerationNumber } from "./issueStatus";

export const getIssueDefaultSortValue = (issue: IIssue): number => {
  const riskValue = getRiskEnumerationNumber(issue.meta.riskCategory.data.id);
  const statusValue = getStatusEnumerationNumber(
    issue.meta.isOpen,
    issue.automatedIntervention?.automatedInterventionTemplate?.resolutionOptions ?? null
  );

  return riskValue + statusValue * 100;
}

export const defaultSortIssues = (issues: IIssue[]): IIssue[] =>
  issues.slice().sort((issue1: IIssue, issue2: IIssue) => 
    getIssueDefaultSortValue(issue1) - getIssueDefaultSortValue(issue2)
  );
