import React, { useEffect, useContext, useRef, useState, FunctionComponent, ReactElement } from "react";
import StyledReactModal from "styled-react-modal";
import styled, { ThemeContext } from "styled-components";
import { StyledBox } from "../Box/Box";
import { ModalProps } from "./types";
import ModalHeader from "./ModalHeader";

type Size = {width: number, height: number};

const StyledContent = styled(StyledBox)<{size: Size}>`
  box-shadow: 0px 18px 25px -9px ${({ theme }) => theme.MODAL.SHADOW};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.MODAL.COLOR};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  height: ${({ size }) => size.height ? size.height + 'px' : 'auto'};
  width: ${({ size }) => size.width ? size.width + 'px' : 'auto'};
  overflow: hidden;
  transition: height 0.35s ease, width 0.35s ease;
  padding: 0;
`;

const StyledBody = styled.div`
  text-align: center;
`;

const Modal: FunctionComponent<ModalProps> = (props): ReactElement => {
  const { MODAL: { HEADER_HEIGHT } } = useContext(ThemeContext);
  const [shouldCloseModal, setShouldCloseModal] = useState(true); // @see https://github.com/AlexanderRichey/styled-react-modal/issues/27
  const { children, onCancel, isOpen, title, step } = props;
  const targetRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const handleBackgroundClick = (e) => {
    if (shouldCloseModal && onCancel) {
      onCancel();
    }
    setShouldCloseModal(true);
  }

  const styledReactModalProps = {
    isOpen,
    ...(onCancel && { onBackgroundClick: handleBackgroundClick })
  };

  useEffect(() => {
    const node = targetRef?.current?.children[0]
    if (node) {
      const height = node.getBoundingClientRect().height + HEADER_HEIGHT;
      const width = node.getBoundingClientRect().width;
      setSize({ height, width });
    }
  }, [HEADER_HEIGHT, children, step]);

  const contentProps = { size, ...props };

  return (
    <StyledReactModal {...styledReactModalProps}>
      <StyledContent {...contentProps} onMouseDown={() => setShouldCloseModal(false)} onMouseUp={() => setShouldCloseModal(true)}>
        { title &&
          <ModalHeader {...props}>{title}</ModalHeader>
        }
        <StyledBody ref={targetRef}>
          { children }
        </StyledBody>
      </StyledContent>
    </StyledReactModal>
  );
};

export default Modal;
