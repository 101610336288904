import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";

const StyledAnswer = styled.div`
  color: ${({ theme }) => theme.QA.ANSWER};
  font-weight: 400;
  padding-right: 8px;
`;

export const SimpleAnswer: FunctionComponent<{ answer: string}> = ({ answer }): ReactElement => <StyledAnswer>{answer}</StyledAnswer>;

export const ArrayAnswer: FunctionComponent<{ answer: string[]}> = ({ answer }): ReactElement => (
  <>
    {answer.map((a, index) => <StyledAnswer key={index}>{a}</StyledAnswer>)}
  </>
);

export const MatrixAnswer: FunctionComponent<{ answer: string[][]}> = ({ answer }): ReactElement => (
  <table>
    <tbody>
      {answer.map((a, index) => 
        <tr key={index}>
          <td><StyledAnswer>{a[0]}</StyledAnswer></td>
          <td><StyledAnswer>{a[1]}</StyledAnswer></td>
        </tr>
      )}
    </tbody>
  </table>
);
