import { useApiRequestOptions } from "../../hooks";

export const useCreateManualAssessmentApi = (surveyResponseId: string) => useApiRequestOptions({
  uri: `survey_responses/${surveyResponseId}/manual_assessment`,
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: 'Follow-up created',
  serialization: {
    type: 'manualAssessment',
    options: {
      attributes: ['status'],
    }
  }
}, [surveyResponseId]);

export const useBulkCreateManualAssessmentApi = () => useApiRequestOptions({
  uri: `manual_assessments/auto_bulk_create`,
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: 'Follow-up created',
  serialization: {
    type: 'manualAssessment',
    options: {
      attributes: ['email', 'status'],
    }
  }
}, []);

export const useUpdateManualAssessmentApi = (surveyResponseId: string) => useApiRequestOptions({
  uri: `survey_responses/${surveyResponseId}/manual_assessment`,
  method: 'patch',
  passBackErrorStatus: [422],
  successMessage: 'Follow-up updated',
  serialization: {
    type: 'manualAssessment',
    options: {
      attributes: ['status'],
    }
  }
}, [surveyResponseId]);
