import { WorkSheet } from "xlsx/types";

export function isSheetValid(sheet: WorkSheet): boolean {
  const columnNames = [sheet['A1']?.v, sheet['B1']?.v, sheet['C1']?.v, sheet['D1']?.v, sheet['E1']?.v, sheet['F1']?.v]

  return (
    columnNames.indexOf('Email Address') >= 0
    && columnNames.indexOf('First Name') >= 0
    && columnNames.indexOf('Last Name') >= 0
    && columnNames.indexOf('Department') >= 0
    && columnNames.indexOf('Job Title') >= 0
    && columnNames.indexOf('Location') >= 0
  )
}
