import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { IAccount, IAccountCapability, IAccountType, ISurveyTemplate } from '../../../../types/resources';
import { Form } from '../../../../common/Form';
import { useAccountEditFormConfig } from '../../../../utils/form/configs/accountEditForm';
import { useCreateNewAccountApi, usePatchAccountByIdApi } from '../../../../utils/api/options/accounts';
import { useApiRequest } from '../../../../utils/api/hooks';
import { ACCOUNT_FEATURES, TYPE_MAP } from '../../../../utils/table/columns/accountList';
import { useSurveyTemplateListApi } from '../../../../utils/api/options/assessments';
import { useOpenConfirmationModal } from '../../../../common/modals/ConfirmationModal/ConfirmationModalContext';

export interface IAccountUpdateFormProps {
  accountSelection: { [key: string]: IAccount };
  onCancel?: () => void;
  onChange?: () => void;
}

export interface IAccountUpdateFormPayload {
  default: {
    name: string;
    enabled: boolean;
    accountType: IAccountType;
    accountCode: string;
    relationship: {
      surveyTemplates: {
        data: ISurveyTemplate[]
      }
    }
  }
}

export interface IAccountUpdateFormValues {
  default: {
    name: string;
    enabled: boolean;
    accountType: IAccountType;
    accountCode: string;
    enabledCapabilities: IAccountCapability[];
    surveyTemplates: string[];
  };
}

export const AccountUpdateForm: FunctionComponent<IAccountUpdateFormProps> = ({
                                                                                accountSelection,
                                                                                onCancel,
                                                                                onChange,
                                                                              }) => {
  const openConfirmationModal = useOpenConfirmationModal();
  const accountData: IAccount & { surveyTemplates?: ISurveyTemplate[] } = accountSelection[Object.keys(accountSelection)[0]];

  const patchAccountByIdApi = usePatchAccountByIdApi(accountData ? accountData.id : '');
  const createNewAccountApi = useCreateNewAccountApi();
  const fetchSurveyTemplateListApi = useSurveyTemplateListApi();

  const { fetch: fetchSurveyTemplate, data: surveyTemplates } = useApiRequest(fetchSurveyTemplateListApi);
  const { fetch: updateAccount } = useApiRequest<IAccountUpdateFormValues['default'] & { id?: string }>(
    accountData ? patchAccountByIdApi : createNewAccountApi,
  );

  useEffect(() => {
    fetchSurveyTemplate?.();
  }, [fetchSurveyTemplate]);

  const initialSurveyTemplates: string[] = useMemo(() => {
    if (accountData && accountData.surveyTemplates) {
      return accountData.surveyTemplates
        .map(survey => survey.id);
    }

    return [];
  }, [accountData]);

  const initialValues = useMemo(
    () => ({
      default: {
        name: accountData ? accountData.name : '',
        enabled: accountData ? accountData.enabled : true,
        accountType: accountData ? accountData.accountType : undefined,
        accountCode: accountData ? accountData.accountCode : undefined,
        isManaged: accountData ? accountData.isManaged : false,
        enabledCapabilities: accountData ? accountData.enabledCapabilities : [],
        surveyTemplates: initialSurveyTemplates,
      },
    }),
    [accountData, initialSurveyTemplates],
  );

  const typeOptions = Object
    .keys(TYPE_MAP)
    .map((accountType) => ({
      value: accountType,
      text: TYPE_MAP[accountType],
    }));

  const surveyTemplatesOptions = surveyTemplates ? Object
    .keys(surveyTemplates)
    .map((template) => ({
      value: surveyTemplates[template].id,
      text: surveyTemplates[template].name,
    })) : [];

    const enabledCapabilitiesOptions = Object.keys(ACCOUNT_FEATURES).map((featureKey) => ({
      value: featureKey,
      text: ACCOUNT_FEATURES[featureKey],
    }));

  const formConfig = useAccountEditFormConfig(typeOptions, accountData ? accountData.id : null, surveyTemplatesOptions, enabledCapabilitiesOptions);

  const handleFormSubmit = useCallback(async (values, form) => {
    const payload = {
      ...values['default'],
      id: accountData ? accountData.id : null,
      surveyTemplates: values['default'].surveyTemplates.map(id => surveyTemplates.find(template => template.id === id)),
    };

    if (accountData && accountData.id && form.getFieldState('default.surveyTemplates').dirty) {
      openConfirmationModal(
        async () => {
          await updateAccount?.(payload);
          await onChange?.();
          onCancel?.();
        },
        async () => {
          onCancel?.();
        },
        'Are you sure you want to change this account\'s assessment templates?'
      );
    } else {
      await updateAccount?.(payload);
      await onChange?.();
      onCancel?.();
    }
  }, [onCancel, onChange, updateAccount, accountData, surveyTemplates, openConfirmationModal]);

  return (
    <Form<IAccountUpdateFormPayload>
      config={formConfig}
      onSubmit={handleFormSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
    />
  );
};
