import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button, { StyledButton } from "../../common/Buttons/Button";
import useFormField from "../../common/Forms/useFormField";
import InputText from "../../common/Forms/InputText";
import { emailValidation, lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";
import { UserFormProps } from "./types";

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
`;

const StyledInput = styled.div`
  height: 74px;
  width: 390px;

  &:nth-child(odd) {
    margin-right: 18px;
  }
`;

const StyledInfo = styled.div`
  margin: 0 10px 23px 0;
  text-align: right;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;

  ${StyledButton} {
    width: 166px;
    max-width: none;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const UserForm: FunctionComponent<UserFormProps> = ({ initialValues, onCancel, onSubmit, cancelLabel, submitLabel }): ReactElement => {
  const [firstName, setFirstName, clearFirstName, validateFirstName, firstNameError] = useFormField<string>(initialValues.firstName, [requiredValidation, lengthBetweenValidation(1, 50)]);
  const [lastName, setLastName, clearLastName, validateLastName, lastNameError] = useFormField<string>(initialValues.lastName, [requiredValidation, lengthBetweenValidation(1, 50)]);
  const [email, setEmail, clearEmail, validateEmail, emailError] = useFormField<string>(initialValues.email, [requiredValidation, emailValidation, lengthBetweenValidation(1, 60)]);
  const [jobTitle, setJobTitle, clearJobTitle, validateJobTitle, jobTitleError] = useFormField<string>(initialValues.jobTitle, [lengthBetweenValidation(0, 100)]);
  const [department, setDepartment, clearDepartment, validateDepartment, departmentError] = useFormField<string>(initialValues.department, [lengthBetweenValidation(0, 100)]);
  const [location, setLocation, clearLocation, validateLocation, locationError] = useFormField<string>(initialValues.location, [lengthBetweenValidation(0, 100)]);

  const resetForm = () => {
    clearFirstName()
    clearLastName()
    clearEmail()
    clearJobTitle()
    clearDepartment()
    clearLocation()
  }

  const handleCancel = () => {
    resetForm()
    onCancel()
  }

  const handleSubmit = () => {
    const errors = [validateFirstName(), validateLastName(), validateEmail(), validateJobTitle(), validateDepartment(), validateLocation()]

    if (errors.filter(e => !!e).length === 0) {
      onSubmit({ firstName, lastName, email, jobTitle, department, location })
    }
  }
  
  return (
    <>
      <StyledForm>
        <StyledInput>
          <InputText placeholder="First name" label="First name" required value={firstName} onChange={setFirstName} error={firstNameError} />
        </StyledInput>
        <StyledInput>
          <InputText placeholder="Last name" label="Last name" required value={lastName} onChange={setLastName} error={lastNameError} />
        </StyledInput>
        <StyledInput>
          <InputText placeholder="Email" label="Email" required value={email} onChange={setEmail} error={emailError} />
        </StyledInput>
        <StyledInput>
          <InputText placeholder="Job title" label="Job title" value={jobTitle} onChange={setJobTitle} error={jobTitleError} />
        </StyledInput>
        <StyledInput>
          <InputText placeholder="Department" label="Department" value={department} onChange={setDepartment} error={departmentError} />
        </StyledInput>
        <StyledInput>
          <InputText placeholder="Location" label="Location" value={location} onChange={setLocation} error={locationError} />
        </StyledInput>
      </StyledForm>
      <StyledInfo>* required</StyledInfo>
      <StyledButtons>
        <Button label={cancelLabel} theme="SUCCESS_SECONDARY" onClick={handleCancel}/>
        <Button icon="ok" label={submitLabel} theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
    </>
  );
};

export default UserForm;
