import React, { FunctionComponent } from 'react';

import { IFormInput, FormField } from '../FormField';
import { Header, Form } from 'semantic-ui-react';
import { useFormState } from 'react-final-form';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';

import get from 'lodash/get';

export interface IFormSegment {
  key: string;
  inputs: IFormInput[];
  header?: string;
  showIf?: string;
  hideIf?: string;
  segmentWidth?: SemanticWIDTHS;
  inputDirection?: 'row' | 'column';
}

export interface IFormSegmentProps {
  config: IFormSegment;
}

export const FormSegment: FunctionComponent<IFormSegmentProps> = ({ config }) => {
  const { values } = useFormState();

  if (config.showIf && !get(values, config.showIf)) {
    return null;
  } else if (config.hideIf && get(values, config.hideIf)) {
    return null;
  }

  return (
    <>
      {config.header && <Header>{config.header}</Header>}
      <Form.Group
        widths={config.inputDirection === 'row' ? 'equal' : undefined}
        grouped={!config.inputDirection || config.inputDirection === 'column'}
      >
        {config.inputs.map((inputConfig) => (
          <FormField key={`form-field-${config.key}-${inputConfig.key}`} config={inputConfig} segmentKey={config.key} />
        ))}
      </Form.Group>
    </>
  );
};
