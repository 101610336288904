import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "../Icons/Icon";
import { ModalProps } from "./types";

const StyledModalHeader = styled.div<{ error?: boolean }>`
  color: ${({ theme }) => theme.MODAL.HEADER};
  font-family: ${({ theme }) => theme.FONT};
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  padding: 36px 40px 3px;
  position: relative;
  text-align: center;
  height: ${({ theme }) => theme.MODAL.HEADER_HEIGHT}px;
  width: 100%;
  flex-shrink: 0;

  &:after {
    display: block;
    content: '';
    width: 100px;
    height: 3px;
    background-color: ${({ error, theme }) => error
      ? theme.MODAL.HEADER_ERRORED_SEPARATOR
      : theme.MODAL.HEADER_SEPARATOR
    };
    border-radius: 1.5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledCloseIcon = styled.div`
  background-color: ${({ theme }) => theme.MODAL.CLOSE_BG};
  border-radius: 50%;
  display: flex;
  width: 19px;
  height: 19px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22px;
  right: 22px;
`;

const ModalHeader: FunctionComponent<ModalProps> = ({ onCancel, error, title }): ReactElement => {
  const { MODAL } = useContext(ThemeContext);

  return (
    <StyledModalHeader error={error}>
      {title}
      {onCancel && (
        <StyledCloseIcon onClick={onCancel}>
          <Icon name="cancel" size={17} color={MODAL.CLOSE} />
        </StyledCloseIcon>
      )}
    </StyledModalHeader>
  );
};

export default ModalHeader;
