import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Spinner from "../../../../common/Spinner/Spinner";

const StyledProcessing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  padding: 36px 0 100px;
`;

const Processing: FunctionComponent = (): ReactElement => {
  return (
    <StyledProcessing>
      <Spinner />
    </StyledProcessing>
  );
};

export default Processing;
