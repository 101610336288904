import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type LocationFilterKey = 'location' | 'userLocation'

const LocationFilter: FunctionComponent<SelectFilterProps<LocationFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Location"
      options={options}
      placeholder="Location"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default LocationFilter;
