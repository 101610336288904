import React, { FunctionComponent, ReactElement, useEffect, useMemo } from "react";
import Checkbox from "../../../../common/Forms/Checkbox"
import InputNumber from "../../../../common/Forms/InputNumber";
import Select from "../../../../common/Forms/Select";
import { Option } from "../../../../common/Forms/types";
import useFormField from "../../../../common/Forms/useFormField";
import { getRiskOptions, riskLabels } from "../../../../utils/risk";
import { IssueAutoClosingTabProps } from "../types";
import AccountSettingsTab from "./AccountSettingsTab";
import { SaveButton, StyledCheckbox, StyledInput } from "./Common";
import SettingsCard from "./SettingsCard";
import { useUpdateAutoClosingApi } from "../../../../utils/api/options/settings/autoClosing";
import { useApiRequest } from "../../../../utils/api/hooks";
import { getAutoClosingFormData, isIssueAutoClosingFormDirty } from "./utils";
import { IIssueRiskId } from "../../../../types/resources";
import { valueBetweenValidation } from "../../../../common/Forms/validation";

const riskOptions: Option<IIssueRiskId>[] = getRiskOptions()

const IssueAutoClosingTab: FunctionComponent<IssueAutoClosingTabProps> = ({ initialValues, onChange, onSave }): ReactElement => {
  const autoClosing = initialValues[0]
  const riskOption = { value: autoClosing.meta.riskCategory.data.id, label: riskLabels[autoClosing.meta.riskCategory.data.id] }
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isAutoClosingEnabled, setIsAutoClosingEnabled] = useFormField<boolean>(autoClosing.enabled)
  const [riskLevel, setRiskLevel] = useFormField<Option<IIssueRiskId>[]>([riskOption])
  const [waitDays, setWaitDays, clearWaitDays, validateWaitDays, waitDaysError] = useFormField<number>(autoClosing.waitDays, [valueBetweenValidation(1, 365)])
  /* eslint-enable */

  const updateAutoClosingApi = useUpdateAutoClosingApi(autoClosing.id);
  const { fetch: updateAutoClosing } = useApiRequest(updateAutoClosingApi);

  const autoClosingFormData = getAutoClosingFormData(autoClosing, isAutoClosingEnabled, waitDays, riskLevel[0])

  const isDirty = useMemo(() => isIssueAutoClosingFormDirty(autoClosing, autoClosingFormData), [autoClosing, autoClosingFormData])

  useEffect(() => {
    onChange(isDirty)
  }, [isDirty, onChange])

  const onSubmit = async () => {
    const errors = [ validateWaitDays() ];

    if (errors.filter(e => !!e).length === 0) {
      const [result, error] = await updateAutoClosing(autoClosingFormData); // eslint-disable-line @typescript-eslint/no-unused-vars

      if(!error) {
        onSave()
      }
    }
  }

  return (
    <AccountSettingsTab title="Issue auto closing">
      <SettingsCard title="Auto closing policy">
        <StyledCheckbox>
            <Checkbox
              id="enable-auto-closing" 
              value={isAutoClosingEnabled} 
              onChange={() => setIsAutoClosingEnabled(!isAutoClosingEnabled)}
              label="Enable issue auto-closing"
            />
          </StyledCheckbox>
          <StyledInput>
            <InputNumber
              disabled={!isAutoClosingEnabled}
              error={waitDaysError}
              label="Number of days until auto close:"
              min={1}
              max={365}
              value={waitDays}
              onChange={setWaitDays}
            />
          </StyledInput>
          <StyledInput>
            <Select<IIssueRiskId>
              disabled={!isAutoClosingEnabled}
              label="Close issues with a risk level of up to:"
              options={riskOptions}
              onChange={setRiskLevel}
              value={riskLevel}
            />
          </StyledInput>
        </SettingsCard>
      <SaveButton disabled={!isDirty} onClick={onSubmit} />
    </AccountSettingsTab>
  );
};

export default IssueAutoClosingTab;
