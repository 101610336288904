import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import LinkButton from "../../pages/admin/DashboardPage/LinkButton";
import Button, { StyledButton } from "../../common/Buttons/Button";
import InputText from "../../common/Forms/InputText";
import useFormField from "../../common/Forms/useFormField";
import { emailValidation, lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const StyledInput = styled.div`
  height: 74px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 34px;

  ${StyledButton} {
    width: 166px;
    max-width: none;
  }
`;

const StyledForgotPassword = styled.div`
  border-top: 1px solid ${({ theme }) => theme.MODAL.SEPARATOR};
  margin-bottom: 10px;
  padding-top: 23px;
  text-align: center;
  width: 100%;

  div {
    margin-right: 0;
  }
`;

type LoginFormProps = {
  initialValues: { email: string, password: string };
  onSubmit: (email: string, password: string) => void;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ initialValues, onSubmit }): ReactElement => {
  const history = useHistory();
  const [email, setEmail, clearEmail, validateEmail, emailError] = useFormField<string>(initialValues.email, [requiredValidation, emailValidation, lengthBetweenValidation(1, 60)]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [password, setPassword, clearPassword, validatePassword, passwordError] = useFormField<string>(initialValues.password, [requiredValidation, lengthBetweenValidation(1, 100)]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const handleSubmit = () => {
    const errors = [validateEmail(), validatePassword()];

    if (errors.filter(e => !!e).length === 0) {
      onSubmit(email, password);
    }
  };

  return (
    <>
      <StyledForm>
        <StyledInput>
          <InputText placeholder="Email" label="Your login" value={email} onChange={(v) => setEmail(v.toLocaleLowerCase().trim())} error={emailError} />
        </StyledInput>
        <StyledInput>
          <InputText type="password" placeholder="Your password" label="Password" value={password} onChange={setPassword} error={passwordError} />
        </StyledInput>
      </StyledForm>
      <StyledButtons>
        <Button label="Sign in" theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
      <StyledForgotPassword onClick={() => history.push('/forgot-password')}>
        <LinkButton label="Forgot password?" onClick={() => history.push('/admin/login')} theme="gray" />
      </StyledForgotPassword>
    </>
  )
}

export default LoginForm
