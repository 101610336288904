import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { FollowUpModalStep } from "./types";

const STEP_TITLE_MAP: { [key in FollowUpModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Follow-up',
  confirm: 'Follow-up',
  processing: 'Changing status',
};

export function getModalTitle(step: FollowUpModalStep): string {
  return STEP_TITLE_MAP[step]
}
