import { IDataUploadStatus } from "../../types/resources";
import { DATA_UPLOAD_STATUS_TYPE } from "../../types/enums";

export const getDataUploadStatus = (status?: IDataUploadStatus): string => {
  if (status === DATA_UPLOAD_STATUS_TYPE.created) {
    return 'Created';
  } else if (status === DATA_UPLOAD_STATUS_TYPE.queued) {
    return 'Queued';
  } else if (status === DATA_UPLOAD_STATUS_TYPE.running) {
    return 'Running';
  } else if (status === DATA_UPLOAD_STATUS_TYPE.finished) {
    return 'Finished';
  } else {
    return '';
  }
};
