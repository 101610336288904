import styled from "styled-components";

export const StyledInput = styled.input<{ error: string | undefined}>`
  border: 1px solid ${({ theme }) => theme.FORMS.INPUT.BORDER_COLOR};
  border-radius: 10px;
  color: ${({ theme }) => theme.FORMS.INPUT.COLOR};
  height: 36px;
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  padding: 1px 12px;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.FORMS.INPUT.PLACEHOLDER_COLOR};
  }

  &:focus {
    border-color: ${({ theme }) => theme.FORMS.INPUT.ACTIVE_BORDER_COLOR};
    box-shadow: 0px 4px 9px -3px ${({ theme }) => theme.FORMS.SHADOW};
  }

  ${({ error, theme }) => error && `
    border-color: ${theme.FORMS.INPUT.ERRORED_BORDER_COLOR};
    color: ${theme.FORMS.INPUT.ERRORED_COLOR};
    padding-right: 32px;

    &:focus {
      border-color: ${theme.FORMS.INPUT.ERRORED_BORDER_COLOR};
      box-shadow: none;
    }
  `};

  &[disabled] {
    border-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BORDER};
    background-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BG};
    color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_COLOR};
  }
`;

export const StyledInputText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const InputRow = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
`;
