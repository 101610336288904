import React, { FunctionComponent, ReactElement } from "react";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import { ROUTES } from "../../../../routing/AdminRouter";
import { InviteUsersCardProps } from "../types";
import CardStats from "./CardStats";
import DashboardCard from "./DashboardCard";
import LinkButton from "../LinkButton";
import DashboardCardBox from "./DashboardCardBox";
import styled from "styled-components";
import { ASSESSMENT_ICON_MAP, ASSESSMENT_NAMES } from "../../../../types/enums";
import { createFilterQueryString, IFilterQueryItem } from "../../../../utils/dashboard/filters";
import { useHistory } from "react-router";
import { useSession } from "../../../auth/AuthContext/SessionContext";
import { ISurveyTemplate } from "../../../../types/resources";
import NoData from "./NoData";

const StyledButtons = styled.div`
  align-items: center;
  display: flex;
  height: 36px;

  ${StyledButton} {
    padding: 10px 16px;
    width: auto;
  }
`;

const StyledSendRequest = styled.div`
  color: ${({ theme }) => theme.DASHBOARD.COLOR};
  font-size: 14px;
  font-weight: 400;
  margin: 7px 0 5px;

  + div {
    margin-bottom: 10px;
  }
`;

export const StyledRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const InviteUsersCard: FunctionComponent<InviteUsersCardProps> = ({ onOpenBulkInviteModal, selfAssessmentInviteSummary, surveyTemplates }): ReactElement => {
  const history = useHistory();
  const { currentUser } = useSession();
  const showHomeworkingDSEPlaceholder = !surveyTemplates.find(template => template.assessmentType === 'homeworking_dse')
  const showOfficeDSEPlaceholder = !surveyTemplates.find(template => template.assessmentType === 'office_dse')
  const homeworkingDSEName = ASSESSMENT_NAMES.homeworking_dse
  const officeDSEName = ASSESSMENT_NAMES.office_dse

  const viewFilteredUsers = (filters: IFilterQueryItem[]) => {
    history.push(`${ROUTES.usersList}?filters=${createFilterQueryString(filters)}`)
  }

  const viewUsersToInvite = (templateId: string) => {
    const filters: IFilterQueryItem[] = [
      { key: 'assessmentDue', value: templateId },
      { key: 'archived', value: [false] },
    ]

    viewFilteredUsers(filters)
  }

  const sendMail = (templateName: string) => {
    const email = 'support@ergofy.co.uk'
    const subject = `Enable ${templateName} assessments for ${currentUser?.accountName}`;

    window.open(`mailto:${email}?subject=${subject}`)
  }

  const openBulkInviteModal = (survey: ISurveyTemplate, usersCount: number) => {
    onOpenBulkInviteModal({ survey, usersCount })
  }

  return (
    <DashboardCard info="Invite users" title="Invite users">
      { selfAssessmentInviteSummary.length === 0 && !showHomeworkingDSEPlaceholder && !showOfficeDSEPlaceholder && <NoData />}
      { selfAssessmentInviteSummary.map(summaryEntry => {
        const surveyTemplate = surveyTemplates.find(template => template.id === summaryEntry.surveyTemplateId.toString())
        const usersToInvite = summaryEntry.dueAndEligible

        return surveyTemplate && (
          <DashboardCardBox key={summaryEntry.surveyTemplateId} icon={ASSESSMENT_ICON_MAP[surveyTemplate.assessmentType]} title={surveyTemplate.name}>
            <StyledRow>
              <CardStats
                extraLabel={`user${summaryEntry.inProgressTotal !== 1 ? 's' : ''} in progress`}
                extraValue={summaryEntry.inProgressTotal}
                label={`user${usersToInvite !== 1 ? 's' : ''} ready to be invited`}
                value={usersToInvite}
              />
              <StyledButtons>
                <LinkButton label="View" theme="green" onClick={() => viewUsersToInvite(surveyTemplate.id)} />
                { usersToInvite > 0 && (
                  <Button
                    icon="userAdd"
                    label="Invite"
                    onClick={() => openBulkInviteModal(surveyTemplate, usersToInvite)}
                    theme="SUCCESS_PRIMARY"
                  />
                )}
              </StyledButtons>
            </StyledRow>
          </DashboardCardBox>
        )
      })}
      { showHomeworkingDSEPlaceholder && (
        <DashboardCardBox icon="homeworkingDSE" title={homeworkingDSEName}>
          <StyledSendRequest>Request access to a {homeworkingDSEName} assessment</StyledSendRequest>
          <LinkButton label="Send email" theme="green" onClick={() => sendMail(homeworkingDSEName)} />
        </DashboardCardBox>
      )}
      { showOfficeDSEPlaceholder && (
        <DashboardCardBox icon="officeDSE" title={officeDSEName}>
          <StyledSendRequest>Request access to an {officeDSEName} assessment</StyledSendRequest>
          <LinkButton label="Send email" theme="green" onClick={() => sendMail(officeDSEName)} />
        </DashboardCardBox>
      )}
    </DashboardCard>
  );
};

export default InviteUsersCard;
