import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Tooltip from "../../../../common/Tooltip/Tooltip";
import { StyledEntryValueProps } from "../types";

export const StyledCardContent = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.div`
  color: ${({ theme }) => theme.COLORS.BLACK};
  display: flex;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
`;

export const StyledRow = styled.div<{padding?: string}>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: ${({ padding }) => padding ?? 0};
  align-items: center;

  &:first-child {
    margin-bottom: 16px;
  }
`

export const StyledEntry = styled.div<{center?: boolean, maxWidth?: string}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => center ? 'center' : 'flex-start'};
  height: 50px;
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : 'none'};
`

export const StyledEntryLabel = styled.div`
  color: ${({ theme }) => theme.COLORS.GRAY};
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
`;

export const StyledEntryValueWrapper = styled.div<{ tooltip?: boolean }>`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: ${({ tooltip }) => tooltip ? 'hidden' : 'auto'};
  white-space: nowrap;
`;

export const StyledEntryValue: FunctionComponent<StyledEntryValueProps> = ({ children, tooltip }) => (
  tooltip 
    ? <Tooltip text={tooltip}><StyledEntryValueWrapper tooltip>{children}</StyledEntryValueWrapper></Tooltip>
    : <StyledEntryValueWrapper>{children}</StyledEntryValueWrapper>
)
