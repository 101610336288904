import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box from "../../../../common/Box/Box";
import RiskTag from "../../../../common/RiskTag/RiskTag";
import Icon from "../../../../common/Icons/Icon";
import { getRiskColor } from "../../../../common/RiskTag/utils";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import { canBeContacted, canBeUpdated, getIssueStatusText, isOpen } from "../../../../utils/issue";
import Tooltip from "../../../../common/Tooltip/Tooltip";
import { HistoryProps, IIssueUpdateRowProps } from "./types";
import { getGuidanceViewedInfo, solutionGuidanceTypes } from "../../../../utils/questionResponseIssue/issueStatus";
import { IIssue } from "../../../../types/resources";
import Pagination from "../../../../common/Pagination/Pagination";
import { StyledTableHeader, StyledTableHeading } from "../Common/TableHeader";
import UpdatesHistoryTable from "./UpdatesHistoryTable";
import useFrontendPagination from "../../../../common/Pagination/useFrontendPagination";
import { StyledButtons as StyledCloseButton } from "../Common/Confirm";
import { dateSorter } from "../../../../utils/date";
import { GuidancePreviewLink } from "../../common/GuidancePreviewLink";
import { getAnswerValue } from "../../../../utils/assessment";
import { getAnswerString } from "../../../../utils/questionResponseIssue/questonAnswer";

const StyledHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 860px;
  padding: 14px 30px 0;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledTemplateNameRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTemplateName = styled.div`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: 18px;
  font-weight: 600;
  margin-left: 4px;
`;

const StyledDetails = styled.div`
  display: flex;
  align-items: flex-start;
  padding-left: 28px;
`;

const StyledCategoryName = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  padding-left: 28px;
  margin-bottom: 8px;
`;

const StyledFieldLabel = styled.div<{ padding?: boolean}>`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: 12px;
  font-weight: 600;
  padding-top: ${({ padding }) => padding ? '20px' : '0'};
`;

const StyledFieldText = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  font-weight: 400;
`;

const StyledFieldMultilineText = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledColumn = styled.div<{ colWidth: string}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ colWidth }) => colWidth};
  padding-left: 16px;

  &:first-child {
    padding-left: 0;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  padding-left: 28px;
  margin-top: 18px;

  ${StyledButton} {
    margin-right: 16px;
    width: auto;

    &:last-child {
      margin-right: 0;
    }
  }
`;

function getAnswer(issue: IIssue): string {
  const answer = getAnswerValue(issue.questionResponse.data);
  return getAnswerString(answer);
}

const updatesSort = (updateA: IIssueUpdateRowProps, updateB: IIssueUpdateRowProps) => dateSorter(updateA.date, updateB.date)

const History: FunctionComponent<HistoryProps> = ({ issue, onCancel, onContact, onUpdate, updates }): ReactElement => {
  const { itemsPerPage, handlePageChange, page, pageItems: pageUpdates } = useFrontendPagination(updates.sort(updatesSort), 3)
  const templateName = issue.issueTemplate.name;
  const categoryName = issue.issueTemplate.issueCategory.name;
  const riskId = issue.meta.riskCategory.data.id;
  const status = getIssueStatusText(isOpen(issue))
  const question = issue.questionResponse.surveyQuestion.displayText;
  const answer = getAnswer(issue);
  const showGuidance = !!issue.automatedIntervention
  const resolutionOption = issue.automatedIntervention?.automatedInterventionTemplate?.resolutionOptions
  const guidanceSolutionType = resolutionOption ? solutionGuidanceTypes[resolutionOption] : ''
  const firstViewedAt = issue.automatedIntervention?.firstViewedAt
  const guidanceViewedInfo = firstViewedAt ? getGuidanceViewedInfo(firstViewedAt) : 'Unviewed'
  const guidanceInfo = `- ${guidanceSolutionType} (${guidanceViewedInfo})`;

  return (
    <StyledHistory>
      <Box shadow border>
        <StyledWrapper>
          <StyledTemplateNameRow>
            <Icon name="warningTriangle" size={24} color={getRiskColor(riskId)} />
            <StyledTemplateName>{templateName}</StyledTemplateName>
          </StyledTemplateNameRow>
          <StyledCategoryName>{categoryName}</StyledCategoryName>
          <StyledDetails>
            <StyledColumn colWidth="13%">
              <StyledFieldLabel>Current risk</StyledFieldLabel>
              <RiskTag value={riskId} />
              <StyledFieldLabel padding>Issue status</StyledFieldLabel>
              <StyledFieldText>{status}</StyledFieldText>
            </StyledColumn>
            <StyledColumn colWidth="31%">
              <StyledFieldLabel>Question</StyledFieldLabel>
              <Tooltip text={question}><StyledFieldMultilineText>{question}</StyledFieldMultilineText></Tooltip>
            </StyledColumn>
            <StyledColumn colWidth="25%">
              <StyledFieldLabel>Answer</StyledFieldLabel>
              <Tooltip text={answer}><StyledFieldMultilineText>{answer}</StyledFieldMultilineText></Tooltip>
            </StyledColumn>
            <StyledColumn colWidth="31%">
              <StyledFieldLabel>Guidance</StyledFieldLabel>
              { showGuidance && issue.automatedIntervention && <GuidancePreviewLink issue={issue} /> }
              <StyledFieldText>{showGuidance ? guidanceInfo : '—'}</StyledFieldText>
            </StyledColumn>
          </StyledDetails>
          <StyledButtons>
            <Button disabled={!canBeUpdated(issue)} label="Update" icon="updateFile" theme="SUCCESS_PRIMARY" onClick={onUpdate} />
            <Button disabled={!canBeContacted(issue)} label="Contact" icon="envelope" theme="SUCCESS_SECONDARY" onClick={onContact} />
          </StyledButtons>
        </StyledWrapper>
      </Box>
      <StyledTableHeader>
        <StyledTableHeading>
          History
        </StyledTableHeading>
        {updates.length > 0 && <Pagination currentPage={page} itemsPerPage={itemsPerPage} total={updates.length} onPageChange={handlePageChange} />}
      </StyledTableHeader>
      <Box border noPadding>
        <UpdatesHistoryTable updates={pageUpdates} />
      </Box>
      <StyledCloseButton>
        <Button label="Close" theme="SUCCESS_SECONDARY" onClick={onCancel} />
      </StyledCloseButton>
    </StyledHistory>
  );
};

export default History;
