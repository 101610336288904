import React from 'react';
import { createContext, FunctionComponent, useContext } from "react";

export type IApplicationKey = 'none' | 'admin' | 'backend' | 'survey';
export interface IApplication {
    applicationKey: IApplicationKey;
    homePath: string;
    loginPath: string;
}

const NULL_APPLICATION: IApplication = { 
    applicationKey: 'none',
    homePath: '',
    loginPath: '',
};

const ApplicationContext = createContext<IApplication>(NULL_APPLICATION);

export const ApplicationProvider: FunctionComponent<{ application: IApplication }> = ({ application, children }) => {
    return(
        <ApplicationContext.Provider value={application}> 
            {children}
        </ApplicationContext.Provider>
    );
};

export const useActiveApplication = () => useContext(ApplicationContext);
