import React, { FunctionComponent, useCallback } from "react";
import LinkCell from "../../../../common/DataTable/Cells/LinkCell";
import { getApiUrl } from "../../../../utils/url";

export const GuidancePreviewLink: FunctionComponent<{ issue: any }> = ({ issue }) => {
    const surveyTemplateId = issue.questionResponse.surveyResponse?.surveyTemplate.id ?? issue.questionResponse.surveyQuestion.questionGroup.surveyTemplate?.id ?? '';
    const interventionId = issue.automatedIntervention?.id ?? '';
    const interventionTemplateId = issue.automatedIntervention?.automatedInterventionTemplate.id ?? '';
    const guidance = issue.automatedIntervention?.automatedInterventionTemplate.name;
  
    const openGuidance = useCallback(() => {
      const url = `${getApiUrl(null)}/admin/survey_templates/${surveyTemplateId}/automated_intervention_templates/${interventionTemplateId}?automated_intervention_id=${interventionId}`
      window.open(url, '_blank');
    }, [surveyTemplateId, interventionTemplateId, interventionId]);
  
    return <LinkCell id={guidance ? issue.id : undefined} onClick={openGuidance} text={guidance} />;
  }
