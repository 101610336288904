import React, { FunctionComponent } from 'react';

import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { SectionHeader } from '../SectionHeader';

import { useDropzone } from 'react-dropzone';
import omit from 'lodash/omit';

export interface IUploadSegmentProps {
  onDrop: (files: File[]) => Promise<void>;
  disabled?: boolean;
}

export const UploadSegment: FunctionComponent<IUploadSegmentProps> = ({ onDrop, disabled }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled, multiple: false });

  return (
    <Grid.Column>
      <SectionHeader>Upload list file</SectionHeader>
      <Segment placeholder {...omit(getRootProps(), 'ref') as any}>
        <Header icon>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <Icon name={isDragActive ? 'bullseye' : 'upload'} />
          Drop your completed list file here to upload it
        </Header>
      </Segment>
    </Grid.Column>
  );
};
