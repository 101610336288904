import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { FollowUpFormData } from "../../../../forms/FollowUpForm/types";
import { BulkCreatePayload, FollowUpModalStep } from "./types";

const STEP_TITLE_MAP: { [key in FollowUpModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Follow-up',
  confirm: 'Follow-up',
  processing: 'Changing status',
};

export function getModalTitle(step: FollowUpModalStep): string {
  return STEP_TITLE_MAP[step]
}

export function getBulkCreatePayload(formData: FollowUpFormData): BulkCreatePayload {
  const payload: BulkCreatePayload = {
    status: formData.status || 'scheduled',
  }

  if (formData.sendEmail) {
    payload.email = {
      message: formData.contact.message,
      subject: formData.contact.subject
    }
  }

  return payload
}
