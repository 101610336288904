import { toast } from 'react-toastify';

export const showMessage = (message: string, level: 'default' | 'success' | 'error' | 'log' = 'default') => {
  if (level === 'default') {
    toast.dark(message);
  } else if (level === 'success') {
    toast.success(message);
  } else if (level === 'error') {
    toast.error(message);
  } else {
    // should never be here
    console.error(message);
  }
};
