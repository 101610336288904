import { useMemo } from 'react';
import { ISurveyTemplate, IUserDepartmentListItem, IUserLocationListItem } from '../../../types/resources';
import { IFilterConfig, IAutoFilterConfig } from '../../../common/Table/Context/TableFilterConfigContext';

import { getSurveyTemplateOptions } from './options/surveyTemplateOptions';
import { getRiskOptions } from './options/riskOptions';
import { getSurveyResponseStatusOptions } from './options/surveyQuestionStatusOptions';
import { getManualAssessmentStatusOptions } from './options/manualAssessmentStatusOptions';
import { getCheckboxOptions } from './options/checkboxOptions';
import { getListedStringOptions } from './options/listedStringOptions';
import { getRequiringReviewFilterApi } from '../../api/options/assessments';

export const useAssessmentListFilters = (
  departmentOptions: IUserDepartmentListItem[],
  locationOptions: IUserLocationListItem[],
  surveyTemplates: ISurveyTemplate[],
) => useMemo<IFilterConfig[]>(() => [
  {
    label: 'Assessment Type',
    key: 'surveyTemplateId',
    type: 'multiSelect',
    options: getSurveyTemplateOptions(surveyTemplates),
  },
  {
    label: 'Assessment Status',
    key: 'status',
    type: 'multiSelect',
    options: getSurveyResponseStatusOptions(),
  },
  {
    label: 'Current Risk',
    key: 'currentRisk',
    type: 'multiSelect',
    options: getRiskOptions(),
  },
  {
    label: 'Follow-up Status',
    key: 'manualAssessmentStatus',
    type: 'multiSelect',
    options: getManualAssessmentStatusOptions(),
  },
  {
    label: 'User Name',
    key: 'userNameLike',
    type: 'text'
  },
  {
    label: 'Department',
    key: 'userDepartment',
    type: 'multiSelect',
    options: getListedStringOptions(departmentOptions),
  },
  {
    label: 'Location',
    key: 'userLocation',
    type: 'multiSelect',
    options: getListedStringOptions(locationOptions),
  },
  {
    label: 'User Archived',
    key: 'userArchived',
    type: 'multiSelect',
    options: getCheckboxOptions('Archived', 'Non-Archived'),
  },
], [departmentOptions, locationOptions, surveyTemplates]);

export const useAssessmentListAutoFilters = (surveyTemplates: ISurveyTemplate[]) => useMemo<IAutoFilterConfig[]>(() => 
  surveyTemplates.map((template) => ({
    label: `${template.name} - requiring review`,
    values: [{ key: 'surveyTemplateId', value: [template.id] }],
    apiOptions: getRequiringReviewFilterApi(),
  })), [surveyTemplates]);
