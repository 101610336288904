import React, { createContext, useContext, MutableRefObject, FunctionComponent, useMemo } from 'react';
import { usePaginationCallbacks } from '../../../utils/api/hooks';
import { IPagination, IPaginationCallbacks } from '../../../utils/api/types';
import { useTableApiInfo } from './TableApiInfoContext';
import { useTableFlags } from './TableFlagsContext';

export interface ITablePaginationProps {
  pagination?: MutableRefObject<IPagination>;
}

const defaultPaginationCallbacks: IPaginationCallbacks = {
  nextPage: () => Promise.resolve(void 0),
  prevPage: () => Promise.resolve(void 0),
  lastPage: () => Promise.resolve(void 0),
  firstPage: () => Promise.resolve(void 0),
  canNextPage: () => false,
  canPrevPage: () => false,
  goToPage: () => Promise.resolve(void 0),
  setItemsPerPage: () => Promise.resolve(void 0),
};

export const TablePaginationContext = createContext<IPaginationCallbacks & ITablePaginationProps>(defaultPaginationCallbacks);

export const TablePaginationProvider: FunctionComponent<ITablePaginationProps> = ({ pagination, children }) => {
  const { fetch } = useTableApiInfo();
  const { paginateable } = useTableFlags();

  const paginationCallbacks = usePaginationCallbacks(pagination ?? { current: { currentPage: 1, itemsPerPage: 10 } }, fetch);
  const values = useMemo(() => paginateable ? ({
    ...paginationCallbacks,
    pagination,
  }) : defaultPaginationCallbacks, [paginateable, pagination, paginationCallbacks]);

  return (
    <TablePaginationContext.Provider value={values}>
      {children}
    </TablePaginationContext.Provider>
  )
};

export const useTablePagination = () => useContext(TablePaginationContext);
