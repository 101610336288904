import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import { BarProps, BarTheme } from "./types";

const StyledBar = styled.div`
  position: relative;
`;

const StyledColorBar = styled.div<{ barColor: string; barShadow: string; value: number}>`
  background-color: ${({ theme }) => theme.CHARTS.BG};
  border-radius: 5px;
  height: 10px;
  margin-bottom: 6px;
  width: 100%;

  ::after {
    border-radius: 5px;
    content: '';
    display: block;
    height: 10px;
    width: ${({ value }) => value }%;
    background-color: ${({ barColor }) => barColor};
    box-shadow: 0px 3px 9px ${({ barShadow }) => barShadow};
  }
`

const getBarColors = (theme: DefaultTheme, barTheme: BarTheme): [string, string] => {
  const { GRAY, GRAY_SHADOW, GREEN, GREEN_SHADOW, RED, RED_SHADOW, YELLOW, YELLOW_SHADOW } = theme.CHARTS

  switch(barTheme) {
    case 'gray': return [GRAY, GRAY_SHADOW]
    case 'green': return [GREEN, GREEN_SHADOW]
    case 'red': return [RED, RED_SHADOW]
    case 'yellow': return [YELLOW, YELLOW_SHADOW]
  }
}

const Bar: FunctionComponent<BarProps> = ({ barTheme, value }): ReactElement => {
  const theme = useContext(ThemeContext)
  const [color, shadow] = getBarColors(theme, barTheme)

  return (
    <StyledBar>
      <StyledColorBar barColor={color} barShadow={shadow} value={value} />
    </StyledBar>
  );
};

export default Bar;
