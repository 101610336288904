import { STEP_TITLE_MAP_COMMON } from '../../../../common/Modal/utils';
import { BulkInviteUsersModalStep } from "./types";

const STEP_TITLE_MAP_SINGLE: { [key in BulkInviteUsersModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  confirm: 'Invite user',
  processing: 'Invite user',
};

const STEP_TITLE_MAP_MULTI: { [key in BulkInviteUsersModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  confirm: 'Invite users',
  processing: 'Invite users',
};

export function getModalTitle(step: BulkInviteUsersModalStep, multi: boolean): string {
  return multi ? STEP_TITLE_MAP_MULTI[step] : STEP_TITLE_MAP_SINGLE[step];
}
