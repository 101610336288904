import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from 'react-router-dom';
import { NavbarLinkProps } from "./types";

const StyledNavbarLink = styled(Link)`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.NAVBAR.COLOR};
  margin: 0 auto;
  position: relative;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};
  }

  &.is-active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.NAVBAR.ACTIVE_LINE};
  }

  @media ${({ theme }) => theme.RWD.LAPTOP_S} {
    margin: 0 16px;
  }

  @media ${({ theme }) => theme.RWD.LAPTOP_L} {
    margin: 0 26px;
  }
`;

export const NavbarLink: FunctionComponent<NavbarLinkProps> = (props): ReactElement => {
  const { to } = props;
  const match = useRouteMatch();
  const isActive = match.url.search(to) !== -1;

  const className = isActive ? 'is-active' : '';

  return <StyledNavbarLink className={className} {...props} />;
};
