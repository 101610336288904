import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import { StyledButton } from "../Buttons/Button";
import Card from "../Card/Card";
import Icon from "../Icons/Icon";
import Tooltip from "../Tooltip/Tooltip";
import { ActionCardProps } from "./types";

const StyledActionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
`;

const StyledHeading = styled.div`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${StyledButton} {
    flex-grow: 1;
    margin-right: 16px;
    max-width: none;
    width: auto;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActionCard: FunctionComponent<ActionCardProps> = ({ cardWidth, children, heading, info }): ReactElement => {
  const { INFO_BADGE } = useContext(ThemeContext);

  return (
    <Card width={cardWidth}>
      <StyledActionCardContent>
        <StyledHeader>
          <StyledHeading>{heading}</StyledHeading>
          {info && (
            <Tooltip text={info} icon trigger="click">
            <Icon name="info" size={16} color={INFO_BADGE.COLOR} />
          </Tooltip>
          )}
        </StyledHeader>
        <StyledButtons>
          { children }
        </StyledButtons>
      </StyledActionCardContent>
    </Card>
  );
};

export default ActionCard;
