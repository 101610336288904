import React, { createContext, FunctionComponent, useCallback, useContext, useState } from 'react';
import { IAccount } from '../../../../../types/resources';
import { AccountUpdateModal } from '../index';

const AccountUpdateModalContext = createContext<(accountSelection: { [id: string]: IAccount }, onAccountUpdate?: () => void) => void>(() => void 0);

export const AccountUpdateModalProvider: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accountSelection, setAccountSelection] = useState<{ [id: string]: IAccount } | null>(null);
  const [onAccountUpdate, setAccountUpdate] = useState<(() => void) | undefined>(undefined);

  const openModal = useCallback((
    accountSelection: { [id: string]: IAccount },
    onAccountUpdate?: () => void,
  ) => {
    setAccountUpdate(() => onAccountUpdate);
    setAccountSelection(accountSelection);
    setIsOpen(true);
  }, [setIsOpen, setAccountSelection]);
  const closeModal = useCallback(() => {
    setAccountSelection(null);
    setAccountUpdate(undefined);
    setIsOpen(false);
  }, [setIsOpen, setAccountSelection]);

  return (
    <AccountUpdateModalContext.Provider value={openModal}>
      {children}
      {accountSelection && (
        <AccountUpdateModal
          isOpen={isOpen}
          accountSelection={accountSelection}
          closeModal={closeModal}
          onAccountUpdate={onAccountUpdate}
        />
      )}
    </AccountUpdateModalContext.Provider>
  );
};

export const useOpenAccountUpdateModal = () => useContext(AccountUpdateModalContext);
