import { useCallback } from 'react';
import { IFormFieldValidation, IFormFieldValidationKey, IFormFieldValidationAttributesByKey, IFormFieldValue } from '../../common/Form/FormField';
import { get } from 'lodash';

const EMAIL_CHECK_REGEX = /^[\w\-'._%+]+@[a-z\d\-.]+\.[a-z]+$/;

const valueExists = (value?: IFormFieldValue | null) => value !== null && value !== undefined && value !== '';

export const parseEmailForIsUniqueValidation = (emailAddress?: string): string => emailAddress?.toLowerCase().trim() || ''

const validationMap: {
  [key in IFormFieldValidationKey]: (
    value: IFormFieldValue | undefined,
    attributes: IFormFieldValidationAttributesByKey[IFormFieldValidationKey],
    values: any,
  ) => string | undefined
} = {
  required: (value) => !valueExists(value) ? 'Required' : undefined,
  email: (value) => valueExists(value) && !EMAIL_CHECK_REGEX.test(`${value}`.toLowerCase().trim()) ? 'Invalid email format' : undefined,
  isUnique: (value, attributes) => {
    value = value as string;
    attributes = attributes as string[];
    return attributes.indexOf(parseEmailForIsUniqueValidation(value)) > -1 ? 'Duplicated email' : undefined
  },
  lengthBetween: (value, attributes) => {
    value = value as string;
    attributes = attributes as IFormFieldValidationAttributesByKey['lengthBetween'];

    return valueExists(value) && (
      (attributes[0] && value.length < attributes[0]) ||
      (attributes[1] && value.length > attributes[1])
    ) ? `Value must be between ${attributes[0]} and ${attributes[1]} characters long` : undefined;
  },
  valueBetween: (value, attributes) => {
    value = value as number;
    attributes = attributes as IFormFieldValidationAttributesByKey['valueBetween'];

    return valueExists(value) && (
      (attributes[0] && value < attributes[0]) ||
      (attributes[1] && value > attributes[1])
    ) ? `Value must be between ${attributes[0]} and ${attributes[1]}` : undefined;
  },
  oneOf: (value, attributes) => {
    attributes = attributes as IFormFieldValidationAttributesByKey['oneOf'];

    return valueExists(value) && !attributes.find((attr) => value === attr) ? `Value must be one of ${attributes.join()}` : undefined;
  },
  isType: (value, attributes) => {
    attributes = attributes as IFormFieldValidationAttributesByKey['isType'];

    return valueExists(value) && typeof value !== attributes ? `Invalid value type, expected type "${attributes}"` : undefined;
  },
  matches: (value, attributes, values) => {
    attributes = attributes as IFormFieldValidationAttributesByKey['matches'];

    return valueExists(value) && value !== get(values, attributes.key) ? `Value must match "${attributes.text}" field` : undefined;
  },
  noTrailingSpaces: (value, attributes) => {
    value = value as string;
    attributes = attributes as IFormFieldValidationAttributesByKey['noTrailingSpaces'];

    return valueExists(value) && /^\s+|\s+$/.test(value) ? 'No leading or trailing spaces allowed' : undefined;
  },
  hasLowerCase: (value) => {
    value = value as string;

    return valueExists(value) && !/[a-z]/.test(value) ? 'Must contain at least one lowercase letter' : undefined;
  },
  hasUpperCase: (value) => {
    value = value as string;

    return valueExists(value) && !/[A-Z]/.test(value) ? 'Must contain at least one uppercase letter' : undefined;
  },
  hasNumericCharacter: (value) => {
    value = value as string;

    return valueExists(value) && !/[0-9]/.test(value) ? 'Must contain at least one number' : undefined;
  },
  hasSpecialCharacter: (value) => {
    value = value as string;

    return valueExists(value) && !/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(value) ? 'Must contain at least one special character' : undefined;
  },
  maxConsecutiveCharacters: (value, attributes) => {
    value = value as string;

    return valueExists(value) && value.split('').some((char, index, arr) => {
      attributes = attributes as IFormFieldValidationAttributesByKey['maxConsecutiveCharacters'];

      const checkIndexes: number[] = [];
      for (let i = 1; i <= attributes; i++) {
        checkIndexes.push(index + i);
      }
      
      return checkIndexes.reduce((acc, cur) => acc && char === arr[cur], true);
    }) ? `Too many consecutive characters (e.g. 'aaaa') - maximum allowed is ${attributes}` : undefined;
  },
};

export const useFormValidators = (
  validation?: IFormFieldValidation[]
): (value: string | number | boolean | undefined, values: any) => string | undefined =>
  useCallback((value: string | number | boolean | undefined, values: any) => 
    validation?.reduce((acc: string | undefined, cur) => acc || validationMap[cur.key](value, cur.attributes, values), undefined),
    [validation]
  );
