import React, { FunctionComponent, ReactElement, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useModal } from "../../../common/Modal/hooks";
import { IAccountSummaryData, ISurveyTemplate } from "../../../types/resources";
import { useApiRequest } from "../../../utils/api/hooks";
import { useSurveyTemplateListApi } from "../../../utils/api/options/assessments";
import { useAccountOverviewApi } from "../../../utils/api/options/dashboard";
import AddNewUserModal from "../modals/AddNewUserModal";
import BulkFollowUpModal from "../modals/BulkFollowUpModal";
import BulkInviteUsersModal from "../modals/BulkInviteUsersModal";
import { BulkInviteModalData } from "../modals/BulkInviteUsersModal/types";
import ActiveAssessmentChartCard from "./Cards/ActiveAssessmentChartCard";
import AddOrUpdateUsersCard from "./Cards/AddOrUpdateUsersCards";
import InviteUsersCard from "./Cards/InviteUsersCard";
import RisksAndFollowUpsCard from "./Cards/RisksAndFollowUpsCard";
import UsersInvitedChartCard from "./Cards/UsersInvitedChartCard";

const StyledDashboardPage = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledColumn = styled.div`
  display: inline-block;
  width: 33.33%;

  > div {
    margin-bottom: 18px;
  }
`;

const DashboardPage: FunctionComponent = (): ReactElement => {
  const accountOverviewApi = useAccountOverviewApi();
  const { fetch: fetchAccountOverview, data: accountOverviewData } = useApiRequest<never, IAccountSummaryData>(accountOverviewApi);

  const fetchSurveyTemplatesApi = useSurveyTemplateListApi();
  const { fetch: fetchSurveyTemplates, data: surveyTemplates } = useApiRequest<never, ISurveyTemplate[]>(fetchSurveyTemplatesApi);

  const { close: closeBulkFollowUpModal, isOpen: isBulkFollowUpModalOpen, open: openBulkFollowUpModal } = useModal();
  const { close: closeBulkInviteModal, data: bulkInviteModalData, isOpen: isBulkInviteModalOpen, open: openBulkInviteModal } = useModal<BulkInviteModalData>();
  const { close: closeAddNewUserModal, isOpen: isAddNewUserModalOpen, open: openAddNewUserModal } = useModal();

  const fetchData = useCallback(() => {
    fetchAccountOverview();
    fetchSurveyTemplates();
  }, [fetchAccountOverview, fetchSurveyTemplates]);

  const handleFollowUpChange = () => {
    closeBulkFollowUpModal()
    fetchData()
  }

  const handleUsersUpdate = () => {
    closeAddNewUserModal();
    fetchData();
  }

  const handleUsersInvite = () => {
    closeBulkInviteModal();
    fetchData();
  }

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <StyledDashboardPage>
      { accountOverviewData && surveyTemplates && (
        <>
          <StyledColumn>
            <AddOrUpdateUsersCard onOpenAddNewUserModal={openAddNewUserModal} usersSummary={accountOverviewData.usersSummary} />
            <UsersInvitedChartCard selfAssessmentInviteSummary={accountOverviewData.selfAssessmentInviteSummary} surveyTemplates={surveyTemplates} />
          </StyledColumn>
          <StyledColumn>
            <InviteUsersCard
              onOpenBulkInviteModal={openBulkInviteModal}
              selfAssessmentInviteSummary={accountOverviewData.selfAssessmentInviteSummary}
              surveyTemplates={surveyTemplates}
            />
            <ActiveAssessmentChartCard selfAssessmentRiskSummary={accountOverviewData.selfAssessmentRiskSummary} />
          </StyledColumn>
          <StyledColumn>
            <RisksAndFollowUpsCard 
              selfAssessmentRiskSummary={accountOverviewData.selfAssessmentRiskSummary}
              onOpenBulkFollowUpModal={openBulkFollowUpModal}
            />
          </StyledColumn>
        </>
      )}
      { accountOverviewData && (
        <BulkFollowUpModal
          assessmentsCount={accountOverviewData.selfAssessmentRiskSummary.activeHighRiskFollowupRequired ?? 0}
          isOpen={isBulkFollowUpModalOpen}
          onCancel={closeBulkFollowUpModal}
          onOk={handleFollowUpChange}
        />
      )}
      <AddNewUserModal isOpen={isAddNewUserModalOpen} onCancel={handleUsersUpdate} onOk={handleUsersUpdate} />
      { bulkInviteModalData && (
        <BulkInviteUsersModal
          isOpen={isBulkInviteModalOpen}
          onCancel={closeBulkInviteModal}
          onOk={handleUsersInvite}
          survey={bulkInviteModalData.survey}
          usersCount={bulkInviteModalData.usersCount}
        />
      )}
    </StyledDashboardPage>
  );
};

export default DashboardPage;
