import React, { ReactElement } from "react";
import styled from "styled-components";
import { Tab, TabsProps } from "./types";

const StyledTabs = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.TABLE.BORDER};
  display: flex;
  height: 46px;
`;

const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0 18px;
  border-right: 1px solid ${({ theme }) => theme.TABLE.BORDER};
  user-select: none;
`

const StyledTabLabel = styled.div<{ isActive: boolean }>`
  border-bottom: ${({ isActive, theme }) => isActive ? `2px solid ${theme.TABS.ACTIVE_UNDERLINE}` : 'none'};
  color: ${({ isActive, theme }) => isActive ? theme.TABS.ACTIVE : theme.TABS.COLOR};
  font-family: ${({ theme }) => theme.FONT};
  font-weight: ${({ isActive }) => isActive ? 600 : 400};
  font-size: 14px;
  line-height: 20px;
`;

const Tabs = <TabType extends Tab>({ activeTab, onClick, tabs }: TabsProps<TabType>): ReactElement => {
  return (
    <StyledTabs>
      { tabs.map((tab) => {
        const isActive = tab.key === activeTab.key;
        return (
          <StyledTab key={tab.key} onClick={() => onClick(tab)}>
            <StyledTabLabel isActive={isActive}>{tab.label}</StyledTabLabel>
          </StyledTab>
        )
      })}
    </StyledTabs>
  );
};

export default Tabs;
