// Project colors
const GREEN_1 = '#D2F1E9';
const GREEN_2 = '#B1E7D9';
const GREEN_3 = '#A9E4D5';
const GREEN_4 = '#20E4BB';
const GREEN_5 = '#56D7B3';
const GREEN_6 = '#15B589';
const GREEN_7 = '#13A079';
const YELLOW_1 = '#F4D494';
const YELLOW_2 = '#E89015';
const RED_1 = '#F2C4CB';
const RED_2 = '#F15D77';
const RED_3 = '#FE5270';
const RED_4 = '#DD3C58';
const RED_5 = '#C82F49';
const RED_6 = '#B13C4C';
const WHITE = '#FFFFFF';
const GRAY_1 = '#FBFBFD';
const GRAY_2 = '#F9FAFC';
const GRAY_3 = '#F3F5F7';
const GRAY_4 = '#F5F7FA';
const GRAY_5 = '#DEE2E8';
const GRAY_6 = '#C4C4C4';
const GRAY_7 = '#8C939B';
const GRAY_8 = '#727A84';
const BLACK = '#2D364A';

const WHITE_TRANSPARENT = 'rgba(255, 255, 255, 0.75)';

const OVERLAY_BG = 'rgba(39, 55, 75, 0.67)';

const GREEN_SHADOW = 'rgba(21, 181, 137, 0.25)';
const YELLOW_SHADOW = 'rgba(237, 163, 58, 0.23)';
const RED_SHADOW_1 = 'rgba(221, 60, 88, 0.25)';
const RED_SHADOW_2 = 'rgba(128, 4, 26, 0.21)';
const GRAY_SHADOW_1 = 'rgba(41, 77, 123, 0.22)';
const GRAY_SHADOW_2 = 'rgba(39, 55, 75, 0.31)';
const GRAY_SHADOW_3 = 'rgba(39, 55, 75, 0.06)';
const BLACK_SHADOW = 'rgba(0, 0, 0, 0.18)';

// SVG
const FILL_CLASSNAME = 'fill';

const DISABLED_BUTTON_COLORS = {
  DISABLED_BORDER: GRAY_5,
  DISABLED_BG: GRAY_5,
  DISABLED_COLOR: WHITE,
};

const FONT = "'Open Sans', sans-serif";

export default {
  RWD: {
    MOBILE_S: `(min-width: 320px)`,
    MOBILE_M: `(min-width: 375px)`,
    MOBILE_L: `(min-width: 425px)`,
    TABLET_S: `(min-width: 576px)`,
    TABLET_L: `(min-width: 768px)`,
    LAPTOP_S: `(min-width: 1024px)`,
    LAPTOP_L: `(min-width: 1440px)`,
    DESKTOP: `(min-width: 2560px)`,
  },
  COLORS: {
    GREEN: GREEN_6,
    YELLOW: YELLOW_2,
    RED: RED_6,
    WHITE,
    GRAY: GRAY_6,
    BLACK,
  },
  FONT,
  FILL_CLASSNAME,
  MINI_ACTION_BUTTON_COLORS: {
    BG: WHITE,
    ACTIVE: GRAY_6,
    ACTIVE_ICON: WHITE,
    HOVER_ACTIVE: GREEN_6,
    DISABLED: GRAY_5,
    SHADOW: GREEN_SHADOW,
  },
  BUTTON_COLORS: {
    SUCCESS_PRIMARY: {
      BORDER: GREEN_6,
      BG: GREEN_6,
      COLOR: WHITE,
      HOVER_BORDER: GREEN_5,
      HOVER_BG: GREEN_5,
      HOVER_COLOR: WHITE,
      ACTIVE_BORDER: GREEN_7,
      ACTIVE_BG: GREEN_7,
      ACTIVE_COLOR: WHITE,
      ICON: WHITE,
      HOVER_ICON: WHITE,
      ACTIVE_ICON: WHITE,
      DISABLED_ICON: WHITE,
      SHADOW: GREEN_SHADOW,
      ...DISABLED_BUTTON_COLORS,
    },
    SUCCESS_SECONDARY: {
      BORDER: GREEN_1,
      BG: GREEN_1,
      COLOR: GREEN_6,
      HOVER_BG: GREEN_3,
      HOVER_BORDER: GREEN_3,
      HOVER_COLOR: GREEN_6,
      ACTIVE_BORDER: GREEN_2,
      ACTIVE_BG: GREEN_2,
      ACTIVE_COLOR: GREEN_6,
      ICON: GREEN_6,
      HOVER_ICON: GREEN_6,
      ACTIVE_ICON: GREEN_6,
      DISABLED_ICON: WHITE,
      SHADOW: GREEN_SHADOW,
      ...DISABLED_BUTTON_COLORS,
    },
    DANGER_PRIMARY: {
      BORDER: RED_4,
      BG: RED_4,
      COLOR: WHITE,
      HOVER_BG: RED_3,
      HOVER_BORDER: RED_3,
      HOVER_COLOR: WHITE,
      ACTIVE_BORDER: RED_5,
      ACTIVE_BG: RED_5,
      ACTIVE_COLOR: WHITE,
      ICON: WHITE,
      HOVER_ICON: WHITE,
      ACTIVE_ICON: WHITE,
      DISABLED_ICON: WHITE,
      SHADOW: RED_SHADOW_2,
      ...DISABLED_BUTTON_COLORS,
    },
    DANGER_SECONDARY: {
      BORDER: RED_1,
      BG: RED_1,
      COLOR: RED_6,
      HOVER_BG: RED_2,
      HOVER_BORDER: RED_2,
      HOVER_COLOR: WHITE,
      ACTIVE_BORDER: RED_4,
      ACTIVE_BG: RED_4,
      ACTIVE_COLOR: WHITE,
      ICON: RED_6,
      HOVER_ICON: WHITE,
      ACTIVE_ICON: WHITE,
      DISABLED_ICON: WHITE,
      SHADOW: RED_SHADOW_1,
      ...DISABLED_BUTTON_COLORS,
    },
    ALTERNATIVE: {
      BORDER: GREEN_6,
      BG: WHITE,
      COLOR: GREEN_6,
      HOVER_BG: WHITE,
      HOVER_BORDER: GREEN_6,
      HOVER_COLOR: GREEN_6,
      ACTIVE_BORDER: GREEN_6,
      ACTIVE_BG: GREEN_6,
      ACTIVE_COLOR: WHITE,
      ICON: GREEN_6,
      HOVER_ICON: GREEN_6,
      ACTIVE_ICON: WHITE,
      DISABLED_ICON: GRAY_6,
      SHADOW: GREEN_SHADOW,
      DISABLED_BORDER: GRAY_6,
      DISABLED_BG: WHITE,
      DISABLED_COLOR: GRAY_6,
    },
  },
  LINK_BUTTON: {
    COLOR: BLACK,
    DISABLED: GRAY_6,
  },
  FORMS: {
    INPUT: {
      PLACEHOLDER_COLOR: GRAY_7,
      COLOR: BLACK,
      BORDER_COLOR: GRAY_6,
      ACTIVE_BORDER_COLOR: GREEN_4,
      ERRORED_BORDER_COLOR: RED_4,
      ERRORED_COLOR: RED_4,
      DISABLED_BORDER: GRAY_5,
      DISABLED_BG: GRAY_3,
      DISABLED_COLOR: GRAY_7,
    },
    CHECKBOX: {
      TICK: GREEN_6,
      BG: WHITE,
      BORDER_COLOR: GRAY_5,
      HOVER_BORDER_COLOR: GRAY_6,
    },
    DROPDOWN: {
      BORDER_COLOR: GRAY_6,
      COLOR: BLACK,
      OPTION_BG: WHITE,
      OPTION_COLOR: BLACK,
      HOVER_OPTION_BG: GRAY_2,
      HOVER_OPTION_COLOR: BLACK,
      SELECTED_OPTION_BG: GRAY_4,
      SELECTED_OPTION_COLOR: BLACK,
      INDICATOR_COLOR: GRAY_6,
    },
    LABEL: {
      COLOR: BLACK,
      DISABLED_COLOR: GRAY_7,
    },
    ERROR_COLOR: RED_4,
    SHADOW: GREEN_SHADOW,
    QUICK_FILTERS: GREEN_6,
  },
  PAGINATION: {
    COLOR: GRAY_7,
  },
  BOX: {
    BG: WHITE,
    BORDER: GRAY_5,
    SHADOW: GRAY_SHADOW_3,
  },
  MODAL: {
    OVERLAY_BG: OVERLAY_BG,
    SHADOW: BLACK_SHADOW,
    HEADER: BLACK,
    HEADER_SEPARATOR: GREEN_4,
    HEADER_ERRORED_SEPARATOR: RED_6,
    HEADER_HEIGHT: 80,
    CLOSE: WHITE,
    CLOSE_BG: GRAY_6,
    COLOR: GRAY_7,
    RED_ACCENT: RED_4,
    BLACK_ACCENT: BLACK,
    FAILURE_ICON: RED_6,
    SUCCESS_ICON: GREEN_6,
    SEPARATOR: GRAY_5,
  },
  TABLE: {
    HEADER_COLOR: BLACK,
    BORDER: GRAY_5,
    HOVER_ROW_BG: GRAY_2,
    COLOR_LIGHT: GRAY_7,
    COLOR: GRAY_8,
    COLOR_DARK: BLACK,
    SORT_ACTIVE: GRAY_7,
    SORT_INACTIVE: GRAY_5,
    PADLOCK: GRAY_6,
    WARNING: RED_6,
    WARNING_2: YELLOW_2,
    SUCCESS: GREEN_6,
    CELL_PADDING: '0 16px',
  },
  TOOLTIP: {
    BG: BLACK,
    COLOR: WHITE,
    SHADOW: GRAY_SHADOW_1,
    ICON: GREEN_6,
  },
  NAVBAR: {
    HEIGHT: '84px',
    BG: BLACK,
    COLOR: WHITE_TRANSPARENT,
    HOVER_COLOR: WHITE,
    ACTIVE_LINE: GREEN_4,
    SWITCH: GREEN_6,
    LOGOUT: RED_6,
    SEARCH_BG: GRAY_8,
    SHADOW: GRAY_SHADOW_2
  },
  INFO_BADGE: {
    COLOR: GRAY_6,
  },
  ASSESMENT_CARD: {
    BORDER: GRAY_5,
    ICON: GREEN_6,
    TYPE: BLACK,
  },
  ASSESSMENT_STATUS: {
    CANCELLED: GRAY_8,
    CANCELLED_BY_USER_ARCHIVAL: GRAY_8,
    FINISHED: GREEN_6,
    IN_PROGRESS: YELLOW_2,
    NOT_STARTED: GRAY_8,
  },
  RISK: {
    HIGH: RED_6,
    MEDIUM: YELLOW_2,
    LOW: GREEN_6,
    NONE: GRAY_6,
  },
  ACCORDION: {
    BORDER: GRAY_5,
    CONTENT_BG: GRAY_4,
    ICON: BLACK,
  },
  ACCORDION_MENU: {
    ACTIVE: GREEN_4,
    BG: GRAY_2,
    BORDER: GRAY_5,
    TOGGLE: BLACK,
  },
  TABS: {
    COLOR: GRAY_8,
    ACTIVE: BLACK,
    ACTIVE_UNDERLINE: GREEN_4,
  },
  QA: {
    BG: GRAY_1,
    ANSWER: GRAY_8,
    QUESTION: BLACK,
  },
  DASHBOARD: {
    COLOR: GRAY_7,
    EXTRA_STAT: GRAY_7,
    STAT: BLACK,
    DEFAULT: {
      COLOR: GRAY_5,
      ICON: GREEN_6,
      SHADOW: GRAY_SHADOW_3,
    },
    ERROR: {
      COLOR: RED_1,
      ICON: RED_4,
      SHADOW: RED_SHADOW_1,
    },
    WARNING: {
      COLOR: YELLOW_1,
      ICON: YELLOW_2,
      SHADOW: YELLOW_SHADOW,
    },
    LINK_BUTTON: {
      GRAY: GRAY_7,
      GREEN: GREEN_6,
      RED: RED_4,
    }
  },
  CHARTS: {
    BG: GRAY_3,
    GRAY: GRAY_7,
    GRAY_SHADOW: GRAY_SHADOW_1,
    GREEN: GREEN_6,
    GREEN_SHADOW: GREEN_SHADOW,
    RED: RED_4,
    RED_SHADOW: RED_SHADOW_1,
    YELLOW: YELLOW_2,
    YELLOW_SHADOW: YELLOW_SHADOW,
    VALUE: BLACK,
  }
};
