import { useMemo } from "react";
import { IExportConfig } from "..";
import { IUser } from "../../../types/resources";
import { exportKeyWrapper } from "./exportKeyWrapper";
import { formatDateField } from "../../date";

export const useUserListExportOptions = () => useMemo<IExportConfig<IUser>[]>(() => [
  {
    header: 'First Name',
    valueExtractor: exportKeyWrapper('firstName'),
  },
  {
    header: 'Last Name',
    valueExtractor: exportKeyWrapper('lastName'),
  },
  {
    header: 'Email Address',
    valueExtractor: exportKeyWrapper('emailAddress'),
  },
  {
    header: 'Location',
    valueExtractor: exportKeyWrapper('location'),
  },
  {
    header: 'Department',
    valueExtractor: exportKeyWrapper('department'),
  },
  {
    header: 'Job Title',
    valueExtractor: exportKeyWrapper('jobTitle'),
  },
  {
    header: 'Active Assessment',
    valueExtractor: exportKeyWrapper('activeSurveyResponse.surveyTemplate.name'),
  },
  {
    header: 'Active Assessment Invited On',
    valueExtractor: exportKeyWrapper('activeSurveyResponse.createdAt', undefined, formatDateField),
  },
  {
    header: 'Last Assessment',
    valueExtractor: exportKeyWrapper('lastCompletedSurveyResponse.surveyTemplate.name'),
  },
  {
    header: 'Last Assessment Completed On',
    valueExtractor: exportKeyWrapper('lastCompletedSurveyResponse.createdAt', undefined, formatDateField),
  }
], []);
