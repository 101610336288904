import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button, { StyledButton } from "../../common/Buttons/Button";
import useFormField from "../../common/Forms/useFormField";
import Select from "../../common/Forms/Select";
import Textarea from "../../common/Forms/Textarea";
import { Option } from "../../common/Forms/types";
import { lengthBetweenValidation } from "../../common/Forms/validation";
import { IssueFormProps } from "./types";
import { getIssueOptions } from "./utils";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const StyledSelect = styled.div`
  margin-bottom: 18px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 22px;

  ${StyledButton} {
    width: 166px;
    max-width: none;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const IssueForm: FunctionComponent<IssueFormProps> = ({ cancelLabel, initialValues, issues, onCancel, onSubmit, submitLabel }): ReactElement => {
  const [message, setMessage, clearMessage, validateMessage, messageError] = useFormField<string>(initialValues.message, [lengthBetweenValidation(1, 10000)]);
  const [operation, setOperation, clearOperation] = useFormField<Option>(initialValues.operation);
  const issueOptions = getIssueOptions(issues);

  const resetForm = () => {
    clearMessage();
    clearOperation();
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    const errors = [validateMessage()];

    if (errors.filter(e => !!e).length === 0) {
      onSubmit({ message, operation });
    }
  };

  return (
    <>
      <StyledForm>
        <StyledSelect>
          <Select label="Operation" options={issueOptions} onChange={value => setOperation(value[0])} value={[operation]} />
        </StyledSelect>
        <Textarea placeholder="Write here your message" label="Your message" value={message} onChange={setMessage} error={messageError} />
      </StyledForm>
      <StyledButtons>
        <Button label={cancelLabel} theme="SUCCESS_SECONDARY" onClick={handleCancel}/>
        <Button icon="ok" label={submitLabel} theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
    </>
  );
};

export default IssueForm;
