import { IExportConfig } from "..";
import { ISurveyResponse, ISurveyResponseStatusType, IManualAssessmentStatusType, IIssueRiskId } from "../../../types/resources";
import { exportKeyWrapper } from "./exportKeyWrapper";
import { formatDateField } from "../../date";
import { getSurveyResponseStatus, getManualAssessmentStatus } from "../../surveyResponse";
import { getRiskText } from "../../questionResponseIssue/riskCategory";

export const useSurveyResponseExportOptions = (): IExportConfig<ISurveyResponse>[] => [
  {
    header: 'Suggested Priority',
    valueExtractor: exportKeyWrapper('suggestedPriority', '-'),
  },
  {
    header: 'First Name',
    valueExtractor: exportKeyWrapper('user.firstName'),
  },
  {
    header: 'Last Name',
    valueExtractor: exportKeyWrapper('user.lastName'),
  },
  {
    header: 'Email Address',
    valueExtractor: exportKeyWrapper('user.emailAddress'),
  },
  {
    header: 'Current Risk',
    valueExtractor: exportKeyWrapper<ISurveyResponse, IIssueRiskId>('meta.riskCategory.data.id', '-', getRiskText),
  },
  {
    header: 'Assessment Type',
    valueExtractor: exportKeyWrapper('surveyTemplate.name'),
  },
  {
    header: 'Assessment Status',
    valueExtractor: exportKeyWrapper<ISurveyResponse, ISurveyResponseStatusType>('status', '-', getSurveyResponseStatus),
  },
  {
    header: 'Assessment Completed',
    valueExtractor: exportKeyWrapper('finishedAt', '-', formatDateField),
  },
  {
    header: 'Invited On',
    valueExtractor: exportKeyWrapper('createdAt'),
  },
  {
    header: 'Follow-up Status',
    valueExtractor: exportKeyWrapper<ISurveyResponse, IManualAssessmentStatusType>('manualAssessment.status', '-', getManualAssessmentStatus),
  },
];
