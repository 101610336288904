import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { useCurrentPageOptions } from '../../../utils/table/pagination';
import { useTablePagination } from '../Context/TablePaginationContext';
import { useTableApiInfo } from '../Context/TableApiInfoContext';

const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CurrentPageDropdown = styled(Dropdown)`
  &&& {
    margin-right: 0.25em;
  }
`;

export const Pagination: FunctionComponent = () => {
  const {
    pagination,
    nextPage,
    prevPage,
    lastPage,
    firstPage,
    canNextPage,
    canPrevPage,
    goToPage,
    setItemsPerPage,
  } = useTablePagination();
  const { loading } = useTableApiInfo();

  const itemsPerPageOptions = useMemo(() => [
    { text: '10',  value: 10  },
    { text: '20',  value: 20  },
    { text: '50',  value: 50  },
    { text: '100', value: 100 },
  ], []);
  const availablePageOptions = useCurrentPageOptions(pagination?.current.totalPageCount ?? 1);

  const handleFirstPage = useCallback(() => firstPage(), [firstPage]);
  const handlePrevPage = useCallback(() => prevPage(), [prevPage]);
  const handleNextPage = useCallback(() => nextPage(), [nextPage]);
  const handleLastPage = useCallback(() => lastPage(), [lastPage]);
  const handleItemsPerPageChange = useCallback((e: unknown, { value }) => { setItemsPerPage(value); }, [setItemsPerPage]);
  const handleCurrentPageChange = useCallback((e: unknown, { value }) => { goToPage(value); }, [goToPage]);

  return (
    <PaginationContainer>
      <Dropdown
        disabled={loading}
        onChange={handleItemsPerPageChange}
        value={pagination?.current.itemsPerPage ?? 10}
        options={itemsPerPageOptions}
        text={`Show ${pagination?.current.itemsPerPage} items`}
        compact
        selection
      />

      <span>
        <Button
          onClick={handleFirstPage}
          disabled={loading || !canPrevPage()}
          icon
          circular
        >
          <Icon name="angle double left" color="green" />
        </Button>
        <Button
          onClick={handlePrevPage}
          disabled={loading || !canPrevPage()}
          icon
          circular
        >
          <Icon name="angle left" color="green" />
        </Button>
        <CurrentPageDropdown
          disabled={loading || !pagination?.current.totalPageCount}
          onChange={handleCurrentPageChange}
          value={pagination?.current.currentPage}
          options={availablePageOptions}
          text={`${pagination?.current.currentPage}` + (pagination?.current.totalPageCount ? ` of ${pagination?.current.totalPageCount}` : '')}
          compact
          selection
        />
        <Button
          onClick={handleNextPage}
          disabled={loading || !canNextPage()}
          icon
          circular
        >
          <Icon name="angle right" color="green" />
        </Button>
        <Button
          onClick={handleLastPage}
          disabled={loading || !canNextPage()}
          icon
          circular
        >
          <Icon name="angle double right" color="green" />
        </Button>
      </span>
    </PaginationContainer>
  );
};
