import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { ContactFormData } from "../../../../forms/ContactForm/types";
import { IIssue, ISurveyResponse } from "../../../../types/resources";
import { ContactItemType, ContactModalStep, SendEmailPayload } from "./types";

const MODAL_TITLES_SINGLE: {[ key in ContactModalStep]: string} = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Send mail',
  confirm: 'Send mail',
  processing: 'Sending mail'
};

const MODAL_TITLES_MULTI: {[ key in ContactModalStep]: string} = {
  ...STEP_TITLE_MAP_COMMON,
  form: 'Send mails',
  confirm: 'Send mails',
  processing: 'Sending mails'
};

export function getModalTitle(step: ContactModalStep, multi: boolean): string {
  return multi ? MODAL_TITLES_MULTI[step] : MODAL_TITLES_SINGLE[step];
}

export function getSendEmailPayload(itemType: ContactItemType, item: ISurveyResponse | IIssue, formData: ContactFormData): SendEmailPayload {
  return {
    subject: formData.subject,
    message: formData.message,
    recipient: {
      id: item.user.id,
      type: 'user',
    },
    relatedItem: {
      id: item.id,
      type: itemType,
    }
  };
}
