import { useMemo } from 'react';
import { IFormConfig } from '../../../common/Form';

export const useAccountEditFormConfig = (typeOptions, id, surveyTemplatesOptions, enabledCapabilitiesOptions) => useMemo<IFormConfig>(() => ({
  segments: [
    {
      key: 'default',
      inputs: [
        {
          key: 'name',
          type: 'input',
          inputProps: {
            label: 'Name',
            placeholder: 'Type a name...',
          },
          validation: [
            { key: 'required', attributes: null },
          ],
        },
        {
          key: 'accountCode',
          type: 'input',
          inputProps: {
            label: 'Account code',
            placeholder: 'Type an account code',
            disabled: !!id,
          },
        },
        {
          key: 'enabled',
          type: 'checkbox',
          inputProps: {
            label: 'Enabled',
          },
        },
        {
          key: 'accountType',
          type: 'select',
          inputProps: {
            label: 'Account type',
            options: typeOptions,
          },
          validation: [
            { key: 'required', attributes: null },
          ],
        },
        {
          key: 'isManaged',
          type: 'checkbox',
          inputProps: {
            label: 'Is managed account',
          },
        },
        {
          key: 'surveyTemplates',
          type: 'select',
          inputProps: {
            label: 'Assessment templates',
            options: surveyTemplatesOptions,
            multiple: true,
          }
        },
        {
          key: 'enabledCapabilities',
          type: 'select',
          inputProps: {
            label: 'Enabled Features (can be added only, no deactivation)',
            options: enabledCapabilitiesOptions,
            multiple: true,
          }
        }
      ],
    },
  ],
}), [typeOptions, id, surveyTemplatesOptions, enabledCapabilitiesOptions]);
