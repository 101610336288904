import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import { IconName } from "../../../../common/types";
import { ButtonTheme } from "../../../../common/Buttons/types";
import { ROUTES } from "../../../../routing/AdminRouter";
import { createFilterQueryString, IFilterQueryItem } from "../../../../utils/dashboard/filters";
import { DashboardCardBoxTheme, RisksAndFollowUpsCardProps } from "../types";
import LinkButton from "../LinkButton";
import CardStats from "./CardStats";
import DashboardCard from "./DashboardCard";
import DashboardCardBox from "./DashboardCardBox";
import { IIssueRiskId, IManualAssessmentStatusType } from "../../../../types/resources";

const StyledButtons = styled.div`
  align-items: center;
  bottom: 14px;
  display: flex;
  position: absolute;
  right: 14px;

  ${StyledButton} {
    padding: 10px 16px;
    width: auto;
  }
`;

const StyledRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const RisksAndFollowUpsCard: FunctionComponent<RisksAndFollowUpsCardProps> = ({ onOpenBulkFollowUpModal, selfAssessmentRiskSummary }): ReactElement => {
  const { activeHighRiskFollowupRequired, followupInvited, followupScheduled } = selfAssessmentRiskSummary
  const history = useHistory();

  const assessmentsToBeReviewed = activeHighRiskFollowupRequired ?? 0
  const contactedByAdmin = followupInvited ?? 0
  const followUpWithAdmin = followupScheduled ?? 0
  const reviewIcon: IconName = assessmentsToBeReviewed === 0 ? 'faceHappy' : 'faceSad'
  const reviewTheme: DashboardCardBoxTheme = assessmentsToBeReviewed === 0 ? 'default' : 'error'
  const followUpTheme: ButtonTheme = reviewTheme === 'default' ? 'SUCCESS_PRIMARY' : 'DANGER_PRIMARY'

  const viewFilteredAssessments = (filters: IFilterQueryItem[]) => {
    history.push(`${ROUTES.assessmentsList}?filters=${createFilterQueryString(filters)}`)
  }

  const viewAssessmentsToBeReviewed = () => {
    const manualAssessmentStatuses: IManualAssessmentStatusType[] = ['cancelled', 'declined'];
    const risks: IIssueRiskId[] = ['high'];

    const filters: IFilterQueryItem[] = [
      { key: 'currentRisk', value: risks },
      { key: 'manualAssessmentStatus', value: ['', ...manualAssessmentStatuses] }
    ]

    viewFilteredAssessments(filters)
  }

  const viewContactedByAdmin = () => {
    const manualAssessmentStatus: IManualAssessmentStatusType[] = ['invited'];

    const filters: IFilterQueryItem[] = [
      { key: 'manualAssessmentStatus', value: manualAssessmentStatus }
    ]

    viewFilteredAssessments(filters)
  }

  const viewScheduled = () => {
    const manualAssessmentStatus: IManualAssessmentStatusType[] = ['scheduled'];

    const filters: IFilterQueryItem[] = [
      { key: 'manualAssessmentStatus', value: manualAssessmentStatus }
    ]

    viewFilteredAssessments(filters)
  }

  return (
    <DashboardCard info="Manage risks and follow-ups" title="Manage risks and follow-ups">
      <DashboardCardBox icon={reviewIcon} theme={reviewTheme} title="Assessments to be reviewed">
        <StyledRow>
          <CardStats label="assessments" value={assessmentsToBeReviewed} />
          <StyledButtons>
            <LinkButton label="View" theme={reviewTheme === 'error' ? 'red' : 'green'} onClick={viewAssessmentsToBeReviewed} />
            {reviewTheme === 'error' && <Button icon="usersTalking" label="Follow up" theme={followUpTheme} onClick={onOpenBulkFollowUpModal} />}
          </StyledButtons>
        </StyledRow>
      </DashboardCardBox>
      <DashboardCardBox icon="envelope" title="Contacted by admin">
        <StyledRow>
          <CardStats label="assessments" value={contactedByAdmin} />
          <StyledButtons>
            <LinkButton label="View" theme="green" onClick={viewContactedByAdmin} />
          </StyledButtons>
        </StyledRow>
      </DashboardCardBox>
      <DashboardCardBox icon="chat" title="Follow-up with admin">
        <StyledRow>
          <CardStats label="assessments" value={followUpWithAdmin} />
          <StyledButtons>
            <LinkButton label="View" theme="green" onClick={viewScheduled} />
          </StyledButtons>
        </StyledRow>
      </DashboardCardBox>
    </DashboardCard>
  );
};

export default RisksAndFollowUpsCard;
