import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import LinkButton from "../Buttons/LinkButton";
import { ClearAllFiltersProps } from "./types";

const StyledClearAllFilters = styled.div`
  display: flex;
  align-items: center;
  height: 59px;
  justify-content: flex-end;
  width: 100%;
`;

const ClearAllFilters: FunctionComponent<ClearAllFiltersProps> = ({ onClear }): ReactElement => {
  return (
    <StyledClearAllFilters>
      <LinkButton icon="clearFilters" label="Clear all filters" onClick={onClear} />
    </StyledClearAllFilters>
  );
};

export default ClearAllFilters;
