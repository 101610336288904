import React, { useContext, FunctionComponent, ReactElement } from "react";
import { AccordionItemProps } from "./types";
import { AccordionContext } from "./AccordionContext";
import styled, { ThemeContext } from "styled-components";
import Icon from "../Icons/Icon";
import { IconName } from "../types";

const StyledAccordionItemHeader = styled.div`
  align-items: center;
  display: flex;
  height: 62px;
  user-select: none;
  border-bottom: 1px solid ${({ theme }) => theme.ACCORDION.BORDER};
`;

const StyledAccordionItemHeading = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
`;

const StyledAccordionItemContent = styled.div`
  background: ${({ theme }) => theme.ACCORDION.CONTENT_BG};
  border-bottom: 1px solid ${({ theme }) => theme.ACCORDION.BORDER};
`;

const AccordionItem: FunctionComponent<AccordionItemProps> = ({ children, id, onClick, title }): ReactElement => {
  const { expanded, toggle } = useContext(AccordionContext)
  const { ACCORDION } = useContext(ThemeContext)
  const isExpanded = expanded.indexOf(id) > -1;
  const icon: IconName = isExpanded ? "arrowDown" : "arrowRight"

  const handleToggle = () => {
    if (onClick) {
      onClick()
    }

    toggle(id)
  }

  return (
    <div>
      <StyledAccordionItemHeader onClick={handleToggle}>
        <StyledIcon>
          <Icon name={icon} size={24} color={ACCORDION.ICON} />
        </StyledIcon>
        <StyledAccordionItemHeading>{title}</StyledAccordionItemHeading>
      </StyledAccordionItemHeader>
      {isExpanded && <StyledAccordionItemContent>{children}</StyledAccordionItemContent>}
    </div>
  );
};

export default AccordionItem;
