import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { DropDownMenuProps } from "./types";

const StyledDropdownMenu = styled.div`
  display: flex;
  position: relative;
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  padding: 0 26px;
  align-items: center;
  justify-content: center;
`;

const StyledLinks = styled.div`
  background: ${({ theme }) => theme.NAVBAR.BG};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 10px 15px -4px ${({ theme }) => theme.NAVBAR.SHADOW};
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;

  a {
    height: 50px;
  }
`;

const StyledName = styled.div`
  color: ${({ theme }) => theme.NAVBAR.COLOR};
  cursor: pointer;
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  user-select: none;

  &:hover {
    color: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};
  }
`;

export const DropdownMenu: FunctionComponent<DropDownMenuProps> = ({ children, isOpen, name, onToggle }): ReactElement => {
  return (
    <StyledDropdownMenu onClick={onToggle}>
      <StyledName>{name}</StyledName>
      { isOpen && (
        <StyledLinks>
          { children }
        </StyledLinks>
      )}
    </StyledDropdownMenu>
  );
};
