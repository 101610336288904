import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type ResolvableFilterKey = 'resolvableByUser'

const ResolvableFilter: FunctionComponent<SelectFilterProps<ResolvableFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Issue resolution type"
      options={options}
      placeholder="All resolution types"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey))}
      isClearable
    />
  );
};

export default ResolvableFilter;
