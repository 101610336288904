import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";

const StyledNoData = styled.div`
  text-align: center;
`;

const NoData: FunctionComponent = (): ReactElement => (
  <StyledNoData>
    Please add some users in order to send assessment invites
  </StyledNoData>
);

export default NoData;
