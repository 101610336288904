import React, { useCallback, useContext, FunctionComponent } from "react";
import { ThemeContext } from "styled-components";
import Icon from '../Icons/Icon';
import Error from "./Error";
import Label from "./Label";
import { IconWrapper, InputRow, StyledInput, StyledInputText } from "./StyledInput";
import { InputTextProps } from "./types";

const InputText: FunctionComponent<InputTextProps> = (props) => {
  const { disabled, error, label, onBlur, onChange, onKeyPress, placeholder, type, value, blurOnEnter } = props;
  const { FORMS } = useContext(ThemeContext);

  const handleChange = useCallback((event) => {
    onChange(event.target.value);
  }, [onChange]);

  const handleBlur = useCallback((event) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  }, [onBlur]);

  const handleKeyPress = useCallback((event) => {
    const { key, target } = event;
    if (onKeyPress) {
      onKeyPress(key);
    }

    if(key === 'Enter' && blurOnEnter) {
      target.blur();
    }
  }, [blurOnEnter, onKeyPress]);

  return (
    <StyledInputText>
      { label && <Label {...props} />}
      <InputRow>
        <StyledInput
          type={type ?? 'text'}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
        />
        { error &&
          <IconWrapper>
            <Icon name="warning" size={16} color={FORMS.ERROR_COLOR} />
          </IconWrapper>
        }
      </InputRow>
      { error && <Error msg={error} />}
    </StyledInputText>
  );
};

export default InputText;
