import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import Tooltip from "../Tooltip/Tooltip";
import Label from "./Label";
import { CheckboxProps } from "./types";

const StyledCheckbox = styled.input<{ disabled?: boolean }>`
  appearance: none;
  background-color: ${({ theme }) => theme.FORMS.CHECKBOX.BG};
  border: 1px solid ${({ disabled, theme }) => disabled ? theme.FORMS.INPUT.DISABLED_BORDER : theme.FORMS.CHECKBOX.BORDER_COLOR};
  border-radius: 6px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  margin-left: 13px;
`;

const StyledLabel = styled.label<{ disabled?: boolean, strong?: boolean }>`
  margin-left: 8px;
  color: ${({ disabled, theme }) => disabled ? theme.FORMS.INPUT.DISABLED_COLOR : theme.FORMS.LABEL.COLOR};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  font-weight: ${({ strong }) => strong ? '600' : '400'};
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  position: relative;
  &:hover {
    ${StyledCheckbox} {
      border: 1px solid ${({ disabled, theme }) => disabled 
        ? theme.FORMS.INPUT.DISABLED_BORDER 
        : theme.FORMS.CHECKBOX.HOVER_BORDER_COLOR};
    }
  }
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
`;

const StyledCheckmark = styled.svg`
  position: absolute;
  pointer-events: none;
  left: 16px;
`;

const Checkmark = () => {
  const { FORMS } = useContext(ThemeContext);
  const color = FORMS.CHECKBOX.TICK;

  return (
    <StyledCheckmark width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1.90997L5.65685 9.06682L0 3.40997L2 1.40997L5.74685 5.14685L11.09 2.38419e-06L13 1.90997Z" fill={color}/>
    </StyledCheckmark>
  );
};

const Checkbox: FunctionComponent<CheckboxProps> = ({ disabled, id, label, fieldLabel, strong, tooltip, onChange, value }): ReactElement => {
  const handleChange = disabled 
    ? undefined
    : () => onChange(id);

  return (
    <Wrapper disabled={disabled}>
      { fieldLabel && <Label label={fieldLabel} />}
      <Row>
        <StyledCheckbox checked={value} disabled={disabled} type="checkbox" onChange={handleChange} />
        { value && <Checkmark />}
        { tooltip ? (
          <Tooltip text={tooltip}>
            <StyledLabel disabled={disabled} onClick={handleChange} strong={strong} >{label}</StyledLabel>
          </Tooltip>
        ) : (
          <StyledLabel disabled={disabled} onClick={handleChange} strong={strong} >{label}</StyledLabel>
        )}

      </Row>
    </Wrapper>
  );
};

export default Checkbox;
