import React, { FunctionComponent, useCallback } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { IAccountTableWrappingCellProps } from './types';

import { useOpenAccountUpdateModal } from '../../../modals/AccountUpdateModal/AccountUpdateModalContext';
import { useOnAccountUpdate } from '../contexts/AccountUpdateContext';

export const AccountEditWrapper: FunctionComponent<IAccountTableWrappingCellProps> = ({ value }) => {
  const openAccountEditModal = useOpenAccountUpdateModal();
  const onAccountEdit = useOnAccountUpdate();

  const editAccount = useCallback(() => {
    openAccountEditModal({ [value.id]: value }, onAccountEdit);
  }, [onAccountEdit, openAccountEditModal, value]);

  return <Popup
    content="Edit Account"
    position="left center"
    mouseEnterDelay={500}
    mouseLeaveDelay={500}
    inverted
    trigger={(
      <Button onClick={editAccount} icon circular basic>
        <Icon color="green" name="edit"/>
      </Button>
    )}
  />;
};
