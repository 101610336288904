import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 60%;
  text-align: center;
  margin-top: 30px;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ users, survey, onCancel, onOk }): ReactElement => {
  const userText = users.length > 1 ? 'selected users' : 'this user';

  return (
    <StyledConfirm>
       <StyledIntro>Are you sure to invite <Accent>{ userText }</Accent> to the <Accent>{survey.name} assessment</Accent>?</StyledIntro>
       <StyledButtons>
        <Button label="No, thanks" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Yes, invite" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
