import React, { createContext, FunctionComponent, ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { ApplicationProvider, IApplication, IApplicationKey, useActiveApplication } from '../pages/auth/AuthContext/ApplicationContext';
import { SessionProvider, useSession } from '../pages/auth/AuthContext/SessionContext';

export const applicationPathTo = (applicationKey: IApplicationKey) => {
    return (endpoint: string | null) => {
        return endpoint ? `/${applicationKey}/${endpoint}`.replaceAll('//', '/') : `/${applicationKey}`;
    };
};
type IApplicationLayout = FunctionComponent | React.Component | null;
const LayoutContext = createContext<IApplicationLayout>(null);
const LayoutProvider = ({ layout, children }) => {
    return (
        <LayoutContext.Provider value={layout}>
            {children}
        </LayoutContext.Provider>
    );
};
const useLayout = () => useContext(LayoutContext);

interface IApplicationRoute extends RouteProps {
    layout?: ReactNode;
    unauthenticated?: boolean;
}

export const ApplicationRoute: FunctionComponent<IApplicationRoute> =
    ({ children, layout, component: RouteComponent, unauthenticated, ...route }) => {
        const { loginPath } = useActiveApplication();
        // Use the default 
        const layoutFromContext = useLayout();
        const RenderComponent = RouteComponent as FunctionComponent;
        const content = RouteComponent ? <RenderComponent /> : children;
        const { state: sessionState } = useSession();

        if (!unauthenticated && sessionState.stabilised && !(sessionState.type === 'loggedIn')) {
           return <Redirect to={loginPath} />;
        } else if (!unauthenticated && !sessionState.stabilised) {
            // Prevent anything that might need a stabilised session from rendering while session is not stabilised
            // This will prevent race conditions like rendering the home page and making API calls with stale session data
            // causing 401s which will then trigger logouts
            return <Loader active />;
        }

        if (typeof(layout) === 'undefined') { 
            layout = layoutFromContext; // Only set to the default if no argument is provided
        }
        const Layout = layout as FunctionComponent;
        return (
            <Route {...route}>
                {layout ? <Layout children={content} /> : content}
            </Route>
        );
    };

interface IAppConfig {
    application: IApplication;
    defaultLayout?: IApplicationLayout;
}

export const ApplicationRouter: FunctionComponent<IAppConfig> = ({ children, application, defaultLayout, ...sessionConfig }) => {
    return (
        <ApplicationProvider application={application}>
            <SessionProvider {...sessionConfig}>
                <LayoutProvider layout={defaultLayout}>
                    {children}
                </LayoutProvider>
            </SessionProvider>
        </ApplicationProvider>
    );
};
