import React, { FunctionComponent } from "react";
import styled from "styled-components";
import RiskTag from "../../RiskTag/RiskTag";
import { RiskCellProps } from "../types";
import Td from "./Td";

const StyledRiskCell = styled.div`
  display: flex;
  justify-content: center;
`;

const RiskCell: FunctionComponent<RiskCellProps> = ({ value }) => (
  <Td>
    <StyledRiskCell>
      <RiskTag value={value} />
    </StyledRiskCell>
  </Td>
);

export default RiskCell;
