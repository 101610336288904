import React, { createContext, useContext, ReactNode, ReactElement, useMemo } from 'react';
import { IApiRequest } from '../../../utils/api/types';

export interface ITableApiInfoProps<TData extends { id: string }> {
  fetch: IApiRequest<never, TData[]>;
  loading: boolean;
}

export const TableApiInfoContext = createContext<ITableApiInfoProps<any>>({
  fetch: () => Promise.resolve([null, null]),
  loading: false,
});

export const TableApiInfoProvider = <TData extends { id: string }>(
  { fetch, loading, children }: ITableApiInfoProps<TData> & { children: ReactNode }
): ReactElement => {
  const apiInfo = useMemo(() => ({ fetch, loading }), [fetch, loading]);

  return (
    <TableApiInfoContext.Provider value={apiInfo}>
      {children}
    </TableApiInfoContext.Provider>
  );
};

export const useTableApiInfo = () => useContext(TableApiInfoContext);
