import React, { FunctionComponent } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export const ListAccountsModalHeader: FunctionComponent = () => (
  <Header as="h1" textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Icon color="orange" name="user circle" />
    <Header.Content>
      Select an account
    </Header.Content>
  </Header>
);
