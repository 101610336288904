import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmArchiveProps } from "./types";

const StyledIntro = styled.div`
  text-align: center;
  margin-top: 20px;
  max-width: 60%;

  &:first-child {
    margin-top: 30px;
  }
`;

const ConfirmArchive: FunctionComponent<ConfirmArchiveProps> = ({ onCancel, onOk, user }): ReactElement => {
  return (
    <StyledConfirm>
      <StyledIntro>Only employees who have left the organisation should be archived, this is an <Accent color="red">irreversible</Accent> action.</StyledIntro>
      <StyledIntro>Are you sure you want to <Accent color="red">archive</Accent> this user?</StyledIntro>
      <StyledButtons>
        <Button label="No, go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Save changes" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default ConfirmArchive;
