import React, { FunctionComponent, ReactElement } from "react";
import { Table, Thead, THr, Tr, TBody } from "../../../../common/DataTable/DataTable";
import { TextHeaderCell, TextCell, DateCell, TrNoData } from "../../../../common/DataTable/Cells";
import { UpdatesHistoryTableProps } from "./types";

const UpdatesHistoryTable: FunctionComponent<UpdatesHistoryTableProps> = ({ updates }): ReactElement => {
  return (
    <Table>
      <Thead>
        <THr>
          <TextHeaderCell>Date</TextHeaderCell>
          <TextHeaderCell>Admin</TextHeaderCell>
          <TextHeaderCell>Action</TextHeaderCell>
          <TextHeaderCell>Note</TextHeaderCell>
        </THr>
      </Thead>
      <TBody>
        { updates.length === 0 && <TrNoData colSpan={4} /> }
        { updates.map(update => {
          const admin = update.user ? `${update.user.firstName} ${update.user.lastName}` : ''
          return (
            <Tr key={update.id}>
              <DateCell date={update.date} />
              <TextCell text={admin} />
              <TextCell text={update.action} />
              <TextCell text={update.text} />
            </Tr>
          );
        }) }
      </TBody>
    </Table>
  );
};

export default UpdatesHistoryTable;
