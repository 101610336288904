import React, { FunctionComponent, useCallback, useState, useEffect, useMemo } from 'react';
import { Input, Icon, InputOnChangeData } from 'semantic-ui-react';

import { IFilterValue } from '../../../../../utils/api/types';
import { IFilterConfig } from '../../../Context/TableFilterConfigContext';

import debounce from 'lodash/debounce';

export interface ITextFilterProps {
  item: IFilterConfig;
  value: IFilterValue;
  onChange: (e: unknown, { value }: InputOnChangeData) => void;
}

export const TextFilter: FunctionComponent<ITextFilterProps> = ({ item, value, onChange }) => {
  const [_value, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value, setValue])

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);
  const handleInputChange = useCallback((e: any) => {
    setValue(e.target.value);
    debouncedOnChange(e, { value: e.target.value });
  }, [debouncedOnChange]);

  const handleClearButtonClick = useCallback((e: any) => { onChange(e, { value: '' })}, [onChange]);

  return (
    <Input
      icon
      placeholder={item.label}
      value={_value}
      onChange={handleInputChange}
    >
      <input />
      {!item.nonClearable && value && <Icon link name="close" onClick={handleClearButtonClick} />}
    </Input>
  );
};
