import React, { FunctionComponent } from 'react';
import { Header, Icon } from 'semantic-ui-react';

interface IAccountUpdateModalHeader {
  title: string;
}

export const AccountUpdateModalHeader: FunctionComponent<IAccountUpdateModalHeader> = ({ title }) => (
  <Header as="h1" textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Icon color="green" name="edit"/>
    <Header.Content>
      {title}
    </Header.Content>
  </Header>
);
