import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import THEME from '../../../theme';
import Icon from "../../../common/Icons/Icon";
import { StyledSvg } from "../../../common/Icons/Icons";
import { useSession } from '../../../pages/auth/AuthContext/SessionContext';
import { UserMenuProps } from "./types";

const { FILL_CLASSNAME } = THEME;

const StyledUserMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  padding: 0 18px;
  user-select: none;
  position: relative;

  color: ${({ theme }) => theme.NAVBAR.COLOR};

  &.is-open, &:hover {
    color: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};

    > ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};
      }
    }
  }

  @media ${({ theme }) => theme.RWD.LAPTOP_S} {
    margin-left: 0;
  }
`;

const StyledUserData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4px 0 10px;
`;

const StyledUsername = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const StyledCompany = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;

const StyledDropdownMenu = styled.div`
  background-color: ${({ theme }) => theme.NAVBAR.BG};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 10px 15px -4px ${({ theme }) => theme.NAVBAR.SHADOW};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 23px 8px 0 18px;
  z-index: 10;
`;

export const UserMenu: FunctionComponent<UserMenuProps> = ({ children, isOpen, onClick }): ReactElement => {
  const { NAVBAR } = useContext(ThemeContext);
  const { currentUser } = useSession();
  const className = isOpen ? 'is-open' : '';

  const userFullName = currentUser ? `${currentUser.userFirstName} ${currentUser.userLastName}` : '';
  const accountName = currentUser ? currentUser.accountName : '';

  return (
    <StyledUserMenu className={className} onClick={() => onClick(isOpen ? null : 'User')}>
      <Icon name="userCircle" size={32} color={NAVBAR.COLOR} />
      <StyledUserData>
      <StyledUsername>{userFullName}</StyledUsername>
      <StyledCompany>{accountName}</StyledCompany>
      </StyledUserData>
      { isOpen
        ? <Icon name="triangleDown" size={24} color={NAVBAR.COLOR} />
        : <Icon name="triangleRight" size={24} color={NAVBAR.COLOR} />
      }
      { isOpen && (
        <StyledDropdownMenu>
          { children }
        </StyledDropdownMenu>
      )}
    </StyledUserMenu>
  );
};
