import React, { FunctionComponent } from "react";
import { useFetchAssessmentListApi } from '../../../../utils/api/options/assessments';
import { useSurveyResponseExportOptions } from "../../../../utils/export/options/assessmentList";
import ExportModal from "../ExportModal";

import { ExportAssessmentsModalProps } from "./types";

const ExportAssessmentsModal: FunctionComponent<ExportAssessmentsModalProps> = ({ isOpen, onCancel, selectedAssessments, pageAssessments, allAssessmentsCount }) => {
  const fetchAssessmentListApi = useFetchAssessmentListApi();
  const exportOptions = useSurveyResponseExportOptions();

  return (
    <ExportModal
      allItemsCount={allAssessmentsCount}
      exportOptions={exportOptions}
      fetchItemListApi={fetchAssessmentListApi}
      fileName="Assessment list"
      isOpen={isOpen}
      onCancel={onCancel}
      pageItems={pageAssessments}
      selectedItems={selectedAssessments}
    />
  );
};

export default ExportAssessmentsModal;
