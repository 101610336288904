import { ISurveyResponseStatusType } from "../../types/resources";
import THEME from "../../theme";

const { ASSESSMENT_STATUS } = THEME;

const colors: {[key in ISurveyResponseStatusType]: string} = {
  'cancelled': ASSESSMENT_STATUS.CANCELLED,
  'cancelled_by_user_archival': ASSESSMENT_STATUS.CANCELLED_BY_USER_ARCHIVAL,
  'finished': ASSESSMENT_STATUS.FINISHED,
  'in_progress': ASSESSMENT_STATUS.IN_PROGRESS,
  'not_started': ASSESSMENT_STATUS.NOT_STARTED,
}

export function getAssessmentStatusColor(status: ISurveyResponseStatusType): string {
  return colors[status]
}
