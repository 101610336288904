import { isArray } from "lodash";
import React, { FunctionComponent, ReactElement } from "react";
import MiniActionButton from "../../../common/Buttons/MiniActionButton";
import { ActionsCell, CheckboxCell, CheckboxHeaderCell, LinkCell, MultilineTextCell, RiskCell, TextCell, TextHeaderCell, TrNoData } from "../../../common/DataTable/Cells";
import { Table, TBody, Thead, THr, Tr } from "../../../common/DataTable/DataTable";
import { getAnswerValue } from "../../../utils/assessment";
import { canBeUpdated } from "../../../utils/issue";
import { getQuestionText } from "../../../utils/questionResponseIssue/questonAnswer";
import { GuidancePreviewLink } from "../common/GuidancePreviewLink";
import { AssessmentTableProps } from "./types";

const AssessmentFilteredIssuesTable: FunctionComponent<AssessmentTableProps> = ({ issues, selectIssues, onOpenUpdateIssuesModal, onOpenUpdatesHistoryModal }): ReactElement => {

  const {
    select: selectIssue,
    selectAll: selectAllIssues,
    areAllItemsSelected: areAllIssuesSelected,
    isSelected: isIssueSelected,
  } = selectIssues

  return (
    <Table>
      <Thead>
        <THr>
          <CheckboxHeaderCell id='all-issues' value={areAllIssuesSelected} onChange={selectAllIssues} />
          <TextHeaderCell>Issue</TextHeaderCell>
          <TextHeaderCell>Question</TextHeaderCell>
          <TextHeaderCell>Answer</TextHeaderCell>
          <TextHeaderCell align="center">Risk level</TextHeaderCell>
          <TextHeaderCell align="center">Guidance given</TextHeaderCell>
          <TextHeaderCell align="center">Notes</TextHeaderCell>
          <TextHeaderCell align="center">Action</TextHeaderCell>
        </THr>
      </Thead>
      <TBody>
        { issues.length === 0 && <TrNoData colSpan={8} />}
        { issues && issues.map(issue => {
          const isSelected = isIssueSelected(issue.id);
          const answer = getAnswerValue(issue.questionResponse.data);
          const question = getQuestionText(issue);
          const displayAnswer = isArray(answer) ? answer.join(' ') : answer;
          const notes = `${issue.updatesCount} note${issue.updatesCount !== 1 ? 's' : ''}`;

          return (
            <Tr key={issue.id}>
              <CheckboxCell id={issue.id} value={isSelected} onChange={selectIssue} />
              <TextCell text={issue.issueTemplate.name} />
              <MultilineTextCell text={question} />
              <MultilineTextCell text={displayAnswer} />
              <RiskCell value={issue.meta.riskCategory.data.id} />
              {issue.automatedIntervention ? <GuidancePreviewLink issue={issue} /> : <LinkCell id={issue.id} text={"-"} disabled={true} /> }
              <LinkCell id={issue.id} text={notes} onClick={() => onOpenUpdatesHistoryModal(issue)} />
              <ActionsCell>
                <MiniActionButton
                  id={issue.id}
                  disabled={!canBeUpdated(issue)}
                  name="updateFile"
                  tooltip="Update issue"
                  onClick={(id) => onOpenUpdateIssuesModal([issue])}
                />
                </ActionsCell>
            </Tr>
          )
        })}
      </TBody>
    </Table>
  );
};

export default AssessmentFilteredIssuesTable;
