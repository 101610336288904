import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "../Icons/Icon";
import { LinkButtonProps } from "./types";

const StyledLinkButton = styled.button`
  cursor: ${({ disabled }) => disabled ? 'normal' : 'pointer'};
  border: 0;
  background: transparent;
  display: flex;
  align-items: flex-end;
  span {
    color: ${({ disabled, theme }) => disabled ? theme.LINK_BUTTON.DISABLED : theme.LINK_BUTTON.COLOR};
    text-decoration: underline;
    margin-left: 5px;
  }
`;

const LinkButton: FunctionComponent<LinkButtonProps> = ({ disabled, icon, onClick, label }): ReactElement => {
  const { LINK_BUTTON: { COLOR, DISABLED } } = useContext(ThemeContext);

  const handleOnClick = () => {
    if(onClick) {
      onClick()
    }
  }

  return (
    <StyledLinkButton disabled={disabled} onClick={handleOnClick}>
       <Icon name={icon} size={16} color={disabled ? DISABLED : COLOR} />
       <span>{label}</span>
    </StyledLinkButton>
  );
};

export default LinkButton;
