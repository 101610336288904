import { Option } from "../../../../common/Forms/types"
import { IAutoClosing, IContact, IIssueRiskId, ILoginMethodsConfig, IScheduleReminder } from "../../../../types/resources"
import { UpdateAutoClosingPayload, UpdateLoginMethodPayload, UpdateScheduleRemindersPayload } from "../types"

export const getReminderScheduleFormData = (reminderSchedule: IScheduleReminder, enabled: boolean, interval: number, maxReminders: number): UpdateScheduleRemindersPayload => ({
  id: reminderSchedule.id,
  enabled,
  interval,
  maxReminders,
  intervalType: reminderSchedule.intervalType,
  reminderType: reminderSchedule.reminderType
})

export const getAutoClosingFormData = (autoClosing: IAutoClosing, enabled: boolean, waitDays: number, riskLevel: Option<IIssueRiskId>): UpdateAutoClosingPayload => ({
  id: autoClosing.id,
  enabled,
  waitDays,
  maxCloseableRiskCategory: riskLevel.value,
})

export const getLoginMethodsConfigFormData = (config: ILoginMethodsConfig): UpdateLoginMethodPayload => ({
  id: 'config',
  adminSamlSso: config.adminSamlSso,
  adminEmailAndPassword: config.adminEmailAndPassword,
  assessmentSamlSso: config.assessmentSamlSso,
  assessmentEmailedTokens: config.assessmentEmailedTokens
})

export const isReminderSchedulesFormDirty = (reminderSchedules: [IScheduleReminder, UpdateScheduleRemindersPayload][]) => {
  for (let i = 0; i < reminderSchedules.length; i++) {
    const [reminderSchedule, reminderScheduleFormData] = reminderSchedules[i]

    if (
      reminderSchedule.enabled !== reminderScheduleFormData.enabled
      || reminderSchedule.interval !== reminderScheduleFormData.interval
      || reminderSchedule.maxReminders !== reminderScheduleFormData.maxReminders
    ) {
      return true
    }
  }

  return false
}

export const isContactsFormDirty = (contacts: IContact[], formData: IContact[]) => {
  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i]
    const contactFormData = formData.find(c => c.id === contact.id)

    if (
      !contactFormData
      || contact.name !== contactFormData.name.trim()
      || contact.emailAddress !== contactFormData.emailAddress.trim()
    ) {
      return true
    }
  }

  return false
}

export const isIssueAutoClosingFormDirty = (autoClosing: IAutoClosing, autoClosingFormData: UpdateAutoClosingPayload) => !(
  autoClosing.enabled === autoClosingFormData.enabled
  && autoClosing.waitDays === autoClosingFormData.waitDays
  && autoClosing.meta.riskCategory.data.id === autoClosingFormData.maxCloseableRiskCategory
)
