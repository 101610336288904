import { useMemo } from 'react';
import { ICreateUserWithId } from '../../../pages/admin/UserUpload/types';
import { ITableConfig } from '../../../common/Table/Context/TableConfigContext';

import { textWrapperCell } from './textWrapperCell';
import { UserActionsMenu } from '../../../pages/admin/UserUpload/UploadUserTable/wrappers/UserActionsMenu';

export const useUploadedUserListTable = (duplicatedEmails: string[]) => useMemo<ITableConfig<ICreateUserWithId>[]>(() => [
  {
    header: 'First Name',
    Cell: textWrapperCell(
      'firstName',
      '-',
      undefined,
      [
        { key: 'required', attributes: null },
        { key: 'isType', attributes: 'string' },
        { key: 'lengthBetween', attributes: [1, 50] },
      ],
    ),
    cellProps: { textAlign: 'center' },
  },
  {
    header: 'Last Name',
    Cell: textWrapperCell(
      'lastName',
      '-',
      undefined,
      [
        { key: 'required', attributes: null },
        { key: 'isType', attributes: 'string' },
        { key: 'lengthBetween', attributes: [1, 50] },
      ],
    ),
  },
  {
    header: 'Email',
    Cell: textWrapperCell(
      'emailAddress',
      'Missing Email',
      undefined,
      [
        { key: 'required', attributes: null },
        { key: 'isType', attributes: 'string' },
        { key: 'email', attributes: null },
        { key: 'lengthBetween', attributes: [1, 60] },
        { key: 'isUnique', attributes: duplicatedEmails },
      ],
    ),
  },
  {
    header: 'Location',
    Cell: textWrapperCell(
      'location',
      undefined,
      undefined,
      [
        { key: 'isType', attributes: 'string' },
        { key: 'lengthBetween', attributes: [1, 100] },
      ]
    ),
  },
  {
    header: 'Department',
    Cell: textWrapperCell(
      'department',
      undefined,
      undefined,
      [
        { key: 'isType', attributes: 'string' },
        { key: 'lengthBetween', attributes: [1, 100] },
      ]
    ),
  },
  {
    header: 'Job Title',
    Cell: textWrapperCell(
      'jobTitle',
      undefined,
      undefined,
      [
        { key: 'isType', attributes: 'string' },
        { key: 'lengthBetween', attributes: [1, 100] },
      ]
    ),
  },
  {
    header: 'Actions',
    Cell: UserActionsMenu,
  }
], [duplicatedEmails]);
