import React, { useContext, useState, FunctionComponent, ReactElement } from "react";
import { ThemeContext } from "styled-components";
import { useHistory } from 'react-router-dom';
import { Navbar, NavbarLink, SearchInput, UserMenu, UserMenuLink } from "../../../shared/Navbar";
import { BackendMenuName } from "./types";
import { ROUTES } from "../../../../routing/BackendRouter";

const BackendNavbar: FunctionComponent = (): ReactElement => {
  const { NAVBAR } = useContext(ThemeContext);
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState<BackendMenuName>(null);
  const searchFilters = (searchTerm) => ([{ key: 'accountNameLike', value: searchTerm }]);
  const logout = () => history.push(ROUTES.logout);

  return (
    <Navbar openMenu={openMenu} onClose={() => setOpenMenu(null)}>
      <NavbarLink to={ROUTES.accountList}>Accounts</NavbarLink>
      <NavbarLink to={ROUTES.userList}>Users</NavbarLink>
      <SearchInput
        isOpen={openMenu === 'Search'}
        onClick={setOpenMenu}
        baseUrl={ROUTES.accountList}
        searchFilters={searchFilters}
      />
      <UserMenu isOpen={openMenu === 'User'} onClick={menuName => setOpenMenu(menuName)}>
        <UserMenuLink onClick={logout} iconName="cancel" iconColor={NAVBAR.LOGOUT}>Logout</UserMenuLink>
      </UserMenu>
    </Navbar>
  );
};

export default BackendNavbar;
