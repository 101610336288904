import React, { FunctionComponent, MutableRefObject, useCallback, useMemo } from 'react';
import { IUser } from '../../../../types/resources';
import { Table } from '../../../../common/Table';
import { IApiRequest, IFilters, IPagination, ISortByItem } from '../../../../utils/api/types';
import { ITableContextProps } from '../../../../common/Table/Context';
import { useBackendUserList } from '../../../../utils/table/columns/backendUserList';
import { useBackendUserListFilters } from '../../../../utils/table/filters/backendUserList';
import { UserUpdateProvider } from './contexts/UserUpdateContext';
import { ConfirmationModalProvider } from '../../../../common/modals/ConfirmationModal/ConfirmationModalContext';

interface IUserListProps {
  users: IUser[] | null;
  fetchUsers: IApiRequest<never, IUser[]>;
  loading?: boolean;
  _pagination?: MutableRefObject<IPagination>;
  _sortBy?: MutableRefObject<ISortByItem[]>;
  _filters?: MutableRefObject<IFilters>;
  height?: string;
}

export const UserListTable: FunctionComponent<IUserListProps> = ({
                                                                              users,
                                                                              fetchUsers,
                                                                              loading,
                                                                              _pagination,
                                                                              _sortBy,
                                                                              _filters,
                                                                              height,
                                                                            }) => {
  const userListTable = useBackendUserList();
  const filterConfig = useBackendUserListFilters();

  const tableContextProps = useMemo<ITableContextProps<IUser>>(() => ({
    config: userListTable,
    data: users,
    fetch: fetchUsers,
    loading: loading ?? false,
    filter: _filters,
    pagination: _pagination,
    sort: _sortBy,
    filterConfig,
  }), [userListTable, users, loading, fetchUsers, _filters, _pagination, _sortBy, filterConfig]);

  const handleUserUpdate = useCallback(async () => {
    await fetchUsers?.();
  }, [fetchUsers]);

  return (
    <ConfirmationModalProvider>
      <UserUpdateProvider value={handleUserUpdate}>
        <Table<IUser>
        contextProps={tableContextProps}
        height={height}
        sortable
        filterable
        sticky
      />
      </UserUpdateProvider>
    </ConfirmationModalProvider>
  );
};
