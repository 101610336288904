import { IIssueRiskId } from "../../types/resources";
import THEME from "../../theme";

const { RISK } = THEME;

const colors: {[key in IIssueRiskId]: string} = {
  high: RISK.HIGH,
  medium: RISK.MEDIUM,
  low: RISK.LOW,
  none: RISK.NONE
}

export function getRiskColor(riskId: IIssueRiskId): string {
  return colors[riskId]
}
