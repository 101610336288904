import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box, { StyledBox } from "../../../../common/Box/Box";
import { SettingsCardProps } from "../types";

const StyledSettingsCard = styled.div`
  margin: 0 18px 18px 0;
  width: 330px;

  >${StyledBox} {
    align-items: flex-start;
    flex-direction: column;
    padding: 18px 0 8px 0;
  }
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.COLORS.BLACK};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  padding-left: 18px;
  text-align: left;
  width: 100%;
`;

const SettingsCard: FunctionComponent<SettingsCardProps> = ({ children, title }): ReactElement => {
  return (
    <StyledSettingsCard>
      <Box border shadow>
        <StyledTitle>{ title }</StyledTitle>
        {children}
      </Box>
    </StyledSettingsCard>
  );
};

export default SettingsCard;
