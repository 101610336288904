import React, { useCallback, useEffect, useMemo, useState, FunctionComponent } from "react";
import styled from "styled-components";
import Select from "../Forms/Select";
import PaginationButton from "./PaginationButton";
import { PaginationProps } from "./types";
import InputText from "../Forms/InputText";
import { StyledInput } from "../Forms/StyledInput";
import { findOptionByValue } from "../Forms/utils";
import { Option } from "../Forms/types";

const selectOptions: Option[] = [
  { value: '10', label: 'Show 10 items' },
  { value: '20', label: 'Show 20 items' },
  { value: '50', label: 'Show 50 items' },
  { value: '100', label: 'Show 100 items' },
];

const StyledPagination = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-width: 360px;
`;

const StyledFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`;

const StyledFilterSummary = styled.div`
  display: flex;
  margin: 0 18px;
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.PAGINATION.COLOR};
`;

const StyledButtons = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;

  ${StyledInput} {
    color: ${({ theme }) => theme.PAGINATION.COLOR};
    margin: 0 8px;
    text-align: center;
    height: 28px;
    width: 50px;
  }
`;

const StyledTotalPages = styled.div`
  display: flex;
  margin: 0 16px 0 0;
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.PAGINATION.COLOR};
`;

const Pagination: FunctionComponent<PaginationProps> = ({ currentPage, itemsPerPage, itemName, onPageChange, onFilterChange, select, total }) => {
  const pages = useMemo(() => Math.ceil(total / itemsPerPage), [itemsPerPage, total]);

  const [inputValue, setInputValue] = useState(currentPage.toString());

  useEffect(() => {
    setInputValue(currentPage.toString());
  }, [currentPage]);

  const parsePageNumber = useCallback((page) => {
    return Math.min(Math.max(1, page), pages);
  }, [pages]);

  const handleInputChange = useCallback((newValue) => {
    setInputValue(newValue);
  }, []);

  const handlePageChange = useCallback(() => {
    const page = +inputValue;
    const isPageValid =
      !Number.isNaN(page) &&
      Number.isInteger(page) &&
      page >= 1 &&
      page <= pages;

    if (isPageValid) {
      onPageChange(page);
    } else {
      setInputValue(currentPage.toString());
    }
  }, [currentPage, inputValue, pages, onPageChange]);

  const handleFilterChange = useCallback((options: Option[]) => {
    if(onFilterChange) {
      onFilterChange(+options[0].value);
    };
  }, [onFilterChange]);

  const goToFirstPage = useCallback(() => {
    onPageChange(1);
  }, [onPageChange]);

  const goToPreviousPage = useCallback(() => {
    onPageChange(parsePageNumber(currentPage - 1));
  }, [onPageChange, parsePageNumber, currentPage]);

  const goToNextPage = useCallback(() => {
    onPageChange(parsePageNumber(currentPage + 1));
  }, [onPageChange, parsePageNumber, currentPage]);

  const goToLastPage = useCallback(() => {
    onPageChange(pages);
  }, [onPageChange, pages]);

  const filterLabel = useMemo(() => {
    return `${total} ${itemName}${total === 1 ? '' : 's'} found`;
  }, [itemName, total]);

  const selectOption = useMemo(() => findOptionByValue(selectOptions, itemsPerPage.toString()), [itemsPerPage]);

  return (
    <StyledPagination>
      {select && (
        <StyledFilter>
          <StyledFilterSummary>{filterLabel}</StyledFilterSummary>
          <Select value={selectOption} options={selectOptions} onChange={handleFilterChange}/>
        </StyledFilter>
      )}
      <StyledButtons>
        <PaginationButton name="first" tooltip="First page" onClick={goToFirstPage} disabled={currentPage === 1} />
        <PaginationButton name="triangleLeft" tooltip="Previous page" onClick={goToPreviousPage} disabled={currentPage === 1} />
        <InputText placeholder="" value={inputValue} onChange={handleInputChange} onBlur={handlePageChange} />
        <StyledTotalPages>of {pages}</StyledTotalPages>
        <PaginationButton name="triangleRight" tooltip="Next page" onClick={goToNextPage} disabled={currentPage === pages || pages === 0} />
        <PaginationButton name="last" tooltip="Last page" onClick={goToLastPage} disabled={currentPage === pages || pages === 0} />
      </StyledButtons>
    </StyledPagination>
  );
};

export default Pagination;
