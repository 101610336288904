import { useApiRequestOptions } from "../../hooks";

export const useFetchAutoClosingApi = () => useApiRequestOptions({
  uri: 'issue_auto_close_policies'
}, []);

export const useUpdateAutoClosingApi = (id: string) => useApiRequestOptions({
  uri: `issue_auto_close_policies/${id}`,
  method: 'patch',
  passBackErrorStatus: [422],
  successMessage: 'Auto-close pilicy settings saved',
  serialization: {
    type: 'issue_auto_close_policy',
    options: {
      attributes: ['enabled', 'maxCloseableRiskCategory', 'waitDays'],
    },
  }
}, [id])