import React, { FunctionComponent, useCallback } from 'react';
import { Filter } from './Filter';
import { Grid, Button } from 'semantic-ui-react';
import { AutoFilter } from './AutoFilter';
import { useTableFilter } from '../Context/TableFilterContext';
import { useTableFilterConfig } from '../Context/TableFilterConfigContext';

export const Filters: FunctionComponent = () => {
  const { filterConfig, filterAutoConfig } = useTableFilterConfig();
  const { clearFilters } = useTableFilter();
  const handleClearButton = useCallback(() => { clearFilters(); }, [clearFilters]);

  return (
    <>
    <Grid.Column width={12}>
      {filterConfig?.map((item) => (
        <Filter key={`table-filter-${item.key}`} item={item} />
      ))}
    </Grid.Column>
    <Grid.Column width={4} textAlign="right">
      {!!filterAutoConfig && <AutoFilter />}
      <Button color="blue" onClick={handleClearButton}>Clear All Filters</Button>
    </Grid.Column>
    </>
  )
};
