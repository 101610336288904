import { useEffect, useState, useCallback, useMemo } from "react";

export interface ISelectionCallbacks<TData extends { id: string }> {
    selection: { [id: string]: TData; };
    selectOne: (resource: TData) => void;
    isSelected: (id: string) => boolean;
    selectAll: (forceClose?: boolean) => void;
    areAllSelected: boolean;
    isAnySelected: boolean;
}

export const useSelection = <TData extends { id: string }>(data: TData[] | null): ISelectionCallbacks<TData> => {
  const [selection, setSelection] = useState<{ [id: string]: TData }>({});

  const selectOne = useCallback((resource: TData) => {
    if (!data || !data.find((entry) => entry.id === resource.id)) {
      return;
    }

    setSelection((prevSelected) => {
      const copy = { ...prevSelected };

      if (copy[resource.id]) {
        delete copy[resource.id];
      } else {
        copy[resource.id] = resource;
      }

      return copy;
    });
  }, [data]);

  const selectAll = useCallback((forceClose: boolean = false) => {
    if (!data) {
      return;
    }

    setSelection((prevSelected) => {
      const areAllSelected = data.reduce((acc, cur) => acc && !!prevSelected[cur.id], true);
      const copy = { ...prevSelected };

      if (areAllSelected || forceClose) {
        data.forEach((resource) => {
          delete copy[resource.id];
        });
      } else {
        data.forEach((resource) => {
          copy[resource.id] = resource;
        });
      }

      return copy;
    });
  }, [data]);

  const isSelected = useCallback((id: string) => !!selection[id], [selection]);

  const areAllSelected = useMemo(() => data && data.length ? data.length === Object.keys(selection).length : false, [data, selection]);
  const isAnySelected = useMemo(() => Object.keys(selection).length !== 0, [selection]);

  useEffect(() => () => {
    if (data) {
      selectAll(true);
    }
  }, [data, selectAll]);

  return {
    selection,
    selectOne,
    isSelected,
    selectAll,
    areAllSelected,
    isAnySelected,
  };
};