import { useActiveApplication } from '../../../pages/auth/AuthContext/ApplicationContext';
import { useApiRequestOptions } from '../hooks';

export const useAuthenticateApi = () => useApiRequestOptions({
  uri: 'auth/authenticate',
  method: 'post',
  noAppKeyInBaseUrl: true,
  noAuth: true,
  passBackErrorStatus: [401, 422],
  successMessage: null,
}, []);

export const useLoginToAccountApi = () => useApiRequestOptions({
  uri: 'login',
  method: 'post',
  passBackErrorStatus: [401],
  successMessage: null,
}, []);

export const useLogoutApi = () => {
  const { applicationKey } = useActiveApplication();

  return useApiRequestOptions({
    uri: `auth/${applicationKey}/login`,
    noAppKeyInBaseUrl: true,
    method: 'delete',
    successMessage: null,
  }, []);
};

export const useSubmitForgottenPasswordApi = () => useApiRequestOptions({
  uri: 'password_resets',
  method: 'post',
  noAppKeyInBaseUrl: true,
  noAuth: true,
  passBackErrorStatus: [422],
  successMessage: null,
  serialization: {
    type: 'password_reset',
    options: {
      attributes: ['emailAddress'],
    }
  },
}, []);

export const useResetPasswordApi = (userIdentityId: string) => useApiRequestOptions({
  uri: `password_resets/${userIdentityId}`,
  method: 'patch',
  noAppKeyInBaseUrl: true,
  noAuth: true,
  passBackErrorStatus: [422],
  successMessage: 'Password reset successfully, please login to continue',
  serialization: {
    type: 'password_reset',
    options: {
      attributes: ['password', 'passwordResetToken']
    }
  }
}, [userIdentityId]);
