import { ISamlIdentityProvider } from "../../types/resources";

export const blankSsoConfig: ISamlIdentityProvider = {
  id: null,
  x509Certificate: '',
  entityId: '',
  ssoUrl: '',
  jitProvisioning: false,
  validated: false,
};
