import React, { FunctionComponent, useEffect } from 'react';
import { Grid, Message } from 'semantic-ui-react';

import { IApiError409, IApiError422 } from '../../../utils/api/types';
import { useAirbrake } from '../../../utils/airbrake';
import { defaultErrorHandler } from '../../../utils/form/error-handlers/defualt';

export interface IFormErrorHandler {
  match: (error: IApiError409 | IApiError422) => boolean;
  describe: (error: IApiError409 | IApiError422) => string | null;
}

export interface IFormErrorProps {
  errors: (IApiError409 | IApiError422)[];
  handlers?: IFormErrorHandler[];
}

export const FormError: FunctionComponent<IFormErrorProps> = ({ errors, handlers = [] }) => {
  const airbrake = useAirbrake();

  useEffect(() => {
    if (errors) {
      // TODO: check if it is alright to do so
      airbrake.notify(new Error(JSON.stringify(errors)));
    }
  }, [airbrake, errors]);

  return errors ? (
    <Grid.Row>
      <Grid.Column width={16}>
        {errors.map((error, index) => (
          <Message key={`error-in-form-${index}-${error.source}`} error>
            {([...handlers, defaultErrorHandler]).find((handler) => handler.match(error))?.describe(error)}
          </Message>
        ))}
      </Grid.Column>
    </Grid.Row>
  ) : null;
};
