import React, { ReactElement } from 'react';
import { FormApi } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import { Form as SemanticForm, Grid } from 'semantic-ui-react';

import { FormButtons } from './FormButtons';
import { IFormSegment, FormSegment } from './FormSegment';
import { FormError, IFormErrorHandler } from './FormError';

import { IApiError409, IApiError422 } from '../../utils/api/types';
import { DeepPartial } from '../types';

export interface IFormConfig {
  segments: IFormSegment[];
}

export interface IFormProps<TData,> {
  config: IFormConfig;
  onSubmit: (values: TData, form: FormApi<TData>) => Promise<IApiError409[] | IApiError422[] | void>;
  onCancel?: () => void;
  errorHandlers?: IFormErrorHandler[];
  initialValues?: DeepPartial<TData>;
}

export const Form = <TData,>(
  { config, onSubmit, onCancel, initialValues, errorHandlers }: IFormProps<TData>
): ReactElement => (
  <FinalForm<TData>
    onSubmit={onSubmit}
    initialValues={initialValues as any}
  >
    {({ handleSubmit, submitting, submitErrors, form, hasSubmitErrors }) => (
      <SemanticForm onSubmit={handleSubmit} error={hasSubmitErrors}>
        <Grid>
          <FormError errors={submitErrors as (IApiError409 | IApiError422)[]} handlers={errorHandlers} />
          <Grid.Row>
            {config.segments.map((segmentItem) => (
              <Grid.Column key={`form-field-${segmentItem.key}`} width={segmentItem.segmentWidth ?? 16}>
                <FormSegment config={segmentItem} />
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <FormButtons submitting={submitting} resetForm={form.reset} onCancel={onCancel} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SemanticForm>
    )}
  </FinalForm>
);
