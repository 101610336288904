import React, { createContext, FunctionComponent, useState, useEffect, useContext } from 'react';

import { useUserUploadData } from './UserUploadDataContext';
import { useCreateUserValidator } from '../../../../../utils/dataUploads/createUser';

const defaultErrorMessage = 'The list you have provided has some errors, shown in the table below. Please correct the errors in your list and upload it again.';
const invalidFileErrorMessage = 'One or more columns are missing or have the wrong name, please use the provided import template to upload users.';

export const UserUploadHasErrorContext = createContext<[boolean, string]>([false, defaultErrorMessage]);

export const UserUploadHasErrorProvider: FunctionComponent = ({ children }) => {
  const [users, setUsers, duplicatedEmails, isFileValid] = useUserUploadData(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const checkUserValidation = useCreateUserValidator(duplicatedEmails);

  useEffect(() => {
    if(!isFileValid) {
      setErrorMessage(invalidFileErrorMessage)
      setHasError(true)
    } else if (users) {
      const foundError = !!users.find(checkUserValidation);
      setHasError(foundError);
      setErrorMessage(defaultErrorMessage)
    }
  }, [checkUserValidation, isFileValid, users]);
  
  return (
    <UserUploadHasErrorContext.Provider value={[!isFileValid || hasError, errorMessage]}>
      {children}
    </UserUploadHasErrorContext.Provider>
  );
};

export const useUserUploadHasError = () => useContext(UserUploadHasErrorContext);
