import React, { useState, FunctionComponent, ReactElement, useMemo } from "react";
import Modal from "../../../../common/Modal/Modal";
import { IssueFormData, IssueOperation } from "../../../../forms/IssueForm/types";
import { getIssueFormDataInitialValues } from "../../../../forms/IssueForm/utils";
import { IIssue } from "../../../../types/resources";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useCreateAdministratorClosureApi, useCreateAdministratorNoteApi, useCreateAdministratorReopeningApi } from "../../../../utils/api/options/questionResponseIssue/administratorNotes";
import { IApiRequest } from "../../../../utils/api/types";
import { isOpen as isIssueOpen, isClosed as isIssueClosed } from "../../../../utils/issue";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import IssueFormWrapper from "./IssueFormWrapper";
import { UpdateIssuesModalProps, UpdateIssuesModalStep } from "./types";
import { getModalTitle } from "./utils";

const UpdateIssuesModal: FunctionComponent<UpdateIssuesModalProps> = ({ isOpen, issues, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<UpdateIssuesModalStep>('form');
  const openIssues = issues.filter(isIssueOpen);
  const closedIssues = issues.filter(isIssueClosed);
  const initialValues = getIssueFormDataInitialValues(issues);
  const multi = issues.length > 1;

  const addNoteApi = useCreateAdministratorNoteApi();
  const addClosureApi = useCreateAdministratorClosureApi();
  const addReopeningApi = useCreateAdministratorReopeningApi();
  const { fetch: addNote } = useApiRequest(addNoteApi);
  const { fetch: addClosure } = useApiRequest(addClosureApi);
  const { fetch: addReopening } = useApiRequest(addReopeningApi);

  const handleRequest = async (request: IApiRequest, data: IssueFormData, filteredIssues: IIssue[]) => {
    const results = await Promise.all(filteredIssues.map((issue) => request({
      note: data.message,
      questionResponseIssue: { id: issue.id }
    })));

    const errors = results.filter(result => !!result[1]);

    if(errors.length > 0) {
      setStep('failure');
      return;
    }

    setStep('success');
  };

  const send = (data: IssueFormData, operation: IssueOperation): Promise<void> => {
    switch(operation) {
      case 'note': return handleRequest(addNote, data, issues);
      case 'noteAndClose': return handleRequest(addClosure, data, openIssues);
      case 'noteAndReopen': return handleRequest(addReopening, data, closedIssues);
    }
  };

  const handleFormSubmit = async (data: IssueFormData) => {
    setStep('processing');
    const operation = data.operation.value as IssueOperation;
    await send(data, operation);
  };

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} title={getModalTitle('form', issues.length > 1)}>
      {step === 'form' && <IssueFormWrapper initialValues={initialValues} issues={issues} onCancel={onCancel} onOk={handleFormSubmit} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text={`Issue${multi ? 's': ''} successfully updated.`} />}
      {step === 'failure' && <Failure onOk={() => setStep('form')} />}
    </Modal>
  );
};

export default UpdateIssuesModal;
