import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { isSheetValid } from '../../pages/admin/UserUpload/utils';

export interface IExportConfig<TData> {
  header: string;
  valueExtractor: (data: TData) => string;
}

export const prepareDataForExport = <TData>(data: TData[], config: IExportConfig<TData>[]): { [key: string]: string }[] =>
  data.map((entry: TData) => {
    const result: { [key: string]: string } = {};
    config.forEach(({ header, valueExtractor }) => {
      result[header] = valueExtractor(entry);
    });

    return result;
  });

export type IExportFileType = 'xlsx' | 'xls' | 'csv';

export const FILE_TYPES: { [key in IExportFileType]: string } = {
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  'xls': 'application/vnd.ms-excel;charset=UTF-8',
  'csv': 'text/csv;charset=UTF-8',
}
export const FILE_EXTENSIONS: { [key in IExportFileType]: IExportFileType } = {
  'xlsx': 'xlsx',
  'xls': 'xls',
  'csv': 'csv',
};

export const exportData = (
  fileName: string,
  data: { [key: string]: string }[],
  header?: string[],
  docType: IExportFileType = 'xlsx'
) => {
  const ws = XLSX.utils.json_to_sheet(data, { header });

  const wb = { Sheets: { 'Data': ws }, SheetNames: ['Data'] };
  const excelBuffer = XLSX.write(wb, { bookType: docType, type: 'array' });
  const blobData = new Blob([excelBuffer], { type: FILE_TYPES[docType] });

  FileSaver.saveAs(blobData, `${fileName}.${FILE_EXTENSIONS[docType]}`);
};

export const loadData = <TData>(file: File): Promise<[TData[], boolean]> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const wb = XLSX.read(e.target?.result, { type: 'array' });
    const sheet = wb.Sheets[wb.SheetNames[0]]
    const data = XLSX.utils.sheet_to_json<TData>(sheet) as TData[];

    resolve([data, isSheetValid(sheet)]);
  };
  reader.readAsArrayBuffer(file);
});
