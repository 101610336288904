import { IIssue, IUser } from "../types/resources";
import { isArchived } from "./user";

export function isOpen(issue: IIssue) {
  return !isArchived(issue.user) && issue.meta.isOpen;
}

export function isClosed(issue: IIssue) {
  return !isOpen(issue);
}

export function canBeContacted(issue: IIssue) {
  return !isArchived(issue.user);
}

export function canBeClosed(issue: IIssue) {
  return !isArchived(issue.user) && !isClosed(issue)
}

export function canBeUpdated(issue: IIssue) {
  return !isArchived(issue.user);
}

export function isResolvableByUser(issue: IIssue) {
  return isOpen(issue) && issue.meta.userResolvable;
}

export function isResolvableByAdmin(issue: IIssue) {
  return isOpen(issue) && !issue.meta.userResolvable;
}

export function getIssueStatusText(isOpen: boolean) {
  return isOpen ? 'Unresolved' : 'Resolved'
}

export function decorateIssues(issues: IIssue[], user: IUser) {
  return issues.map(issue => ({
    ...issue,
    user
  }))
}
