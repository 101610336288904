import React, { FunctionComponent, ReactElement } from "react";
import ActionCard from "../../../../common/ActionCard/ActionCard";
import Button from "../../../../common/Buttons/Button";
import { canBeClosed, canBeUpdated, decorateIssues } from "../../../../utils/issue";
import { AssessmentActionsCardProps } from "../types";

const AssessmentActionsCard: FunctionComponent<AssessmentActionsCardProps> = ({ issues, onOpenUpdateIssuesModal, onOpenExportAssessmentIssuesModal, onOpenCloseIssuesModal, selectedIssues, user }): ReactElement => {
  const decoratedIssues = decorateIssues(issues, user)
  const decoratedSelectedIssues = decorateIssues(selectedIssues, user)
  const canUpdate = decoratedSelectedIssues.filter(canBeUpdated).length > 0;
  const canClose = decoratedIssues.filter(canBeClosed).length > 0;

  return (
    <ActionCard
      cardWidth="34%"
      heading="Bulk actions"
    >
      <Button label="Update issues" disabled={!canUpdate} theme="SUCCESS_PRIMARY" icon="updateFile" onClick={() => onOpenUpdateIssuesModal(decoratedSelectedIssues)} />
      <Button label="Export" theme="SUCCESS_SECONDARY" icon="download" onClick={() => onOpenExportAssessmentIssuesModal(decoratedSelectedIssues)} />
      <Button label="Close all" disabled={!canClose} theme="DANGER_PRIMARY" icon="cancel" onClick={() => onOpenCloseIssuesModal(decoratedIssues)} />
    </ActionCard>
  );
};

export default AssessmentActionsCard;
