import { ContactFormData } from "./types";

const initialValues = {
  subject: '',
  message: '',
};

export function getContactFormDataInitialValues(): ContactFormData {
  return { ...initialValues };
}
