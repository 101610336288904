import React, { createContext, FunctionComponent, useState, useCallback, useContext } from 'react';
import { UserFormModal } from '..';
import { IUser } from '../../../../../types/resources';
import { ICreateUserWithId } from '../../../UserUpload/types';

const UserFormModalContext = createContext<
  (
    user?: IUser | ICreateUserWithId,
    onUserUpdate?: () => void,
    onUserFormSubmit?: (user: IUser | ICreateUserWithId) => void
  ) => void
>(() => void 0);

export const UserFormModalProvider: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | ICreateUserWithId | null>(null);
  const [onUserUpdate, setUserUpdate] = useState<(() => void) | undefined>(undefined);
  const [onUserFormSubmit, setUserFormSubmit] = useState<((user: IUser | ICreateUserWithId) => void) | undefined>(undefined);

  const openModal = useCallback((
    user?: IUser | ICreateUserWithId,
    onUserUpdate?: () => void,
    onUserFormSubmit?: (user: IUser | ICreateUserWithId) => void,
  ) => {
    setUser(user ?? null);
    setUserUpdate(() => onUserUpdate);
    setUserFormSubmit(() => onUserFormSubmit);

    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setUser(null);
    setUserUpdate(undefined);
    setUserFormSubmit(undefined);

    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <UserFormModalContext.Provider value={openModal}>
      {children}
      <UserFormModal
        isOpen={isOpen}
        user={user}
        closeModal={closeModal}
        onUserUpdate={onUserUpdate}
        onUserFormSubmit={onUserFormSubmit}
      />
    </UserFormModalContext.Provider>
  )
};

export const useOpenUserFormModal = () => useContext(UserFormModalContext);
