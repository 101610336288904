import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type AssessmentStatusFilterKey = "status"

const AssessmentStatusFilter: FunctionComponent<SelectFilterProps<AssessmentStatusFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Assessment status"
      options={options}
      placeholder="Assessment status"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default AssessmentStatusFilter;
