import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { AccountList } from '../pages/backend/AccountList';
import { ILoginComponent, LoginPage } from '../pages/auth/LoginPage';
import { applicationPathTo, ApplicationRoute, ApplicationRouter } from './ApplicationRouter';
import { IApplication } from '../pages/auth/AuthContext/ApplicationContext';
import { ΒackendLayout } from '../layouts/backend/Default';
import { Logout } from '../pages/auth/Logout';
import { ModalProvider } from '../pages/backend/modals';
import { ThemeProvider } from "styled-components";
import THEME from '../theme';
import { UserList } from '../pages/backend/UserList';

export const BASE_PATH = 'backend';
const pathTo = applicationPathTo(BASE_PATH);
export const ROUTES = {
  login: pathTo('login'),
  logout: pathTo('logout'),
  root: pathTo(null),
  home: pathTo('/'),
  accountList: pathTo('accounts'),
  userList: pathTo('users'),
};

const UserLogin: ILoginComponent = ({ users, loginToAccount }) => {
  if (users.length > 1) {
    throw new Error('cannot handle multiple backend accounts');
  }
  
  useEffect(() =>{
    loginToAccount(users[0])
  }, [loginToAccount, users])

  return <></>;
};

export const BackendRouter: FunctionComponent = () => {
  const backendApplication = useMemo<IApplication>(() => ({
    applicationKey: BASE_PATH,
    homePath: ROUTES.home,
    loginPath: ROUTES.login,
  }), []);

  return (
    <ApplicationRouter application={backendApplication} defaultLayout={ΒackendLayout}>
      <ThemeProvider theme={THEME}>
        <ModalProvider>
          <Switch>
            <ApplicationRoute path={ROUTES.login} layout={null} unauthenticated>
              <LoginPage homeUri={ROUTES.home} loginComponent={UserLogin}/>
            </ApplicationRoute>

            <ApplicationRoute path={ROUTES.logout} layout={null} unauthenticated>
              <Logout redirectTo={ROUTES.login}/>
            </ApplicationRoute>

            <ApplicationRoute path={[ROUTES.home, ROUTES.root]} exact>
              <Redirect to={ROUTES.accountList}/>
            </ApplicationRoute>
            <ApplicationRoute path={ROUTES.accountList} component={AccountList}/>
            <ApplicationRoute path={ROUTES.userList} component={UserList}/>

            <Redirect to={ROUTES.home}/>
          </Switch>
        </ModalProvider>
      </ThemeProvider>
    </ApplicationRouter>
  );
};
