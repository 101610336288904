import React, { FunctionComponent, ReactElement } from "react";
import { IconName, IconProps } from "../types";
import {
  ArrowDown,
  ArrowRight,
  Bell,
  Calendar,
  Cancel,
  CancelCircle,
  Chat,
  ClearFilters,
  Clock,
  Download,
  Envelope,
  Eye,
  FaceHappy,
  FaceSad,
  Filter,
  First,
  HomeworkingDSE,
  Info,
  InfoOutlined,
  Last,
  Loupe,
  Ok,
  OkCircle,
  OfficeDSE,
  Pencil,
  Padlock,
  Refresh,
  Switch,
  TriangleBigDown,
  TriangleBigUp,
  TriangleLeft,
  TriangleRight,
  TriangleDown,
  UpdateFile,
  Upload,
  UserAdd,
  UserCircle,
  UserEdit,
  Users,
  UsersTalking,
  Warning,
  WarningTriangle,
} from "./Icons";

export type Icon = FunctionComponent<IconProps>;

const ICON_COMPONENTS_MAP: {[key in IconName]: Icon} = {
  'arrowDown': ArrowDown,
  'arrowRight': ArrowRight,
  'bell': Bell,
  'calendar': Calendar,
  'cancel': Cancel,
  'cancelCircle': CancelCircle,
  'chat': Chat,
  'clearFilters': ClearFilters,
  'clock': Clock,
  'download': Download,
  'envelope': Envelope,
  'eye': Eye,
  'faceHappy': FaceHappy,
  'faceSad': FaceSad,
  'filter': Filter,
  'first': First,
  'homeworkingDSE': HomeworkingDSE,
  'info': Info,
  'infoOutlined': InfoOutlined,
  'last': Last,
  'loupe': Loupe,
  'ok': Ok,
  'okCircle': OkCircle,
  'officeDSE': OfficeDSE,
  'padlock': Padlock,
  'pencil': Pencil,
  'refresh': Refresh,
  'switch': Switch,
  'triangleBigDown': TriangleBigDown,
  'triangleBigUp': TriangleBigUp,
  'triangleLeft': TriangleLeft,
  'triangleRight': TriangleRight,
  'triangleDown': TriangleDown,
  'updateFile': UpdateFile,
  'upload': Upload,
  'userAdd': UserAdd,
  'userCircle': UserCircle,
  'userEdit': UserEdit,
  'users': Users,
  'usersTalking': UsersTalking,
  'warning': Warning,
  'warningTriangle': WarningTriangle,
};

const Icon = (props: IconProps): ReactElement => {
  const { name } = props;
  const IconComponent = ICON_COMPONENTS_MAP[name];

  return <IconComponent {...props} />;
};

export default Icon;
