import React, { ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { get } from 'lodash';
import { IFormFieldValidation } from '../../../common/Form/FormField';
import { useFormValidators } from '../../form/validation';

const ErrorWrapper = styled.span`
  color: red;
`;

export const textWrapperCell = <TData, TValue = string>(
  key: string,
  noValueLabel?: string,
  format?: (value: TValue, noValue?: string) => string,
  validation?: IFormFieldValidation[],
) =>
  ({ value }: { value: TData }): ReactElement => {
    const result = get(value, key);
    const formattedResult = (format && result !== undefined) ? format(result) : result;

    const validator = useFormValidators(validation);
    const error = validator(result, value);

    return error ? (
      <Popup
        content={error}
        pinned
        wide="very"
        mouseEnterDelay={500}
        mouseLeaveDelay={500}
        inverted
        trigger={<ErrorWrapper>{formattedResult ?? noValueLabel ?? ''}</ErrorWrapper>}
      />
    ) : (
      <>
      {formattedResult ?? noValueLabel ?? ''}
      </>
    );
  };
