import React, { FunctionComponent, useCallback } from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { AccountUpdateModalHeader } from './AccountUpdateModalHeader';
import { IAccount } from '../../../../types/resources';
import { AccountUpdateForm } from '../../common/AccountUpdateForm';

export interface AccountUpdateModalProps {
  isOpen: boolean;
  accountSelection: { [key: string]: IAccount };
  closeModal: () => void;
  onAccountUpdate?: () => void;
  onChange?: () => void;
}

export const AccountUpdateModal: FunctionComponent<AccountUpdateModalProps> = ({
                                                                                 isOpen,
                                                                                 accountSelection,
                                                                                 closeModal,
                                                                                 onChange,
                                                                                 onAccountUpdate,
                                                                               }) => {

  const handleOnChange = useCallback(() => {
    onChange?.();
    onAccountUpdate?.();
    closeModal();
  }, [onAccountUpdate, onChange, closeModal]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <AccountUpdateModalHeader title={_.isEmpty(accountSelection) ? 'New account' : 'Edit account'}/>
      <Modal.Content>
        <AccountUpdateForm
          accountSelection={accountSelection}
          onChange={handleOnChange}
        />
      </Modal.Content>
    </Modal>
  );
};
