import React, { FunctionComponent, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { IAccount } from '../../../types/resources';
import { useApiRequest } from '../../../utils/api/hooks';
import { useGetAccountAssessmentReportApi } from '../../../utils/api/options/accounts';
import { FILE_EXTENSIONS, FILE_TYPES } from '../../../utils/export';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export interface IAssessmentReport {
    id: string;
    selfAssessments: {
        assessmentType: string
        invited: number;
        completed: number;
    }[];
    followupAssessments: {
        required: number;
        initiated: number;
        declined: number;
    },
    topHighRiskIssues: {
        issue: string;
        users: number;
    }[]
}

interface IAssessmentReportExport {
    account: IAccount;
    report: IAssessmentReport;
}

const exportToExcelWorksheet: (report: IAssessmentReportExport) => [string, XLSX.Sheet] = ({ account, report }) => {
    const sheetName = `${account.name.substring(0, 22)} - ${account.accountCode}`
    let selfAssessments = report.selfAssessments.map((assessmentStats) => {
        const invited = Math.round(((assessmentStats as any).invited ?? 0) * 100) / 100
        const completed = Math.round(((assessmentStats as any).completed ?? 0) * 100) / 100
        return [
            [{ t: 'n', v: invited, z: '0%' },   `of employees were invited for ${assessmentStats.assessmentType} assessment`],
            [{ t: 'n', v: completed, z: '0%' }, `of employees completed the ${assessmentStats.assessmentType} assessment`],
        ]
    });
    const followups = report.followupAssessments;
    const followupData = [
        [{ t: 'n', v: followups.required }, `assessments were rated high risk and still require a follow-up (no follow-up invite sent yet)`],
        [{ t: 'n', v: followups.initiated }, `high risk assessments have a follow-up in progress (invited or scheduled)`],
        [{ t: 'n', v: followups.declined }, `high risk assessment follow-up invites have been declined`],
    ]

    const issues = report.topHighRiskIssues;
    const issuesData = issues.slice(0, 5).map((entry) => {
        return [{ t: 'n', v: entry.users, z: '0%' }, entry.issue]
    });

    const data = [
        ["Self assessments:"],
        ...selfAssessments.flat(),
        [],
        ["Follow-ups:"],
        ...followupData,
        [],
        ["Top high risk issues"],
        ...issuesData
    ]
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    return [sheetName, worksheet];
}

export const exportToExcelWorkbook = (reports: IAssessmentReportExport[]) => {
    const wb = XLSX.utils.book_new();
    
    reports.forEach(report => {
        const [name, worksheet] = exportToExcelWorksheet(report);
        XLSX.utils.book_append_sheet(wb, worksheet, name);
    });

    const bookType: XLSX.BookType = 'xlsx'
    const excelBuffer = XLSX.write(wb, { bookType, type: 'array' });
    const blobData = new Blob([excelBuffer], { type: FILE_TYPES[bookType] });
    FileSaver.saveAs(blobData, `AssessmentReports.${FILE_EXTENSIONS[bookType]}`);
}

export const GetAssessmentsReport: FunctionComponent<{ value: IAccount }> = ({ value }) => {
    const apiConfig = useGetAccountAssessmentReportApi([value.id]);
    const { data: reports, fetch: getReport, loading } = useApiRequest(apiConfig);

    let props, text;

    if (value.enabled && !loading) {
        props = { onClick: getReport, color: 'green' };
        text = 'Get Assessments Report';
    }
    else if (loading) {
        props = { disabled: true };
        text = '- Loading -';
    }
    else {
        props = { disabled: true };
        text = '- Account Disabled -';
    }

    useEffect(() => {
        if (reports)
        {
            exportToExcelWorkbook([{ account: value, report: reports[0] }])
        }
    }, [reports, value])

    return (
        <Button {...props} >
            {text}
        </Button>
    );
};
