import React, { createContext, useContext, useCallback, FunctionComponent } from 'react';
import { IUser } from '../../../../../types/resources';
import { ICreateUserWithId } from '../../types';
import { useUserUploadData } from './UserUploadDataContext';

export type IOnUserUpdateCallback = () => void;
export type IOnUserSubmitCallback = (user: IUser | ICreateUserWithId) => void;

export const UserUpdateContext = createContext<[IOnUserUpdateCallback, IOnUserSubmitCallback]>([() => void 0, () => void 0]);

export const UserUpdateProvider: FunctionComponent = ({ children }) => {
  const [users, setUsers] = useUserUploadData();
  const handleOnUserUpdate = useCallback(() => {}, []);
  const handleOnUserFormSubmit = useCallback((user: IUser | ICreateUserWithId) => { 
    setUsers(users.map((u) => u.id === user.id ? user as ICreateUserWithId : u));
  }, [setUsers, users]);

  return (
    <UserUpdateContext.Provider value={[handleOnUserUpdate, handleOnUserFormSubmit]}>
      {children}
    </UserUpdateContext.Provider>
  );
};

export const useOnUserUpdate = () => useContext(UserUpdateContext);
