import React, { FunctionComponent } from "react";
import { useFetchUserListApi } from '../../../../utils/api/options/users';
import { useUserListExportOptions } from '../../../../utils/export/options/userList';
import ExportModal from "../ExportModal";

import { ExportUsersModalProps } from "./types";

const ExportUsersModal: FunctionComponent<ExportUsersModalProps> = ({ isOpen, onCancel, selectedUsers, pageUsers, allUsersCount }) => {
  const fetchUserListApi = useFetchUserListApi();
  const exportOptions = useUserListExportOptions();

  return (
    <ExportModal
      allItemsCount={allUsersCount}
      exportOptions={exportOptions}
      fetchItemListApi={fetchUserListApi}
      fileName="User list"
      isOpen={isOpen}
      onCancel={onCancel}
      pageItems={pageUsers}
      selectedItems={selectedUsers}
    />
  );
};

export default ExportUsersModal;
