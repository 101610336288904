import React, { FunctionComponent } from 'react';
import { IUser } from '../../../../types/resources';
import { ICreateUserWithId } from '../../UserUpload/types';
import { Modal } from 'semantic-ui-react';

import { UserFormModalHeader } from './UserFormModalHeader';
import { UserForm } from './UserForm';

export interface IUserFormModalProps {
  isOpen: boolean;
  user?: IUser | ICreateUserWithId | null;
  closeModal: () => void;
  onUserUpdate?: () => void;
  onUserFormSubmit?: (user: IUser | ICreateUserWithId) => void;
}

export const UserFormModal: FunctionComponent<IUserFormModalProps> = ({ isOpen, closeModal, user, onUserUpdate, onUserFormSubmit }) => (
  <Modal open={isOpen} onClose={closeModal} size="tiny">
    <UserFormModalHeader mode={user ? 'update' : 'create'} />
    <Modal.Content>
      <UserForm user={user} onCancel={closeModal} onUserUpdate={onUserUpdate} onSubmit={onUserFormSubmit} />
    </Modal.Content>
  </Modal>
);
