import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { LinkCellProps } from "../types";
import Td from "./Td";

const StyledLinkCell = styled.div<{ clickable: boolean }>`
  color: ${({ theme }) => theme.TABLE.COLOR};
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};
  text-decoration: ${({ clickable }) => clickable ? 'underline' : 'none'};
  text-align: center;
`;

const LinkCell: FunctionComponent<LinkCellProps> = ({ id, onClick, text, disabled }) => {
  const handleOnClick = onClick && id && !disabled ? () => onClick(id) : undefined;

  return (
    <Td>
      <StyledLinkCell onClick={handleOnClick} clickable={!!handleOnClick}>
        { text }
      </StyledLinkCell>
    </Td>
  );
};

export default LinkCell;
