import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import LinkButton from "../../pages/admin/DashboardPage/LinkButton";
import Button, { StyledButton } from "../../common/Buttons/Button";
import InputText from "../../common/Forms/InputText";
import useFormField from "../../common/Forms/useFormField";
import { hasLowerCaseValidation, hasNumericCharacterValidation, hasSpecialCharacterValidation, hasUpperCaseValidation, lengthBetweenValidation, matchValidation, maxConsecutiveCharactersValidation, noTrailingSpacesValidation, requiredValidation } from "../../common/Forms/validation";
import { ResetPasswordFormProps } from "./types";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const StyledInput = styled.div`
  height: 74px;
`;

const StyledButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 34px;

  ${StyledButton} {
    width: 166px;
    max-width: none;
  }
`;

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({ initialValues, onSubmit }): ReactElement => {
  const history = useHistory();
  const passwordValidation = [requiredValidation, lengthBetweenValidation(10, 100), noTrailingSpacesValidation, hasLowerCaseValidation, hasUpperCaseValidation, hasNumericCharacterValidation, hasSpecialCharacterValidation, maxConsecutiveCharactersValidation(2)]
  const [password, setPassword, clearPassword, validatePassword, passwordError] = useFormField<string>(initialValues.password, passwordValidation); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [passwordRepeated, setPasswordRepeated, clearPasswordRepeated, validatePasswordRepeated, passwordRepeatedError] = useFormField<string>(initialValues.password, [requiredValidation, matchValidation(password)]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const handleSubmit = () => {
    const errors = [validatePassword(), validatePasswordRepeated()];

    if (errors.filter(e => !!e).length === 0) {
      onSubmit({ password, passwordRepeated });
    }
  };

  return (
    <>
      <StyledForm>
        <StyledInput>
          <InputText type="password" placeholder="Password" label="Your new password" value={password} onChange={setPassword} error={passwordError} />
        </StyledInput>
        <StyledInput>
          <InputText type="password" placeholder="Password" label="Repeat password" value={passwordRepeated} onChange={setPasswordRepeated} error={passwordRepeatedError} />
        </StyledInput>
      </StyledForm>
      <StyledButtons>
        <LinkButton label="Go back to login" onClick={() => history.push('/admin/login')} theme="gray" />
        <Button label="Change password" theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
    </>
  )
}

export default ResetPasswordForm
