import React, { FunctionComponent, ReactElement, useMemo } from "react";
import styled from "styled-components";
import Checkbox from "../../common/Forms/Checkbox";
import Select from "../../common/Forms/Select";
import Button, { StyledButton } from "../../common/Buttons/Button";
import ContactForm from "../ContactForm/ContactForm";
import { FollowUpFormProps, isManualAssessmentStatusType } from "./types";
import useFormField from "../../common/Forms/useFormField";
import { Option } from "../../common/Forms/types";
import { IManualAssessmentStatusType } from "../../types/resources";
import { getSelectOptions, getStatusOption } from "./utils";
import { ContactFormData } from "../ContactForm/types";
import { getContactFormDataInitialValues } from "../ContactForm/utils";
import { lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";
import Textarea from "../../common/Forms/Textarea";

const StyledCheckbox = styled.div`
  display: flex;
`;

const StyledButtons = styled.div`
  display: flex;
  margin-top: 44px;

  ${StyledButton}:first-child {
    margin-right: 18px;
  }
`;

const StyledSelect = styled.div`
  height: 74px;
  text-align: left;
  width: 350px;
`;

const StyledCloseIssues = styled.div`
  width: 100%;

  >div {
    margin-bottom: 12px;
  }
`;

const FollowUpForm: FunctionComponent<FollowUpFormProps> = ({ currentStatus, initialValues, isActive, onCancel, onStatusChange, onSubmit, onToggleContact, openIssues }): ReactElement => {
  const [sendEmail, setSendEmail] = useFormField<boolean>(initialValues.sendEmail);
  const [message, setMessage, clearMessage, validateMessage, messageError] = useFormField<string>(initialValues.closeIssuesMessage, [lengthBetweenValidation(1, 5000)]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [status, setStatus, clearStatus, validateStatus, statusError] = useFormField<IManualAssessmentStatusType | null>(initialValues.status, [requiredValidation]); // eslint-disable-line @typescript-eslint/no-unused-vars

  const cancelLabel = "No, thanks"
  const okLabel = "Change"
  
  const handleToggleContact = () => {
    onToggleContact(!sendEmail)
    setSendEmail(!sendEmail)
  }

  const handleStatusChange = (statuses: Option[]) => {
    const status = statuses[0].value
    if (isManualAssessmentStatusType(status)) {
      setStatus(status)
      onStatusChange(status)
    }
  }

  const handleSubmit = (data?: ContactFormData) => {
    const errors = [validateStatus()];

    if (issueClosureRequired) {
      errors.push(validateMessage())
    }

    if (errors.filter(e => !!e).length === 0 && status) {
      onSubmit({
        closeIssues: issueClosureRequired,
        closeIssuesMessage: message,
        contact: data ?? getContactFormDataInitialValues(),
        sendEmail,
        status,
      })
    }
  }

  const handleSubmitWithEmail = (data: ContactFormData) => {
    handleSubmit(data)
  }

  const issueClosureRequired = useMemo(() => {
    return (status === 'completed' || status === 'declined') && !!openIssues && openIssues.length > 0
  }, [openIssues, status])

  return (
    <>
      <StyledSelect>
        <Select error={statusError} options={getSelectOptions(isActive, currentStatus)} onChange={handleStatusChange} value={status ? [getStatusOption(status)] : []} label="Status" />
      </StyledSelect>
      { issueClosureRequired && (
        <StyledCloseIssues>
          <div>
            This assessment still has open issues. In order to mark the follow-up {status}, you will need to close all open issues first. Please enter a note to close all issues to proceed.
          </div>
          <Textarea required placeholder="Write your note here" label="Your message" value={message} onChange={setMessage} error={messageError} />
        </StyledCloseIssues>
      )}
      <StyledCheckbox>
        <Checkbox id="show-contact-form" value={sendEmail} onChange={handleToggleContact} label="send an email to the user" strong={sendEmail} />
      </StyledCheckbox>
      {sendEmail && (
        <ContactForm 
          initialValues={initialValues.contact}
          onCancel={onCancel}
          onSubmit={handleSubmitWithEmail}
          cancelLabel={cancelLabel}
          submitLabel="Change"
        />
      )}
      {!sendEmail && (
        <StyledButtons>
          <Button label={cancelLabel} theme="SUCCESS_SECONDARY" onClick={onCancel}/>
          <Button icon="ok" label={okLabel} theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
        </StyledButtons>
      )}
    </>
  );
};

export default FollowUpForm;
