import React, { ReactElement, FunctionComponent } from 'react';
import { Table as SemanticTable, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';

import { Pagination } from './Pagination';
import { Filters } from './Filters';
import { ITableContextProps, TableProvider } from './Context';
import { ITableFlags } from './Context/TableFlagsContext';
import { TableItemCount } from './TableItemCount';

export interface ITableProps<TData extends { id: string }> extends ITableFlags {
  contextProps: ITableContextProps<TData>;
  height?: string;
  sticky?: boolean;
  emptyMessage?: string;
  ActionButtons?: FunctionComponent;
}

const TableWrapper = styled.div<{ height?: string }>`
  height: ${(props) => props.height ?? '100%'};
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
`;

const FlatterRow = styled(Grid.Row)`
  &&& {
    padding-bottom: 0;
  }
`;

const ButtonColumn = styled(Grid.Column)`
  margin-left: 10px;
  &&&&&& {
    box-shadow: none;
  }
`;

export const Table = <TData extends { id: string }>({
  contextProps,
  filterable,
  paginateable,
  sortable,
  selectable,
  sticky,
  height,
  emptyMessage,
  ActionButtons,
}: ITableProps<TData>): ReactElement<ITableProps<TData>> => (
  <TableProvider<TData>
    {...contextProps}
    selectable={selectable}
    sortable={sortable}
    filterable={filterable}
    paginateable={paginateable}
  >
    <Grid divided>
      {!!filterable && (
        <FlatterRow>
          <Filters />
        </FlatterRow>
      )}
      {!!ActionButtons && (
        <FlatterRow>
          <ButtonColumn width={12}>
            <ActionButtons />
          </ButtonColumn>
          <ButtonColumn width={2} floated="right">
            <TableItemCount />
          </ButtonColumn>
        </FlatterRow>
      )}
      <Grid.Row columns={1}>
        <Grid.Column>
          <TableWrapper height={height}>
            <SemanticTable sortable>
              <TableHeader sticky={sticky} />
              <TableBody<TData> emptyMessage={emptyMessage} />
            </SemanticTable>
          </TableWrapper>
        </Grid.Column>
      </Grid.Row>
      {!!paginateable && (
        <Grid.Row columns={1}>
          <Grid.Column>
            <Pagination />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  </TableProvider>
);
