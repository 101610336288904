import { useMemo } from "react";
import { IFilterConfig } from "../../../common/Table/Context/TableFilterConfigContext";
import { TYPE_MAP } from "../columns/accountList";

const typeOptions = Object.keys(TYPE_MAP).map((accountType) => ({
    value: accountType,
    text: TYPE_MAP[accountType],
}));

export const useAccountFilters = (
) => useMemo<IFilterConfig[]>(() => [
  {
    label: 'Account Status',
    key: 'accountType',
    type: 'multiSelect',
    options: typeOptions,
  },
  {
    label: 'Account Enabled',
    key: 'enabled',
    type: 'multiSelect',
    options: [{ value: true, text: 'Enabled' }, { value: false, text: 'Disabled' }],
  },
  {
    label: 'Is Managed',
    key: 'isManaged',
    type: 'multiSelect',
    options: [{ value: true, text: 'Yes' }, { value: false, text: 'No' }],
  },
  {
    label: 'Account Name Search',
    key: 'accountNameLike',
    type: 'text',
  }
], []);
