import React, { FunctionComponent, MutableRefObject, useCallback, useMemo } from 'react';
import { IAccount } from '../../../../types/resources';
import { Table } from '../../../../common/Table';
import { useAccountListTable } from '../../../../utils/table/columns/accountList';
import { useAccountFilters } from '../../../../utils/table/filters/accountList';
import { IApiRequest, IFilters, IPagination, ISortByItem } from '../../../../utils/api/types';
import { ITableContextProps } from '../../../../common/Table/Context';
import { AccountUpdateProvider } from './contexts/AccountUpdateContext';
import { AccountListActions } from '../AccountListActions';

interface IAccountListTableProps {
  accounts: IAccount[] | null;
  fetchAccounts: IApiRequest<never, IAccount[]>;
  loading?: boolean;
  _pagination?: MutableRefObject<IPagination>;
  _sortBy?: MutableRefObject<ISortByItem[]>;
  _filters?: MutableRefObject<IFilters>;
  height?: string;
}

export const AccountListTable: FunctionComponent<IAccountListTableProps> = ({
                                                                              accounts,
                                                                              fetchAccounts,
                                                                              loading,
                                                                              _pagination,
                                                                              _sortBy,
                                                                              _filters,
                                                                              height,
                                                                            }) => {
  const accountListTable = useAccountListTable();
  const filterConfig = useAccountFilters();

  const handleAccountUpdate = useCallback(async () => {
    await fetchAccounts?.();
  }, [fetchAccounts]);

  const tableContextProps = useMemo<ITableContextProps<IAccount>>(() => ({
    config: accountListTable,
    data: accounts,
    fetch: fetchAccounts,
    loading: loading ?? false,
    filter: _filters,
    pagination: _pagination,
    sort: _sortBy,
    filterConfig,
  }), [accountListTable, accounts, loading, fetchAccounts, _filters, _pagination, _sortBy, filterConfig]);

  return (
    <AccountUpdateProvider value={handleAccountUpdate}>
      <Table<IAccount>
        contextProps={tableContextProps}
        ActionButtons={AccountListActions}
        height={height}
        selectable
        sortable
        filterable
        paginateable
        sticky
      />
    </AccountUpdateProvider>
  );
};
