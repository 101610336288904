import React, { createContext, useMemo, FunctionComponent, useContext } from "react";

export interface ITableFlags {
  selectable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  paginateable?: boolean;
}

export const TableFlagsContext = createContext<ITableFlags>({});

export const TableFlagProvider: FunctionComponent<ITableFlags> = ({ selectable, sortable, filterable, paginateable, children }) => {
  const flags = useMemo(() => ({
    selectable,
    sortable,
    filterable,
    paginateable,
  }), [filterable, paginateable, selectable, sortable])

  return (
    <TableFlagsContext.Provider value={flags}>
      {children}
    </TableFlagsContext.Provider>
  );
};

export const useTableFlags = () => useContext(TableFlagsContext);
