import { round } from "lodash";
import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import { RingChartProps } from "../../types";
import { GaugeProps } from "./types";

const padding = 30
const radius = 80
const strokeWidth = 14;
const innerRadius = radius - strokeWidth / 2;
const circumference = innerRadius * 2 * Math.PI;
const arc = circumference * 0.5;
const dashArray = `${arc} ${circumference}`;
const transform = `rotate(180, ${radius}, ${radius})`;

const StyledCircle = styled.circle<{ shadow: string }>`
  filter: drop-shadow(0px 3px 9px ${({ shadow }) => shadow})
`

const StyledRingChart = styled.div`
  text-align: center;
`;

const Gauge: FunctionComponent<GaugeProps> = ({ gaugeColor, gaugeShadow, value }): ReactElement => {
  const { CHARTS } = useContext(ThemeContext)

  const percentNormalized = Math.min(Math.max(value, 0), 100);
  const offset = arc - (percentNormalized / 100) * arc;

  return (
    <svg
       height={radius * 2 + padding}
       width={radius * 2 + padding}
    >‍
      <circle
        cx={radius - (0.5 * padding)}
        cy={radius - (0.5 * padding)}
        fill="transparent"
        r={innerRadius}
        stroke={CHARTS.BG}
        strokeLinecap="round"
        strokeDasharray={dashArray}
        strokeWidth={strokeWidth}
        transform={transform}
      />‍
      <StyledCircle
        cx={radius - (0.5 * padding)}
        cy={radius - (0.5 * padding)}
        fill="transparent"
        r={innerRadius}
        stroke={gaugeColor}
        strokeDasharray={dashArray}
        strokeDashoffset={offset}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        transform={transform}
        shadow={gaugeShadow}
      />
   </svg>
  )
}

const StyledGauge = styled.div`
  display: inline-block;
  position: relative;
  height: 175px;
  overflow-y: hidden;
`

const StyledValue = styled.div`
  color: ${({ theme }) => theme.CHARTS.VALUE};
  font-size: 36px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 87px;
  width: 100%;

  span {
    color: ${({ theme }) => theme.CHARTS.GRAY};
    font-size: 21px;
    margin-left: 4px;
  }
`

const StyledCaption = styled.div`
  color: ${({ theme }) => theme.CHARTS.VALUE};
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 138px;
  width: 100%;
`;

const getColors = (theme: DefaultTheme, value: number): [string, string] => {
  const { GREEN, GREEN_SHADOW, RED, RED_SHADOW, YELLOW, YELLOW_SHADOW } = theme.CHARTS
  if (value <= 50) {
    return [RED, RED_SHADOW]
  } else if (value <= 80) {
    return [YELLOW, YELLOW_SHADOW]
  }
  return [GREEN, GREEN_SHADOW]
}

const RingChart: FunctionComponent<RingChartProps> = ({ data }): ReactElement => {
  const theme = useContext(ThemeContext)

  return (
    <StyledRingChart>
      { data.map(item => {
        const [color, shadow] = getColors(theme, item.value)
        return (
          <StyledGauge key={item.id}>
            <Gauge gaugeColor={color} gaugeShadow={shadow} value={item.value} />
            <StyledValue>{round(item.value, 1)}<span>%</span></StyledValue>
            <StyledCaption>{item.caption}</StyledCaption>
          </StyledGauge>
        )
      })}
    </StyledRingChart>
  );
};

export default RingChart;
