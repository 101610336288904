import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box from "../Box/Box";

const StyledFilters = styled.div`
  margin-bottom: 16px;
`;

export const FilterItem = styled.div`
  display: flex;
  height: 76px;
  padding: 0 16px 16px 0;
`;

const StyledFilterItems = styled.div<{columns: number}>`
  align-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 168px;
  padding: 16px 0 0 16px;

  ${FilterItem} {
    width: ${({ columns }) => 100/columns}%;
  }
`;

export const Filters: FunctionComponent<{columns: number}> = ({ columns, children }): ReactElement => {
  return (
    <StyledFilters>
      <Box shadow noPadding>
        <StyledFilterItems columns={columns}>
          {children}
        </StyledFilterItems>
      </Box>
    </StyledFilters>
  );
};
