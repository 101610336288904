import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "../../../common/Modal/hooks";
import { IAutoClosing, IContact, ILoginMethodsConfig, IScheduleReminder } from "../../../types/resources";
import { useApiRequest } from "../../../utils/api/hooks";
import { useFetchAutoClosingApi } from "../../../utils/api/options/settings/autoClosing";
import { useFetchLoginMethodsApi } from "../../../utils/api/options/settings/adminLoginMethods";
import { useFetchContactsApi } from "../../../utils/api/options/settings/contacts";
import { useFetchRemindersApi } from "../../../utils/api/options/settings/scheduleReminders";
import ChangesWarningModal from "../modals/ChangesWarningModal/ChangesWarningModal";
import AccountSettingsMenu from "./AccountSettingsMenu";
import ContactsTab from "./Tabs/ContactsTab";
import IssueAutoClosingTab from "./Tabs/IssueAutoClosingTab";
import ReminderSchedulesTab from "./Tabs/ReminderSchedulesTab";
import { AccountSettingsTab } from "./types";
import { useSession } from "../../auth/AuthContext/SessionContext";
import LoginMethodsTab from "./Tabs/LoginMethodsTab";

const StyledAccountSettings = styled.div`
  display: flex;
  width: 100%;
`;

const AccountSettings: FunctionComponent = (): ReactElement => {
  const [tab, setTab] = useState<AccountSettingsTab>('reminder-schedules')
  const [pendingTab, setPendingTab] = useState<AccountSettingsTab>('reminder-schedules')
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const scheduleRemindersApi = useFetchRemindersApi();
  const contactsApi = useFetchContactsApi();
  const autoClosingApi = useFetchAutoClosingApi();
  const fetchLoginMethodsApi = useFetchLoginMethodsApi();

  const { fetch: fetchReminders, data: scheduleReminders } = useApiRequest<any, IScheduleReminder[]>(scheduleRemindersApi);
  const { fetch: fetchContacts, data: contacts } = useApiRequest<any, IContact[]>(contactsApi);
  const { fetch: fetchAutoClosing, data: autoClosings } = useApiRequest<any, IAutoClosing[]>(autoClosingApi);
  const { fetch: fetchLoginMethods, data: loginMethods } = useApiRequest<any, ILoginMethodsConfig>(fetchLoginMethodsApi);

  const { close: closeChangesWarningModal, isOpen: isChangesWarningModalOpen, open: openChangesWarningModal } = useModal()

  const { accountFeatures: accountEnabledFeatures } = useSession();

  useEffect(() => {
    fetchReminders();
    fetchContacts();
    fetchAutoClosing();
    fetchLoginMethods();
  }, [fetchAutoClosing, fetchContacts, fetchLoginMethods, fetchReminders]);

  const checkChanges = (tab: AccountSettingsTab) => {
    if (isDirty) {
      setPendingTab(tab)
      openChangesWarningModal()
    } else {
      setTab(tab)
    }
  }

  const changeTabWithoutSave = () => {
    closeChangesWarningModal()
    setIsDirty(false)
    setTab(pendingTab)
  }

  return (
    <StyledAccountSettings>
      <AccountSettingsMenu tab={tab} onTabChange={checkChanges} />
      {tab === 'reminder-schedules' && scheduleReminders && <ReminderSchedulesTab initialValues={scheduleReminders} onChange={setIsDirty} onSave={fetchReminders} />}
      {tab === 'contacts' && contacts && <ContactsTab initialValues={contacts} onChange={setIsDirty} onSave={fetchContacts} />}
      {tab === 'issue-auto-closing' && autoClosings && <IssueAutoClosingTab initialValues={autoClosings} onChange={setIsDirty} onSave={fetchAutoClosing} />}
      {tab === 'login-methods' && loginMethods && (
        <LoginMethodsTab
          accountEnabledFeatures={accountEnabledFeatures}
          initialValues={loginMethods}
          onSave={fetchLoginMethods}
        />
      )}
      <ChangesWarningModal isOpen={isChangesWarningModalOpen} onCancel={closeChangesWarningModal} onOk={changeTabWithoutSave} />
    </StyledAccountSettings>
  );
};

export default AccountSettings;
