import React, { createContext, FunctionComponent, useState, useCallback, useContext } from 'react';
import { ConfirmationModal } from '../index';
import { SemanticCOLORS } from 'semantic-ui-react';

type IConfirmationCallback = (() => void) | (() => Promise<void>);

const ConfirmationModalContext = createContext<
  (onConfirm: IConfirmationCallback, onDeny?: IConfirmationCallback, message?: string, confirmColor?: SemanticCOLORS) => void
>(() => void 0);

export const ConfirmationModalProvider: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [onConfirm, setOnConfirm] = useState<IConfirmationCallback | null>(null);
  const [onDeny, setOnDeny] = useState<IConfirmationCallback | null>(null);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [confirmColor, setConfirmColor] = useState<SemanticCOLORS | undefined>(undefined);

  const openModal = useCallback((
    onConfirm: IConfirmationCallback,
    onDeny?: IConfirmationCallback,
    message?: string,
    confirmColor?: SemanticCOLORS,
  ) => {
    setOnConfirm(() => onConfirm);
    setOnDeny(() => onDeny ?? null);
    setMessage(message);
    setConfirmColor(confirmColor);

    setIsOpen(true);
  }, [setIsOpen]);

  const closeModal = useCallback(() => {
    setOnConfirm(null);
    setOnDeny(null);
    setMessage(undefined);
    setConfirmColor(undefined);
    setIsOpen(false);
  }, [])

  const handleConfirm = useCallback(async () => {
    await Promise.resolve(onConfirm?.());

    closeModal();
  }, [closeModal, onConfirm]);
  const handleDeny = useCallback(async () => {
    if (onDeny) {
      await Promise.resolve(onDeny());
    }

    closeModal();
  }, [closeModal, onDeny])

  return (
    <ConfirmationModalContext.Provider value={openModal}>
      {children}
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onDeny={handleDeny}
        message={message}
        confirmColor={confirmColor}
      />
    </ConfirmationModalContext.Provider>
  )
};

export const useOpenConfirmationModal = () => useContext(ConfirmationModalContext);
