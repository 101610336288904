import { IIssue } from "../../../types/resources";
import { isClosed, isResolvableByAdmin, isResolvableByUser } from "../../../utils/issue";
import { AssessmentFilteredIssuesTab, AssessmentTab } from "./types";

export const tabs: AssessmentTab[] = [
  { key: 'admin-resolvable', label: 'Admin resolvable issues' },
  { key: 'user-resolvable', label: 'User resolvable issues' },
  { key: 'resolved', label: 'Resolved issues' },
  { key: 'qa', label: 'Questions & Answers' }
]

export function getFilteredData(data: IIssue[], tab: AssessmentFilteredIssuesTab): IIssue[] {
  if (!data) {
    return []
  }
  switch(tab.key) {
    case 'admin-resolvable': {
      return data.filter(isResolvableByAdmin)
    }
    case 'user-resolvable': {
      return data.filter(isResolvableByUser)
    }
    case 'resolved': {
      return data.filter(isClosed)
    }
  }
}
