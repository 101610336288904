import { ICreateUser } from '../../types/resources';

export interface IUserFromExcel {
  'Email Address': string;
  'First Name': string;
  'Last Name': string;
  'Location': string;
  'Department': string;
  'Job Title': string;
}

export const transformExcelArray = (users: IUserFromExcel[]): ICreateUser[] => users.map((user) => ({
  emailAddress: user['Email Address'],
  firstName: user['First Name'],
  lastName: user['Last Name'],
  department: user['Department'],
  jobTitle: user['Job Title'],
  location: user['Location'],
}));
