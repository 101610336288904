import React, { FunctionComponent, ReactElement } from "react";
import AccordionMenu from "../../../common/AccordionMenu/AccordionMenu";
import AccordionMenuCategory from "../../../common/AccordionMenu/AccordionMenuCategory";
import { AccountSettingsMenuProps, AccountSettingsTab } from "./types";

const AccountSettingsMenu: FunctionComponent<AccountSettingsMenuProps> = ({ onTabChange, tab }): ReactElement => {
  return (
    <AccordionMenu<AccountSettingsTab> heading="Settings" onTabChange={onTabChange} activeTab={tab}>
      <AccordionMenuCategory<AccountSettingsTab> label="Reminder schedules" tab="reminder-schedules" />
      <AccordionMenuCategory<AccountSettingsTab> label="Contacts" tab="contacts" />
      <AccordionMenuCategory<AccountSettingsTab> label="Issue auto closing" tab="issue-auto-closing" />
      <AccordionMenuCategory<AccountSettingsTab> label="Authentication methods" tab="login-methods" />
    </AccordionMenu>
  );
};

export default AccountSettingsMenu;
