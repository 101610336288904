import React, { useContext, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Icon from "../../../../common/Icons/Icon";
import { FailureProps } from "./types";

const StyledFailure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  padding: 15px 0 48px;
`;

const StyledText = styled.div`
  margin-bottom: 8px;
  padding: 0 24px;
`;

const StyledIcon = styled.div`
  margin-bottom: 20px;
`;

const Failure: FunctionComponent<FailureProps> = ({ onOk, text }): ReactElement => {
  const { MODAL: { FAILURE_ICON } } = useContext(ThemeContext);

  return (
    <StyledFailure>
      <StyledText>{text || 'Something went wrong!'}</StyledText>
      <StyledIcon>
        <Icon name="cancelCircle" size={51} color={FAILURE_ICON} />
      </StyledIcon>
      <Button label="Go back" theme="DANGER_SECONDARY" onClick={onOk} />
    </StyledFailure>
  );
};

export default Failure;
