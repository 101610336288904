import React, { FunctionComponent, ReactElement } from "react";
import RingChart from "../Charts/RingChart/RingChart";
import { RingChartItem, UsersInvitedChartCardProps } from "../types";
import DashboardCard from "./DashboardCard";
import NoData from "./NoData";

const UsersInvitedChartCard: FunctionComponent<UsersInvitedChartCardProps> = ({ selfAssessmentInviteSummary, surveyTemplates }): ReactElement => {
  const data: RingChartItem[] = []
  
  selfAssessmentInviteSummary.forEach((summaryEntry) => {
    const surveyTemplate = surveyTemplates.find(template => template.id === summaryEntry.surveyTemplateId.toString())

    if (!surveyTemplate) {
      return
    }

    data.push({
      caption: surveyTemplate.name,
      id: surveyTemplate.id,
      value: (summaryEntry.inviteCompliant / summaryEntry.totalRelevantUsers) * 100
    })
  })

  return (
    <DashboardCard info="Users invited to assessement" title="Users invited to assessement">
      {data.length === 0 && <NoData />}
      {data.length > 0 && <RingChart data={data} />}
    </DashboardCard>
  );
};

export default UsersInvitedChartCard;
