import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Accordion from "../../../../common/Accordion/Accordion";
import AccordionItem from "../../../../common/Accordion/AccordionItem";
import UserForm from "../../../../forms/UserForm/UserForm";
import { getUserFormDataInitialValues } from "../../../../forms/UserForm/utils";
import AdvancedEditProfile from "./AdvancedEditProfile";
import { EditProfileProps } from "./types";

const StyledEditProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 860px;
`;

const StyledUserForm = styled.div`
  padding: 13px 30px 50px;
`;

const EditProfile: FunctionComponent<EditProfileProps> = ({ onSubmit, onChangeStep, step, user }): ReactElement => {
  const initialValues = getUserFormDataInitialValues(user)

  return (
    <StyledEditProfile>
      <StyledUserForm>
        <UserForm
          initialValues={initialValues}
          onCancel={() => onChangeStep('loading')}
          onSubmit={onSubmit}
          cancelLabel="Don't save"
          submitLabel="Save changes"
        />
      </StyledUserForm>
      <Accordion>
        <AccordionItem id="advanced-options" title="Advanced options" onClick={() => onChangeStep(step === 'edit' ? 'edit-advanced' : 'edit')}>
          <AdvancedEditProfile
            onArchive={() => onChangeStep('confirm-archive')}
            onToggleAdmin={() => onChangeStep('confirm-admin')}
            user={user}
          />
        </AccordionItem>
      </Accordion>
    </StyledEditProfile>
  );
};

export default EditProfile;
