import React, { FunctionComponent, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { useUserUploadData } from '../contexts/UserUploadDataContext';

export const ClearUsersUpload: FunctionComponent = () => {
  const [users, setUsers] = useUserUploadData();
  const clearUsers = useCallback(() => setUsers([]), [setUsers]);
  
  return (
    <Button color="red" disabled={!users.length} onClick={clearUsers}>Start again</Button>
  );
};
