import React, { FunctionComponent } from "react";
import { useParams } from "react-router";
import { useFetchAssessmentIssuesApi } from "../../../../utils/api/options/assessments";
import { useIssueListExportOptions } from "../../../../utils/export/options/issueList";
import ExportModal from "../ExportModal";

import { ExportIssuesModalProps } from "./types";

const ExportIssuesModal: FunctionComponent<ExportIssuesModalProps> = ({ isOpen, onCancel, selectedIssues, pageIssues, allIssuesCount }) => {
  const { id: surveyResponseId } = useParams<{ id: string }>();
  const fetchIssueListApi = useFetchAssessmentIssuesApi(surveyResponseId);
  const exportOptions = useIssueListExportOptions(true);

  return (
    <ExportModal
      allItemsCount={allIssuesCount}
      exportOptions={exportOptions}
      fetchItemListApi={fetchIssueListApi}
      fileName="Issue list"
      isOpen={isOpen}
      onCancel={onCancel}
      pageItems={pageIssues}
      selectedItems={selectedIssues}
    />
  );
};

export default ExportIssuesModal;
