import React, { useEffect, useCallback, useState, FunctionComponent } from "react";
import styled from "styled-components";
import Modal from "../../../../common/Modal/Modal";
import { UserFormData } from "../../../../forms/UserForm/types";
import UserForm from "../../../../forms/UserForm/UserForm";
import { ICreateUser, IUser } from "../../../../types/resources";
import { useApiRequest } from '../../../../utils/api/hooks';
import { useCreateUserApi } from '../../../../utils/api/options/users';
import { IApiError422 } from "../../../../utils/api/types";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import InviteUsersModal from "../InviteUsersModal";
import { AddNewUserModalStep, AddNewUserModalProps } from "./types";
import { createUserPayload } from "./utils";

const StyledAddNewUserModal = styled.div`
  padding: 30px 30px 50px;
  width: 860px;
`;

const AddNewUserModal: FunctionComponent<AddNewUserModalProps> = ({ isOpen, onCancel, onOk }) => {
  const [step, setStep] = useState<AddNewUserModalStep>();
  const createUserApi = useCreateUserApi();
  const [newUser, setNewUser] = useState<IUser>()
  const { fetch: createUser } = useApiRequest<ICreateUser, IUser>(createUserApi);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const goBackFromError = useCallback(() => {
    setStep('form');
  }, [oncancel])

  useEffect(() => {
    if(isOpen && step === undefined) {
      setStep('form');
    }
  }, [isOpen, step]);
  
  const handleSubmit = useCallback(async (data: UserFormData) => {
    setStep('processing')
    const payload = createUserPayload(data)
    const [result, error] = await createUser(payload);
    const attrError = error as unknown as IApiError422[];

    if (error || !result) {
      attrError.findIndex((e) => {
        return (e.code === 'attribute_validation_error' 
            && e.meta.validationFailure.attributeName === 'emailAddress' 
            && e.meta.validationFailure.predicateType === 'isUnique')
      }) !== -1 && setErrorMessage(`A user with the email address '${data.email}' already exists`)

      setStep('failure')
      return
    }

    setNewUser(result)
    setStep('success')
  }, [createUser, setErrorMessage])

  const handleOnCancel = () => {
    setStep('form')
    onCancel()
  }

  const handleOk = () => {
    setStep('form')
    onOk()
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    department: '',
    location: '',
  }

  return step === "success" && newUser
    ? (
      <InviteUsersModal isOpen onCancel={handleOnCancel} users={[newUser]} onOk={handleOk} newUser /> 
    ) : (
      <Modal onCancel={step === 'processing' ? undefined : handleOnCancel} isOpen={isOpen} step={step} title="Create new user">
        {step === 'form' && (
          <StyledAddNewUserModal>
            <UserForm 
              initialValues={initialValues}
              onCancel={handleOnCancel}
              onSubmit={handleSubmit}
              cancelLabel="No, thanks"
              submitLabel="Create"
            />
          </StyledAddNewUserModal>
        )}
        {step === 'processing' && <Processing />}
        {step === 'failure' && <Failure onOk={goBackFromError} text={errorMessage} />}
      </Modal>
  );
}

export default AddNewUserModal;
