import React, { useCallback, useContext, useEffect, FunctionComponent, ReactElement } from "react";
import { useHistory } from "react-router";
import styled, { ThemeContext } from "styled-components";
import Box from "../../../../common/Box/Box";
import Button from "../../../../common/Buttons/Button";
import LinkButton from "../../../../common/Buttons/LinkButton";
import Icon from "../../../../common/Icons/Icon";
import Pagination from "../../../../common/Pagination/Pagination";
import { getAssessmentDetailsRoute } from "../../../../routing/AdminRouter";
import { ISurveyResponse } from "../../../../types/resources";
import { useApiRequest, usePaginationCallbacks, useSortByCallbacks } from "../../../../utils/api/hooks";
import { useFetchUserAssessmentListApi } from "../../../../utils/api/options/assessments";
import { isArchived, status, type } from "../../../../utils/user";
import { StyledTableHeader, StyledTableHeading } from "../Common/TableHeader";
import AssessmentHistoryTable from "./AssessmentHistoryTable";
import { UserProfileProps } from "./types";
import UserProfileField from "./UserProfileField";

const StyledUserProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 790px;
  padding: 13px 30px 50px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 20px;
  position: relative;

  &:first-child {
    padding: 0 20px;
    margin-bottom: 14px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const StyledIcon = styled.div`
  left: -8px;
  position: absolute;
`;

const StyledUsername = styled.div`
  color: ${({ theme }) => theme.MODAL.BLACK_ACCENT};
  display: flex;
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const UserProfile: FunctionComponent<UserProfileProps> = ({ onCancel, onEdit, onLoad, user }): ReactElement => {
  const { MODAL } = useContext(ThemeContext);
  const history = useHistory()
  const userStatus = status(user)
  const statusColor = userStatus === 'Active' ? MODAL.SUCCESS_ICON : MODAL.FAILURE_ICON

  const fetchAssessmentListApi = useFetchUserAssessmentListApi(user.id);
  const {
    fetch: fetchAssessments,
    data: assessments,
    _pagination,
    _sortBy,
  } = useApiRequest<never, ISurveyResponse[]>(fetchAssessmentListApi);
  const { currentPage, totalItemCount, itemsPerPage } = _pagination.current;
  const paginationCallbacks = usePaginationCallbacks(_pagination, fetchAssessments);
  const { sortBy } = useSortByCallbacks(_sortBy, fetchAssessments, _pagination);

  const loadData = useCallback(async () => {
    await fetchAssessments()
    onLoad()
  }, [fetchAssessments, onLoad])

  const showAssessment = (assessmentId: string) => {
    onCancel()
    history.push(getAssessmentDetailsRoute(assessmentId))
  }

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <StyledUserProfile>
      <Box border shadow>
        <StyledWrapper>
          <StyledRow>
            <StyledIcon>
              <Icon name="userCircle" color={MODAL.SUCCESS_ICON} size={24} />
            </StyledIcon>
            <StyledUsername>{ user.firstName } { user.lastName }</StyledUsername>
            <LinkButton icon="pencil" label="Edit profile" onClick={onEdit} disabled={isArchived(user)} />
          </StyledRow>
          <StyledRow>
            <UserProfileField label="Email" value={user.emailAddress}/>
            <UserProfileField label="Job title" value={user.jobTitle} />
            <UserProfileField label="Department" value={user.department} />
            <UserProfileField label="Location" value={user.location} />
            <UserProfileField label="User type" value={type(user)} />
            <UserProfileField label="Status" value={userStatus} color={statusColor} />
          </StyledRow>
        </StyledWrapper>
      </Box>
      <StyledTableHeader>
        <StyledTableHeading>Assessment history</StyledTableHeading>
        <Pagination
          currentPage={currentPage}
          total={totalItemCount || 0}
          itemsPerPage={itemsPerPage}
          onPageChange={paginationCallbacks.goToPage}
          onFilterChange={paginationCallbacks.setItemsPerPage}
        />
      </StyledTableHeader>
      <Box border noPadding>
        <AssessmentHistoryTable
          assessments={assessments || []}
          currentSort={_sortBy.current}
          onShowAssessment={showAssessment}
          onSort={sortBy}
        />
      </Box>
      <StyledButton>
        <Button label="Close" theme="SUCCESS_SECONDARY" onClick={onCancel} />
      </StyledButton>
    </StyledUserProfile>
  );
};

export default UserProfile;
