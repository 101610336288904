import React, { useCallback, useEffect, useState, FunctionComponent, useMemo } from "react";
import Modal from "../../../../common/Modal/Modal";
import { useBulkInviteApi } from '../../../../utils/api/options/assessments';
import { useApiRequest } from '../../../../utils/api/hooks';
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import { BulkInviteUsersModalProps, BulkInviteUsersModalStep } from "./types";
import Confirm from "./Confirm";
import { getModalTitle } from "./utils";

const BulkInviteUsersModal: FunctionComponent<BulkInviteUsersModalProps> = ({ isOpen, onCancel, onOk, survey, usersCount }) => {
  const [step, setStep] = useState<BulkInviteUsersModalStep>();

  const bulkInviteApi = useBulkInviteApi(survey.id);

  const { fetch: bulkInvite } = useApiRequest(bulkInviteApi);

  useEffect(() => {
    setStep('confirm');
  }, []);

  const title = getModalTitle(step || 'confirm', usersCount > 1);

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  const invite = useCallback(async () => {
    if (!survey) {
      setStep('failure');
      return;
    }

    setStep('processing');
    const [result, error] = await bulkInvite(); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }, [bulkInvite, survey]);

  const error = step === 'failure';
  const successMsg = `User${usersCount > 1 ? 's' : ''} successfuly invited.`;

  return (
    <Modal onCancel={handleCancel} isOpen={isOpen} title={title} step={step} error={error}>
      {step === 'confirm' && survey && <Confirm survey={survey} usersCount={usersCount} onCancel={onCancel} onOk={invite} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text={successMsg} />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default BulkInviteUsersModal;
