import React, { useCallback, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Redirect } from "react-router";
import { Loader } from "semantic-ui-react";
import { NumberParam, useQueryParams } from "use-query-params";
import { IUserAccount } from "../../../types/resources";
import { IApplicationKey } from "../AuthContext/ApplicationContext";
import { useSession } from "../AuthContext/SessionContext";
import { AuthenticationPage } from "../AuthenticationPage";

export type ILoginComponent = FunctionComponent<{ users: IUserAccount[], loginToAccount: (user: IUserAccount) => void }>;
export interface ILoginPageConfig {
  loginComponent: ILoginComponent;
  homeUri: string;
}
export interface ILoginToAccountParams {
  applicationKey: IApplicationKey;
  accountId: number;
  userId: number;
}
export const loginToAccountUri = ({ applicationKey, accountId, userId }: ILoginToAccountParams) => {
  return `/${applicationKey}/login?accountId=${accountId}&userId=${userId}`;
};

export const LoginPage: FunctionComponent<ILoginPageConfig> = ({ loginComponent, homeUri }) => {
  const session = useSession();
  const LoginComponent = loginComponent as ILoginComponent;
  const requestAccountLogin = useCallback(async (account: IUserAccount) => {
    session.login({ userId: account.userId, accountId: account.accountId });
  }, [session]);
  const [loginAttempted, setLoginAttempted] = useState(false);

  const [{ accountId: accountIdParam, userId: userIdParam }] = useQueryParams({
    accountId: NumberParam,
    userId: NumberParam
  });

  useEffect(() => {
    if (session.state.stabilised && userIdParam && accountIdParam && !loginAttempted) {
      setLoginAttempted(true);
      session.login({ userId: userIdParam, accountId: accountIdParam })
    }
  }, [session, loginAttempted, userIdParam, accountIdParam])

  // Render the appropriate UI component based on the current state of the login flow
  switch(session.state.type) {
    case 'initializing':
    case 'authenticating':
    case 'loggingIn':
    case 'loggingOut':
    case 'queryingCurrentUser':
      return <Loader active />;
  
    case 'loggedIn':
      return <Redirect to={homeUri} />;
  
    case 'loggedOut':
      return <AuthenticationPage onSubmit={session.authenticate} />;
  
    case 'authenticationFailed':
      return <AuthenticationPage onSubmit={session.authenticate} errorMessage={session.state.error} />;
  
    case 'authenticated':
      return <LoginComponent users={session.state.availableAccounts} loginToAccount={requestAccountLogin} />; 

    default: {
      throw new Error(`Unhandled session state ${session.state}`)
    }
  }
};
