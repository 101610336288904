import { IQuestionCategory } from "../../pages/admin/Assessment/types";
import { IQuestionResponse } from "../../types/resources";

export const categorizeAnswers = (answers: IQuestionResponse[]): IQuestionCategory[] =>
  answers.reduce<IQuestionCategory[]>((acc, cur) => {
    if (acc[acc.length - 1]?.id === cur.surveyQuestion.questionGroup.id) {
      acc[acc.length - 1].answers = [...(acc[acc.length - 1].answers ?? []), cur];
    } else {
      acc = [
        ...acc,
        {
          id: cur.surveyQuestion.questionGroup.id,
          name: cur.surveyQuestion.questionGroup.name,
          answers: [cur],
        }
      ]
    }

    return acc;
  }, []);
