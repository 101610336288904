import { Option } from "../../common/Forms/types";
import { IIssue } from "../../types/resources";
import { isClosed, isOpen } from "../../utils/issue";
import { IssueFormData, IssueOption } from "./types";

export function getIssueOptions(issues: IIssue[]): Option[] {
  let options: Option[] = [];
  const openIssues = issues.filter(isOpen);
  const closedIssues = issues.filter(isClosed);
  const noteOptionLabel = 'Add note';
  const noteAndCloseOptionLabel = 'Add note and close';
  const noteAndReopenOptionLabel = 'Add note and reopen';

  const noteOption: IssueOption = {
    value: 'note',
    label: issues.length === 1 ? noteOptionLabel : `${noteOptionLabel} (${issues.length})`
  };
  options.push(noteOption);

  if (openIssues.length > 0) {
    const noteAndCloseOption: IssueOption = {
      value: 'noteAndClose',
      label: issues.length === 1 
        ? noteAndCloseOptionLabel 
        : `${noteAndCloseOptionLabel} (${openIssues.length} open issue${openIssues.length !== 1 ? 's' : ''})`
    };
    options.push(noteAndCloseOption);
  }

  if (closedIssues.length > 0) {
    const noteAndReopenOption: IssueOption = {
      value: 'noteAndReopen',
      label: issues.length === 1 
        ? noteAndReopenOptionLabel 
        : `${noteAndReopenOptionLabel} (${closedIssues.length} closed issue${closedIssues.length !== 1 ? 's' : ''})`
    };
    options.push(noteAndReopenOption);
  }

  return options;
}

export function getIssueFormDataInitialValues(issues: IIssue[]): IssueFormData {
  const options = getIssueOptions(issues);
  return {
    message: '',
    operation: options[0],
  };
}
