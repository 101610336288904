import React, { useContext, FunctionComponent, ReactElement } from "react";
import ReactTooltip from "rc-tooltip";
import styled, { createGlobalStyle, ThemeContext } from "styled-components";
import Icon from '../Icons/Icon';
import { TooltipProps } from "./types";
import 'rc-tooltip/assets/bootstrap_white.css';

export const GlobalTooltipStyle = createGlobalStyle`
  .rc-tooltip {
    &.rc-tooltip {
      background-color: transparent;

      .rc-tooltip-inner {
        background: ${({ theme }) => theme.TOOLTIP.BG };
        border: 1px solid ${({ theme }) => theme.TOOLTIP.BG };
        border-radius: 10px;
        box-shadow: 0px 7px 13px ${({ theme }) => theme.TOOLTIP.SHADOW };
        color: ${({ theme }) => theme.TOOLTIP.COLOR };
        font-family: ${({ theme }) => theme.FONT};
        font-size: 11px;
        font-weight: 400;
        line-height: 17px;
        max-width: 300px;
        padding: 0;
      }

      .rc-tooltip-arrow {
        border-bottom-color: ${({ theme }) => theme.TOOLTIP.BG };
      }
    }
  }
`;

const StyledTooltip = styled.div<{ icon?: boolean }>`
  display: flex;
  padding: ${({ icon }) => icon ? '12px 16px 12px 14px' : '8px'}
`;

const StyledIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 30px;
`;
const StyledText = styled.div`
  display: flex;
`;

const TooltipContent: FunctionComponent<TooltipProps> = ({ icon, text }): ReactElement => {
  const { TOOLTIP } = useContext(ThemeContext);

  return (
    <StyledTooltip icon={icon}>
      { icon && <StyledIcon><Icon name="infoOutlined" size={16} color={TOOLTIP.ICON} /></StyledIcon>}
      <StyledText>{ text }</StyledText>
    </StyledTooltip>
  );
};

const Tooltip: FunctionComponent<TooltipProps> = (props): ReactElement => {
  const { children, trigger } = props;

  return (
      <ReactTooltip placement="bottom" trigger={trigger || 'hover'} overlay={<TooltipContent {...props} />}>
        {children}
      </ReactTooltip>
  );
};

export default Tooltip;
