import React, { FunctionComponent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { IFilterValue } from '../../../../../utils/api/types';
import { IFilterConfig } from '../../../Context/TableFilterConfigContext';

export interface ISelectFilterProps {
  item: IFilterConfig;
  value: IFilterValue;
  onChange: (e: unknown, { value }: DropdownProps) => void;
}

export const SelectFilter: FunctionComponent<ISelectFilterProps> = ({ item, value, onChange }) => (
  <Dropdown
    search
    selection
    selectOnBlur={false}
    clearable={!item.nonClearable}
    options={item.options}
    placeholder={item.label}
    value={value}
    onChange={onChange}
  />
);
