import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import Modal from "../../../../common/Modal/Modal";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ChangesWarningModalProps } from "./types";

const StyledIntro = styled.div`
  max-width: 80%;
  text-align: center;
  margin-top: 30px;
`;

const ChangesWarningModal: FunctionComponent<ChangesWarningModalProps> = ({ isOpen, onCancel, onOk }): ReactElement => {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel} title="Warning">
      <StyledConfirm>
        <StyledIntro>Are you sure you want to leave <Accent color="red">without saving</Accent> changed settings?</StyledIntro>
        <StyledButtons>
          <Button label="No, go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
          <Button icon="ok" label="Yes" theme="SUCCESS_PRIMARY" onClick={onOk} />
        </StyledButtons>
    </StyledConfirm>
    </Modal>
  );
};

export default ChangesWarningModal;
