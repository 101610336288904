import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { getIssueStatusText } from "../../../utils/issue";
import { IssueStatusCellProps } from "../types";
import Td from "./Td";

const StyledIssueStatusCell = styled.div<{ isOpen: boolean }>`
  color: ${({ isOpen, theme }) => isOpen ? theme.TABLE.WARNING : theme.TABLE.SUCCESS};
`;

const IssueStatusCell: FunctionComponent<IssueStatusCellProps> = ({ isOpen }) => (
  <Td>
    <StyledIssueStatusCell isOpen={isOpen}>
      { getIssueStatusText(isOpen) }
    </StyledIssueStatusCell>
  </Td>
);

export default IssueStatusCell;
