import { useMemo } from 'react';
import { IUser } from '../../../types/resources';
import { ITableConfig } from '../../../common/Table/Context/TableConfigContext';
import { textWrapperCell } from './textWrapperCell';
import { formatDateField } from '../../date';
import { UserAccountLogin } from '../../../pages/backend/UserList/UserAccountLogin';
import { UnarchiveUserButton } from '../../../pages/backend/UserList/UnarchiveUserButton';

export const useBackendUserList = () => useMemo<ITableConfig<IUser>[]>(() => [
  {
    header: 'First Name',
    Cell: textWrapperCell('firstName'),
  },
  {
    header: 'Last Name',
    Cell: textWrapperCell('lastName'),
  },
  {
    header: 'Email Address',
    Cell: textWrapperCell('emailAddress'),
  },
  {
    header: 'Created At',
    Cell: textWrapperCell('createdAt', undefined, formatDateField),
  },
  {
    header: 'Is Archived',
    Cell: textWrapperCell('archived', undefined, archived => archived ? 'Yes' : '-'),
  },
  {
    header: 'Is Administrator',
    Cell: textWrapperCell('userIsAdministrator', undefined, archived => archived ? 'Yes' : '-'),
  },
  {
    header: 'Account Name',
    Cell: textWrapperCell('account.name'),
  },
  {
    header: 'Account Code',
    Cell: textWrapperCell('account.accountCode'),
  },
  {
    header: 'Login to Account',
    Cell: UserAccountLogin
  },
  {
    header: 'Unarchive',
    Cell: UnarchiveUserButton
  }
], []);
