import React, { FunctionComponent, createContext, useContext, useState, useMemo } from 'react';

export interface INonLayoutTitleContext {
  title: string;
  setTitle: (title: string) => void;
}

export const NonLayoutTilteContext = createContext<INonLayoutTitleContext>({
  title: '',
  setTitle: () => void 0,
});

export const NonLayoutTitleProvider: FunctionComponent = ({ children }) => {
  const [title, setTitle] = useState('');

  const value = useMemo<INonLayoutTitleContext>(() => ({
    title,
    setTitle,
  }), [title]);

  return (
    <NonLayoutTilteContext.Provider value={value}>
      {children}
    </NonLayoutTilteContext.Provider>
  );
};

export const useNonLayoutTitle = () => useContext(NonLayoutTilteContext);
