import { Validation } from "./types";

const EMAIL_CHECK_REGEX = /^[\w\-'._%+]+@[a-z\d\-.]+\.[a-z]+$/;

const numberTypeError = `Value must be a number.`;
const stringTypeError = `Value must be a string.`;

export function validateEmail(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return EMAIL_CHECK_REGEX.test(value) ? null : 'Invalid email.';
}

export function validateUrl(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  try {
    new URL(value);
  } catch {
    return "Invalid URL"
  }

  return null;
}

export function validateX509Certificate(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return /BEGIN CERTIFICATE/.test(value) ? null : "Invalid X509 certificate"
}

export function validateHasLowerCase(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return /[a-z]/.test(value) ? null : 'Must contain at least one lowercase letter'
}

export function validateHasNumericCharacter(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return /[0-9]/.test(value) ? null : 'Must contain at least one number'
}

export function validateHasSpecialCharacter(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return /[!@#$%^&(){}[\]<>?|;:'"+\-/*=~`.,\\]/.test(value) ? null : 'Must contain at least one special character'
}

export function validateHasUpperCase(value: any): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return /[A-Z]/.test(value) ? null : 'Must contain at least one uppercase letter'
}

export function validateMatch(value: any, validation: Validation): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  return value === validation.params.value ? null : `Passwords don't match.`
}

export function validateMaxConsecutiveCharacters(value: any, validation: Validation): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  const max = validation.params.maxConsecutiveCharacters

  return value.split('').some((char, index, arr) => {
    const checkIndexes: number[] = [];
    for (let i = 1; i <= max; i++) {
      checkIndexes.push(index + i);
    }

    return checkIndexes.reduce((acc, cur) => acc && char === arr[cur], true);
  }) ? `Too many consecutive characters (e.g. 'aaaa') - maximum allowed is ${max}` : null;
}

export function validateMinLength(value: any, validation: Validation): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  const { minLength } = validation.params;
  return value.length >= minLength ? null : `Value too short, minimum ${minLength} characters required`;
}

export function validateNoTrailingSpaces(value: any, validation: Validation): string | null {
  value = value as string;

  return !/^\s+|\s+$/.test(value) ? null : 'No leading or trailing spaces allowed';
}

export function validateLengthBetween(value: any, validation: Validation): string | null {
  if (typeof value !== 'string') {
    return stringTypeError;
  }

  const { minLength, maxLength } = validation.params;
  const trimmedValue = value.trim()
  return trimmedValue.length >= minLength && trimmedValue.length <= maxLength
    ? null
    : `Value must be between ${minLength} and ${maxLength} characters long`;
}

export function validateRequired(value: any): string | null {
  return value ? null : 'This field is required.'
}

export function validateValueBetween(value: any, validation: Validation): string | null {
  if (typeof value !== 'number') {
    return numberTypeError;
  }

  const { minValue, maxValue } = validation.params;
  return value >= minValue && value <= maxValue
    ? null
    : `Value must be between ${minValue} and ${maxValue}`;
}
