import React, { useCallback, useState, FunctionComponent, useMemo } from "react";
import Modal from "../../../../common/Modal/Modal";
import { UserFormData } from "../../../../forms/UserForm/types";
import EditProfile from "./EditProfile";
import UserProfile from "./UserProfile";
import { EditUserModalProps, EditUserModalStep } from "./types";
import { useEffect } from "react";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Failure from "../Common/Failure";
import { useUpdateUserApi } from "../../../../utils/api/options/users";
import { useApiRequest } from "../../../../utils/api/hooks";
import { IUpdateUser, IUser } from "../../../../types/resources";
import { getUserFormDataInitialValues } from "../../../../forms/UserForm/utils";
import { updateUserPayload } from "./utils";
import ConfirmAdmin from "./ConfirmAdmin";
import ConfirmArchive from "./ConfirmArchive";

const EditUserModal: FunctionComponent<EditUserModalProps> = ({ initialStep, isOpen, onCancel, onOk, user }) => {
  const [step, setStep] = useState<EditUserModalStep>();
  const [successMessage, setSuccessMessage] = useState('');
  const updateUserApi = useUpdateUserApi(user.id);
  const { fetch: updateUser } = useApiRequest<IUpdateUser, IUser>(updateUserApi);

  useEffect(() => {
    setStep('loading')
  }, [])

  useEffect(() => {
    if (initialStep) {
      setStep(initialStep)
    }
  }, [initialStep])

  const handleUpdateUser = useCallback(async (payload: IUpdateUser, msg: string) => {
    setStep('processing')
    const [result, error] = await updateUser(payload);

    if (error || !result) {
      setStep('failure')
      return
    }

    setSuccessMessage(msg)
    setStep('success')
  }, [updateUser])

  const handleSubmit = useCallback(async (data: UserFormData) => {
    const payload = updateUserPayload(user.id, data)
    await handleUpdateUser(payload, 'User has been updated.')
  }, [handleUpdateUser, user.id])

  const handleToggleAdmin = useCallback(async () => {
    const formData = getUserFormDataInitialValues(user)
    const payload = {
      ...updateUserPayload(user.id, formData),
      userIsAdministrator: !user.userIsAdministrator,
    }
    await handleUpdateUser(payload, 'User has been updated.')
  }, [handleUpdateUser, user])

  const handleArchive = useCallback(async () => {
    const formData = getUserFormDataInitialValues(user)
    const payload = {
      ...updateUserPayload(user.id, formData),
      archived: true,
    }
    await handleUpdateUser(payload, 'User has been archived.')
  }, [handleUpdateUser, user])

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  return (
    <Modal onCancel={handleCancel} isOpen={isOpen} title="User profile" step={step}>
      { (step === 'loading' || step === 'profile') && 
        <UserProfile
          user={user}
          onCancel={onCancel}
          onEdit={() => setStep('edit')}
          onLoad={() => setStep('profile')}
        />
      }
      { (step === 'edit' || step === 'edit-advanced') && 
        <EditProfile
          user={user}
          onSubmit={handleSubmit}
          onChangeStep={setStep}
          step={step}
        />
      }
      { step === 'confirm-admin' && <ConfirmAdmin user={user} onCancel={() => setStep('edit')} onOk={handleToggleAdmin} />}
      { step === 'confirm-archive' && <ConfirmArchive user={user} onCancel={() => setStep('edit')} onOk={handleArchive} />}
      { step === 'processing' && <Processing />}
      { step === 'success' && <Success onOk={onOk} text={successMessage} />}
      { step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
}

export default EditUserModal;
