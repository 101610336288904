import React, { useCallback, useEffect, ReactElement, useState } from 'react';
import { Dropdown, Button } from 'semantic-ui-react';

import { useTableSelection } from './Table/Context/TableSelectionContext';
import { useTableData } from './Table/Context/TableDataContext';
import { IApiRequestOptions } from '../utils/api/types';

import { useTablePagination } from './Table/Context/TablePaginationContext';
import { useTableFilter } from './Table/Context/TableFilterContext';
import { useApiFetchAll } from '../utils/api/hacks';
import { prepareDataForExport, exportData as exportToFile, IExportConfig } from '../utils/export';

export interface IExportDataButtonProps<TData> {
  apiRequestOptions?: IApiRequestOptions;
  exportOptions: IExportConfig<TData>[];
  fileName: string;
}

export const ExportDataButton = <TData extends { id: string }>(
  { apiRequestOptions, exportOptions, fileName }: IExportDataButtonProps<TData>
): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState<TData[] | null>(null);

  const { filter } = useTableFilter();
  const { pagination } = useTablePagination();
  const { selection, isAnySelected } = useTableSelection();
  const currentPageData = useTableData();

  const { start: fetchAllData, reset: resetFetchAll, data: allData } = useApiFetchAll(apiRequestOptions ?? { uri: '' });
  
  useEffect(() => {
    if(allData)
    {
      setDataToExport(allData);
      resetFetchAll();
    } 
    
    if (dataToExport) {
      setIsLoading(false);
      exportToFile(fileName, prepareDataForExport<TData>(dataToExport, exportOptions));
      setDataToExport(null);
    }
  }, [dataToExport, exportOptions, fileName, resetFetchAll, allData]);

  const exportCurrentPage = useCallback(() => {
    setIsLoading(true);
    setDataToExport(currentPageData)
  }, [currentPageData]);
 
  const exportSelected = useCallback(() => {
    setIsLoading(true);
    setDataToExport(Object.values(selection));
  }, [selection]);

  const exportAll = useCallback(async () => {
    setIsLoading(true);
    await fetchAllData(filter?.current);
    setIsLoading(false);
  }, [fetchAllData, filter]);

  const hasMorePages = pagination && (pagination?.current?.totalPageCount || 0) > pagination?.current?.currentPage;

  return (
    <Dropdown
      selectOnBlur={false}
      icon={false}
      trigger={<Button color="green" loading={isLoading}>Export</Button>}
      disabled={isLoading}
    >
      <Dropdown.Menu>
        <Dropdown.Item onClick={exportSelected} disabled={!isAnySelected}>Export selected ({Object.values(selection).length})</Dropdown.Item>
        <Dropdown.Item onClick={exportCurrentPage}>Export current page ({currentPageData?.length})</Dropdown.Item>
        {hasMorePages &&
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={exportAll}>Export all results ({pagination?.current.totalItemCount})</Dropdown.Item>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>
  )
};
