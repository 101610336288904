import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box, { StyledBox } from "../../../common/Box/Box";
import { ArrayAnswer, MatrixAnswer, SimpleAnswer } from "./AssessmentQuestionAnswer";
import { AssessmentQuestionAnswersProps, isAnswerArray, isAnswerMatrix, isAnswerSimple } from "./types";

const StyledAssessmentQuestionAnswer = styled.div`
  font-size: 12px;
  line-height: 20px;
  margin-top: 14px;

  ${StyledBox} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const StyledQuestion = styled.div`
  color: ${({ theme }) => theme.QA.QUESTION};
  margin-bottom: 8px;
  font-weight: 600;
`;

const AssessmentQuestionAnswers: FunctionComponent<AssessmentQuestionAnswersProps> = ({ questionAnswer }): ReactElement => {
  const { answer, question } = questionAnswer

  return (
    <StyledAssessmentQuestionAnswer>
      <Box border shadow>
        <StyledQuestion>{question}</StyledQuestion>
        { isAnswerSimple(answer) && <SimpleAnswer answer={answer} />}
        { isAnswerArray(answer) && <ArrayAnswer answer={answer} />}
        { isAnswerMatrix(answer) && <MatrixAnswer answer={answer} />}
      </Box>
    </StyledAssessmentQuestionAnswer>
  );
};

export default AssessmentQuestionAnswers;
