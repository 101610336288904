import { IIssue } from '../../types/resources';

export const getQuestionText = (value: IIssue) => {
  return `${
    value.questionResponse.surveyQuestion.meta.matrixQuestionText ?
    value.questionResponse.surveyQuestion.meta.matrixQuestionText.replace(/:$/, '') + ': ' :
      ''
  }${value.questionResponse.surveyQuestion.displayText}`;
}

export const getAnswerString = (answer: string | string[]): string =>
  Array.isArray(answer) ? answer.join(', ') : answer;
