import React, { FunctionComponent, useEffect } from 'react';
import { IAccount } from '../../../types/resources';
import { useFetchAccountListApi } from '../../../utils/api/options/accounts';
import { useApiRequest } from '../../../utils/api/hooks';
import { AccountListTable } from './AccountListTable';

export const AccountList: FunctionComponent = () => {
  const fetchAccountListApi = useFetchAccountListApi();

  const {
    fetch: fetchAccounts,
    loading,
    data: accounts,
    _pagination,
    _sortBy,
    _filters,
  } = useApiRequest<never, IAccount[]>(fetchAccountListApi);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <AccountListTable
      height="60vh"
      fetchAccounts={fetchAccounts}
      accounts={accounts}
      loading={loading}
      _pagination={_pagination}
      _sortBy={_sortBy}
      _filters={_filters}
    />
  );
};
