import React, { FunctionComponent } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { SectionHeader } from '../SectionHeader';

export interface IGeneralInfoSegmentProps {}

export const GeneralInfoSegment: FunctionComponent<IGeneralInfoSegmentProps> = () => (
  <Grid.Column>
    <SectionHeader>Instructions</SectionHeader>
    <Segment placeholder>
      <p>
        You can upload lists of users to create new users, update existing users or both simultaneously. 
        Existing users are matched based on email address. To get started, download an empty list template 
        from the adjacent menu or use an exported list of existing users.
      </p>
    </Segment>
  </Grid.Column>
);
