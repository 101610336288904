import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 90%;
  text-align: center;
  margin-top: 8px;

  :first-child {
    margin-top: 30px;
  }
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ usersCount, survey, onCancel, onOk }): ReactElement => {
  const users = `${usersCount} user${usersCount !== 1 ? 's' : ''}`
  return (
    <StyledConfirm>
       <StyledIntro>You are about to invite <Accent>{users}</Accent> to an assessment - <Accent>{survey.name}</Accent>.</StyledIntro>
       <StyledIntro>Would you like to go ahead?</StyledIntro>
       <StyledButtons>
        <Button label="No, thanks" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Yes, invite" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
