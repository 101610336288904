import React, { FunctionComponent, useCallback } from 'react';
import { Grid, Menu, Header } from 'semantic-ui-react';

import { ListAccountItem } from './ListAccountItem';
import { IUserAccount } from '../../../../../types/resources';
import { useListAccountsPagination, useSearchedAccounts } from '../../../../../utils/listAccounts';
import { ListAccountPagination } from './ListAccountPagination';
import { ListAccountSearch } from './ListAccountSearch';

export interface IListAccountsProps {
  accounts: IUserAccount[] | null;
  loginToAccount: (account: IUserAccount) => void;
}

export const ListAccounts: FunctionComponent<IListAccountsProps> = ({
  accounts,
  loginToAccount,
}) => {
  const { matchedAccounts, searchAccountsValue, searchAccounts } = useSearchedAccounts(accounts);
  const {
    hasPagination,
    paginatedAccounts,
    canNextPage,
    canPrevPage,
    handleNextPage,
    handlePrevPage,
  } = useListAccountsPagination(matchedAccounts);

  const handleLoginToAccount = useCallback(async (account: IUserAccount) => {
    //TODO: Should grey out the modal so you can't double click
    loginToAccount(account);
  }, [loginToAccount]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={2} />
        <Grid.Column width={12}>
          <ListAccountSearch value={searchAccountsValue} onChange={searchAccounts} />
          {(!paginatedAccounts || !paginatedAccounts.length) && <Header as="h3" textAlign="center">No accounts</Header>}
          <Menu vertical fluid secondary>
            {paginatedAccounts?.map((account) => (
              <ListAccountItem
                key={`account-list-id-${account.id}`}
                account={account}
                onClick={handleLoginToAccount}
              />
            ))}
          </Menu>
          {hasPagination && (
            <ListAccountPagination
              canNextPage={canNextPage}
              canPrevPage={canPrevPage}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          )}
        </Grid.Column>
        <Grid.Column width={2} />
      </Grid.Row>
    </Grid>
  );
};
