import React, { FunctionComponent, ReactElement, useState } from "react";
import Modal from "../../../../common/Modal/Modal";
import { CloseIssuesFormData } from "../../../../forms/CloseIssuesForm/types";
import { getCloseIssuesFormDataInitialValues } from "../../../../forms/CloseIssuesForm/utils";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useCreateAdministratorClosureApi } from "../../../../utils/api/options/questionResponseIssue/administratorNotes";
import { canBeClosed } from "../../../../utils/issue";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import CloseIssuesFormWrapper from "./CloseIssuesFormWrapper";
import { CloseIssuesModalProps, CloseIssuesModalStep } from "./types";

const CloseIssuesModal: FunctionComponent<CloseIssuesModalProps> = ({ isOpen, issues, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<CloseIssuesModalStep>('form');
  const [formData, setFormData] = useState<CloseIssuesFormData>(getCloseIssuesFormDataInitialValues());
  const addClosureApi = useCreateAdministratorClosureApi();
  const { fetch: addClosure } = useApiRequest(addClosureApi);

  const closeIssues = async (data: CloseIssuesFormData) => {
    const filteredIssues = issues.filter(canBeClosed)
    const results = await Promise.all(filteredIssues.map((issue) => addClosure({
      note: data.message,
      questionResponseIssue: { id: issue.id }
    })));

    const errors = results.filter(result => !!result[1]);

    if(errors.length > 0) {
      setStep('failure');
      return;
    }

    setStep('success');
  }

  const handleFormSubmit = async (data: CloseIssuesFormData) => {
    setStep('processing')
    setFormData(data)
    await closeIssues(data)
  }

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} title="Close all issues">
      {step === 'form' && <CloseIssuesFormWrapper items={issues} initialValues={formData} onOk={handleFormSubmit} onCancel={onCancel}/>}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text="Issues successfully closed." />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default CloseIssuesModal;
