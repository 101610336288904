import React, { FunctionComponent } from "react";
import { AssessmentStatusCellProps } from "../types";
import AssessmentStatus from "../../AssessmentStatus/AssessmentStatus";
import { StyledAssessmentCell } from "./AssessmentCell";
import Td from "./Td";

const AssessmentStatusCell: FunctionComponent<AssessmentStatusCellProps> = ({ center, status, date }) => (
  <Td>
    <StyledAssessmentCell center={center}>
      <AssessmentStatus status={status} date={date} />
    </StyledAssessmentCell>
  </Td>
);

export default AssessmentStatusCell;
