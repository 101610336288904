import React, { createContext, FunctionComponent, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { ListAccountsModal } from '..';
import { IUserAccount } from '../../../../../types/resources';
import { ROUTES as AdminRoutes } from "../../../../../routing/AdminRouter";
import { useSession } from '../../../AuthContext/SessionContext';
import { loginToAccountUri } from '../../../LoginPage';

const ListAccountsModalContext = createContext<() => void>(() => void 0);

export const ListAccountsModalProvider: FunctionComponent = ({ children }) => {
  const history = useHistory();
  const session = useSession();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<IUserAccount[] | null>(null);

  const openModal = useCallback(async () => {
    if (session.state.type === 'loggedIn' || session.state.type === 'authenticated') {
      const accounts = session.state.availableAccounts;

      if (accounts && accounts.length > 0) {
        setAccounts(accounts.sort((a, b) => a.accountName.localeCompare(b.accountName)));
        setIsOpen(true);
      }
    } else {
      throw new Error(`Can't handle session state ${session.state.type}: ${session}`)
    }
  }, [session]);

  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onClickAccount = useCallback(async (account: IUserAccount) => {
    history.push(loginToAccountUri({ applicationKey: 'admin', accountId: account.accountId, userId: account.userId }));
    setIsOpen(false);
  }, [history, session]);

  return (
    <ListAccountsModalContext.Provider value={openModal}>
      {children}
      <ListAccountsModal
        isOpen={isOpen}
        accounts={accounts}
        loginToAccount={onClickAccount}
        onCancel={onCancel}
      />
    </ListAccountsModalContext.Provider>
  );
};

export const useOpenListAccountsModal = () => useContext(ListAccountsModalContext);
