import React, { FunctionComponent, useCallback, useMemo } from "react";
import styled from "styled-components";
import { formatDateField } from "../../../utils/date";
import { getAssessmentDetailsRoute } from "../../../routing/AdminRouter";
import { AssessmentCellProps } from "../types";
import Td from "./Td";
import { useHistory } from "react-router";

export const StyledAssessmentCell = styled.div<{ center?: boolean, clickable?: boolean}>`
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'normal'};
  display: flex;
  align-items: ${({ center }) => center ? 'center' : 'flex-start'};
  flex-direction: column;
  text-decoration: ${({ clickable }) => clickable ? 'underline' : 'none'};
`;

export const StyledNoAssessment = styled.div`
  color: ${({ theme }) => theme.TABLE.COLOR_LIGHT};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
`;

export const StyledAssessmentName = styled.div<{ center?: boolean; color?: string}>`
  color: ${({ color, theme }) => color ? color : theme.TABLE.COLOR_DARK};
  text-align: ${({ center }) => center ? 'center' : 'left'};;
  font-weight: 600;
  line-height: 14px;
`;

export const StyledAssessmentDate = styled.div<{ center?: boolean}>`
  color: ${({ theme }) => theme.TABLE.COLOR_LIGHT};
  text-align: ${({ center }) => center ? 'center' : 'left'};;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
`;

const AssessmentCell: FunctionComponent<AssessmentCellProps> = ({ center, date, id, name }) => {
  const history = useHistory();
  const assessmentRoute = useMemo(() => id ? getAssessmentDetailsRoute(id) : undefined, [id])

  const handleClick = useCallback(() => {
    if (id && assessmentRoute) {
      history.push(assessmentRoute)
    }
  }, [assessmentRoute, history, id])
  return (
    <Td>
      <StyledAssessmentCell center={center} clickable={!!id} onClick={handleClick}>
        { (!name && !date) && (
          <StyledNoAssessment>—</StyledNoAssessment>
        )}
        { name && <StyledAssessmentName center={center}>{name}</StyledAssessmentName> }
        { date && <StyledAssessmentDate center={center}>{formatDateField(date)}</StyledAssessmentDate> }
      </StyledAssessmentCell>
    </Td>
  );
}

export default AssessmentCell;
