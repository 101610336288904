import React, { ReactElement, useCallback } from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import { useTableSelection } from '../../Context/TableSelectionContext';
import { useTableConfig } from '../../Context/TableConfigContext';
import { useTableFlags } from '../../Context/TableFlagsContext';


export interface ITableRowProps<TData extends { id: string }> {
  value: TData;
}

export const TableRow = <TData extends { id: string }>({ value }: ITableRowProps<TData>): ReactElement<ITableRowProps<TData>> => {
  const config = useTableConfig();
  const { selectable } = useTableFlags();
  const { isSelected, selectOne } = useTableSelection();

  const handleCheckboxClick = useCallback(() => { selectOne(value); }, [selectOne, value]);

  return (
    <Table.Row>
      {selectable && (
        <Table.Cell>
          <Checkbox checked={isSelected(value.id)} onClick={handleCheckboxClick} />
        </Table.Cell>
      )}
      {config.map(({ Cell, cellProps }, index) => (
        <Table.Cell key={`table-row-cell-${index}`} {...cellProps}>
          <Cell value={value} />
        </Table.Cell>
      ))}
    </Table.Row>
  );
};
