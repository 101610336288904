import React, { createContext, useContext, FunctionComponent, useMemo } from 'react';
import { useSelection, ISelectionCallbacks } from '../../../utils/table/selection';
import { useTableData } from './TableDataContext';
import { useTableFlags } from './TableFlagsContext';

export interface ITableSelectionProps {}

const defaultSelection: ISelectionCallbacks<any> = {
  selection: {},
  selectOne: () => void 0,
  selectAll: () => void 0,
  isSelected: () => false,
  areAllSelected: false,
  isAnySelected: false,
};

export const TableSelectionContext = createContext<ISelectionCallbacks<any>>(defaultSelection);

export const TableSelectionProvider: FunctionComponent<ITableSelectionProps> = ({ children }) => {
  const data = useTableData();
  const { selectable } = useTableFlags();
  const selectionProps = useSelection(data);
  const value = useMemo(() => selectable ? selectionProps : defaultSelection, [selectable, selectionProps]);

  return (
    <TableSelectionContext.Provider value={value}>
      {children}
    </TableSelectionContext.Provider>
  )
};

export const useTableSelection = () => useContext(TableSelectionContext);
