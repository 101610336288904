import { Option } from "../../common/Forms/types";
import { MANUAL_ASSESSMENT_STATUS_TYPE } from "../../types/enums";
import { IManualAssessmentStatusType } from "../../types/resources";
import { getContactFormDataInitialValues } from "../ContactForm/utils";
import { FollowUpFormData } from "./types";

export const selectOptions: {[key in IManualAssessmentStatusType]: Option} = {
  invited: { value: MANUAL_ASSESSMENT_STATUS_TYPE.invited, label: 'Invited' },
  scheduled: { value: MANUAL_ASSESSMENT_STATUS_TYPE.scheduled, label: 'Scheduled' },
  declined: { value: MANUAL_ASSESSMENT_STATUS_TYPE.declined, label: 'Declined' },
  completed: { value: MANUAL_ASSESSMENT_STATUS_TYPE.completed, label: 'Completed' },
  cancelled: { value: MANUAL_ASSESSMENT_STATUS_TYPE.cancelled, label: 'Cancelled' },
}

export const getSelectOptions = (isActive: boolean, currentStatus?: IManualAssessmentStatusType): Option[] => {
  const { invited, scheduled, declined, completed, cancelled } = selectOptions
  const options = isActive
    ? [scheduled, declined, completed, cancelled] 
    : [invited, scheduled]
  return options.filter(option => option.value !== currentStatus)
}

export const getStatusOption = (status?: IManualAssessmentStatusType): Option => {
  return status
    ? selectOptions[status]
    : selectOptions.scheduled
}

export function getFollowUpFormDataInitialValues(): FollowUpFormData {
  return { 
    closeIssues: false,
    closeIssuesMessage: '',
    contact: getContactFormDataInitialValues(),
    sendEmail: false,
    status: null,
   };
}
