import { useApiRequestOptions } from '../hooks';

export const useFetchDataUploads = () => useApiRequestOptions({
  uri: 'data_uploads',
}, []);

export const useFetchActiveDataUpload = () => useApiRequestOptions({
  uri: 'data_uploads/active',
}, []);

export const useStartDataUpload = (id: string) => useApiRequestOptions({
  uri: `user_list_uploads/${id}/start`,
  method: 'patch',
  successMessage: null,
}, [id]);

export const useDeleteDataUploads = (id: string) => useApiRequestOptions({
  uri: `data_uploads/${id}`,
  method: 'delete',
  successMessage: null,
}, [id]);
