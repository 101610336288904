import React, { createContext, useContext, FunctionComponent, useMemo, useCallback, useEffect, Reducer, useReducer, useState } from 'react';
import { AccountFeature, IUserAccount } from '../../../../types/resources';
import { useApiRequest } from '../../../../utils/api/hooks';
import { useAuthenticateApi, useLoginToAccountApi, useLogoutApi } from '../../../../utils/api/options/auth';
import { IApiRequestOptions } from '../../../../utils/api/types';
import { AUTH_ERRORS } from '../../authErrors';
import { useActiveApplication } from '../ApplicationContext';

export interface ILoginUser extends IUserAccount {
  isSuperAdministrator: boolean;
  hasMultipleAccounts: boolean;
  canAccessBackend: boolean;
}

type ILoginFunc = (user: Pick<IUserAccount, 'userId' | 'accountId'>) => void;

type ISessionCommand = 
  | { action: 'authenticate', email: string, password: string }
  | { action: 'setAuthenticated', availableAccounts: IUserAccount[] }
  | { action: 'setAuthenticationFailed', error: string }
  | { action: 'queryCurrentUser' }
  | { action: 'login', account: { accountId: number, userId: number } }
  | { action: 'setLoggedIn', user: ILoginUser, availableAccounts: IUserAccount[], accountFeatures: AccountFeature[] }
  | { action: 'logout' }
  | { action: 'setLoggedOut' }

type SessionState = 
  | { type: 'initializing', stabilised: false }
  | { type: 'authenticating', stabilised: false  }
  | { type: 'authenticated', availableAccounts: IUserAccount[], stabilised: true }
  | { type: 'authenticationFailed', error: string, stabilised: true }
  | { type: 'loggingIn', stabilised: false  }
  | { type: 'queryingCurrentUser', stabilised: false  }
  | { type: 'loggedIn', user: ILoginUser, availableAccounts: IUserAccount[], accountFeatures: AccountFeature[], stabilised: true }
  | { type: 'loggingOut', stabilised: false  }
  | { type: 'loggedOut', stabilised: true  }

interface ISessionContext {
  readonly state: SessionState;
  readonly currentUser: ILoginUser | null;
  readonly accountFeatures: AccountFeature[];
  readonly authenticate: (email: string, password: string) => void,
  readonly login: ILoginFunc;
  readonly logout: () => void;
  readonly handle401: (route: string) => void;
}

const EMPTY_SESSION : ISessionContext = {
  state: { type: 'initializing', stabilised: false },
  currentUser: null,
  accountFeatures: [],
  authenticate: (email: string, password: string) => void 0,
  login: () => void 0,
  logout: () => void 0,
  handle401: (route: string) => void 0,
}

export const SessionContext = createContext<ISessionContext>(EMPTY_SESSION);

export const SessionProvider: FunctionComponent = ({ children }) => {
    const { applicationKey } = useActiveApplication();
    const authApi = useAuthenticateApi();
    const { fetch: submitAuthentication, error: authError, loading: authLoading } = useApiRequest(authApi);

    const LOGIN_TRACKER_KEY = `ERGOFY_LOGIN_STATE_${applicationKey.toUpperCase()}`;
    const readLoginState = useCallback(() => localStorage.getItem(LOGIN_TRACKER_KEY), []);
    const [loginState, setLoginLastModified] = useState<string | null>(readLoginState());
    const syncLoginState = useCallback((userAndAccountId: string) => {
      setLoginLastModified(userAndAccountId);
      localStorage.setItem(LOGIN_TRACKER_KEY, userAndAccountId);
    }, [setLoginLastModified]);

    const whoAmIApi = useMemo<IApiRequestOptions>(() => (
      { 
        uri: `/auth/${applicationKey}/whoami`, 
        noAppKeyInBaseUrl: true,
        passBackErrorStatus: [401],
      }), [applicationKey]);
    const { fetch: whoAmI, data: whoAmIData, loading: whoAmILoading, error: whoAmIError } = useApiRequest(whoAmIApi);

    const logoutApi = useLogoutApi();
    const { fetch: requestLogout, loading: logoutLoading } = useApiRequest(logoutApi);

    const loginToAccountApi = useLoginToAccountApi();
    const { fetch: requestLogin, loading: loginLoading } = useApiRequest<{ user_id: number; account_id: number }, never>(loginToAccountApi);

    const executeLogin = useCallback<ILoginFunc>(async ({ userId, accountId }) => {
      await requestLogin({ user_id: userId, account_id: accountId });
    }, [requestLogin])

    const mergeSession = useCallback((context: ISessionContext, newState: SessionState) : ISessionContext => {
      return { ...context, state: newState };
    }, [])

    const commandHandler: Reducer<ISessionContext, ISessionCommand> = useCallback((session, command) => {
      switch (command.action) {
        case 'authenticate':
          submitAuthentication({ email_address: command.email.toLowerCase().trim(), password: command.password });
          return mergeSession(session, { type: 'authenticating', stabilised: false });
        case 'queryCurrentUser':
          whoAmI();
          return mergeSession(session, { type: 'queryingCurrentUser', stabilised: false });
        case 'setAuthenticated': 
          return mergeSession(session, { type: 'authenticated', availableAccounts: command.availableAccounts, stabilised: true });
        case 'setAuthenticationFailed':
          return mergeSession(session, { type: 'authenticationFailed', error: command.error, stabilised: true })
        case 'login':
          executeLogin(command.account);
          return mergeSession(session, { type: 'loggingIn', stabilised: false });
        case 'setLoggedIn': 
          syncLoginState(command.user.id);
          return mergeSession(session, { type: 'loggedIn', user: command.user, availableAccounts: command.availableAccounts, accountFeatures: command.accountFeatures, stabilised: true });
        case 'logout':
          requestLogout();
          return mergeSession(session, { type: 'loggingOut', stabilised: false });
        case 'setLoggedOut':
          syncLoginState("");
          return mergeSession(session, { type: 'loggedOut', stabilised: true });
        default: {
          throw Error(`Unhandled command ${command}`);
        }
      }
    }, [submitAuthentication, mergeSession, whoAmI, executeLogin, requestLogout, syncLoginState])
    const [session, dispatch] = useReducer(commandHandler, EMPTY_SESSION);

    // Track changes to the logged in user in other tabs by subscribing to the last login modified time
    useEffect(() => {
      const changeHandler = () => {
        if (loginState != readLoginState() && !whoAmILoading)
        {
          dispatch({ action: 'queryCurrentUser' });
        }
      }
      window.addEventListener("storage", changeHandler);
      return () => {
        window.removeEventListener("storage", changeHandler);
      };
    }, [loginState, readLoginState, whoAmILoading]);

    const authenticate = useCallback((email: string, password: string) => { 
      dispatch({ action: 'authenticate', email: email, password: password })
    }, [])
    const logout = useCallback(() => dispatch({ action: 'logout' }), [dispatch]);
    const login = useCallback<ILoginFunc>((user) => {
      dispatch({ action: 'login', account: { accountId: user.accountId, userId: user.userId } });
    }, [dispatch]);
    const handle401 = useCallback(uri => {
      if (!uri.match('whoami')) {
        dispatch({ action: 'logout' });
      }
    }, [])

    const loggedInUser = useMemo(() => {
      if (whoAmIData) {
        if (whoAmIData.user && whoAmIData.account) {
          const user = whoAmIData.user.attributes;
          const account = whoAmIData.account.attributes;

          return({
            id: `${account.id}-${user.id}`,
            accountId: account.id,
            accountCode: account.accountCode,
            accountName: account.name,
            userId: user.id,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            hasMultipleAccounts: user.hasMultipleAccounts,
            isSuperAdministrator: user.isSuperAdministrator,
            canAccessBackend: user.canAccessBackend,
            accountEnabledFeatures: account.enabledFeatures
          })
        } else {
          return null;
        }
      }
    }, [whoAmIData])

    const accountFeatures = useMemo(() => {
      if (whoAmIData) {
        if (whoAmIData.account) {
          return whoAmIData.account.attributes.enabledFeatures;
        } else {
          return null;
        }
      }
    }, [whoAmIData])

    const availableAccounts = useMemo(() => {
      if (whoAmIData) {
        return whoAmIData.availableAccounts.map((entry) => {
          const values = entry.attributes;

          return({
            id: `${values.accountId}-${values.userId}`,
            accountId: values.accountId,
            accountCode: values.accountCode,
            accountName: values.accountName,
            userId: values.userId,
            userFirstName: values.userFirstName,
            userLastName: values.userLastName,
          });
        })
      } else {
        return null;
      }
    }, [whoAmIData])

    const getAuthErrorText = useCallback((code: string) => {
      switch (code) {
        case 'authentication_required':
          return AUTH_ERRORS.INVALID_CREDENTIALS;
        case 'insufficient_authentication':
          return AUTH_ERRORS.INSUFFICIENT_AUTHENTICATION;
        case 'user_locked_out':
          return AUTH_ERRORS.USER_LOCKED_OUT;
        default:
          return AUTH_ERRORS.INVALID_CREDENTIALS;
      }
    }, [])

    useEffect(() => {
      switch (session.state.type) {
        case 'authenticated':
        case 'loggedIn':
        case 'authenticationFailed':
        case 'loggedOut':
          break;

        case 'initializing':
          dispatch({ action: 'queryCurrentUser' });
          break;
        case 'queryingCurrentUser':
          if (!whoAmILoading) {
            if (loggedInUser && availableAccounts) {
              dispatch({ action: 'setLoggedIn', user: loggedInUser, availableAccounts: availableAccounts, accountFeatures: accountFeatures })
            } else if (!loggedInUser && availableAccounts) {
              if (availableAccounts.length > 0) {
                dispatch({ action: 'setAuthenticated', availableAccounts: availableAccounts })
              } else {
                dispatch({ action: 'setAuthenticationFailed', error: AUTH_ERRORS.NO_ACCOUNTS_AVAILABLE });
              }
            } else if(whoAmIError && +whoAmIError[0].status === 401) {
              dispatch(({ action: 'setLoggedOut' }))
            }
            else {
              throw new Error("Unable to interpret whoami data");
            }
          }
          break;
        case 'authenticating':
          if (!authLoading && !authError) {
            dispatch({ action: 'queryCurrentUser' });
          } else if (!authLoading && authError && +authError[0].status === 401) {
            dispatch({ action: 'setAuthenticationFailed', error: getAuthErrorText(authError[0].code) })
          } else if (!authLoading && authError && +authError[0].status === 429) {
            dispatch({ action: 'setAuthenticationFailed', error: AUTH_ERRORS.USER_LOCKED_OUT })
          }
          break;
        case 'loggingIn':
          if (!loginLoading) {
            dispatch({ action: 'queryCurrentUser' });
          }
          break;
        case 'loggingOut':
          if (!logoutLoading) {
            dispatch({ action: 'queryCurrentUser' });
          }
          break;
        default: {
          throw new Error(`Unhandled state ${session.state}`)
        }
      }
    }, [session.state.type, session.state, authLoading, authError, whoAmILoading, whoAmIData, loggedInUser, whoAmIError, availableAccounts, loginLoading, logoutLoading, getAuthErrorText, accountFeatures])

    const currentUser = session.state.type === 'loggedIn' ? session.state.user : null;

    const value = useMemo(() => ({
      ...session,
      currentUser,
      accountFeatures,
      authenticate,
      login,
      logout,
      handle401,
    }), [
      session,
      currentUser,
      accountFeatures,
      authenticate,
      login,
      logout,
      handle401,
    ]);

    return (
      <SessionContext.Provider value={value}>
        {children}
      </SessionContext.Provider>
    );
  };

export const useSession = () => useContext(SessionContext);
