import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const Table: FunctionComponent = ({ children }): ReactElement => {
  return (
    <StyledWrapper>
      <StyledTable>{children}</StyledTable>
    </StyledWrapper>
  );
};

export const Thead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.TABLE.BORDER};
`;

export const THr = styled.tr`
  height: 50px;
`;

export const Tr = styled.tr`
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.TABLE.BORDER};

  &:hover {
    background-color: ${({ theme }) => theme.TABLE.HOVER_ROW_BG}; 
  }

  &:last-child {
    border-bottom: 0;

    td:first-child {
      border-bottom-left-radius: 14px;
    }
    td:last-child {
      border-bottom-right-radius: 14px;
    }
  }
`;

export const TBody = styled.tbody`
`;
