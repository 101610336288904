import React, { FunctionComponent, useCallback } from 'react';
import { Grid, Segment, Header, Button, Dropdown } from 'semantic-ui-react';
import { SectionHeader } from '../SectionHeader';
import { prepareDataForExport, exportData as exportUtil, IExportFileType } from '../../../../utils/export';
import { useCreateUserListExportOptions } from '../../../../utils/export/options/createUserList';
import { ICreateUserWithId } from '../types';

export const TemplateSegment: FunctionComponent = () => {
  const exportOptions = useCreateUserListExportOptions();

  const handleDownloadTemplate = useCallback((docType: IExportFileType) => () => {
    exportUtil(
      'import_template',
      prepareDataForExport<ICreateUserWithId>([], exportOptions),
      exportOptions.map((option) => option.header),
      docType
    );
  }, [exportOptions]);

  return (
    <Grid.Column>
      <SectionHeader>Download template</SectionHeader>
      <Segment placeholder>
        <Header icon>
          Download a blank template
        </Header>
        <p>
          Note: the following columns are mandatory and may not be removed or left blank:
        </p>
        <ul>
          <li>Email Address</li>
          <li>First Name</li>
          <li>Last Name</li>
        </ul>

        <Dropdown
          selectOnBlur={false}
          icon={false}
          inline
          floating
          pointing="top"
          trigger={<Button color="green">Download template</Button>}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleDownloadTemplate('xlsx')}>Excel</Dropdown.Item>
            <Dropdown.Item onClick={handleDownloadTemplate('csv')}>Comma-separated Values (CSV)</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Segment>
    </Grid.Column>
  );
};
