import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import Box from "../../../common/Box/Box";
import useSelect from "../../../common/Forms/useSelect";
import { useModal } from "../../../common/Modal/hooks";
import { IIssue, IQuestionResponse, ISurveyResponse, IUser } from "../../../types/resources";
import { useApiRequest } from "../../../utils/api/hooks";
import { useFetchAssessmentDetailsApi, useFetchAssessmentIssuesApi, useFetchAssessmentQuestionsApi } from "../../../utils/api/options/assessments";
import { decorateIssues } from "../../../utils/issue";
import { defaultSortIssues } from "../../../utils/questionResponseIssue/sort";
import CancelAssessmentsModal from "../modals/CancelAssessmentsModal";
import CloseIssuesModal from "../modals/CloseIssuesModal";
import EditUserModal from "../modals/EditUserModal";
import ExportAssessmentIssuesModal from "../modals/ExportAssessmentIssuesModal";
import FollowUpModal from "../modals/FollowUpModal";
import ResendInviteModal from "../modals/ResendInviteModal";
import UpdateIssuesModal from "../modals/UpdateIssuesModal";
import UpdatesHistoryModal from "../modals/UpdatesHistoryModal";
import AssessmentTabs from "./AssessmentTabs";
import AssessmentCards from "./Cards/AssessmentCards";
import { AssessmentTab, isTabFilteredIssues } from "./types";
import { getFilteredData, tabs } from "./utils";

const Assessment: FunctionComponent = (): ReactElement => {
  const [tab, setTab] = useState<AssessmentTab>(tabs[0]);
  const [filteredIssues, setFilteredIssues] = useState<IIssue[]>([]);
  const { close: closeCancelAssessmentsModal, data: cancelAssessmentsModalData, isOpen: isCancelAssessmentsModalOpen, open: openCancelAssessmentsModal }  = useModal<ISurveyResponse[]>();
  const { close: closeCloseIssuesModal, data: closeIssuesModalData, isOpen: isCloseIssuesModalOpen, open: openCloseIssuesModal } = useModal<IIssue[]>();
  const { close: closeEditUserModal, data: editUserModalData, isOpen: isEditUserModalOpen, open: openEditUserModal }  = useModal<IUser>();
  const { close: closeExportAssessmentIssuesModal, isOpen: isExportAssessmentIssuesModalOpen, open: openExportAssessmentIssuesModal } = useModal<IIssue[]>();
  const { close: closeFollowUpModal, data: followUpModalData, isOpen: isFollowUpModalOpen, open: openFollowUpModal }  = useModal<ISurveyResponse>();
  const { close: closeResendInviteModal, data: resendInviteModalData, isOpen: isResendInviteModalOpen, open: openResendInviteModal }  = useModal<ISurveyResponse>();
  const { close: closeUpdateIssuesModal, data: updateIssuesModalData, isOpen: isUpdateIssuesModalOpen, open: openUpdateIssuesModal } = useModal<IIssue[]>();
  const { close: closeUpdatesHistoryModal, data: updatesHistoryModalData, isOpen: isUpdatesHistoryModalOpen, open: openUpdatesHistoryModal } = useModal<IIssue>();
  const { id: surveyResponseId } = useParams<{ id: string }>();
  const assessmentDetailsApi = useFetchAssessmentDetailsApi(surveyResponseId);
  const assessmentIssuesApi = useFetchAssessmentIssuesApi(surveyResponseId);
  const assessmentQuestionAnswersApi = useFetchAssessmentQuestionsApi(surveyResponseId);
  const { fetch: fetchAssessmentDetails, data: assessmentDetails } = useApiRequest<never, ISurveyResponse>(assessmentDetailsApi);
  const { fetch: fetchAssessmentIssues, data: assessmentIssues } = useApiRequest<never, IIssue[]>(assessmentIssuesApi);
  const { fetch: fetchAssessmentQuestionAnswers, data: assessmentQuestionAnswers } = useApiRequest<never, IQuestionResponse[]>(assessmentQuestionAnswersApi);
  const selectIssues = useSelect(filteredIssues);
  const user = assessmentDetails?.user;

  const { 
    selected: selectedIssues
  } = selectIssues

  useEffect(() => { 
    fetchAssessmentDetails()
    fetchAssessmentIssues()
    fetchAssessmentQuestionAnswers()
  }, [fetchAssessmentDetails, fetchAssessmentIssues, fetchAssessmentQuestionAnswers]);

  useEffect(() => {
    if (user) {
      let data = decorateIssues(assessmentIssues || [], user);
      data = isTabFilteredIssues(tab) ? getFilteredData(data, tab) : []
      data = defaultSortIssues(data);
      setFilteredIssues(data);
    }
  }, [assessmentIssues, tab, user])

  const closeModals = () => {
    closeCancelAssessmentsModal();
    closeCloseIssuesModal();
    closeEditUserModal();
    closeExportAssessmentIssuesModal();
    closeFollowUpModal();
    closeResendInviteModal();
    closeUpdateIssuesModal();
    closeUpdatesHistoryModal();
  }

  const handleAssessmentUpdate = () => {
    fetchAssessmentDetails();
    fetchAssessmentIssues();
    closeModals();
  };

  return (
    <div>
      {assessmentDetails && assessmentIssues && (
        <AssessmentCards
          assessmentDetails={assessmentDetails}
          issues={assessmentIssues}
          onOpenCancelAssessmentsModal={openCancelAssessmentsModal}
          onOpenCloseIssuesModal={openCloseIssuesModal}
          onOpenEditUserModal={openEditUserModal}
          onOpenExportAssessmentIssuesModal={openExportAssessmentIssuesModal}
          onOpenFollowUpModal={openFollowUpModal}
          onOpenResendInviteModal={openResendInviteModal}
          onOpenUpdateIssuesModal={openUpdateIssuesModal}
          onOpenUpdatesHistoryModal={openUpdatesHistoryModal}
          selectedIssues={selectedIssues}
          user={assessmentDetails.user}
        />
      )}

      {user && (
        <Box noPadding shadow>
          <AssessmentTabs
            assessmentQuestionAnswers={assessmentQuestionAnswers}
            issues={filteredIssues || []}
            onOpenUpdatesHistoryModal={openUpdatesHistoryModal}
            onOpenUpdateIssuesModal={openUpdateIssuesModal}
            onTabChange={setTab}
            selectIssues={selectIssues}
            tab={tab}
            user={user}
          />
        </Box>
      )}
      {resendInviteModalData && (
        <ResendInviteModal
          assessment={resendInviteModalData}
          isOpen={isResendInviteModalOpen}
          onCancel={closeResendInviteModal}
          onOk={handleAssessmentUpdate}
        />
      )}
      {cancelAssessmentsModalData && (
        <CancelAssessmentsModal
          assessments={cancelAssessmentsModalData}
          isOpen={isCancelAssessmentsModalOpen}
          onCancel={closeCancelAssessmentsModal}
          onOk={handleAssessmentUpdate}
        />
      )}
      {editUserModalData && (
        <EditUserModal
          isOpen={isEditUserModalOpen}
          user={editUserModalData}
          onCancel={closeEditUserModal}
          onOk={handleAssessmentUpdate}
        />
      )}
      {followUpModalData && (
        <FollowUpModal
          assessment={followUpModalData}
          isOpen={isFollowUpModalOpen}
          onCancel={closeFollowUpModal}
          onOk={handleAssessmentUpdate}
        />
      )}

      {updateIssuesModalData && (
        <UpdateIssuesModal
          isOpen={isUpdateIssuesModalOpen}
          issues={updateIssuesModalData}
          onCancel={closeUpdateIssuesModal}
          onOk={handleAssessmentUpdate}
        />
      )}
      <ExportAssessmentIssuesModal
        isOpen={isExportAssessmentIssuesModalOpen}
        selectedIssues={selectedIssues}
        pageIssues={filteredIssues || []}
        allIssuesCount={assessmentIssues?.length || 0}
        onCancel={closeExportAssessmentIssuesModal}
      />
      {closeIssuesModalData && (
        <CloseIssuesModal
          isOpen={isCloseIssuesModalOpen}
          issues={closeIssuesModalData}
          onCancel={closeCloseIssuesModal}
          onOk={handleAssessmentUpdate}
        />
      )}
      {updatesHistoryModalData && (
        <UpdatesHistoryModal
          isOpen={isUpdatesHistoryModalOpen}
          issue={updatesHistoryModalData}
          onCancel={closeUpdatesHistoryModal}
          onOk={handleAssessmentUpdate}
        />
      )}
    </div>
  );
};

export default Assessment;
