import { forEach } from "lodash";
import { Validation, ValidationType, Validator } from "./types";
import { 
  validateEmail,
  validateHasLowerCase,
  validateHasNumericCharacter,
  validateHasSpecialCharacter,
  validateHasUpperCase,
  validateLengthBetween,
  validateMatch,
  validateMaxConsecutiveCharacters,
  validateMinLength,
  validateNoTrailingSpaces,
  validateRequired,
  validateUrl,
  validateValueBetween,
  validateX509Certificate,
} from "./validators";

export const emailValidation: Validation = {
  type: 'email'
}

export const urlValidation: Validation = {
  type: 'url'
}

export const x509CertifcateValidation: Validation = {
  type: 'x509Certifcate'
}

export const hasLowerCaseValidation: Validation = {
  type: 'hasLowerCase'
}

export const hasNumericCharacterValidation: Validation = {
  type: 'hasNumericCharacter'
}

export const hasSpecialCharacterValidation: Validation = {
  type: 'hasSpecialCharacter'
}

export const hasUpperCaseValidation: Validation = {
  type: 'hasUpperCase'
}

export const lengthBetweenValidation = (minLength: number, maxLength: number): Validation => ({
  type: 'lengthBetween',
  params: {
    minLength,
    maxLength,
  }
});

export const matchValidation = (value: string): Validation => ({
  type: 'match',
  params: {
    value,
  }
});

export const maxConsecutiveCharactersValidation = (maxConsecutiveCharacters: number): Validation => ({
  type: 'maxConsecutiveCharacters',
  params: {
    maxConsecutiveCharacters,
  }
});

export const minLengthValidation = (minLength: number): Validation => ({
  type: 'minLength',
  params: {
    minLength
  }
})

export const noTrailingSpacesValidation: Validation = {
  type: 'noTrailingSpaces'
}

export const requiredValidation: Validation = {
  type: 'required'
}

export const valueBetweenValidation = (minValue: number, maxValue: number): Validation => ({
  type: 'valueBetween',
  params: {
    minValue,
    maxValue,
  }
});

const validators: {[key in ValidationType]: Validator} = {
  'email': validateEmail,
  'url': validateUrl,
  'x509Certifcate': validateX509Certificate,
  'hasLowerCase': validateHasLowerCase,
  'hasNumericCharacter': validateHasNumericCharacter,
  'hasSpecialCharacter': validateHasSpecialCharacter,
  'hasUpperCase': validateHasUpperCase,
  'lengthBetween': validateLengthBetween,
  'match': validateMatch,
  'maxConsecutiveCharacters': validateMaxConsecutiveCharacters,
  'minLength': validateMinLength,
  'noTrailingSpaces': validateNoTrailingSpaces,
  'required': validateRequired,
  'valueBetween': validateValueBetween,
}

function validateRule(value: any, validation: Validation): string | null {
  const validator = validators[validation.type]
  return validator(value, validation)
}

export function validate(value: any, validation: Validation[]): string[] {
  const errors: string[] = [];

  forEach(validation, (v) => {
    const error = validateRule(value, v)
    if (error) {
      errors.push(error)
    }
  })

  return errors
}
