import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useHistory, Redirect } from 'react-router-dom';
import { useApiRequest } from '../../../utils/api/hooks';
import { useResetPasswordApi } from '../../../utils/api/options/auth';
import { useNonLayoutTitle } from '../../../layouts/NonAuth/NonLayoutTitleContext';
import { IApiError409, IApiError422 } from '../../../utils/api/types';
import ResetPasswordForm from '../../../forms/ResetPasswordForm/ResetPasswordForm';
import { getResetPasswordFormDataInitialValues } from '../../../forms/ResetPasswordForm/utils';
import { ResetPasswordFormData } from '../../../forms/ResetPasswordForm/types';
import { NonAuthLayout } from '../../../layouts/NonAuth';
import { passwordErrorHandler } from '../../../utils/form/error-handlers/password';

export const ResetPasswordPage: FunctionComponent = () => {
  const [success, setSuccess] = useState(false);
  const [resetPasswordErrors, setResetPasswordErrors] = useState<string[]>([]);

  const [{ token, userIdentityId }] = useQueryParams({
    token: StringParam,
    userIdentityId: StringParam,
  });
  const history = useHistory();

  useEffect(() => {
    if (!token || !userIdentityId) {
      history.push('/');
    }
  }, [history, token, userIdentityId]);

  const { setTitle } = useNonLayoutTitle();
  useEffect(() => {
    setTitle('Reset Password');
  }, [setTitle]);

  const resetPasswordApi = useResetPasswordApi(userIdentityId ?? '');
  const { fetch: resetPassword } = useApiRequest<{ password: string; id: string; passwordResetToken: string; }>(resetPasswordApi);

  const handleResetPasswordClick = useCallback(async (data: ResetPasswordFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [result, error] = await resetPassword({
      password: data.password,
      id: userIdentityId ?? '',
      passwordResetToken: token ?? '',
    });

    if (error) {
      const errors: string[] = []
      error.forEach((e) => {
        if (passwordErrorHandler.match(e as IApiError409 | IApiError422)) {
          errors.push(passwordErrorHandler.describe(e as IApiError409 | IApiError422) ?? '')
        }
      })
      setResetPasswordErrors(errors)
    } else {
      setSuccess(true);
    }
  }, [resetPassword, token, userIdentityId]);

  return success ? (
    <Redirect to="/admin/login" />
  ) : (
    <NonAuthLayout title="Reset password" errors={resetPasswordErrors}>
      <ResetPasswordForm initialValues={getResetPasswordFormDataInitialValues()} onSubmit={handleResetPasswordClick} />
    </NonAuthLayout>
  );
};
