import { IUser, UserStatus, UserType } from "../types/resources";

export function isArchived(user: IUser): boolean {
  return user.archived;
}

export function type(user: IUser): UserType {
  return user.userIsAdministrator ? 'Admin' : 'User';
}

export function status(user: IUser): UserStatus {
  return isArchived(user) ? 'Archived' : 'Active';
}

export function canBeInvited(user: IUser): boolean {
  return !isArchived(user) && !hasActiveAssessment(user);
}

export function hasActiveAssessment(user: IUser): boolean {
  return !!user.activeSurveyResponse;
}

export function hasValidEmail(user: IUser): boolean {
  return user.emailDeliveryStatus !== 'hard_bouncing';
}
