import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { formatDateField } from "../../utils/date";
import { getSurveyResponseStatus } from "../../utils/surveyResponse";
import { AssessmentStatusProps } from "./types";
import { getAssessmentStatusColor } from "./utils";

const StyledNoAssessment = styled.div`
  color: ${({ theme }) => theme.TABLE.COLOR_LIGHT};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
`;

const StyledAssessmentStatus = styled.div<{ center?: boolean; color?: string}>`
  color: ${({ color, theme }) => color ? color : theme.TABLE.COLOR_DARK};
  text-align: ${({ center }) => center ? 'center' : 'left'};;
  font-weight: 600;
  line-height: 14px;
`;

const StyledAssessmentDate = styled.div<{ center?: boolean}>`
  color: ${({ theme }) => theme.TABLE.COLOR_LIGHT};
  text-align: ${({ center }) => center ? 'center' : 'left'};;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
`;

const AssessmentStatus: FunctionComponent<AssessmentStatusProps> = ({ date, status }): ReactElement => {
  return (
    <StyledAssessmentStatus>
    { (!status && !date) && (
      <StyledNoAssessment>—</StyledNoAssessment>
    )}
    { status && <StyledAssessmentStatus color={getAssessmentStatusColor(status)}>{getSurveyResponseStatus(status)}</StyledAssessmentStatus> }
    { date && <StyledAssessmentDate>{formatDateField(date)}</StyledAssessmentDate> }
  </StyledAssessmentStatus>
  );
};

export default AssessmentStatus;
