import React, { createContext, useContext, ReactElement, FunctionComponent, useMemo } from 'react';
import { IFilterValue, IApiRequestOptions } from "../../../utils/api/types";

export type IFilterType = 'multiSelect' | 'select' | 'text';

export interface IFilterConfig {
  key: string;
  label: string;
  type: IFilterType;
  nonClearable?: boolean;
  options?: { value: IFilterValue; text: string }[];
}

export interface IAutoFilterConfig {
  label: string;
  values: { value: IFilterValue | IFilterValue[]; key: string }[];
  apiOptions?: IApiRequestOptions;
}

export interface ITableFilterConfigProps {
  filterConfig?: IFilterConfig[];
  filterAutoConfig?: IAutoFilterConfig[];
}

export const TableFilterConfigContext = createContext<ITableFilterConfigProps>({});

export const TableFilterConfigProvider: FunctionComponent<ITableFilterConfigProps> = ({ filterConfig, filterAutoConfig, children }): ReactElement => {
  const value = useMemo(() => ({ filterConfig, filterAutoConfig }), [filterAutoConfig, filterConfig]);

  return (
    <TableFilterConfigContext.Provider value={value}>
      {children}
    </TableFilterConfigContext.Provider>
  );
};

export const useTableFilterConfig = () => useContext(TableFilterConfigContext);
