import React, { FunctionComponent, ReactElement } from "react";
import { useHistory } from 'react-router-dom';
import Button from "../../../common/Buttons/Button";
import { UserCardsProps } from "./types";
import { ROUTES } from "../../../routing/AdminRouter";
import { canBeInvited } from "../../../utils/user";
import ActionCard from "../../../common/ActionCard/ActionCard";
import { CardsRow } from "../../../common/Card/Card";

const userAcionsInfo = 'You can carry out bulk functions for employees that you have selected.';

const UserCards: FunctionComponent<UserCardsProps> = ({ selectedUsers, onOpenExportModal, onOpenInviteModal, onOpenNewUserModal }): ReactElement => {
  const history = useHistory();

  // Disable invite button if no user selected or selected only users with active assessments
  const canInvite = selectedUsers.filter(canBeInvited).length > 0;

  return (
    <CardsRow>
      <ActionCard
        cardWidth="265px"
        heading="Bulk actions"
        info={userAcionsInfo}
      >
        <Button label="Invite" theme="SUCCESS_PRIMARY" icon="userAdd" disabled={!canInvite} onClick={onOpenInviteModal} />
        <Button label="Export" theme="SUCCESS_SECONDARY" icon="download" onClick={onOpenExportModal} />
      </ActionCard>
      <ActionCard
        cardWidth="404px"
        heading="Add/update actions"
        info={userAcionsInfo}
      >
        <Button label="Add new" theme="SUCCESS_PRIMARY" icon="userAdd" onClick={onOpenNewUserModal} />
        <Button label="Upload user list" theme="SUCCESS_SECONDARY" icon="upload" onClick={() => history.push(ROUTES.usersUpload)} />
      </ActionCard>
    </CardsRow>
  );
};

export default UserCards;
