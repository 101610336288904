import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTablePagination } from '../Context/TablePaginationContext';
import { useTableData } from '../Context/TableDataContext';

const TextWrapper = styled.div`
  padding-top: 10px;
  font-size: 16px;
`;

export const TableItemCount: FunctionComponent = () => {
  const { pagination } = useTablePagination();
  const data = useTableData();

  return (
    <TextWrapper>
      {pagination?.current.totalItemCount ?? data?.length} items found
    </TextWrapper>
  )
}