import React, { useContext, useState, FunctionComponent, ReactElement, useCallback } from "react";
import { ThemeContext } from "styled-components";
import { useHistory } from 'react-router-dom';
import { useSession } from '../../../../pages/auth/AuthContext/SessionContext';
import { ROUTES as AdminRoutes } from "../../../../routing/AdminRouter";
import { useOpenListAccountsModal } from '../../../../pages/auth/AuthenticationPage/ListAccountsModal/ListAccountModalContext';
import { Navbar, NavbarLink, SearchInput, UserMenu, UserMenuLink } from "../../../shared/Navbar";
import { AdminMenuName } from "./types";
import { loginToAccountUri } from "../../../../pages/auth/LoginPage";

const AdminNavbar: FunctionComponent = (): ReactElement => {
  const { NAVBAR } = useContext(ThemeContext);
  const { currentUser, state: sessionState } = useSession();
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState<AdminMenuName>(null);
  const openListAccountsModal = useOpenListAccountsModal();
  const searchFilters = (searchTerm) => ([{ key: 'userNameLike', value: searchTerm }]);
  const logout = () => history.push(AdminRoutes.logout);
  const switchToBackend = useCallback(() => {
    if (sessionState.type === 'loggedIn'){
      history.push(loginToAccountUri({ applicationKey: 'backend', userId: sessionState.user.userId, accountId: sessionState.user.accountId }));
    }
  }, [sessionState, history])

  return (
    <Navbar openMenu={openMenu} onClose={() => setOpenMenu(null)}>
      <NavbarLink to={AdminRoutes.dashboard}>Dashboard</NavbarLink>
      <NavbarLink to={AdminRoutes.usersList}>Users</NavbarLink>
      <NavbarLink to={AdminRoutes.assessmentsList}>Assessments</NavbarLink>
      <NavbarLink to={AdminRoutes.issuessList}>Issues</NavbarLink>
      <NavbarLink to={AdminRoutes.accountSettings}>Settings</NavbarLink>
      <SearchInput
        isOpen={openMenu === 'Search'}
        onClick={setOpenMenu}
        baseUrl={AdminRoutes.usersList}
        searchFilters={searchFilters}
      />
      <UserMenu isOpen={openMenu === 'User'} onClick={menuName => setOpenMenu(menuName)}>
        { currentUser?.hasMultipleAccounts && (
          <UserMenuLink onClick={openListAccountsModal} iconName="switch" iconColor={NAVBAR.SWITCH}>Switch account</UserMenuLink>
        )}
        { currentUser?.canAccessBackend && (
          <UserMenuLink onClick={switchToBackend} iconName="switch" iconColor={NAVBAR.SWITCH}>Switch to Backend</UserMenuLink>
        )}
        <UserMenuLink onClick={logout} iconName="cancel" iconColor={NAVBAR.LOGOUT}>Logout</UserMenuLink>
      </UserMenu>
    </Navbar>
  );
};

export default AdminNavbar;
