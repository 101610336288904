import React, { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';

import { TableLoadingRow } from './TableLoadingRow';
import { TableEmptyRow } from './TableEmptyRow';
import { TableRow } from './TableRow';
import { useTableData } from '../Context/TableDataContext';

export interface ITableBodyProps<TData extends { id: string }> {
  emptyMessage?: string;
}

export const TableBody = <TData extends { id: string }>(
  { emptyMessage }: ITableBodyProps<TData>
): ReactElement<ITableBodyProps<TData>> => {
  const data = useTableData();

  return (
    <Table.Body>
      {!data ? (
        <TableLoadingRow />
      ) : (data.length ?
        data.map((value, index) => <TableRow<TData> key={`table-row-${index}`} value={value} />) :
        <TableEmptyRow emptyMessage={emptyMessage} />
      )}
    </Table.Body>
  );
};