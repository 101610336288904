import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { MultilineTextCellProps } from "../types";
import Tooltip from "../../Tooltip/Tooltip";
import Td from "./Td";

const StyledMultilineTextCell = styled.div<{ lines?: number }>`
  position: relative;
  color: ${({ theme }) => theme.TABLE.COLOR};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lines }) => lines ?? 2};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MultilineTextCell: FunctionComponent<MultilineTextCellProps> = ({ id, lines, text }) => {
  return (
    <Td>
      <Tooltip text={text ?? ''}>
        <StyledMultilineTextCell lines={lines}>
          {text}
        </StyledMultilineTextCell>
      </Tooltip>
    </Td>
  );
};

export default MultilineTextCell;
