import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import { type } from "../../../../utils/user";
import { useSession } from "../../../auth/AuthContext/SessionContext";
import { AdvancedEditProfileProps } from "./types";

const StyledAdvancedEditProfile = styled.div`
  display: flex;
  align-items: center;
  height: 62px;
  padding-left: 54px;
`;

const StyledColumn = styled.div`
  display: flex;
  width: 50%;

  &:last-child {
    padding-left: 9px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  ${StyledButton} {
    margin-right: 16px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

const AdvancedEditProfile: FunctionComponent<AdvancedEditProfileProps> = ({ onArchive, onToggleAdmin, user }): ReactElement => {
  const adminLabel = type(user) === 'Admin' ? 'Revoke admin access' : 'Give admin access';
  const { currentUser } = useSession();
  const editingThemself = user.id === currentUser?.userId.toString();

  return (
    <StyledAdvancedEditProfile>
      <StyledColumn>
        <span>You can give this user admin rights or archive them.</span>
      </StyledColumn>
      <StyledColumn>
        <StyledButtons>
          <Button disabled={editingThemself} label={adminLabel} theme="DANGER_SECONDARY" onClick={onToggleAdmin} />
          <Button disabled={editingThemself} label="Archive this user" theme="DANGER_PRIMARY" onClick={onArchive} />
        </StyledButtons>
      </StyledColumn>
    </StyledAdvancedEditProfile>
  );
};

export default AdvancedEditProfile;
