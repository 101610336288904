import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import logo from '../../assets/images/ergofy-master-logo-pink.png';
import { NavbarProps } from "./types";

const StyledNavbarWrapper = styled.div`
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  background: ${({ theme }) => theme.NAVBAR.BG};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNavbar = styled.div`
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  flex: 1;
  max-width: 1200px;
  padding-left: 18px;

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    padding-left: 0;
  }
`;

const StyledLogo = styled.img`
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  display: none;

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    display: block;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const Navbar: FunctionComponent<NavbarProps> = ({ children, openMenu, onClose }): ReactElement => {
  return (
    <StyledNavbarWrapper>
      { openMenu && <Overlay onClick={onClose} />}
      <StyledNavbar>
        <StyledLogo src={logo} alt="Ergofy" />
        { children }
      </StyledNavbar>
    </StyledNavbarWrapper>
  );
};
