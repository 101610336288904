import { useApiRequestOptions } from '../../hooks';

export const useFetchAccountListApi = () => useApiRequestOptions({
  uri: 'accounts',
  query: {
    includes: [
      'surveyTemplates',
    ],
  },
  defaultFilter: {},
}, []);

export const usePatchAccountByIdApi = (id: string) => useApiRequestOptions({
  uri: `accounts/${id}`,
  method: 'patch',
  serialization: {
    type: 'accounts',
    options: {
      attributes: ['id', 'name', 'accountType', 'isManaged', 'enabled', 'enabledCapabilities', 'accountCode', 'surveyTemplates'],
      surveyTemplates: {
        ref: 'id',
        included: true,
      }
    },
  },
}, [id]);

export const useCreateNewAccountApi = () => useApiRequestOptions({
  uri: `accounts`,
  method: 'post',
  serialization: {
    type: 'accounts',
    options: {
      attributes: ['name', 'accountType', 'isManaged', 'enabled', 'enabledCapabilities', 'accountCode', 'surveyTemplates'],
      surveyTemplates: {
        ref: 'id',
        included: true,
      }
    },
  },
}, []);

export const useGetAccountAssessmentReportApi = (accountIds: string[]) => useApiRequestOptions({
  uri: `assessments_reports?${accountIds.map(id => `account_ids[]=${id}`).join('&')}`,
  method: 'get',
  serialization: {
    type: 'assessmentReport',
    options: {
      attributes: ['selfAssessments', 'followUpAssessments', 'topIssueTypes']
    },
  },
}, [accountIds]);

export const useFetchUserListApi = () => useApiRequestOptions({
  uri: 'users',
  defaultFilter: {},
  defaultPagination: {
    'itemsPerPage': 100
  },
  query: {
    includes: [
      'account',
    ],
  },
}, []);

export const useUnarchiveUserApi = (id) => useApiRequestOptions({
  uri: `users/${id}/unarchive`,
  method: 'patch',
}, [id]);
