import { IUserDepartmentListItem, IUserLocationListItem } from '../../../../types/resources';
import { uniqBy } from 'lodash';

export const getListedStringOptions = (options: IUserDepartmentListItem[] | IUserLocationListItem[]) => 
  uniqBy(
    options.map((option) => ({
      value: option.id ?? '',
      text: option.name || '-- BLANK --',
    })).sort((option1, option2) => option1.text.toLowerCase() === option2.text.toLowerCase() ? 0 : (
      option1.text.toLowerCase() < option2.text.toLowerCase() ? -1 : 1
    )),
    'value'
  );
