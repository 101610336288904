import React, { FunctionComponent, useCallback } from 'react';
import { Dropdown, Button, Icon } from 'semantic-ui-react';

import { IUserTableWrappingCellProps } from './types';
import { useOpenUserFormModal } from '../../../modals/UserFormModal/UserFormModalContext';
import { useOnUserUpdate } from '../contexts/UserUpdateContext';
import { useUserUploadData } from '../contexts/UserUploadDataContext';

export const UserActionsMenu: FunctionComponent<IUserTableWrappingCellProps> = ({ value }) => {
  const [users, setUsers] = useUserUploadData();
  const openUserFormModal = useOpenUserFormModal();
  const [onUserUpdated, onUserSubmit] = useOnUserUpdate();

  const handleEdit = useCallback(() => {
    openUserFormModal(value, onUserUpdated, onUserSubmit);
  }, [onUserSubmit, onUserUpdated, openUserFormModal, value]);
  const handleDelete = useCallback(() => {
    setUsers(users.filter((user) => user.id !== value.id));
  }, [setUsers, users, value.id]);

  return (
    <Dropdown
      selectOnBlur={false}
      icon={false}
      pointing="right"
      trigger={<Button icon circular basic><Icon name="ellipsis vertical" /></Button>}
    >
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
