import { getManualAssessmentStatus } from '../../../surveyResponse';
import { MANUAL_ASSESSMENT_STATUS_TYPE } from '../../../../types/enums';

export const getManualAssessmentStatusOptions = () => [
  { value: '', text: 'No Follow-up Scheduled' },
  { value: MANUAL_ASSESSMENT_STATUS_TYPE.invited, text: getManualAssessmentStatus(MANUAL_ASSESSMENT_STATUS_TYPE.invited) },
  { value: MANUAL_ASSESSMENT_STATUS_TYPE.scheduled, text: getManualAssessmentStatus(MANUAL_ASSESSMENT_STATUS_TYPE.scheduled) },
  { value: MANUAL_ASSESSMENT_STATUS_TYPE.completed, text: getManualAssessmentStatus(MANUAL_ASSESSMENT_STATUS_TYPE.completed) },
  { value: MANUAL_ASSESSMENT_STATUS_TYPE.cancelled, text: getManualAssessmentStatus(MANUAL_ASSESSMENT_STATUS_TYPE.cancelled) },
  { value: MANUAL_ASSESSMENT_STATUS_TYPE.declined, text: getManualAssessmentStatus(MANUAL_ASSESSMENT_STATUS_TYPE.declined) },
];
