import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Box, { StyledBox } from '../../common/Box/Box';
import { AuthBg } from './AuthBg';
import { Logo } from './Logo';

const StyledNonAuthLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    flex-direction: row;
  }
`;

const StyledLeftColumn = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.COLORS.BLACK};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    width: 50%;
  }
`;

const StyledRightColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding: 20px 20px;
  flex: 1;

  ${StyledBox} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    padding: 0 20px;
    width: 50%;
  }

  @media ${({ theme }) => theme.RWD.LAPTOP_S} {
    ${StyledBox} {
      width: 480px;
    }
  }
`;

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
  width: 100%;

  svg {
    max-width: 220px;
  }

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    margin: 0 auto 50px;
  }
`;

const StyledBg = styled.div`
  display: none;

  @media ${({ theme }) => theme.RWD.TABLET_L} {
    display: block;
    max-width: 700px;
    width: 100%;
  }
`;


const StyledTitle = styled.div<{ error?: boolean }>`
  color: ${({ theme }) => theme.MODAL.HEADER};
  font-family: ${({ theme }) => theme.FONT};
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 30px;
  padding: 32px 18px 8px;
  position: relative;
  text-align: center;
  width: 100%;
  flex-shrink: 0;

  &:after {
    display: block;
    content: '';
    width: 100px;
    height: 3px;
    background-color: ${({ error, theme }) => error
      ? theme.MODAL.HEADER_ERRORED_SEPARATOR
      : theme.MODAL.HEADER_SEPARATOR
    };
    border-radius: 1.5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledError = styled.div`
  color: ${({ theme }) => theme.MODAL.HEADER_ERRORED_SEPARATOR};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
`;

export const NonAuthLayout: FunctionComponent<{ errors?: string[], title: string }> = ({ children, errors, title }) => {
  const hasError = errors && errors?.length > 0
  return (
    <StyledNonAuthLayout>
      <StyledLeftColumn>
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledBg>
          <AuthBg />
        </StyledBg>
      </StyledLeftColumn>
      <StyledRightColumn>
        <Box>
          <StyledTitle error={hasError}>{ title }</StyledTitle>
          { errors && hasError && errors.map((error, index) => <StyledError key={index}>{error}</StyledError>)}
          { children }
        </Box>
      </StyledRightColumn>
    </StyledNonAuthLayout>
  );
}
