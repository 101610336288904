import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";

const StyledError = styled.div`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.FORMS.ERROR_COLOR};
  margin: 0 0 2px 13px;
`;

const Error: FunctionComponent<{ msg: string }> = ({ msg }): ReactElement => {
  return <StyledError>{msg}</StyledError>;
};

export default Error;
