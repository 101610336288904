import { IconName } from '../common/types';
import {
  IIssueRiskId,
  IIssueStatus,
  IQuestionResponseType,
  IAutomatedInterventionType,
  ISurveyResponseStatusType,
  IManualAssessmentStatusType,
  ISurveyQuestionType,
  ISurveyQuestionMetaAnswerInputType,
  ISurveyQuestionGroupGroupingType,
  IAutomatedInterventionResolutionType,
  IDataUploadStatus,
  IAssessmentType,
} from './resources';

export const ISSUE_RISK_ID: { [key in IIssueRiskId]: IIssueRiskId } = {
  high: 'high',
  medium: 'medium',
  low: 'low',
  none: 'none',
};

export const ISSUE_STATUS: { [key in IIssueStatus]: IIssueStatus } = {
  open: 'open',
  escalated: 'escalated',
  escalated_by_user: 'escalated_by_user',
  resolved: 'resolved',
  auto_closed: 'auto_closed',
  closed_on_user_archival: 'closed_on_user_archival',
};

export const QUESTION_RESPONSE_TYPE: { [key in IQuestionResponseType]: IQuestionResponseType } = {
  answered: 'answered',
};

export const AUTOMATED_INTERVENTION_TYPE: { [key in IAutomatedInterventionType]: IAutomatedInterventionType } = {
  open: 'open',
};

export const SURVEY_RESPONSE_STATUS_TYPE: { [key in ISurveyResponseStatusType]: ISurveyResponseStatusType } = {
  not_started: 'not_started',
  in_progress: 'in_progress',
  cancelled: 'cancelled',
  cancelled_by_user_archival: 'cancelled_by_user_archival',
  finished: 'finished',
};

export const MANUAL_ASSESSMENT_STATUS_TYPE: { [key in IManualAssessmentStatusType]: IManualAssessmentStatusType } = {
  invited: 'invited',
  scheduled: 'scheduled',
  declined: 'declined',
  completed: 'completed',
  cancelled: 'cancelled',
};

export const SURVEY_QUESTION_TYPE: { [key in ISurveyQuestionType]: ISurveyQuestionType } = {
  user_input: 'user_input',
  asset_placeholder: 'asset_placeholder',
};

export const SURVEY_QUESTION_META_ANSWER_INPUT_TYPE: { [key in ISurveyQuestionMetaAnswerInputType]: ISurveyQuestionMetaAnswerInputType } = {
  string: 'string',
};

export const SURVEY_QUESTION_GROUP_GROUPING_TYPE: { [key in ISurveyQuestionGroupGroupingType]: ISurveyQuestionGroupGroupingType } = {
  display: 'display',
};

export const AUTOMATED_INTERVENTION_RESOLUTION_TYPE: { [key in IAutomatedInterventionResolutionType]: IAutomatedInterventionResolutionType } = {
  resolve_only: 'resolve_only',
  resolve_escalate_defer: 'resolve_escalate_defer',
  view_only: 'view_only'
};

export const DATA_UPLOAD_STATUS_TYPE: { [key in IDataUploadStatus]: IDataUploadStatus } = {
  created: 'created',
  finished: 'finished',
  queued: 'queued',
  running: 'running',
};

export const ASSESSMENT_ICON_MAP: { [key in IAssessmentType]: IconName } = {
  homeworking_dse: 'homeworkingDSE',
  office_dse: 'officeDSE',
};

export const ASSESSMENT_NAMES: { [key in IAssessmentType]: string } = {
  homeworking_dse: 'Homeworking DSE',
  office_dse: 'Office DSE',
}
