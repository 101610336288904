import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';

export interface IListAccountPaginationProps {
  handlePrevPage: () => void;
  handleNextPage: () => void;
  canPrevPage: () => boolean;
  canNextPage: () => boolean;
}

export const ListAccountPagination: FunctionComponent<IListAccountPaginationProps> = ({
  handlePrevPage,
  handleNextPage,
  canPrevPage,
  canNextPage,
}) => {
  return (
    <>
    <Button
      floated="right"
      onClick={handleNextPage}
      disabled={!canNextPage()}
      icon
      circular
    >
      <Icon color="green" name="angle right" />
    </Button>
    <Button
      floated="right"
      onClick={handlePrevPage}
      disabled={!canPrevPage()}
      icon
      circular
    >
      <Icon color="green" name="angle left" />
    </Button>
    </>
  );
};
