import React, { FunctionComponent } from 'react';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

export interface ITableLoadingRowProps { }

export const TableLoadingRow: FunctionComponent<ITableLoadingRowProps> = () => (
  <Table.Row>
    <Table.Cell>
      <Dimmer inverted active>
        <Loader active />
      </Dimmer>
    </Table.Cell>
  </Table.Row>
)