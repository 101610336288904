import { Deserializer, Serializer } from 'jsonapi-serializer';
import { AxiosResponse } from 'axios';

import { IApiRequestOptions, IApiResponseMeta, IPagination, ISortByItem } from './types';

export const stringifySortQuery = (sort: ISortByItem[]): string =>
  sort.map((sortByItem) => `${sortByItem.direction === 'desc' ? '-' : ''}${sortByItem.key}`).join();

export const parseSortQuery = (sortQuery: string): ISortByItem[] =>
  sortQuery
    .split(',')
    .map((sorted) => ({
      key: sorted.replace(/^-/, ''),
      direction: sorted.startsWith('-') ? 'desc' : 'asc',
    }));

export const createQueryObject = (requestInfo: IApiRequestOptions, pagination?: IPagination, filters?: any, sort?: ISortByItem[]) => {
  if (requestInfo.method && requestInfo.method !== 'get') {
    return {};
  }

  const query: any = {};

  if (requestInfo.query?.includes?.length) {
    query.includes = requestInfo.query?.includes?.join();
  }
  if (sort && sort.length) {
    query.sort = stringifySortQuery(sort);
  }
  if (pagination) {
    query['page[number]'] = pagination.currentPage;
    query['page[size]'] = pagination.itemsPerPage;
  }
  if (Object.keys(filters).length) {
    Object.keys(filters || {}).forEach((key) => {
      query[`filter[${key}]`] = filters[key];
    });
  }

  return query;
};

export const serializePayload = <TPayload = any>(requestInfo: IApiRequestOptions, payload?: TPayload | null) =>
  payload && requestInfo.serialization ?
    new Serializer(requestInfo.serialization.type, { keyForAttribute: 'camelCase', ...requestInfo.serialization.options })
      .serialize(payload) :
    payload;

export const deserializeResponse = async <TResponse = any>(response: AxiosResponse<TResponse>): Promise<TResponse | null> => {
  const deserializer = new Deserializer({ keyForAttribute: 'camelCase' });
  return response.data && (response.data as any).data ?
    await deserializer.deserialize(response.data) :
    null;
}


export const extractResponseMeta = (response: AxiosResponse<any>): IApiResponseMeta | null =>
  response.data?.meta ?? null;

export const getDefaultSuccessMessageByMethod = (method: IApiRequestOptions['method']): string => {
  if (method === 'post') {
    return 'Created';
  } else if (method === 'patch' || method === 'put') {
    return 'Changes Saved';
  } else {
    return 'Deleted';
  }
};
