import React, { FunctionComponent, ReactElement } from "react";
import { Table, Thead, THr, Tr, TBody } from "../../../../common/DataTable/DataTable";
import { ActionsCell, TextHeaderCell, TextCell, AssessmentCell, RiskCell, DateCell, AssessmentStatusCell, TrNoData } from "../../../../common/DataTable/Cells";
import MiniActionButton from "../../../../common/Buttons/MiniActionButton";
import { AssessmentHistoryTableProps } from "./types";
import { getManualAssessmentStatus } from "../../../../utils/surveyResponse";

const AssessmentHistoryTable: FunctionComponent<AssessmentHistoryTableProps> = ({ assessments, currentSort, onShowAssessment, onSort }): ReactElement => {
  return (
    <Table>
      <Thead>
        <THr>
          <TextHeaderCell currentSort={currentSort} sortableKey="currentRiskFactor" onSort={onSort} align="center">Current risk</TextHeaderCell>
          <TextHeaderCell currentSort={currentSort} sortableKey='surveyTemplateName' onSort={onSort} align="center">Assessment type</TextHeaderCell>
          <TextHeaderCell currentSort={currentSort} sortableKey='status' onSort={onSort}>Assessment status</TextHeaderCell>
          <TextHeaderCell currentSort={currentSort} sortableKey='createdAt' onSort={onSort}>Invited on</TextHeaderCell>
          <TextHeaderCell currentSort={currentSort} sortableKey='manualAssessmentStatus' onSort={onSort}>Follow-up status</TextHeaderCell>
          <TextHeaderCell align="center">Action</TextHeaderCell>
        </THr>
      </Thead>
      <TBody>
        { assessments.length === 0 && <TrNoData colSpan={6} /> }
        { assessments.map(assessment => {
          const followUpStatus = getManualAssessmentStatus(assessment.manualAssessment?.status)

          return (
            <Tr key={assessment.id}>
              <RiskCell value={assessment.meta.riskCategory.data.id} />
              <AssessmentCell name={assessment.surveyTemplate.name} />
              <AssessmentStatusCell status={assessment.status} date={assessment.finishedAt} />
              <DateCell date={assessment.createdAt} />
              <TextCell text={followUpStatus} />
              <ActionsCell>
                <MiniActionButton id={assessment.id} name="eye" tooltip="Show" onClick={() => onShowAssessment(assessment.id)} />
              </ActionsCell>
            </Tr>
          );
        }) }
      </TBody>
    </Table>
  );
};

export default AssessmentHistoryTable;
