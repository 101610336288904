import { Option } from "../common/Forms/types";
import { ISSUE_RISK_ID } from "../types/enums";
import { IIssueRiskId } from "../types/resources";
import { getRiskText } from "./questionResponseIssue/riskCategory";

export const riskLabels: {[key in IIssueRiskId]: string} = {
  high: 'High risk',
  medium: 'Medium risk',
  low: 'Low risk',
  none: 'No risk',
}

export const getRiskOptions = (): Option<IIssueRiskId>[] => [
  { value: ISSUE_RISK_ID.high, label: getRiskText(ISSUE_RISK_ID.high) },
  { value: ISSUE_RISK_ID.medium, label: getRiskText(ISSUE_RISK_ID.medium) },
  { value: ISSUE_RISK_ID.low, label: getRiskText(ISSUE_RISK_ID.low) },
  { value: ISSUE_RISK_ID.none, label: getRiskText(ISSUE_RISK_ID.none) },
];
