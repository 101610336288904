import get from 'lodash/get';

export const exportKeyWrapper = <TData extends { id: string }, TValue = string>(
  key: string,
  noValueLabel?: string,
  format?: (value: TValue, noValue?: string) => string
) =>  (value: TData): string => {
  let result = get(value, key);

  if (typeof result === 'string') {
    result = result.replace(/^[^a-z\d()]*/gi, '');
  }

  const formattedResult = format && typeof result !== 'undefined' ? format(result) : result;

  return formattedResult ?? noValueLabel ?? '';
};
