import { isArray } from "lodash";
import { UseSelect } from "../../../common/Forms/types";
import { IIssue, IQuestionResponse, ISurveyQuestionGroup, ISurveyResponse, IUser } from "../../../types/resources"

export interface IQuestionCategory extends Pick<ISurveyQuestionGroup, 'id' | 'name'> {
  answers: IQuestionResponse[];
}

export type AssessmentTabKey = AssessmentFilteredIssuesTabKey | AssessmentQATabKey | AssessmentHistoryTabKey;

export type AssessmentFilteredIssuesTabKey = 'admin-resolvable' | 'user-resolvable' | 'resolved'
export type AssessmentQATabKey = 'qa'
export type AssessmentHistoryTabKey = 'history'

export interface AssessmentTab {
  key: AssessmentTabKey;
  label: string;
}

export interface AssessmentFilteredIssuesTab extends AssessmentTab {
  key: AssessmentFilteredIssuesTabKey;
}

export interface AssessmentQATab extends AssessmentTab {
  key: AssessmentQATabKey;
}

export interface AssessmentHistoryTab extends AssessmentTab {
  key: AssessmentHistoryTabKey;
}

export type AssessmentTabsProps = {
  assessmentQuestionAnswers: IQuestionResponse[] | null;
  issues: IIssue[];
  onOpenUpdatesHistoryModal: (data: IIssue) => void;
  onOpenUpdateIssuesModal: (data: IIssue[]) => void;
  onTabChange: (tab: AssessmentTab) => void;
  selectIssues: UseSelect<IIssue>;
  tab: AssessmentTab;
  user: IUser;
}

export type AssessmentTableProps = {
  issues: IIssue[];
  selectIssues: UseSelect<IIssue>;
  onOpenUpdatesHistoryModal: (data: IIssue) => void;
  onOpenUpdateIssuesModal: (data: IIssue[]) => void;
  tab: AssessmentFilteredIssuesTab;
  user: IUser;
}

export type QuestionAnswer = {
  id: string;
  question: string;
  answer: string | (string | string[])[];
}

export type AssessmentQATableProps = {
  assessmentQuestionAnswers: IQuestionResponse[] | null;
}

export type AssessmentQACategoryProps = {
  questionCategory: IQuestionCategory;
}

export type AssessmentQuestionAnswersProps = {
  questionAnswer: QuestionAnswer;
}

export type StyledEntryValueProps = {
  tooltip?: string;
}

export type AssessmentInfoCardsProps = {
  assessmentDetails: ISurveyResponse;
  onOpenEditUserModal: (data: IUser) => void;
  onOpenFollowUpModal: (data: ISurveyResponse) => void;
}

export type UserCardProps = {
  assessmentDetails: ISurveyResponse;
  onOpenEditUserModal: (data: IUser) => void;
}
export type FollowUpCardProps = {
  assessmentDetails: ISurveyResponse;
  onOpenFollowUpModal: (data: ISurveyResponse) => void;
}

export type SelfAssessmentCardProps = {
  assessmentDetails: ISurveyResponse;
}

export type AssessmentCardsProps = {
  assessmentDetails: ISurveyResponse;
  issues: IIssue[]
  onOpenCancelAssessmentsModal: (data: ISurveyResponse[]) => void;
  onOpenCloseIssuesModal: (data: IIssue[]) => void;
  onOpenEditUserModal: (data: IUser) => void;
  onOpenExportAssessmentIssuesModal: (data: IIssue[]) => void;
  onOpenFollowUpModal: (data: ISurveyResponse) => void;
  onOpenResendInviteModal: (data: ISurveyResponse) => void;
  onOpenUpdateIssuesModal: (data: IIssue[]) => void;
  onOpenUpdatesHistoryModal: (data: IIssue) => void;
  selectedIssues: IIssue[];
  user: IUser;
}

export type SelfAssessmentActionsCardProps = {
  assessmentDetails: ISurveyResponse;
  onOpenCancelAssessmentsModal: (data: ISurveyResponse[]) => void;
  onOpenResendInviteModal: (data: ISurveyResponse) => void;
}

export type AssessmentActionsCardProps = {
  issues: IIssue[]
  selectedIssues: IIssue[];
  onOpenCloseIssuesModal: (data: IIssue[]) => void;
  onOpenExportAssessmentIssuesModal: (data: IIssue[]) => void;
  onOpenUpdatesHistoryModal: (data: IIssue) => void;
  onOpenUpdateIssuesModal: (data: IIssue[]) => void;
  user: IUser;
}

export const isTabFilteredIssues = (tab: AssessmentTab): tab is AssessmentFilteredIssuesTab => {
  const assessmentTableTabs: AssessmentFilteredIssuesTabKey[] = ['admin-resolvable', 'user-resolvable', 'resolved'];
  return (assessmentTableTabs as AssessmentTabKey[]).includes(tab.key)
}

export const isTabQA = (tab: AssessmentTab): tab is AssessmentQATab => {
  return tab.key === 'qa'
}

export const isAnswerSimple = (answer: string | (string | string[])[]): answer is string => {
  return typeof answer === 'string'
}

export const isAnswerArray = (answer: string | (string | string[])[]): answer is string[] => {
  return isArray(answer) && !isArray(answer[0])
}

export const isAnswerMatrix = (answer: string | (string | string[])[]): answer is string[][] => {
  return isArray(answer) && isArray(answer[0])
}
