import { useMemo } from 'react';
import { IDataUpload, IDataUploadStatus } from '../../../types/resources';
import { ITableConfig } from '../../../common/Table/Context/TableConfigContext';

import { textWrapperCell } from './textWrapperCell';
import { getDataUploadStatus } from '../../dataUploads';

export const useDataUploadsTable = () => useMemo<ITableConfig<IDataUpload>[]>(() => [
  {
    header: 'ID',
    Cell: textWrapperCell('id'),
    cellProps: { textAlign: 'center' },
  },
  {
    header: 'Name',
    Cell: textWrapperCell('name'),
  },
  {
    header: 'Description',
    Cell: textWrapperCell('description'),
  },
  {
    header: 'Status',
    Cell: textWrapperCell<IDataUpload, IDataUploadStatus>('status', '-', getDataUploadStatus),
  },
], []);
