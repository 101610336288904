import React, { FunctionComponent, ReactElement } from "react";
import { CardsRow } from "../../../../common/Card/Card";
import { AssessmentCardsProps } from "../types";
import AssessmentActionsCard from "./AssessmentActionsCard";
import FollowUpCard from "./FollowUpCard";
import SelfAssessmentActionsCard from "./SelfAssessmentActionsCard";
import SelfAssessmentCard from "./SelfAssessmentCard";
import UserCard from "./UserCard";

const AssessmentCards: FunctionComponent<AssessmentCardsProps> = ({ 
  assessmentDetails,
  issues,
  onOpenCancelAssessmentsModal,
  onOpenCloseIssuesModal,
  onOpenEditUserModal,
  onOpenExportAssessmentIssuesModal,
  onOpenFollowUpModal,
  onOpenResendInviteModal,
  onOpenUpdateIssuesModal,
  onOpenUpdatesHistoryModal,
  selectedIssues,
  user 
}): ReactElement => {
  return (
    <>
      <CardsRow>
        <UserCard onOpenEditUserModal={onOpenEditUserModal} assessmentDetails={assessmentDetails} />
        <SelfAssessmentCard assessmentDetails={assessmentDetails} />
        <FollowUpCard assessmentDetails={assessmentDetails} onOpenFollowUpModal={onOpenFollowUpModal} />
      </CardsRow>
      <CardsRow>
        <AssessmentActionsCard 
          issues={issues}
          onOpenCloseIssuesModal={onOpenCloseIssuesModal}
          onOpenExportAssessmentIssuesModal={onOpenExportAssessmentIssuesModal}
          onOpenUpdateIssuesModal={onOpenUpdateIssuesModal}
          onOpenUpdatesHistoryModal={onOpenUpdatesHistoryModal}
          selectedIssues={selectedIssues}
          user={user}
        />
        <SelfAssessmentActionsCard
          assessmentDetails={assessmentDetails}
          onOpenCancelAssessmentsModal={onOpenCancelAssessmentsModal}
          onOpenResendInviteModal={onOpenResendInviteModal}
        />
      </CardsRow>
    </>
  );
};

export default AssessmentCards;
