import React, { ReactElement, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import { FormApi } from 'final-form';

export interface IFormButtonsProps<TData> {
  resetForm: FormApi<TData>['reset'];
  submitting: boolean;
  onCancel?: () => void;
}

export const FormButtons = <TData,>({ resetForm, submitting, onCancel }: IFormButtonsProps<TData>): ReactElement => {
  const handleCancel = useCallback(() => {
    resetForm();
    onCancel?.();
  }, [onCancel, resetForm]);

  return (
    <>
    <Button floated="right" type="submit" color="green" loading={submitting} disabled={submitting}>Submit</Button>
    {onCancel && <Button floated="right" type="button" onClick={handleCancel} disabled={submitting}>Cancel</Button>}
    </>
  );
};
