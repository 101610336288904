import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Icon from "../../../common/Icons/Icon";
import { StyledSvg } from "../../../common/Icons/Icons";
import { UserMenuLinkProps } from "./types";

const StyledUserMenuLink = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  color: ${({ theme }) => theme.NAVBAR.COLOR};

  > ${StyledSvg} {
    margin-right: 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};
  }
`;

export const UserMenuLink: FunctionComponent<UserMenuLinkProps> = ({ children, onClick, iconName, iconColor }): ReactElement => {
  return (
    <StyledUserMenuLink onClick={onClick}>
      <Icon name={iconName} size={24} color={iconColor} />
      {children}
    </StyledUserMenuLink>
  );
};
