import React, { useMemo, useState, FunctionComponent, ReactElement } from "react";
import { debounce } from "lodash";
import InputText from "../Forms/InputText";
import { InputTextFilterProps } from "./types";
import { useEffect } from "react";
import { useUserNameFilterFromUrl } from "./utils";

type NameFilterKey = "userNameLike"

const NameFilter: FunctionComponent<InputTextFilterProps<NameFilterKey>> = ({ filterKey, value, onChange }): ReactElement => {
  const [ userNameLike, setUserNameLike ] = useState(value || '');
  const userNameUrlFilter = useUserNameFilterFromUrl()
  const debuncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleOnChange = (value: string) => {
    setUserNameLike(value);
    debuncedOnChange([{ key: filterKey, value }]);
  };

  useEffect(() => {
    if(value === '') {
      setUserNameLike(value);
    }
  }, [value]);

  useEffect(() => {
    if (userNameUrlFilter) {
      setUserNameLike(userNameUrlFilter);
    }
  }, [userNameUrlFilter])

  return (
    <InputText value={userNameLike} onChange={handleOnChange} placeholder="User name or email" label="User name or email"/>
  );
};

export default NameFilter;
