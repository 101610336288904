import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type IssueCategoryFilterKey = 'issueCategoryId'

const IssueCategoryFilter: FunctionComponent<SelectFilterProps<IssueCategoryFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Category"
      options={options}
      placeholder="All"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default IssueCategoryFilter;
