import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { CardStatsProps } from "../types";

const StyledExtraStats = styled.div`
  color: ${({ theme }) => theme.DASHBOARD.EXTRA_STAT};
  font-size: 11px;
  font-weight: 400;
  margin: 7px 0;
`;

const StyledExtraValue = styled.span`
  color: ${({ theme }) => theme.DASHBOARD.EXTRA_STAT};
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  margin-right: 4px;
`;

const StyledLabel = styled.span`
  color: ${({ theme }) => theme.DASHBOARD.STAT};
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
`;

const StyledValue = styled.span`
  color: ${({ theme }) => theme.DASHBOARD.STAT};
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;

const StyledCardStats = styled.div``;

const CardStats: FunctionComponent<CardStatsProps> = ({ extraLabel, extraValue, label, value }): ReactElement => {
  return (
    <StyledCardStats>
      <div>
        <StyledValue>{value}</StyledValue><StyledLabel>{label}</StyledLabel>
      </div>
      { extraLabel ? (
        <StyledExtraStats>
          <StyledExtraValue>{extraValue ?? 0}</StyledExtraValue>{extraLabel}
        </StyledExtraStats>
      ) : null}
    </StyledCardStats>
  );
};

export default CardStats;
