import React, { FunctionComponent } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export interface IConfirmationModalHeaderProps { }

export const ConfirmationModalHeader: FunctionComponent<IConfirmationModalHeaderProps> = () => (
  <Header as="h1" textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Icon color="orange" name="warning sign" />
    <Header.Content>
      Confirm action
    </Header.Content>
  </Header>
);
