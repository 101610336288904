import { useState } from "react";
import { UseModal } from "./types";

export function useModal<ModalDataType = void, ModalStepType = void>(): UseModal<ModalDataType, ModalStepType> {
  const [isOpen, setIsOpen] = useState(false);
  const [initialStep, setInitialStep] = useState<ModalStepType>();
  const [data, setData] = useState<ModalDataType>();

  function open(modalData?: ModalDataType, initialStep?: ModalStepType) {
    setData(modalData);
    setInitialStep(initialStep);
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
    setData(undefined);
    setInitialStep(undefined);
  }

  return {
    close,
    data,
    initialStep,
    isOpen,
    open,
  }
}
