import { useApiRequestOptions } from '../../hooks';

export const useFetchLoginMethodsApi = () => useApiRequestOptions({
  uri: 'login_methods/config',
}, []);

export const useUpdateLoginMethodsApi = () => useApiRequestOptions({
  uri: 'login_methods/config',
  method: 'patch',
  passBackErrorStatus: [409, 422],
  successMessage: 'Login methods settings saved',
  serialization: {
    type: 'loginMethod',
    options: {
      attributes: [
        'id',
        'adminSamlSso',
        'adminEmailAndPassword',
        'assessmentSamlSso',
        'assessmentEmailedTokens'
      ],
    }
  }
}, []);
