import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 60%;
  text-align: center;
  margin-top: 30px;
`;

const StyledExtraIntro = styled.div`
  border-top: 1px solid ${({ theme }) => theme.MODAL.SEPARATOR};
  font-weight: 600;
  margin-top: 25px;
  width: 60%;
  padding-top: 20px;
  text-align: center;
`;

const StyledList = styled.ul`
  margin-bottom: 0;
  text-align: left;
  width: 60%;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ closeIssues, sendEmail, status, onCancel, onOk }): ReactElement => {
  const showExtraInfo = closeIssues || sendEmail

  return (
    <StyledConfirm>
      <StyledIntro>Are you sure you want to update this assessment's follow-up status to <Accent color="red">{status}</Accent>?</StyledIntro>
      {(showExtraInfo) && (
        <>
          <StyledExtraIntro>You will also:</StyledExtraIntro>
          <StyledList>
            {closeIssues && <li>Close all open issues for this assessment</li>}
            {sendEmail && <li>Send an email to the user</li>}
          </StyledList>
        </>
      )}
      <StyledButtons>
        <Button label="Go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Change" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
