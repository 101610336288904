import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Accent from "../../../../common/Modal/Accent";
import ContactForm from "../../../../forms/ContactForm/ContactForm";
import { ContactFormWrapperProps } from "./types";

const StyledContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 860px;
  padding: 30px 30px 50px;
`;

const StyledIntro = styled.div`
  margin-bottom: 15px;
`;

const ContactFormWrapper: FunctionComponent<ContactFormWrapperProps> = ({ items, initialValues, onCancel, onOk }): ReactElement => {
  const multi = items.length > 1;
  const submitLabel = multi ? `Send to ${items.length} users` : 'Send';

  return (
    <StyledContactFormWrapper>
      {multi && <StyledIntro>Send mail to <Accent color="red">selected users</Accent>.</StyledIntro>}
      <ContactForm
        cancelLabel="No, thanks"
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={onOk}
        submitLabel={submitLabel}
      />
    </StyledContactFormWrapper>
  );
};

export default ContactFormWrapper;
