import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useNonLayoutTitle } from '../../../layouts/NonAuth/NonLayoutTitleContext';
import { NonAuthLayout } from '../../../layouts/NonAuth';
import LoginForm from '../../../forms/LoginForm/LoginForm';

type AuthProps = {
  errorMessage?: string | null;
  onSubmit: (email: string, password: string) => void
}

export const AuthenticationPage: FunctionComponent<AuthProps> = ({ errorMessage, onSubmit }) => {
  const { setTitle } = useNonLayoutTitle();
  useEffect(() => setTitle('Login'), [setTitle]);
  const errors = useMemo(() => errorMessage ? [errorMessage] : undefined, [errorMessage])

  return (
    <NonAuthLayout title="Enter your credentials" errors={errors}>
      <LoginForm initialValues={{ email: '', password: '' }} onSubmit={onSubmit} />
    </NonAuthLayout>
  )
};
