import React, { FunctionComponent } from "react";
import styled from "styled-components";

const StyledTd = styled.td`
  text-align: center;
`;

const TrNoData: FunctionComponent<{ colSpan: number }> = ({ colSpan }) => {
  return (
    <tr>
      <StyledTd colSpan={colSpan}>—</StyledTd>
    </tr>
  );
};

export default TrNoData;
