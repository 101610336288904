import React, { useContext, useState, FunctionComponent, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import { useHistory } from 'react-router';
import InputText from "../../../common/Forms/InputText";
import { StyledInput } from "../../../common/Forms/StyledInput";
import Icon from "../../../common/Icons/Icon";
import THEME from '../../../theme';
import { StyledSvg } from "../../../common/Icons/Icons";
import { createFilterQueryString } from '../../../utils/dashboard/filters';
import { SearchInputProps } from "./types";

const { FILL_CLASSNAME } = THEME;

const StyledSearchInput = styled.div`
  display: none;
  position: relative;
  width: 150px;
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  margin: 0 0 0 auto;

  @media ${({ theme }) => theme.RWD.LAPTOP_S} {
    display: block;
  }
`;

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.NAVBAR.BG};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: ${({ theme }) => theme.NAVBAR.HEIGHT};
  top: 0;
  display: flex;
  align-items: center;
  position: absolute;
  width: 146px;
  transition: all ease 0.3s;
  padding: 18px;
  z-index: 2;

  ${StyledInput} {
    width: 110px;
    background-color: ${({ theme }) => theme.NAVBAR.SEARCH_BG};
    color: ${({ theme }) => theme.NAVBAR.COLOR};
    transition: all ease 0.3s;
    padding-left: 36px;
    font-family: ${({ theme }) => theme.FONT};
    font-weight: 400;
    font-size: 16px;

    ::placeholder {
      color: ${({ theme }) => theme.NAVBAR.COLOR};
    }
  }

  &.is-active:focus-within {
    top: ${({ theme }) => theme.NAVBAR.HEIGHT};
    box-shadow: 0px 10px 15px -4px ${({ theme }) => theme.NAVBAR.SHADOW};
    width: 295px;
    padding-top: 0;
    padding-bottom: 10px;
    height: 58px;

    ${StyledInput} {
      width: 260px;
    }

    ${StyledSvg} {
      .${FILL_CLASSNAME} {
        fill: ${({ theme }) => theme.NAVBAR.HOVER_COLOR};
      }
    }
  }
`;

const StyledIcon = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  pointer-events: none;
  left: 26px;
  z-index: 3;
`;

export const SearchInput: FunctionComponent<SearchInputProps> = ({ onClick, searchFilters, baseUrl, isOpen }): ReactElement => {
  const [value, setValue] = useState('');
  const { NAVBAR } = useContext(ThemeContext);
  const history = useHistory();

  const handleKeyPress = (key) => {
    if (key === 'Enter') {
      const filters = searchFilters(value);
      const filterQueryString = createFilterQueryString(filters);
      setValue('');
      history.push(`${baseUrl}?filters=${filterQueryString}`);
      onClick(null);
    }
  };

  return (
    <StyledSearchInput onClick={() => onClick('Search')}>
      <StyledContainer className={isOpen ? 'is-active' : ''}>
        <StyledIcon>
          <Icon name="loupe" size={24} color={NAVBAR.COLOR} />
        </StyledIcon>
        <InputText placeholder="Search" value={value} onChange={setValue} onKeyPress={handleKeyPress} blurOnEnter />
      </StyledContainer>
    </StyledSearchInput>
  );
};
