import { useCallback, useMemo, useState } from 'react';
import { IUserAccount } from '../types/resources';

const PAGE_SIZE = 10;

export const useListAccountsPagination = (accounts: IUserAccount[] | null) => {
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfAccounts = useMemo(() => accounts?.length ?? 0, [accounts]);

  const hasPagination = useMemo(() => numberOfAccounts > PAGE_SIZE, [numberOfAccounts]);

  const resetCurrentPage = useCallback(() => setCurrentPage(1), []);

  const canPrevPage = useCallback(() => currentPage > 1, [currentPage]);
  const canNextPage = useCallback(() => currentPage * PAGE_SIZE < numberOfAccounts, [currentPage, numberOfAccounts]);

  const handlePrevPage = useCallback(() => {
    if (canPrevPage()) {
      setCurrentPage((cP) => cP - 1);
    }
  }, [canPrevPage]);
  const handleNextPage = useCallback(() => {
    if (canNextPage()) {
      setCurrentPage((cP) => cP + 1);
    }
  }, [canNextPage]);

  const paginatedAccounts = useMemo(() =>
    accounts?.filter((_, index) =>
      index >= (currentPage - 1) * PAGE_SIZE && index < currentPage * PAGE_SIZE,
    ) ?? null,
    [accounts, currentPage],
  );

  return {
    hasPagination,
    paginatedAccounts,
    canPrevPage,
    canNextPage,
    resetCurrentPage,
    handlePrevPage,
    handleNextPage,
  };
};

export const useSearchedAccounts = (accounts: IUserAccount[] | null) => {
  const [searchAccountsValue, searchAccounts] = useState('');

  const matchedAccounts = useMemo(
    () => accounts?.filter((account) =>
      searchAccountsValue ?
        account.accountName.toLowerCase().search(searchAccountsValue.toLowerCase()) !== -1 :
        true,
    ) ?? null,
    [accounts, searchAccountsValue],
  );

  return {
    matchedAccounts,
    searchAccountsValue,
    searchAccounts,
  };
};
