import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { type } from "../../../../utils/user";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmAdminProps } from "./types";

const StyledIntro = styled.div`
  text-align: center;
  margin: 30px 0 20px;
`;

const ConfirmAdmin: FunctionComponent<ConfirmAdminProps> = ({ onCancel, onOk, user }): ReactElement => {
  const action = type(user) === 'Admin' ? 'revoke' : 'give';

  return (
    <StyledConfirm>
      <StyledIntro>Are you sure you want to {action} this user <Accent color="red">administrator access</Accent>?</StyledIntro>
      <StyledButtons>
        <Button label="No, go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Save changes" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default ConfirmAdmin;
