import React, { FunctionComponent, ReactElement } from "react";
import Modal from "../../../../common/Modal/Modal";
import Failure from "../Common/Failure";
import { AccountSettingsErrorModalProps } from "./types";

const AccounSettingsErrorModal: FunctionComponent<AccountSettingsErrorModalProps> = ({ error, isOpen, onOk }): ReactElement => {
  const text = error ?? undefined;

  return (
    <Modal isOpen={isOpen} onCancel={onOk} title="Error" error>
      <Failure onOk={onOk} text={text} />
    </Modal>
  );
};

export default AccounSettingsErrorModal;
