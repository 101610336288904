import React, { FunctionComponent, useCallback } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { IUserAccount } from '../../../../../../types/resources';

export interface IListAccountItemProps {
  account: IUserAccount;
  onClick: (account: IUserAccount) => void;
}

export const ListAccountItem: FunctionComponent<IListAccountItemProps> = ({ account, onClick }) => {
  const handleAccountClick = useCallback(() => {
    onClick(account);
  }, [account, onClick])

  return (
    <Menu.Item onClick={handleAccountClick}>
      <Header as="h4">
        {account.accountName}
        <Header.Subheader>
          {account.userFirstName} {account.userLastName}
        </Header.Subheader>
      </Header>
    </Menu.Item>
  );
};
