import React, { FunctionComponent, useCallback } from 'react';
import { Input } from 'semantic-ui-react';

export interface IListAccountSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export const ListAccountSearch: FunctionComponent<IListAccountSearchProps> = ({ value, onChange }) => {
  const handleChange = useCallback((_, { value }) => { onChange(value); }, [onChange]);

  return (
    <Input
      placeholder="Search Accounts"
      icon="search"
      fluid
      value={value}
      onChange={handleChange}
    />
  );
};
