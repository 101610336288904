import React, { useState, FunctionComponent, ReactElement } from "react";
import { isArray } from "lodash";
import { FilterItem, Filters } from "../../../common/Filters/Filters";
import { filterOptions, getQuickFilterValues, getSelectOptions } from "../../../common/Filters/utils";
import AssessmentTypeFilter from "../../../common/Filters/AssessmentTypeFilter";
import RiskFilter from "../../../common/Filters/RiskFilter";
import AssessmentStatusFilter from "../../../common/Filters/AssessmentStatusFilter";
import FollowUpStatusFilter from "../../../common/Filters/FollowUpStatusFilter";
import NameFilter from "../../../common/Filters/NameFilter";
import ClearAllFilters from "../../../common/Filters/ClearAllFilters";
import LocationFilter from "../../../common/Filters/LocationFilter";
import DepartmentFilter from "../../../common/Filters/DepartmentFilter";
import ArchivedFilter from "../../../common/Filters/ArchivedFilter";
import QuickFilters from "../../../common/Filters/QuickFilters";
import { ChangeFilters, FiltersProps } from "../../../common/Filters/types";
import { useApiRequest, useFilterCallbacks } from "../../../utils/api/hooks";
import { Option } from "../../../common/Forms/types";

const AssessmentFilters: FunctionComponent<FiltersProps> = ({ config, quickFiltersConfig, filters, onChange, onClear }): ReactElement => {
  const { fetch: fetchAutoFilterEndpoint, _filters: _quickFilters } = useApiRequest({ uri: 'survey_responses/requiring_action' });
  const { clearFilters: clearQuickFilters } = useFilterCallbacks(_quickFilters, fetchAutoFilterEndpoint);

  const assessmentTypeOptions = getSelectOptions(config, 'surveyTemplateId');
  const assessmentType = filterOptions(assessmentTypeOptions, filters.current.surveyTemplateId);
  const currentRiskOptions = getSelectOptions(config, 'currentRisk');
  const currentRisk = filterOptions(currentRiskOptions, filters.current.currentRisk);
  const assessmentStatusOptions = getSelectOptions(config, 'status');
  const assessmentStatus = filterOptions(assessmentStatusOptions, filters.current.status);
  const followUpStatusOptions = getSelectOptions(config, 'manualAssessmentStatus');
  const followUpStatus = filterOptions(followUpStatusOptions, filters.current.manualAssessmentStatus);
  const userNameLike = filters.current.userNameLike?.toString() || '';
  const locationOptions = getSelectOptions(config, 'userLocation');
  const location = filterOptions(locationOptions, filters.current.userLocation);
  const departmentOptions = getSelectOptions(config, 'userDepartment');
  const department = filterOptions(departmentOptions, filters.current.userDepartment);
  const archived = isArray(filters.current.userArchived) && filters.current.userArchived.includes(true);
  const [quickFilter, setQuickFilter] = useState<Option[] | null>(null)

  const handleQuickFilterChange = async (option: Option[] | null, filters: ChangeFilters[]) => {
    await fetchAutoFilterEndpoint()
    const quickFilters = getQuickFilterValues(_quickFilters.current)
    clearQuickFilters(undefined, true)
    await onClear(undefined, true)
    await onChange([...filters, ...quickFilters])
    setQuickFilter(option)
  }

  const handleOnClear = () => {
    setQuickFilter(null)
    onClear()
  }

  return (
    <Filters columns={5}>
      <FilterItem>
        <AssessmentTypeFilter filterKey="surveyTemplateId" value={assessmentType} options={assessmentTypeOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <RiskFilter filterKey="currentRisk" value={currentRisk} options={currentRiskOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <AssessmentStatusFilter filterKey="status" value={assessmentStatus} options={assessmentStatusOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <FollowUpStatusFilter filterKey="manualAssessmentStatus" value={followUpStatus} options={followUpStatusOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <NameFilter filterKey="userNameLike" value={userNameLike} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <LocationFilter filterKey='userLocation' value={location} options={locationOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <DepartmentFilter filterKey='userDepartment' value={department} options={departmentOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <ArchivedFilter filterKey='userArchived' value={archived} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <QuickFilters config={quickFiltersConfig} onChange={handleQuickFilterChange} value={quickFilter} />
      </FilterItem>
      <FilterItem>
        <ClearAllFilters onClear={handleOnClear} />
      </FilterItem>
    </Filters>
  );
};

export default AssessmentFilters;
