import { useMemo } from 'react';
import { IExportConfig } from '..';
import { ICreateUserWithId } from '../../../pages/admin/UserUpload/types';
import { exportKeyWrapper } from './exportKeyWrapper';

export const useCreateUserListExportOptions = () => useMemo<IExportConfig<ICreateUserWithId>[]>(() => [
  {
    header: 'Email Address',
    valueExtractor: exportKeyWrapper('emailAddress'),
  },
  {
    header: 'First Name',
    valueExtractor: exportKeyWrapper('firstName'),
  },
  {
    header: 'Last Name',
    valueExtractor: exportKeyWrapper('lastName'),
  },
  {
    header: 'Location',
    valueExtractor: exportKeyWrapper('location', 'unknown'),
  },
  {
    header: 'Department',
    valueExtractor: exportKeyWrapper('department'),
  },
  {
    header: 'Job Title',
    valueExtractor: exportKeyWrapper('jobTitle'),
  },
], []);
