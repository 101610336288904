import React, { FunctionComponent, useCallback, useState, useEffect, useMemo } from 'react';
import { Grid } from 'semantic-ui-react';

import { loadData } from '../../../utils/export';
import { IUserFromExcel, transformExcelArray } from '../../../utils/api/userUpload';
import { ICreateUserWithId } from './types';
import { useCreateUserValidator } from '../../../utils/dataUploads/createUser';

import { GeneralInfoSegment } from './GeneralInfoSegment';
import { TemplateSegment } from './TemplateSegment';
import { UploadSegment } from './UploadSegment';
import { UploadUserTable } from './UploadUserTable';
import { useFetchDataUploads, useFetchActiveDataUpload, useDeleteDataUploads } from '../../../utils/api/options/dataUploads';
import { useApiRequest } from '../../../utils/api/hooks';
import { DataUploadsTable } from './DataUploadsTable';
import { DataUploadUpdateProvider } from './DataUploadsTable/contexts/DataUploadUpdateContext';
import { IDataUpload } from '../../../types/resources';
import { UserUploadFileNameProvider } from './UploadUserTable/contexts/UserUploadFileNameContext';
import { groupBy, pickBy, sortBy } from 'lodash';
import { parseEmailForIsUniqueValidation } from '../../../utils/form/validation';

export const UserUpload: FunctionComponent = () => {
  const [currentUserUploadId, setCurrentUserUploadId] = useState<string>('');
  const [users, setUsers] = useState<ICreateUserWithId[]>([]);
  const [duplicatedEmails, setDuplicatedEmails] = useState<string[]>([]);
  const [isFileValid, setIsFileValid] = useState(true)
  const [activeFileName, setActiveFileName] = useState<string>('');
  const [activeDataUploadResolved, setActiveDataUploadResolved] = useState<boolean>(false);

  const checkUserValidation = useCreateUserValidator(duplicatedEmails);

  const dataUploadsApi = useFetchDataUploads();
  const activeDataUploadApi = useFetchActiveDataUpload();
  const deleteUserUploadApi = useDeleteDataUploads(currentUserUploadId);

  const { fetch: fetchDataUploads, data: dataUploads, loading, _pagination } = useApiRequest<never, IDataUpload[]>(dataUploadsApi);
  const { fetch: fetchActiveDataUpload, data: activeDataUpload } = useApiRequest<never, IDataUpload>(activeDataUploadApi);
  const { fetch: deleteUserUpload } = useApiRequest(deleteUserUploadApi);

  const isUploadDisabled = useMemo(() => !!activeDataUpload && activeDataUpload.meta.isFinalised, [activeDataUpload]);
  const activeUplaodMessage = useMemo(
    () => activeDataUploadResolved ? 'active_resolved' : (isUploadDisabled ? 'active' : null),
    [activeDataUploadResolved, isUploadDisabled]
  );

  const onDataUploadUpdate = useCallback(() => {
    fetchDataUploads();
    fetchActiveDataUpload();

    setUsers([]);
  }, [fetchDataUploads, fetchActiveDataUpload]);

  useEffect(() => {
    onDataUploadUpdate();
  }, [onDataUploadUpdate]);

  // polling if there is active data upload
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const polling = () => {
      if (!dataUploads || (activeDataUpload && activeDataUpload.meta.isFinalised)) {
        onDataUploadUpdate();
        timeout = setTimeout(polling, 5000);
      }
    }
    timeout = setTimeout(polling, 5000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [activeDataUpload, dataUploads, onDataUploadUpdate]);

  // update current userUploadId with active one (if it exists)
  useEffect(() => {
    if (activeDataUpload) {
      setCurrentUserUploadId(activeDataUpload.id);
      setActiveDataUploadResolved(false);
    } else if (!activeDataUpload && currentUserUploadId) {
      setActiveDataUploadResolved(true);
    }
  }, [activeDataUpload, currentUserUploadId]);
  
  const onDrop = useCallback(async (files: File[]) => {
    if (activeDataUpload && !activeDataUpload.meta.isFinalised) {
      await deleteUserUpload();
    }

    const [loadedData, isValid] = await loadData<IUserFromExcel>(files[0])

    setIsFileValid(isValid)
    const readUsers = transformExcelArray(loadedData).map((user, index) => ({ ...user, id: `${index}` })) 

    setUsers(readUsers);
    setActiveFileName(files[0].name);
  }, [activeDataUpload, deleteUserUpload]);

  useEffect(() => {
    const parsedUsers = users.map(user => ({
      ...user,
      emailAddress: parseEmailForIsUniqueValidation(user.emailAddress)
    }))
    const groupedUsers = groupBy(parsedUsers, (u) => u.emailAddress)
    const duplicated = Object.keys(pickBy(groupedUsers, entry => entry.length > 1))
    setDuplicatedEmails(duplicated)
  }, [users])

  const sortedUsers = useMemo(() => {
    const duplicatedEmailUsers: ICreateUserWithId[] = []
    const rest: ICreateUserWithId[] = []

    users.forEach((user) => duplicatedEmails.includes(parseEmailForIsUniqueValidation(user.emailAddress))
      ? duplicatedEmailUsers.push(user)
      : rest.push(user)
    )
  
    return [
      ...sortBy(duplicatedEmailUsers, user => parseEmailForIsUniqueValidation(user.emailAddress)),
      ...rest
        .sort((u1, u2) => +!!checkUserValidation(u2) - +!!checkUserValidation(u1))
    ]
  },
  [checkUserValidation, duplicatedEmails, users])

  return (
    <Grid>
      {!activeDataUpload && 
        <Grid.Row columns={3}>
          <GeneralInfoSegment />
          <TemplateSegment />
          <UploadSegment onDrop={onDrop} disabled={isUploadDisabled} />
        </Grid.Row>
      }
      <Grid.Row columns={1}>
        <Grid.Column>
          <DataUploadUpdateProvider value={onDataUploadUpdate}>
            <UserUploadFileNameProvider value={activeFileName}>
              {users.length ? (
                <UploadUserTable users={sortedUsers} setUsers={setUsers} duplicatedEmails={duplicatedEmails} isFileValid={isFileValid}/>
              ) : (
                <DataUploadsTable
                  data={dataUploads}
                  fetch={fetchDataUploads}
                  loading={loading}
                  pagination={_pagination}
                  showMessage={activeUplaodMessage}
                />
              )}
            </UserUploadFileNameProvider>
          </DataUploadUpdateProvider>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
