import React, { createContext, useContext, MutableRefObject, FunctionComponent, useMemo } from 'react';
import { useFilterCallbacks } from '../../../utils/api/hooks';
import { IFilters, IFilterCallbacks } from '../../../utils/api/types';
import { useTableApiInfo } from './TableApiInfoContext';
import { useTableFlags } from './TableFlagsContext';
import { useTablePagination } from './TablePaginationContext';

export interface ITableFilterProps {
  filter?: MutableRefObject<IFilters>;
}

const defaultFilterCallbacks: IFilterCallbacks = {
  setFilters: () => Promise.resolve(void 0),
  clearFilters: () => Promise.resolve(void 0),
};

export const TableFilterContext = createContext<IFilterCallbacks & ITableFilterProps>(defaultFilterCallbacks);

export const TableFilterProvider: FunctionComponent<ITableFilterProps> = ({ filter, children }) => {
  const { fetch } = useTableApiInfo();
  const { filterable } = useTableFlags();
  const { pagination } = useTablePagination();

  const filterCallbacks = useFilterCallbacks(filter ?? { current: { } }, fetch, pagination);
  const value = useMemo(() => filterable ? ({
    ...filterCallbacks,
    filter,
  }) : defaultFilterCallbacks, [filter, filterCallbacks, filterable]);

  return (
    <TableFilterContext.Provider value={value}>
      {children}
    </TableFilterContext.Provider>
  )
};

export const useTableFilter = () => useContext(TableFilterContext);
