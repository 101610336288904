import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { IPagination } from "../api/types";

export const useCurrentPageOptions = (pageCount: number) => useMemo(() => {
  const options: { text: string, value: number }[] = [];
  for (let i = 1; i <= pageCount; i++) {
    options.push({ text: `${i}`, value: i });
  }

  return options;
}, [pageCount]);

export const useMockPagination = <TData extends { id: string }>(data: TData[]) => {
  const [mockData, setMockData] = useState<TData[]>([]);
  const mockPagination = useRef<IPagination>({
    currentPage: 1,
    itemsPerPage: 10,
  });
  const mockFetch = useCallback((): Promise<[TData[], null]> => new Promise(
    (resolve) => {
      const fetchedUsers = data.filter((_, index) =>
        index < mockPagination.current.currentPage * mockPagination.current.itemsPerPage &&
        index >= (mockPagination.current.currentPage - 1) * mockPagination.current.itemsPerPage
      );

      setMockData(fetchedUsers);

      mockPagination.current.totalItemCount = data.length;
      mockPagination.current.totalPageCount = Math.ceil(data.length / mockPagination.current.itemsPerPage);

      resolve([fetchedUsers, null]);
    },
  ), [data]);

  useEffect(() => {
    if (data) {
      mockFetch();
    }
  }, [data, mockFetch]);

  return {
    data: mockData,
    fetch: mockFetch,
    pagination: mockPagination,
  };
};
