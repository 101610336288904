import styled from "styled-components";

export const StyledTableHeader = styled.div`
  display: flex;
  padding: 40px 0 16px 16px;
  justify-content: space-between;
`;

export const StyledTableHeading = styled.div`
  color: ${({ theme }) => theme.MODAL.BLACK_ACCENT};
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;
