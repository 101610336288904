import React, { FunctionComponent, ReactNode } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { GlobalTooltipStyle } from "../../common/Tooltip/Tooltip";

const AppContainer = styled(Container)`
  margin: auto;
  &&& {
    width: 95%;
  }
`;

const ContentRow = styled(Grid.Row)`
  &&& {
    padding-top: 2rem;
  }
`;

export const AppLayout: FunctionComponent<{ topMenu?: ReactNode }> = ({ children, topMenu }) => {
    const TopMenu = topMenu ? topMenu as FunctionComponent : null;

    return (
        <>
            { TopMenu && <TopMenu /> }
            <AppContainer fluid>
                <Grid>
                    <ContentRow>
                        <Grid.Column width={16}>{children}</Grid.Column>
                    </ContentRow>
                </Grid>
            </AppContainer>
            <GlobalTooltipStyle />
        </>
    );
};
