import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import Modal from "../../../../common/Modal/Modal";
import { FollowUpFormData } from "../../../../forms/FollowUpForm/types";
import { getFollowUpFormDataInitialValues } from "../../../../forms/FollowUpForm/utils";
import { IManualAssessment, IManualAssessmentStatusType } from "../../../../types/resources";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useBulkCreateManualAssessmentApi } from "../../../../utils/api/options/assessments/manualAssessment";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import FollowUpFormWrapper from "./FollowUpFormWrapper";
import { BulkCreatePayload, BulkFollowUpModalProps, FollowUpModalStep } from "./types";
import { getBulkCreatePayload, getModalTitle } from "./utils";

const BulkFollowUpModal: FunctionComponent<BulkFollowUpModalProps> = ({ assessmentsCount, isOpen, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<FollowUpModalStep>();
  const [formData, setFormData] = useState<FollowUpFormData>(getFollowUpFormDataInitialValues());
  const title = getModalTitle(step || 'form');
  const bulkCreateManualAssessments = useBulkCreateManualAssessmentApi();

  const { fetch: bulkCreate } = useApiRequest<BulkCreatePayload, IManualAssessment>(bulkCreateManualAssessments);

  const handleToggleContact = (showContactForm: boolean) => {
    setFormData({ ...formData, sendEmail: showContactForm })
  }

  const handleStatusChange = (status: IManualAssessmentStatusType) => {
    setFormData({ ...formData, status })
  }

  const handleSubmit = (data: FollowUpFormData) => {
    setFormData(data)
    setStep('confirm')
  }

  const changeStatus = async () => {
    setStep('processing');
    try {
      const payload = getBulkCreatePayload(formData)
      const [result, error] = await bulkCreate(payload); // eslint-disable-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }

  useEffect(() => {
    setStep('form')
    setFormData(getFollowUpFormDataInitialValues())
  }, [isOpen])

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} title={title} step={step}>
      { step === 'form' && (
        <FollowUpFormWrapper
          assessmentsCount={assessmentsCount}
          initialValues={formData}
          onCancel={onCancel}
          onStatusChange={handleStatusChange}
          onSubmit={handleSubmit}
          onToggleContact={handleToggleContact}
        />
      )}
      { step === 'confirm' && formData.status && (
        <Confirm
          assessmentsCount={assessmentsCount}
          onOk={changeStatus}
          onCancel={() => setStep('form')}
          sendEmail={formData.sendEmail}
          status={formData.status}
        />
      )}
      { step === 'processing' && <Processing />}
      { step === 'success' && <Success text="Status successfuly changed" onOk={onOk} />}
      { step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default BulkFollowUpModal;
