import { useEffect, useState } from 'react';
import { UseSelect } from './types';

export default function useSelect<ItemType extends { id: string }>(items: ItemType[] | null): UseSelect<ItemType> {
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const areAllItemsSelected = selectedItemIds.length > 0 && selectedItemIds.length === items?.length;
  const selected = items?.filter(item => selectedItemIds.includes(item.id)) || [];

  function isSelected(id: string) {
    return selectedItemIds.indexOf(id) > -1;
  }

  function select(id: string | null) {
    if (!items) {
      return;
    }

    if (id === null) {
      setSelectedItemIds([]);
      return;
    }

    if (selectedItemIds.indexOf(id) > -1) {
      setSelectedItemIds(selectedItemIds.filter(assessmentId => assessmentId !== id));
    } else {
      setSelectedItemIds([...selectedItemIds, id]);
    }
  }

  function selectAll() {
    if (!items) {
      return;
    }

    if (areAllItemsSelected) {
      setSelectedItemIds([]);
    } else {
      setSelectedItemIds(items.map(items => items.id));
    }
  };

  useEffect(() => {
    setSelectedItemIds([]);
  }, [items]);

  return { select, selectAll, selected, areAllItemsSelected, isSelected };
}
