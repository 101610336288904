import React from 'react';

import { Router } from './routing/Router';
import { ErrorHandler } from './wrappers/ErrorHandler';

export const App: React.FC = () => {
  return (
    <ErrorHandler>
      <Router />
    </ErrorHandler>
  );
};
