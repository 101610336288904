import { useCallback, useEffect, useState } from 'react';
import { UseFormField, Validation } from './types';
import { validate } from './validation';

export default function useFormField<ValueType>(initialValue: ValueType, validation?: Validation[]): UseFormField<ValueType> {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const validateField = useCallback(() => {
    if(!validation) {
      return
    }

    const errors: string[] = validate(value, validation)
    const errorValue = errors.length > 0 ? errors[0] : ''
    setError(errorValue)
    return errorValue
  }, [validation, value])

  const clearField = () => {
    setValue(initialValue)
    setIsDirty(false)
    setError('')
  }

  useEffect(() => {
    if (isDirty) {
      validateField()
      return
    }
    if (value !== initialValue) {
      setIsDirty(true)
    }
  }, [initialValue, isDirty, validateField, value])

  return [value, setValue, clearField, validateField, error, isDirty]
}
