import { isArray } from "lodash";
import { useQueryParams, StringParam } from "use-query-params";
import { IFilterConfig } from "../../common/Table/Context/TableFilterConfigContext";
import { Option } from "../../common/Forms/types";
import { ChangeFilters, IFilterConfigKey } from "./types";
import { IFilters, IFilterValue } from "../../utils/api/types";
import { parseFilterQueryString } from "../../utils/dashboard/filters";

export function filterOptions(options: Option[], currentFilters: IFilterValue | IFilterValue[] | null): Option[] | null {
  return options.filter((option) => {
    return isArray(currentFilters)
      ? currentFilters.indexOf(option.value) > -1 || null
      : option.value === currentFilters;
  });
}

export function getSelectOptions(config: IFilterConfig[], key: IFilterConfigKey): Option[] {
  const filterConfig = config.find(configValue => configValue.key === key);

  if (!filterConfig || !filterConfig.options) {
    return [];
  }

  return filterConfig.options.map(option => ({ label: option.text, value: option.value }));
}

export function optionToFilterValue(options: Option[], key: IFilterConfigKey, isArray?: boolean): ChangeFilters[] {
  const value = isArray
    ? (options && options[0]) ? options.map(option => option.value) : []
    : (options && options[0]) ? options[0].value : null;

  const filter = { key, value };

  return [filter];
}

function getQuickFilterValue(filterValues: IFilterValue | IFilterValue[] | null): IFilterValue | IFilterValue[] {
  if (filterValues === null) {
    return ''
  }

  return isArray(filterValues) ? filterValues.map(value => value === null ? '' : value) : filterValues
}

export function getQuickFilterValues(filters: IFilters): ChangeFilters[] {
  return Object.keys(filters).map(filterKey => ({
      key: filterKey,
      value: getQuickFilterValue(filters[filterKey])
    }))
}

export function useUserNameFilterFromUrl(): string | null {
  const [{ filters }] = useQueryParams({ filters: StringParam });

  if(!filters) {
    return null
  }

  const parsedFilters = parseFilterQueryString(filters);
  const userNameLike = parsedFilters.find(filter => filter.key === 'userNameLike')

  return userNameLike ? userNameLike.value.toString() : null
}
