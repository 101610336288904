import { useMemo } from "react";
import { IFilterConfig } from "../../../common/Table/Context/TableFilterConfigContext";

export const useBackendUserListFilters = (
) => useMemo<IFilterConfig[]>(() => [
  {
    label: 'User Email Search',
    key: 'userEmailLike',
    type: 'text',
  },
  {
    label: 'Account Administrators',
    key: 'accountAdministrators',
    type: 'text'
  }
], []);
