import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button from "../../../../common/Buttons/Button";
import Accent from "../../../../common/Modal/Accent";
import { canBeReminded } from "../../../../utils/assessment";
import { StyledButtons, StyledConfirm } from "../Common/Confirm";
import { ConfirmProps } from "./types";

const StyledIntro = styled.div`
  max-width: 80%;
  text-align: center;
  margin-top: 30px;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ assessments, onCancel, onOk }): ReactElement => {
  const assessmentsText = assessments.length > 1 ? 'the selected assessments' : 'this assessment';
  const showWarning = assessments.filter(canBeReminded).length !== assessments.length;

  return (
    <StyledConfirm>
      { showWarning
        ? (
          <>
            <StyledIntro><Accent>One or more</Accent> of the assessments you have selected are <Accent color="red">inactive</Accent> (i.e. already cancelled or completed). <Accent>Only the active (incomplete) assessment(s)</Accent> you have selected will be cancelled.</StyledIntro>
            <StyledIntro>Would you like to cancel the selected active assessments?</StyledIntro>
          </>
        ) 
        : <StyledIntro>Are you sure you want to <Accent>cancel</Accent> {assessmentsText}?</StyledIntro>
      }
      <StyledButtons>
        <Button label="No, go back" theme="SUCCESS_SECONDARY" onClick={onCancel} />
        <Button icon="ok" label="Yes, cancel" theme="SUCCESS_PRIMARY" onClick={onOk} />
      </StyledButtons>
    </StyledConfirm>
  );
};

export default Confirm;
