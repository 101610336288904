import { useApiRequestOptions } from "../../hooks";

export const useCreateAdministratorNoteApi = () => useApiRequestOptions({
  uri: 'issue_administrator_notes',
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: null,  // because it can be used in a loop, we must disable message
  serialization: {
    type: 'issueAdministratorNotes',
    options: {
      attributes: ['note', 'questionResponseIssue'],
      questionResponseIssue: {
        ref: 'id',
        included: false,
      },
    },
  },
}, []);

export const useCreateAdministratorClosureApi = () => useApiRequestOptions({
  uri: 'issue_administrator_closures',
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: null,
  serialization: {
    type: 'issueAdministratorClosures',
    options: {
      attributes: ['note', 'questionResponseIssue'],
      questionResponseIssue: {
        ref: 'id',
        included: false,
      },
    },
  },
}, []);

export const useCreateAdministratorReopeningApi = () => useApiRequestOptions({
  uri: 'issue_administrator_reopening',
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: null,
  serialization: {
    type: 'issueAdministratorReopenings',
    options: {
      attributes: ['note', 'questionResponseIssue'],
      questionResponseIssue: {
        ref: 'id',
        included: false,
      },
    },
  },
}, []);
