import { UserFormData } from "../../../../forms/UserForm/types";
import { ICreateUser } from "../../../../types/resources";

export function createUserPayload(data: UserFormData): ICreateUser {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    emailAddress: data.email,
    jobTitle: data.jobTitle,
    department: data.department,
    location: data.location,
  }
}
