export const formatDateField = (dateStr: string): string => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date ) 
  
  return `${day} ${month} ${year}`;
};

export const dateSorter = (dateStr1: string, dateStr2: string) =>
  new Date(dateStr2).getTime() - new Date(dateStr1).getTime();

export const weeksSince = (dateStr: string): number => 
  Math.round(((new Date()).getTime() - (new Date(dateStr)).getTime()) / (1000 * 60 * 60 * 24 * 7));
