import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Accent from "../../../../common/Modal/Accent";
import FollowUpForm from "../../../../forms/FollowUpForm/FollowUpForm";
import { FollowUpFormWrapperProps } from "./types";

const StyledIntro = styled.div`
  margin-bottom: 18px;
`;

const StyledFollowUpFormWrapper = styled.div<{showContactForm: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ showContactForm }) => showContactForm ? '860px' : '540px'};
  padding: 30px 30px 50px;
`;

const FollowUpFormWrapper: FunctionComponent<FollowUpFormWrapperProps> = ({ assessmentsCount, initialValues, onCancel, onStatusChange, onSubmit, onToggleContact }): ReactElement => {
  return (
    <StyledFollowUpFormWrapper showContactForm={initialValues.sendEmail}>
      <StyledIntro>Change the status of all <Accent color="red">{assessmentsCount} high risk assessments</Accent> to:</StyledIntro>
      <FollowUpForm
        isActive={false}
        initialValues={initialValues}
        onCancel={onCancel}
        onStatusChange={onStatusChange}
        onSubmit={onSubmit}
        onToggleContact={onToggleContact}
      />
    </StyledFollowUpFormWrapper>
  );
};

export default FollowUpFormWrapper;

