import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { ResendInviteModalStep } from "./types";

const STEP_TITLE_MAP: { [key in ResendInviteModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  confirm: 'Resend invite link',
  processing: 'Resending invite link',
};

export function getModalTitle(step: ResendInviteModalStep): string {
  return STEP_TITLE_MAP[step]
}
