import React, { FunctionComponent } from "react";
import { CheckboxProps } from "../../Forms/types";
import Checkbox from "../../Forms/Checkbox";
import Td from "./Td";

export const CheckboxCell: FunctionComponent<CheckboxProps> = ({ id, onChange, value }) => (
  <Td><Checkbox id={id} value={value} onChange={onChange} /></Td>
);

export default CheckboxCell;
