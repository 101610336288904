import React, { FunctionComponent, ReactElement } from "react";
import Checkbox from "../Forms/Checkbox";
import { CheckboxFilterProps } from "./types";

type InvalidEmailsFilterKey = "emailDeliveryStatus";

const InvalidEmailsFilter: FunctionComponent<CheckboxFilterProps<InvalidEmailsFilterKey>> = ({ filterKey, value, onChange }): ReactElement => {
  return (
    <Checkbox
      id='email'
      fieldLabel="Invalid emails"
      label="show only invalid addresses"
      value={value}
      onChange={() => onChange([{ key: filterKey, value: value ? null : ['hard_bouncing'] }])}
    />
  );
};

export default InvalidEmailsFilter;
