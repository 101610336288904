import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Tabs from "../../../common/Tabs/Tabs";
import { AssessmentTabsProps, isTabFilteredIssues, isTabQA } from "./types";
import AssessmentFilteredIssuesTable from "./AssessmentFilteredIssuesTable";
import AssessmentQATable from "./AssessmentQATable";
import { tabs } from "./utils";

const StyledAssessmentTabs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AssessmentTabs: FunctionComponent<AssessmentTabsProps> = ({ 
  assessmentQuestionAnswers, 
  issues, 
  onOpenUpdatesHistoryModal,
  onOpenUpdateIssuesModal,
  onTabChange,
  selectIssues,
  tab,
  user,
}): ReactElement => {
  return (
    <StyledAssessmentTabs>
      <Tabs activeTab={tab} tabs={tabs} onClick={onTabChange}/>
      { isTabFilteredIssues(tab) && user && (
        <AssessmentFilteredIssuesTable 
          issues={issues}
          selectIssues={selectIssues}
          onOpenUpdatesHistoryModal={onOpenUpdatesHistoryModal}
          onOpenUpdateIssuesModal={onOpenUpdateIssuesModal}
          tab={tab}
          user={user} 
        />
      )}
      { isTabQA(tab) && <AssessmentQATable assessmentQuestionAnswers={assessmentQuestionAnswers} /> }
    </StyledAssessmentTabs>
  );
};

export default AssessmentTabs;
