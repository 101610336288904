import React, { useEffect, useState, FunctionComponent, ReactElement, useCallback } from "react";
import Modal from "../../../../common/Modal/Modal";
import { IIssue } from "../../../../types/resources";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useFetchQuestionResponseIssueApi } from "../../../../utils/api/options/questionResponseIssue";
import ContactModal from "../ContactModal";
import UpdateIssuesModal from "../UpdateIssuesModal";
import History from "./History";
import { IIssueUpdateRowProps, UpdatesHistoryModalProps, UpdatesHistoryModalStep } from "./types";
import { prepareUpdateRows } from "./utils";

const UpdatesHistoryModal: FunctionComponent<UpdatesHistoryModalProps> = ({ isOpen, issue, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<UpdatesHistoryModalStep>('loading');
  const [updates, setUpdates] = useState<IIssueUpdateRowProps[]>([])

  const questionResponseIssueApi = useFetchQuestionResponseIssueApi(issue.id);
  const { fetch: fetchQuestionResponseIssue, data: issueData } = useApiRequest<never, IIssue>(questionResponseIssueApi);

  const loadData = useCallback(async () => {
    await fetchQuestionResponseIssue()
    setStep('history')
  }, [fetchQuestionResponseIssue])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    const updates = prepareUpdateRows(issueData)
    setUpdates(updates)
  }, [issueData])

  switch(step) {
    case 'contact': 
      return <ContactModal items={[issue]} itemType="questionResponseIssue" isOpen onOk={onCancel} onCancel={() => setStep('history')} />
    case 'update':
      return <UpdateIssuesModal issues={[issue]} isOpen onOk={onOk} onCancel={() => setStep('history')} />
    default:
      return (
        <Modal isOpen={isOpen} onCancel={onCancel} title="Updates">
          <History issue={issue} updates={updates} onContact={() => setStep('contact')} onUpdate={() => setStep('update')} onCancel={onCancel} />
        </Modal>
      );
  }
};

export default UpdatesHistoryModal;
