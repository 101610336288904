import React, { FunctionComponent } from 'react';
import { AccountUpdateModalProvider } from './AccountUpdateModal/AccountUpdateModalContext';
import { ConfirmationModalProvider } from '../../../common/modals/ConfirmationModal/ConfirmationModalContext';

export const ModalProvider: FunctionComponent = ({ children }) => (
  <ConfirmationModalProvider>
    <AccountUpdateModalProvider>
      {children}
    </AccountUpdateModalProvider>
  </ConfirmationModalProvider>
);
