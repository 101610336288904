import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Button, { StyledButton } from "../../../../common/Buttons/Button";
import Modal from "../../../../common/Modal/Modal";
import { useApiFetchAll } from "../../../../utils/api/hacks";
import { prepareDataForExport, exportData as exportToFile } from '../../../../utils/export';

import { ExportModalProps } from "./types";

const StyledExportModal = styled.div`
  padding: 30px 0 50px;
  width: 670px;
`;

const StyledQuestion = styled.div`
  margin-bottom: 56px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;

  ${StyledButton} {
    width: 194px;
    max-width: none;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ExportModal = <ItemType extends object>({ 
  allItemsCount,
  exportOptions,
  fetchItemListApi,
  fileName,
  isOpen,
  onCancel,
  pageItems,
  selectedItems,
}: ExportModalProps<ItemType>) => {
  const [itemsToExport, setItemsToExport] = useState<ItemType[]>([]);
  const { start: fetchAllData, data: allItemsData } = useApiFetchAll<any, ItemType>(fetchItemListApi);

  const buttonLabels = {
    selected: `Export selected (${selectedItems.length})`,
    page: `Export current page (${pageItems.length})`,
    all: `Export all results (${allItemsCount})`,
  };

  useEffect(() => {
    if(itemsToExport.length > 0) {
      exportToFile(fileName, prepareDataForExport(itemsToExport, exportOptions));
    }
  }, [exportOptions, fileName, itemsToExport]);

  useEffect(() => {
    if (allItemsData) {
      setItemsToExport(allItemsData);
    }
  }, [allItemsData]);

  const exportSelected = () => {
    setItemsToExport(selectedItems);
  };

  const exportCurrentPage = () => {
    setItemsToExport(pageItems);
  };

  const exportAll = async () => {
    await fetchAllData();
  };

  return (
    <Modal onCancel={onCancel} isOpen={isOpen} title="Export">
      <StyledExportModal>
        <StyledQuestion>What do you want to export?</StyledQuestion>
        <StyledButtons>
          <Button label={buttonLabels.selected} theme="SUCCESS_SECONDARY" onClick={exportSelected} disabled={selectedItems.length === 0} />
          <Button label={buttonLabels.page} theme="SUCCESS_SECONDARY" onClick={exportCurrentPage} disabled={pageItems.length === 0} />
          <Button label={buttonLabels.all} theme="SUCCESS_PRIMARY" onClick={exportAll} />
        </StyledButtons>
      </StyledExportModal>
    </Modal>
  );
};

export default ExportModal;
