import { IUser } from "../../types/resources";
import { UserFormData } from "./types";

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  department: '',
  location: '',
}

export function getUserFormDataInitialValues(user?: IUser): UserFormData {
  if (!user) {
    return { ...initialValues }
  }
 
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.emailAddress,
    jobTitle: user.jobTitle || '',
    department: user.department || '',
    location: user.location || '',
  };
}
