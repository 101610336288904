import React, { useCallback, useEffect, useState, FunctionComponent, ReactElement } from "react";
import Modal from "../../../../common/Modal/Modal";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useSendEmailApi } from "../../../../utils/api/options/outboundEmails";
import { ContactFormData } from "../../../../forms/ContactForm/types";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import ContactFormWrapper from "./ContactFormWrapper";
import { ContactModalProps, ContactModalStep } from "./types";
import { getModalTitle, getSendEmailPayload } from "./utils";
import { getContactFormDataInitialValues } from "../../../../forms/ContactForm/utils";

const ContactModal: FunctionComponent<ContactModalProps> = ({ items, itemType, isOpen, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<ContactModalStep>('form');
  const [formData, setFormData] = useState<ContactFormData>(getContactFormDataInitialValues());
  const [confirmed, setConfirmed] = useState(false);
  const sendEmailApi = useSendEmailApi();
  const { fetch: sendEmail } = useApiRequest(sendEmailApi);
  const multi = items.length > 1;
  const handleOnCancel = step === 'processing' ? undefined : onCancel;

  const sendEmails = useCallback(async (formData: ContactFormData) => {
    setStep('processing');
    for (let i = 0; i < items.length; i++) {
      const payload = getSendEmailPayload(itemType, items[i], formData);
      const [result, error] = await sendEmail(payload); // eslint-disable-line @typescript-eslint/no-unused-vars

      if (error) {
        setStep('failure');
        return;
      }
    }

    setStep('success');
    setConfirmed(false);
  }, [itemType, items, sendEmail]);

  const handleFormSubmit = (data: ContactFormData) => {
    setFormData(data);
    if (multi) {
      setStep('confirm');
      return;
    }

    setConfirmed(true);
  };

  useEffect(() => {
    if (confirmed) {
      sendEmails(formData);
    }
  }, [confirmed, formData, sendEmails]);

  const title = getModalTitle(step, multi);

  return (
    <Modal isOpen={isOpen} onCancel={handleOnCancel} title={title}>
      {step === 'form' && <ContactFormWrapper items={items} initialValues={formData} onCancel={onCancel} onOk={handleFormSubmit} />}
      {step === 'confirm' && <Confirm onCancel={() => setStep('form')} onOk={() => setConfirmed(true)} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text={`Mail${multi ? 's' : ''} successfuly sent.`} />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default ContactModal;
