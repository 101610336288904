import React, { useCallback, useMemo, useState, FunctionComponent, ReactElement } from "react";
import Modal from "../../../../common/Modal/Modal";
import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useResendAssessmentLinkApi } from "../../../../utils/api/options/assessments";
import { canBeReminded } from "../../../../utils/assessment";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import { SendReminderModalProps, SendReminderModalStep } from "./types";

const MODAL_TITLES: {[ key in SendReminderModalStep]: string} = {
  ...STEP_TITLE_MAP_COMMON,
  confirm: 'Send reminders',
  processing: 'Sending reminders'
}

const SendReminderModal: FunctionComponent<SendReminderModalProps> = ({ assessments, isOpen, onCancel }): ReactElement => {
  const [step, setStep] = useState<SendReminderModalStep>('confirm');
  const resendLinkApi = useResendAssessmentLinkApi();
  const { fetch: resendLink } = useApiRequest(resendLinkApi);
  const filteredAssessments = useMemo(() => assessments.filter(canBeReminded), [assessments]);

  const handleOnCancel = step === 'processing' ? undefined : onCancel;

  const sendReminder = useCallback(async () => {
    setStep('processing');
    const payload = filteredAssessments.map(assessments => ({ id: assessments.id }))
    const [result, error] = await resendLink(payload); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }, [filteredAssessments, resendLink]);

  return (
    <Modal isOpen={isOpen} onCancel={handleOnCancel} title={MODAL_TITLES[step]}>
      {step === 'confirm' && <Confirm assessments={assessments} onCancel={onCancel} onOk={sendReminder} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onCancel} text={"Reminder successfuly sent."} />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default SendReminderModal;
