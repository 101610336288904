import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { IUser, ICreateUser } from '../../../../../types/resources';
import { ICreateUserWithId } from '../../../UserUpload/types';

import { useApiRequest } from '../../../../../utils/api/hooks';
import { useCreateUserApi, useUpdateUserApi } from '../../../../../utils/api/options/users';

import { Form } from '../../../../../common/Form';
import { useUserFormConfig } from '../../../../../utils/form/configs/userForm';
import { useOpenConfirmationModal } from '../../../../../common/modals/ConfirmationModal/ConfirmationModalContext';
import { useSession } from '../../../../auth/AuthContext/SessionContext';
import { IApiError422 } from '../../../../../utils/api/types';
import { uniqueEmailHandler } from '../../../../../utils/form/error-handlers/uniqueEmail';

export interface IUserFormProps {
  user?: IUser | ICreateUserWithId | null;
  onCancel?: () => void;
  onUserUpdate?: () => void;
  onSubmit?: (user: IUser | ICreateUserWithId) => void;
}

export interface IUserFormValues {
  default: ICreateUser & {
    archived?: boolean;
    userIsAdministrator?: boolean;
  };
}

export const UserForm: FunctionComponent<IUserFormProps> = ({ user, onCancel, onUserUpdate, onSubmit }) => {
  const openConfirmationModal = useOpenConfirmationModal();
  const { currentUser } = useSession()

  const createUserApi = useCreateUserApi();
  const updateUserApi = useUpdateUserApi((user as IUser)?.id ?? '');
  
  const { fetch: createUser } = useApiRequest<IUserFormValues['default']>(createUserApi);
  const { fetch: updateUser } = useApiRequest<IUserFormValues['default'] & { id: string }>(updateUserApi);

  const formConfig = useUserFormConfig(!!(user as IUser)?.id && user?.id !== `${currentUser?.userId}`);
  const errorHandlers = useMemo(() => [uniqueEmailHandler],[]);

  const initialValues = useMemo<IUserFormValues>(() => ({
    default: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      emailAddress: user?.emailAddress ?? '',
      jobTitle: user?.jobTitle ?? undefined,
      department: user?.department ?? undefined,
      location: user?.location ?? undefined,
      archived: (user as IUser)?.archived ?? undefined,
      userIsAdministrator: (user as IUser)?.userIsAdministrator ?? undefined,
    },
  }), [user]);

  const handleFormSubmit = useCallback(async (values: IUserFormValues) => {
    const parsedValues = {
      firstName: values.default.firstName,
      lastName: values.default.lastName,
      emailAddress: values.default.emailAddress,
      jobTitle: values.default.jobTitle ?? null,
      department: values.default.department ?? null,
      location: values.default.location ?? null,
    };

    if (onSubmit) {
      onSubmit({ ...parsedValues, id: (user as ICreateUserWithId)?.id });
    } else if (!user) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [result, error] = await createUser(parsedValues);
      if (error) {
        return error as IApiError422[];
      }
    } else {
      // open up confirmation modal if this two attributes changed
      if (
        values.default.archived !== (user as IUser).archived ||
        values.default.userIsAdministrator !== (user as IUser).userIsAdministrator
      ) {
        openConfirmationModal(
          async () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [result, error] = await updateUser({
              ...parsedValues,
              id: (user as IUser)?.id,
              archived: values.default.archived,
              userIsAdministrator: values.default.userIsAdministrator,
            });

            if (error) {
              throw error;
            }

            onUserUpdate?.();
            onCancel?.();        
          },
        );
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [result, error] = await updateUser({ ...parsedValues, id: (user as IUser)?.id });
      if (error) {
        return error as IApiError422[];
      }
    }

    onUserUpdate?.();
    onCancel?.();
  }, [createUser, onCancel, onSubmit, onUserUpdate, openConfirmationModal, updateUser, user]);

  return (
    <Form<IUserFormValues>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      config={formConfig}
      onCancel={onCancel}
      errorHandlers={errorHandlers}
    />
  );
};
