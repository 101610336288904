import React, { useState, FunctionComponent, ReactElement } from "react";
import { AccordionContext  } from "./AccordionContext";

const Accordion: FunctionComponent = ({ children }): ReactElement => {
  const [ expanded, setExpanded ] = useState<string[]>([])

  const toggle = (id: string) => {
    if (expanded.indexOf(id) > -1) {
      setExpanded(expanded.filter(expandedId => expandedId !== id))
    } else {
      setExpanded([...expanded, id])
    }
  }

  return (
    <AccordionContext.Provider value={{ expanded, toggle }}>
      { children }
    </AccordionContext.Provider>
  );
};

export default Accordion;
