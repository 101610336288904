import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { AccentProps } from "./types";
const StyledAccent = styled.span<AccentProps>`
  font-weight: 600;
  color: ${({ color, theme }) => color === 'red' ? theme.MODAL.RED_ACCENT : theme.MODAL.BLACK_ACCENT};
`;

const Accent: FunctionComponent<AccentProps> = ({ color = 'black', children }): ReactElement => (
  <StyledAccent color={color}>
    {children}
  </StyledAccent>
);

export default Accent;
