import { useMemo } from 'react';
import { IFilterConfig, IAutoFilterConfig } from '../../../common/Table/Context/TableFilterConfigContext';
import { IUserDepartmentListItem, IUserLocationListItem, ISurveyTemplate, IIssueCategory } from '../../../types/resources';

import { getRiskOptions } from './options/riskOptions';
import { getCheckboxOptions } from './options/checkboxOptions';
import { getSurveyTemplateOptions } from './options/surveyTemplateOptions';
import { getRequiringActionFilterApi } from '../../api/options/questionResponseIssue';
import { getListedStringOptions } from './options/listedStringOptions';
import { getIssueCategoryOptions } from './options/issueCategoryOptions';

export const useIssueListFilters = (
  issueCategories: IIssueCategory[],
  departmentOptions: IUserDepartmentListItem[],
  locationOptions: IUserLocationListItem[],
  surveyTemplates: ISurveyTemplate[],
) => useMemo<IFilterConfig[]>(() => [
  {
    key: 'active',
    label: 'Issue Status',
    type: 'select',
    options: getCheckboxOptions('Unresolved issues', 'Resolved issues'),
  },
  {
    key: 'resolvableByUser',
    label: 'Resolvable by User',
    type: 'select',
    options: getCheckboxOptions('Resolvable by User', 'Not resolvable by user'),
  },
  {
    key: 'riskFactor',
    label: 'Risk Level',
    type: 'multiSelect',
    options: getRiskOptions(),
  },
  {
    key: 'surveyTemplateId',
    label: 'Assessment Type',
    type: 'multiSelect',
    options: getSurveyTemplateOptions(surveyTemplates),
  },
  {
    key: 'issueCategoryId',
    label: 'Category',
    type: 'multiSelect',
    options: getIssueCategoryOptions(issueCategories),
  },
  {
    label: 'User Department',
    key: 'userDepartment',
    type: 'multiSelect',
    options: getListedStringOptions(departmentOptions),
  },
  {
    label: 'User Location',
    key: 'userLocation',
    type: 'multiSelect',
    options: getListedStringOptions(locationOptions),
  },
  {
    label: 'User Archived',
    key: 'userArchived',
    type: 'multiSelect',
    options: getCheckboxOptions('Archived users', 'Non-archived users'),
  },
], [departmentOptions, issueCategories, locationOptions, surveyTemplates]);

export const useIssueListAutoFilters = () => useMemo<IAutoFilterConfig[]>(() => [
  {
    label: 'Requiring Action',
    values: [],
    apiOptions: getRequiringActionFilterApi(),
  },
], []);
