import React, { FunctionComponent } from 'react';
import { Modal, Button, Grid, Header, SemanticCOLORS } from 'semantic-ui-react';
import { ConfirmationModalHeader } from './ConfirmationModalHeader';

export interface IConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onDeny: () => void;
  message?: string;
  confirmColor?: SemanticCOLORS;
}

export const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = ({
  isOpen,
  onConfirm,
  onDeny,
  message,
  confirmColor,
}) => (
  <Modal open={isOpen}>
    <ConfirmationModalHeader />
    <Modal.Content>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">
              {message ?? 'Are you sure you want to save your changes?'}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8} floated="right" textAlign="right">
            <Button onClick={onDeny}>Cancel</Button>
            <Button onClick={onConfirm} color={confirmColor ?? 'green'}>Confirm</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Content>
  </Modal>
);
