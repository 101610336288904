import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useTableSelection } from '../../../../common/Table/Context/TableSelectionContext';
import { IAccount } from '../../../../types/resources';
import { useApiRequest } from '../../../../utils/api/hooks';
import { useGetAccountAssessmentReportApi } from '../../../../utils/api/options/accounts';
import { exportToExcelWorkbook, IAssessmentReport } from '../GetAccountAssessmentReport';

export const ExportManagementReports: FunctionComponent = () => {
  const { selection, isAnySelected } = useTableSelection();
  const selectedAccounts = Object.keys(selection);
  const apiConfig = useGetAccountAssessmentReportApi(selectedAccounts);
  const { data: reports, fetch: getReport, loading } = useApiRequest<any, IAssessmentReport[]>(apiConfig);
  const [exportRequested, setExportRequested] = useState<Boolean>(false);

  const runExport = useCallback(() => {
    getReport();
    setExportRequested(true)
  }, [getReport, setExportRequested])

  useEffect(() => {
    if (reports && exportRequested && !loading)
    {
      const newReports = reports.map(report => {
        const account = selection[report.id] as IAccount;
        return { report, account }
      })
      exportToExcelWorkbook(newReports);
      setExportRequested(false)
    }
  }, [reports, selection, exportRequested, loading])

  return (
    <Button color="green" disabled={!isAnySelected || loading} onClick={runExport}>Export Assessment Reports</Button>
  );
};
