import styled from "styled-components";

const Td = styled.td`
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.TABLE.COLOR};
  padding: ${({ theme }) => theme.TABLE.CELL_PADDING};
  text-align: left;
`;

export default Td;
