import React, { FunctionComponent, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Header, Icon, Button, Modal, Grid } from 'semantic-ui-react';

import { useAirbrake } from '../utils/airbrake';

const ErrorFallback: FunctionComponent = () => {
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);
  const handleHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <Modal open={true}>
      <Header as="h1" textAlign="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon name="warning sign" color="orange" />
        <Header.Content>
          Something went wrong
        </Header.Content>
      </Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                Something unexpected happened. Please reload page or navigate back to home page.
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated="right" textAlign="right">
              <Button onClick={handleHome}>Home</Button>
              <Button color="green" onClick={handleReload}>Reload page</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export const ErrorHandler: FunctionComponent = ({ children }) => {
  useAirbrake();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};
