import React, { FunctionComponent, ReactElement, useEffect, useMemo } from "react";
import useFormField from "../../../../common/Forms/useFormField";
import Checkbox from "../../../../common/Forms/Checkbox"
import InputNumber from "../../../../common/Forms/InputNumber";
import { ReminderSchedulesTabProps } from "../types";
import AccountSettingsTab from "./AccountSettingsTab";
import SettingsCard from "./SettingsCard";
import { SaveButton, StyledCheckbox, StyledInput } from "./Common";
import { useUpdateRemindersApi } from "../../../../utils/api/options/settings/scheduleReminders";
import { useApiRequest } from "../../../../utils/api/hooks";
import { IScheduleReminder } from "../../../../types/resources";
import { getReminderScheduleFormData, isReminderSchedulesFormDirty } from "./utils";
import { valueBetweenValidation } from "../../../../common/Forms/validation";

const ReminderSchedulesTab: FunctionComponent<ReminderSchedulesTabProps> = ({ initialValues, onChange, onSave }): ReactElement => {
  const automatedIntervention = initialValues.find(reminderSchedule => reminderSchedule.reminderType === "automated_intervention") || initialValues[0]
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isAutomatedInterventionEnabled, setIsAutomatedInterventionEnabled] = useFormField<boolean>(automatedIntervention.enabled)
  const [automatedInterventionInterval, setAutomatedInterventionInterval, clearAutomatedInterventionInterval, validateAutomatedInterventionInterval, automatedInterventionIntervalError] = useFormField<number>(automatedIntervention.interval, [valueBetweenValidation(1, 30)])
  const [maxAutomatedInterventionReminders, setMaxAutomatedInterventionReminders, clearMaxAutomatedInterventionReminders, validateMaxAutomatedInterventionReminders, maxAutomatedInterventionRemindersError] = useFormField<number>(automatedIntervention.maxReminders, [valueBetweenValidation(1, 60)])

  const assessmentReminder = initialValues.find(reminderSchedule => reminderSchedule.reminderType === "assessment") || initialValues[1]
  const [isAssessmentReminderEnabled, setIsAssessmentReminderEnabled] = useFormField<boolean>(assessmentReminder.enabled)
  const [assessmentReminderInterval, setAssessmentReminderInterval, clearAssessmentReminderInterval, validateAssessmentReminderInterval, assessmentReminderIntervalError] = useFormField<number>(assessmentReminder.interval, [valueBetweenValidation(1, 30)])
  const [maxAssessmentReminderReminders, setMaxAssessmentReminderReminders, clearMaxAssessmentReminderReminders, validateMaxAssessmentReminderReminders, maxAssessmentReminderRemindersError] = useFormField<number>(assessmentReminder.maxReminders, [valueBetweenValidation(1, 60)])
  /* eslint-enable */

  const updateScheduleRemindersApi = useUpdateRemindersApi();
  const { fetch: updateScheduleReminders } = useApiRequest<IScheduleReminder[], any>(updateScheduleRemindersApi);

  const automatedInterventionFormData = getReminderScheduleFormData(automatedIntervention, isAutomatedInterventionEnabled, automatedInterventionInterval, maxAutomatedInterventionReminders)
  const assessmentReminderFormData = getReminderScheduleFormData(assessmentReminder, isAssessmentReminderEnabled, assessmentReminderInterval, maxAssessmentReminderReminders)

  const isDirty = useMemo(() => 
    isReminderSchedulesFormDirty([[automatedIntervention, automatedInterventionFormData], [assessmentReminder, assessmentReminderFormData]])
  , [assessmentReminder, assessmentReminderFormData, automatedIntervention, automatedInterventionFormData])
  
  useEffect(() => {
    onChange(isDirty)
  }, [isDirty, onChange])

  const onSubmit = async () => {
    const errors = [
      validateAutomatedInterventionInterval(), 
      validateMaxAutomatedInterventionReminders(),
      validateAssessmentReminderInterval(),
      validateMaxAssessmentReminderReminders()
    ];

    if (errors.filter(e => !!e).length === 0) {
      const [result, error] = await updateScheduleReminders([automatedInterventionFormData, assessmentReminderFormData]); // eslint-disable-line @typescript-eslint/no-unused-vars

      if(!error) {
        onSave()
      }
    }
  }

  return (
    <AccountSettingsTab title="Reminder schedules">
      <SettingsCard title="Assessment">
        <StyledCheckbox>
          <Checkbox
            id="send-assessment-reminder" 
            value={isAssessmentReminderEnabled} 
            onChange={() => setIsAssessmentReminderEnabled(!isAssessmentReminderEnabled)}
            label="Send assessment reminders"
          />
        </StyledCheckbox>
        <StyledInput>
          <InputNumber
            disabled={!isAssessmentReminderEnabled}
            error={assessmentReminderIntervalError}
            label="Send reminder every 'X' days:"
            value={assessmentReminderInterval}
            onChange={setAssessmentReminderInterval}
            min={1}
            max={30}
          />
        </StyledInput>
        <StyledInput>
          <InputNumber
            disabled={!isAssessmentReminderEnabled}
            error={maxAssessmentReminderRemindersError}
            label="Maximum number of reminders to send:"
            value={maxAssessmentReminderReminders}
            onChange={setMaxAssessmentReminderReminders}
            min={1}
            max={60}
          />
        </StyledInput>
      </SettingsCard>
      <SettingsCard title="Guidance">
        <StyledCheckbox>
          <Checkbox
            id="send-automated-intervention" 
            value={isAutomatedInterventionEnabled} 
            onChange={() => setIsAutomatedInterventionEnabled(!isAutomatedInterventionEnabled)}
            label="Send guidance reminders"
          />
        </StyledCheckbox>
        <StyledInput>
          <InputNumber
            disabled={!isAutomatedInterventionEnabled}
            error={automatedInterventionIntervalError}
            label="Send reminder every 'X' days:"
            value={automatedInterventionInterval}
            onChange={setAutomatedInterventionInterval}
            min={1}
            max={30}
          />
        </StyledInput>
        <StyledInput>
          <InputNumber
            disabled={!isAutomatedInterventionEnabled}
            error={maxAutomatedInterventionRemindersError}
            label="Maximum number of reminders to send:"
            value={maxAutomatedInterventionReminders}
            onChange={setMaxAutomatedInterventionReminders}
            min={1}
            max={60}
          />
        </StyledInput>
      </SettingsCard>
      <SaveButton disabled={!isDirty} onClick={onSubmit} />
    </AccountSettingsTab>
  );
};

export default ReminderSchedulesTab;
