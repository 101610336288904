import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { UserResolvableCellProps } from "../types";
import Td from "./Td";

const StyledUserResolvableCell = styled.div<{ isResolvable: boolean }>`
  color: ${({ isResolvable, theme }) => isResolvable ? theme.TABLE.COLOR : theme.TABLE.WARNING};
  text-align: center;
`;

const UserResolvableCell: FunctionComponent<UserResolvableCellProps> = ({ isResolvable }) => (
  <Td>
    <StyledUserResolvableCell isResolvable={isResolvable}>
      { isResolvable ? 'Yes' : 'No' }
    </StyledUserResolvableCell>
  </Td>
);

export default UserResolvableCell;
