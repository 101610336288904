import { useState, useCallback } from 'react';
import { IApiRequestOptions, IFilters } from './types';
import { useApiRequest, usePaginationCallbacks, useFilterCallbacks } from './hooks';

export const useApiFetchAll = <TPayload = any, TResponse = any>(
  requestInfo: IApiRequestOptions,
) => {
  const [finished, setFinished] = useState(false);
  const [allData, setAllData] = useState<TResponse[] | null>(null);
  const { fetch, _pagination, _filters } = useApiRequest({
    ...requestInfo,
    defaultPagination: { itemsPerPage: 500 },
    defaultSort: [{ key: 'id', direction: 'asc' }],
  });

  const { setFilters } = useFilterCallbacks(_filters, fetch, _pagination);
  const { nextPage, canNextPage } = usePaginationCallbacks(_pagination, fetch);
    
  const fetchAll = useCallback(async (filters?: IFilters) => {
    if (filters) {
      setFilters(Object.keys(filters).map((f) => ({ key: f, value: filters[f] })), true);
    }

    const newData = await fetch();
    setAllData((currentData) => [...(currentData ?? []), ...newData[0]]);

    if (canNextPage()) {
      nextPage(true);
      await fetchAll();
    } else {
      setFinished(true);
    }
  }, [canNextPage, fetch, nextPage, setFilters]);

  const reset = useCallback(() => setAllData(null), [setAllData]);

  return {
    start: fetchAll,
    data: finished ? allData : null,
    reset,
  };
};
