import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type DepartmentFilterKey = 'department' | 'userDepartment'

const DepartmentFilter: FunctionComponent<SelectFilterProps<DepartmentFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label="Department"
      options={options}
      placeholder="Department"
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default DepartmentFilter;
