import React, { FunctionComponent } from 'react';
import { Modal } from 'semantic-ui-react';
import { ListAccountsModalHeader } from './ListAccountsModalHeader';
import { ListAccounts } from './ListAccounts';
import { IUserAccount } from '../../../../types/resources';
export interface IListAccountsModalProps {
  isOpen: boolean;
  accounts: IUserAccount[] | null;
  loginToAccount: (account: IUserAccount) => void;
  onCancel?: () => void;
}

export const ListAccountsModal: FunctionComponent<IListAccountsModalProps> = ({
  isOpen,
  accounts,
  loginToAccount,
  onCancel,
}) => (
  <Modal open={isOpen} onClose={onCancel} closeIcon={!!onCancel}>
    <ListAccountsModalHeader />
    <Modal.Content>
      <ListAccounts accounts={accounts} loginToAccount={loginToAccount} />
    </Modal.Content>
  </Modal>
);
