import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Tooltip from "../../../../common/Tooltip/Tooltip";
import { UserProfileFieldProps } from "./types";

const StyledUserProfileField = styled.div`
  margin-right: 16px;
  min-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledLabel = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
`;

const StyledValue = styled.div`
  color: ${({ color, theme }) => color ? color : theme.MODAL.BLACK_ACCENT};
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserProfileField: FunctionComponent<UserProfileFieldProps> = ({ color, label, value }): ReactElement => {
  const text = value || '-'
  return (
    <StyledUserProfileField>
      <StyledLabel>{label}</StyledLabel>
      { value ? (
        <Tooltip text={text}>
          <StyledValue color={color}>{text}</StyledValue>
        </Tooltip>
      ) : (
        <StyledValue color={color}>{text}</StyledValue>
      )}
    </StyledUserProfileField>
  );
};

export default UserProfileField;
