import React, { useState, FunctionComponent, ReactElement } from "react";
import { isArray } from "lodash";
import { FilterItem, Filters } from "../../../common/Filters/Filters";
import { filterOptions, getQuickFilterValues, getSelectOptions } from "../../../common/Filters/utils";
import AssessmentTypeFilter from "../../../common/Filters/AssessmentTypeFilter";
import RiskFilter from "../../../common/Filters/RiskFilter";
import ClearAllFilters from "../../../common/Filters/ClearAllFilters";
import LocationFilter from "../../../common/Filters/LocationFilter";
import DepartmentFilter from "../../../common/Filters/DepartmentFilter";
import ArchivedFilter from "../../../common/Filters/ArchivedFilter";
import QuickFilters from "../../../common/Filters/QuickFilters";
import { ChangeFilters, FiltersProps } from "../../../common/Filters/types";
import { useApiRequest, useFilterCallbacks } from "../../../utils/api/hooks";
import { Option } from "../../../common/Forms/types";
import IssueStatusFilter from "../../../common/Filters/IssueStatusFilter";
import IssueCategoryFilter from "../../../common/Filters/IssueCategoryFilter";
import ResolvableFilter from "../../../common/Filters/ResolvableFilter";

const IssueFilters: FunctionComponent<FiltersProps> = ({ config, quickFiltersConfig, filters, onChange, onClear }): ReactElement => {
  const { fetch: fetchAutoFilterEndpoint, _filters: _quickFilters } = useApiRequest({ uri: 'question_response_issues/requiring_action' });
  const { clearFilters: clearQuickFilters } = useFilterCallbacks(_quickFilters, fetchAutoFilterEndpoint);

  const issueStatusOptions = getSelectOptions(config, 'active');
  const issueStatus = filterOptions(issueStatusOptions, filters.current.active);
  const issueCategoryOptions = getSelectOptions(config, 'issueCategoryId');
  const issueCategory = filterOptions(issueCategoryOptions, filters.current.issueCategoryId);
  const resolvableByUserOptions = getSelectOptions(config, 'resolvableByUser');
  const resolvableByUser = filterOptions(resolvableByUserOptions, filters.current.resolvableByUser);
  const departmentOptions = getSelectOptions(config, 'userDepartment');
  const department = filterOptions(departmentOptions, filters.current.userDepartment);
  const riskFactorOptions = getSelectOptions(config, 'riskFactor');
  const riskFactor = filterOptions(riskFactorOptions, filters.current.riskFactor);
  const locationOptions = getSelectOptions(config, 'userLocation');
  const location = filterOptions(locationOptions, filters.current.userLocation);
  const assessmentTypeOptions = getSelectOptions(config, 'surveyTemplateId');
  const assessmentType = filterOptions(assessmentTypeOptions, filters.current.surveyTemplateId);
  const archived = isArray(filters.current.userArchived) && filters.current.userArchived.includes(true);
  const [quickFilter, setQuickFilter] = useState<Option[] | null>(null);

  const handleQuickFilterChange = async (option: Option[] | null, filters: ChangeFilters[]) => {
    await fetchAutoFilterEndpoint();
    const quickFilters = getQuickFilterValues(_quickFilters.current);
    clearQuickFilters(undefined, true);
    await onClear(undefined, true);
    await onChange([...filters, ...quickFilters]);
    setQuickFilter(option);
  };

  const handleOnClear = () => {
    setQuickFilter(null);
    onClear();
  };

  return (
    <Filters columns={5}>
      <FilterItem>
        <IssueStatusFilter filterKey="active" value={issueStatus} options={issueStatusOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <IssueCategoryFilter filterKey="issueCategoryId" value={issueCategory} options={issueCategoryOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <ResolvableFilter filterKey="resolvableByUser" value={resolvableByUser} options={resolvableByUserOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <DepartmentFilter filterKey='userDepartment' value={department} options={departmentOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <RiskFilter filterKey="riskFactor" value={riskFactor} options={riskFactorOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <LocationFilter filterKey='userLocation' value={location} options={locationOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <AssessmentTypeFilter filterKey="surveyTemplateId" value={assessmentType} options={assessmentTypeOptions} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <ArchivedFilter filterKey='userArchived' value={archived} onChange={onChange} />
      </FilterItem>
      <FilterItem>
        <QuickFilters config={quickFiltersConfig} onChange={handleQuickFilterChange} value={quickFilter} />
      </FilterItem>
      <FilterItem>
        <ClearAllFilters onClear={handleOnClear} />
      </FilterItem>
    </Filters>
  );
};

export default IssueFilters;
