import React, { createContext, useContext, MutableRefObject, FunctionComponent, useMemo } from 'react';
import { useSortByCallbacks } from '../../../utils/api/hooks';
import { ISortByItem, ISortByCallbacks } from '../../../utils/api/types';
import { useTableApiInfo } from './TableApiInfoContext';
import { useTableFlags } from './TableFlagsContext';

export interface ITableSortByProps {
  sort?: MutableRefObject<ISortByItem[]>;
}

const defaultSortCallbacks: ISortByCallbacks = {
  sortBy: () => Promise.resolve(void 0),
};

export const TableSortByContext = createContext<ISortByCallbacks & ITableSortByProps>(defaultSortCallbacks);

export const TableSortByProvider: FunctionComponent<ITableSortByProps> = ({ sort, children }) => {
  const { fetch } = useTableApiInfo();
  const { sortable } = useTableFlags();
  const sortByCallbacks = useSortByCallbacks(sort ?? { current: [] }, fetch);
  const value = useMemo(() => sortable ? ({
    ...sortByCallbacks,
    sort,
  }) : defaultSortCallbacks, [sort, sortByCallbacks, sortable]);

  return (
    <TableSortByContext.Provider value={value}>
      {children}
    </TableSortByContext.Provider>
  )
};

export const useTableSortBy = () => useContext(TableSortByContext);
