import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../../../common/Forms/Checkbox"
import useFormField from "../../../../common/Forms/useFormField";
import { useModal } from "../../../../common/Modal/hooks";
import SamlIdentityProviderForm from "../../../../forms/SamlIdentityProviderForm/SamlIdentityProviderForm";
import { blankSsoConfig } from "../../../../forms/SamlIdentityProviderForm/utils";
import { ILoginMethodsConfig, ISamlIdentityProvider } from "../../../../types/resources";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useUpdateLoginMethodsApi } from "../../../../utils/api/options/settings/adminLoginMethods";
import { IApiError409 } from "../../../../utils/api/types";
import { getApiUrl } from "../../../../utils/url";
import { useSession } from "../../../auth/AuthContext/SessionContext";
import AccounSettingsErrorModal from "../../modals/AccountSettingsErrorModal/AccountSettingsErrorModal";
import { LoginMethodsTabProps } from "../types";
import AccountSettingsTab from "./AccountSettingsTab";
import { SaveButton, StyledCheckbox } from "./Common";
import SettingsCard from "./SettingsCard";
import { getLoginMethodsConfigFormData } from "./utils";

const SsoInfo = styled.div `
  display: flex;
  width: 80%;
  padding-bottom: 24px;
`
const SsoInfoText = styled.div `
  margin-inline: 24px;
`

const StyledHeader = styled.h2 `
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  margin-left: 12px;
  margin-bottom: 24px;
`

const LoginMethodsTab: FunctionComponent<LoginMethodsTabProps> = ({ accountEnabledFeatures, initialValues, onSave }): ReactElement => {
  const { currentUser } = useSession();
  const adminSamlFeatureAvailable = accountEnabledFeatures.includes('admin.authentication.sso.saml');
  const assessmentSamlFeatureAvailable = accountEnabledFeatures.includes('assessment.authentication.sso.saml');
  const [adminSsoEnabled, setAdminSsoEnabled, clearAdminSsoEnabled, validateAdminSsoEnabled, adminSsoEnabledError, adminSsoEnabledIsDirty] = useFormField<boolean>(initialValues.adminSamlSso.enabled); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [assessmentSsoEnabled, setAssessmentSsoEnabled, clearAssessmentSsoEnabled, validateAssessmentSsoEnabled, assessmentSsoEnabledError, assessmentSsoEnabledIsDirty] = useFormField<boolean>(initialValues.assessmentSamlSso.enabled); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [adminEmailAndPasswordEnabled, setAdminEmailAndPasswordEnabled, clearAdminEmailAndPasswordEnabled, validateAdminEmailAndPasswordEnabled, adminEmailAndPasswordEnabledErrory, adminEmailAndPasswordEnabledIsDirty] = useFormField<boolean>(initialValues.adminEmailAndPassword.enabled); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [assessmentEmailedTokensEnabled, setAssessmentEmailedTokensEnabled, clearAssessmentEmailedTokensEnabled, validateAssessmentEmailedTokensEnabled, assessmentEmailedTokensEnabledError, assessmentEmailedTokensEnabledIsDirty] = useFormField<boolean>(initialValues.assessmentEmailedTokens.enabled); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [configValid, setConfigValid] = useState(true);

  const setAdminSsoEnabledCallback = () => {
    if (adminSsoEnabled)
    {
      setAdminSsoEnabled(false);
      setFormValue({ ...formValue, adminSamlSso: { ...formValue.adminSamlSso, enabled: false, config: false } })
    } else {
      setAdminSsoEnabled(true);
      setFormValue({ ...formValue, adminSamlSso: { ...formValue.adminSamlSso } })
    }
  }

  const setAdminEmailAndPasswordEnabledCallback = () => {
    const enabled = !adminEmailAndPasswordEnabled
    setAdminEmailAndPasswordEnabled(enabled);
    setFormValue({ ...formValue, adminEmailAndPassword: { ...formValue.adminEmailAndPassword, enabled: enabled } })
  }

  const setAssessmentSsoEnabledCallback = () => {
    if (assessmentSsoEnabled)
    {
      setAssessmentSsoEnabled(false);
      setFormValue({ ...formValue, assessmentSamlSso: { ...formValue.assessmentSamlSso, enabled: false, config: false } })
    } else {
      setAssessmentSsoEnabled(true);
      setFormValue({ ...formValue, assessmentSamlSso: { ...formValue.assessmentSamlSso } })
    }
  }

  const setAssessmentEmailedTokensEnabledCallback = () => {
    const enabled = !assessmentEmailedTokensEnabled
    setAssessmentEmailedTokensEnabled(enabled);
    setFormValue({ ...formValue, assessmentEmailedTokens: { ...formValue.assessmentEmailedTokens, enabled: enabled } })
  }

  const [formValue, setFormValue] = useState<ILoginMethodsConfig>(initialValues);
  const canDisableAdminEmailAndPassword = adminSsoEnabled && formValue.adminSamlSso.enabled && formValue.adminSamlSso.config.validated;
  const canDisableAssessmentEmailTokens = assessmentSsoEnabled && formValue.assessmentSamlSso.enabled && formValue.assessmentSamlSso.config.validated;

  const [isDirty, setDirty] = useState(false);
  const onAdminSsoDirty = useCallback((adminSamlSsoConfig: ISamlIdentityProvider) => {
    if (adminSsoEnabled) {
      setFormValue({ ...formValue, adminSamlSso: { ...formValue.adminSamlSso, enabled: true, config: adminSamlSsoConfig } });
      setDirty(true);
    }
  }, [setFormValue, setDirty, formValue, adminSsoEnabled])

  const onAssessmentSsoDirty = useCallback((assessmentSamlSso: ISamlIdentityProvider) => {
    if (assessmentSsoEnabled) {
      setFormValue({ ...formValue, assessmentSamlSso: { ...formValue.assessmentSamlSso, enabled: true, config: assessmentSamlSso } });
      setDirty(true);
    }
  }, [setFormValue, setDirty, formValue, assessmentSsoEnabled])

  useEffect(() => {
    if (assessmentSsoEnabledIsDirty || adminSsoEnabledIsDirty || adminEmailAndPasswordEnabledIsDirty || assessmentEmailedTokensEnabledIsDirty) {
      setDirty(true);
    }
  }, [assessmentSsoEnabledIsDirty, adminSsoEnabledIsDirty, adminEmailAndPasswordEnabledIsDirty, assessmentEmailedTokensEnabledIsDirty])

  const { close: closeModal, data: modalData, isOpen: isModalOpen, open: openModal } = useModal<string>();
  const updateLoginMethodsApi = useUpdateLoginMethodsApi();
  const { fetch: updateLoginMethods } = useApiRequest(updateLoginMethodsApi);
  const onSubmit = async () => {
    const submitData = getLoginMethodsConfigFormData(formValue);
    const [result, error] = await updateLoginMethods(submitData); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      const errorMsg = (error[0] as IApiError409).detail
      openModal(errorMsg)
    }
    else {
      onSave()
    }
  }

  return (
    <AccountSettingsTab title="Authentication methods">
      <StyledHeader>Admin console</StyledHeader>
      <SettingsCard title="Email and password">
      <StyledCheckbox>
          <Checkbox
            id="enable-email-and-password"
            disabled={!canDisableAdminEmailAndPassword}
            value={adminEmailAndPasswordEnabled}
            onChange={setAdminEmailAndPasswordEnabledCallback}
            label="Enable"
          />
        </StyledCheckbox>
      </SettingsCard>
      <SettingsCard title="SAML SSO">
      { !adminSamlFeatureAvailable ? (
          <SsoInfo>
              <SsoInfoText>
                <p><strong>SAML SSO for the admin console is not enabled on your account.</strong> </p>
                <p>Please contact <a href="mailto:support@ergofy.co.uk?Subject=Enabling Admin SAML SSO" target="_blank" rel="noopener noreferrer">Ergofy support</a> if you would like to have SSO enabled.</p>
              </SsoInfoText>
            </SsoInfo> 
        ) : (
          <>
            <StyledCheckbox>
              <Checkbox
                disabled={!adminSamlFeatureAvailable || !adminEmailAndPasswordEnabled}
                id="enable-admin-saml-sso"
                value={adminSsoEnabled}
                onChange={setAdminSsoEnabledCallback}
                label="Enable"
              />
            </StyledCheckbox>
            { adminSsoEnabled && <SamlIdentityProviderForm onDirty={onAdminSsoDirty} setIsValid={setConfigValid} initialValues={initialValues.adminSamlSso.config || blankSsoConfig} locked={!adminEmailAndPasswordEnabled} showJitProvisioning={true} /> }
          </>
        ) }        
      </SettingsCard>

      <StyledHeader>Assessments</StyledHeader>

      <SettingsCard title="Emailed magic links">
      <StyledCheckbox>
          <Checkbox
            id="enable-magic-links"
            disabled={!canDisableAssessmentEmailTokens}
            value={assessmentEmailedTokensEnabled}
            onChange={setAssessmentEmailedTokensEnabledCallback}
            label="Enable"
          />
        </StyledCheckbox>
      </SettingsCard>
      <SettingsCard title="SAML SSO">
      { !assessmentSamlFeatureAvailable ? (
            <SsoInfo>
              <SsoInfoText>
                <p><strong>SAML SSO for assessments is not enabled on your account.</strong> </p>
                <p>Please contact <a href="mailto:support@ergofy.co.uk?Subject=Enabling Assessment SAML SSO" target="_blank" rel="noopener noreferrer">Ergofy support</a> if you would like to have SSO enabled.</p>
              </SsoInfoText>
            </SsoInfo> 
            ) : (
            <>
              <StyledCheckbox>
                <Checkbox
                  disabled={!assessmentSamlFeatureAvailable || !assessmentEmailedTokensEnabled}
                  id="enable-assessment-saml-sso"
                  value={assessmentSsoEnabled}
                  onChange={setAssessmentSsoEnabledCallback}
                  label="Enable"
                />          
              </StyledCheckbox>

              { assessmentSsoEnabled && <SamlIdentityProviderForm onDirty={onAssessmentSsoDirty} setIsValid={setConfigValid} initialValues={initialValues.assessmentSamlSso.config || blankSsoConfig} locked={!assessmentEmailedTokensEnabled} showJitProvisioning={false} testLoginUrl={`${getApiUrl('survey')}/test_saml_sso?user_id=${currentUser?.userId}`} /> }
            </>
          )
        }
      </SettingsCard>
      <SaveButton disabled={!isDirty || !configValid} onClick={onSubmit} />
      {<AccounSettingsErrorModal error={modalData} isOpen={isModalOpen} onOk={closeModal} />}
    </AccountSettingsTab>
  )
};

export default LoginMethodsTab;
