import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';

import { MultiSelectFilter } from './Multiselect';
import { SelectFilter } from './Select';
import { TextFilter } from './Text';

import { IFilterValue } from '../../../../utils/api/types';
import { useTableFilter } from '../../Context/TableFilterContext';
import { IFilterConfig } from '../../Context/TableFilterConfigContext';

export interface IFilterProps {
  item: IFilterConfig;
}

const FilterWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Filter: FunctionComponent<IFilterProps> = ({ item }) => {
  const { filter, setFilters, clearFilters } = useTableFilter();

  const handleChange = useCallback((e: unknown, { value }) => {
    if (typeof value === 'string' && (!value || !value.length)) {
      clearFilters(item.key);
    } else {
      setFilters({ key: item.key, value });
    }
  }, [clearFilters, item.key, setFilters]);

  if (item.type === 'multiSelect') {
    return (
      <FilterWrapper>
        <MultiSelectFilter
          item={item}
          onChange={handleChange}
          value={(filter?.current[item.key] ?? []) as IFilterValue[]}
        />
      </FilterWrapper>
    );
  } else if (item.type === 'select') {
    return (
      <FilterWrapper>
        <SelectFilter
          item={item}
          onChange={handleChange}
          value={(filter?.current[item.key] ?? '') as IFilterValue}
        />
      </FilterWrapper>
    );
  } else {
    return (
      <FilterWrapper>
        <TextFilter
          item={item}
          onChange={handleChange}
          value={(filter?.current[item.key] ?? '') as IFilterValue}
        />
      </FilterWrapper>
    );
  }
};
