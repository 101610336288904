import React, { FunctionComponent, ReactElement } from "react";
import styled, { keyframes } from "styled-components";
import spinnerImg from "./spinner.png";

const rotate = keyframes`
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
`;

const StyledSpinner = styled.img`
  animation: ${rotate} 1s linear infinite;
  width: 45px;
  height: 45px;
`;

const Spinner: FunctionComponent = (): ReactElement => {
  return (
    <StyledSpinner src={spinnerImg} />
  );
};

export default Spinner;
