import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { ContactsTabProps } from "../types";
import AccountSettingsTab from "./AccountSettingsTab";
import { SaveButton } from "./Common";
import SettingsCard from "./SettingsCard";
import { useUpdateContactsApi } from "../../../../utils/api/options/settings/contacts";
import { useApiRequest } from "../../../../utils/api/hooks";
import { IContact } from "../../../../types/resources";
import { isContactsFormDirty } from "./utils";
import SettingsContactForm from "../../../../forms/SettingsContactForm/SettingsContactForm";

const ContactsTab: FunctionComponent<ContactsTabProps> = ({ initialValues, onChange, onSave }): ReactElement => {
  const initialErrors = initialValues.map(contact => [contact.contactRole.name, false])
  const initialFormValues = initialValues.map(contact => [contact.contactRole.name, contact])
  const [errors, setErrors] = useState<{ [key: string]: boolean}>(Object.fromEntries(initialErrors))
  const [formValues, setFormValues] = useState<{ [key: string]: IContact}>(Object.fromEntries(initialFormValues))

  const updateContactsApi = useUpdateContactsApi();
  const { fetch: updateContacts } = useApiRequest<IContact[]>(updateContactsApi);

  const isDirty = useMemo(() => isContactsFormDirty(initialValues, Object.values(formValues)), [formValues, initialValues])
  const isFormValid = useMemo(() => { return !(Object.values(errors).indexOf(true) > -1) }, [errors])

  const updateValues = useCallback((formKey: string, name: string, emailAddress: string) => {
    setFormValues((prevFormValues)  => {
      return {
        ...prevFormValues,
        [formKey]: {
          ...prevFormValues[formKey],
          name,
          emailAddress
        }
      }
    })
  }, [])

  const updateErrors = useCallback((formKey: string, isValid: boolean) => {
    setErrors((prevErrors)  => ({
      ...prevErrors,
      [formKey]: isValid
    }))
  }, [])

  useEffect(() => {
    onChange(isDirty)
  }, [isDirty, onChange])

  const onSubmit = async () => {
    if (isFormValid) {
      const [result, error] = await updateContacts(Object.values(formValues)); // eslint-disable-line @typescript-eslint/no-unused-vars

      if(!error) {
        onSave()
      }
    }
  }

  return (
    <AccountSettingsTab title="Contacts">
      {
        initialValues.map((contact, index) => {
          return (
            <SettingsCard title={contact.contactRole.name} key={index}>
              <SettingsContactForm initialValues={contact} onChange={updateValues} onValidate={updateErrors} />
            </SettingsCard>
          )
        })
      }
      <SaveButton disabled={!isDirty} onClick={onSubmit} />
    </AccountSettingsTab>
  );
};

export default ContactsTab;
