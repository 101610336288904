import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import IssueForm from "../../../../forms/IssueForm/IssueForm";
import { IssueFormWrapperProps } from "./types";

const StyledIssueFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  padding: 30px 30px 50px;
`;

const IssueFormWrapper: FunctionComponent<IssueFormWrapperProps> = ({ issues, initialValues, onCancel, onOk }): ReactElement => {
  return (
    <StyledIssueFormWrapper>
      <IssueForm
        cancelLabel="No, thanks"
        initialValues={initialValues}
        issues={issues}
        onCancel={onCancel}
        onSubmit={onOk}
        submitLabel="Update"
      />
    </StyledIssueFormWrapper>
  );
};

export default IssueFormWrapper;
