import React, { FunctionComponent, ReactElement } from "react";
import Select from "../Forms/Select";
import { SelectFilterProps } from "./types";
import { optionToFilterValue } from "./utils";

type RiskFilterKey = "currentRisk" | "riskFactor"

const labels: {[key in RiskFilterKey]: string} = {
  currentRisk: 'Current risk',
  riskFactor: 'Risk level'
};

const placeholders: {[key in RiskFilterKey]: string} = {
  currentRisk: 'Current risk',
  riskFactor: 'All levels'
};

const RiskFilter: FunctionComponent<SelectFilterProps<RiskFilterKey>> = ({ filterKey, value, options, onChange }): ReactElement => {
  return (
    <Select
      value={value}
      label={labels[filterKey]}
      options={options}
      placeholder={placeholders[filterKey]}
      onChange={(selectedOptions) => onChange(optionToFilterValue(selectedOptions, filterKey, true))}
      isMulti
      isClearable
    />
  );
};

export default RiskFilter;
