import { STEP_TITLE_MAP_COMMON } from '../../../../common/Modal/utils';
import { ISurveyTemplate, IUser } from '../../../../types/resources';
import { InviteUserToAssessmentPayload } from '../../../../utils/api/options/assessments/types';
import { InviteUsersModalStep } from "./types";

const STEP_TITLE_MAP_SINGLE: { [key in InviteUsersModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  selectAssessmentType: 'Invite user',
  confirm: 'Invite user',
  processing: 'Invite user',
};

const STEP_TITLE_MAP_NEW_USER: { [key in InviteUsersModalStep]: string } = {
  ...STEP_TITLE_MAP_SINGLE,
  selectAssessmentType: 'Successful',
};

const STEP_TITLE_MAP_MULTI: { [key in InviteUsersModalStep]: string } = {
  ...STEP_TITLE_MAP_COMMON,
  selectAssessmentType: 'Invite users',
  confirm: 'Invite users',
  processing: 'Invite users',
};

export function getModalTitle(step: InviteUsersModalStep, multi: boolean, newUser?: boolean): string {
  if (newUser) {
    return STEP_TITLE_MAP_NEW_USER[step];
  } else if (multi) {
    return STEP_TITLE_MAP_MULTI[step];
  }
  return STEP_TITLE_MAP_SINGLE[step];
}

export function getInviteUserToAssessmentPayload(users: IUser[], surveyTemplate: ISurveyTemplate): InviteUserToAssessmentPayload[] {
  return users.map((user) => ({
    surveyTemplate: {
      id: surveyTemplate.id,
    },
    user: {
      id: user.id,
    },
  }));
}
