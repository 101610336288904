import React, { createContext, FunctionComponent, useContext } from 'react';
import { ICreateUserWithId } from '../../types';

export const UserUploadDataContext = createContext<[
  ICreateUserWithId[],
  (users: ICreateUserWithId[]) => void,
  string[],
  boolean,
]>([[], () => void 0, [], true]);

export interface IUserUploadDataProviderProps {
  users: ICreateUserWithId[];
  setUsers: (users: ICreateUserWithId[]) => void;
  duplicatedEmails: string[];
  isFileValid: boolean;
}

export const UserUploadDataProvider: FunctionComponent<IUserUploadDataProviderProps> = ({ users, setUsers, children, duplicatedEmails, isFileValid }) => (
  <UserUploadDataContext.Provider value={[users, setUsers, duplicatedEmails, isFileValid]}>
    {children}
  </UserUploadDataContext.Provider>
);

export const useUserUploadData = () => useContext(UserUploadDataContext);
