import { useApiRequestOptions } from "../hooks";

export const useSendEmailApi = () => useApiRequestOptions({
  uri: 'outbound_emails',
  method: 'post',
  passBackErrorStatus: [422],
  successMessage: null, // disabled because it might get sent in a loop
  serialization: {
    type: 'outboundEmail',
    options: {
      attributes: ['subject', 'message', 'recipient', 'relatedItem'],
      typeForAttribute: (attribute: string, record: any) => record?.type,
      recipient: {
        ref: 'id',
        included: false,
      },
      relatedItem: {
        ref: 'id',
        included: false,
      },
    },
  },
}, []);
