import React, { FunctionComponent, useCallback } from 'react';
import { Field as FinalFormField } from 'react-final-form';
import {
  Form,
  FormInputProps,
  FormCheckboxProps,
  FormDropdownProps,
  FormSelectProps,
  FormRadioProps,
  FormTextAreaProps
} from 'semantic-ui-react';

import { useFormValidators } from '../../../utils/form/validation';

export type IFormFieldValue = string | number | boolean;
export type IFormFieldType = 'input' | 'checkbox' | 'dropdown' | 'select' | 'radio' | 'textArea';

export type IFormFieldValidationKey =
  'required' |
  'email' |
  'isUnique' |
  'lengthBetween' |
  'oneOf' |
  'valueBetween' |
  'isType' |
  'matches' |
  'noTrailingSpaces' |
  'hasLowerCase' |
  'hasUpperCase' |
  'hasNumericCharacter' |
  'hasSpecialCharacter' |
  'maxConsecutiveCharacters';

export interface IFormFieldValidationAttributesByKey {
  'required': null;
  'email': null;
  'isUnique': string[];
  'lengthBetween': [number | null, number | null];
  'valueBetween': [number | null, number | null];
  'oneOf': IFormFieldValue[];
  'isType': 'string' | 'number' | 'boolean';
  'matches': { key: string; text: string };
  'noTrailingSpaces': null;
  'hasLowerCase': null;
  'hasUpperCase': null;
  'hasNumericCharacter': null;
  'hasSpecialCharacter': null;
  'maxConsecutiveCharacters': number;
}

export interface IFormFieldValidation<TFormFieldValidationKey extends IFormFieldValidationKey = IFormFieldValidationKey> {
  key: TFormFieldValidationKey;
  attributes: IFormFieldValidationAttributesByKey[TFormFieldValidationKey];
}

export interface IFormInput {
  key: string;
  type: IFormFieldType;
  validation?: IFormFieldValidation[];
  inputProps?: FormInputProps | FormCheckboxProps | FormDropdownProps | FormSelectProps | FormRadioProps | FormTextAreaProps;
}

export interface IFormFieldProps {
  segmentKey: string;
  config: IFormInput;
}

const inputMap: { [key in IFormFieldType]: any } = {
  input: Form.Input,
  checkbox: Form.Checkbox,
  dropdown: Form.Dropdown,
  select: Form.Select,
  radio: Form.Radio,
  textArea: Form.TextArea,
};

export const FormField: FunctionComponent<IFormFieldProps> = ({ segmentKey, config }) => {
  const Input = inputMap[config.type];
  const validator = useFormValidators(config.validation);

  const handleInputChange = useCallback((onChange: (e: { target: { value: IFormFieldValue } }) => void) => 
    (e, { value, checked }) =>  {
      if (config.type === 'checkbox') {
        onChange({ target: { value: checked } });
      } else {
        onChange({ target: { value: value && config.inputProps?.type === 'number' ? +value : value } });
      }
  }, [config.inputProps, config.type])

  const valueWrapper = useCallback((value?: IFormFieldValue) => config.type === 'checkbox' ? undefined : value, [config.type]);
  const checkedWrapper = useCallback((value?: IFormFieldValue) => config.type === 'checkbox' ? !!value : undefined, [config.type]);

  return (
    <FinalFormField
      name={`${segmentKey}.${config.key}`}
      validate={validator}
    >
      {({ input, meta }) => (
        <Input
          {...input}
          {...config.inputProps ?? {}}
          onChange={handleInputChange(input.onChange)}
          value={valueWrapper(input.value)}
          checked={checkedWrapper(input.value)}
          error={meta.touched && meta.error}
        />
      )}
    </FinalFormField>
  );
};
