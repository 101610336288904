import React, { createContext, useContext, ReactNode, ReactElement } from 'react';

export interface ITableDataProps<TData> {
  data: TData[] | null;
}

export const TableDataContext = createContext<any>(null);

export const TableDataProvider = <TData extends { id: string }>(
  { data, children }: ITableDataProps<TData> & { children: ReactNode }
): ReactElement => (
  <TableDataContext.Provider value={data}>
    {children}
  </TableDataContext.Provider>
);

export const useTableData = () => useContext(TableDataContext);
