import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import Box, { StyledBox } from "../Box/Box";
import { AccordionMenuContext  } from "./AccordionMenuContext";
import { AccordionMenuProps } from "./types";

const StyledAccordionMenu = styled.div`
  width: 280px;

  ${StyledBox} {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 90px;
  }
`

const StyledHeading = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.ACCORDION_MENU.BORDER};
  display: flex;
  flex: 0 0 90px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
  width: 100%;
`;

const AccordionMenu = <TabType extends string>(props: AccordionMenuProps<TabType>): ReactElement<AccordionMenuProps<TabType>> => {
  const { activeTab, children, heading, onTabChange } = props;
  const [ expanded, setExpanded ] = useState<string[]>([])

  const toggle = (id: string) => {
    if (expanded.indexOf(id) > -1) {
      setExpanded(expanded.filter(expandedId => expandedId !== id))
    } else {
      setExpanded([...expanded, id])
    }
  }

  const contextValue = {
    activeTab: activeTab,
    expanded,
    onTabChange: (tab: TabType) => onTabChange(tab),
    toggle,
  }

  return (
    <AccordionMenuContext.Provider value={contextValue}>
      <StyledAccordionMenu>
        <Box shadow noPadding>
          <StyledHeading>{heading}</StyledHeading>
          {children}
        </Box>
      </StyledAccordionMenu>
    </AccordionMenuContext.Provider>
  );
};

export default AccordionMenu;
