import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';
import { IAccount } from '../../../types/resources';
import { useSession } from '../../auth/AuthContext/SessionContext';
import { loginToAccountUri } from '../../auth/LoginPage';

export const LoginToAdminButton: FunctionComponent<{ value: IAccount }> = ({ value }) => {
    const history = useHistory();
    const session = useSession();
    const loginToAccount = useCallback(async () => {
      if (session.state.type === 'loggedIn') {
        history.push(loginToAccountUri({ applicationKey: 'admin', userId: session.state.user.userId, accountId: +value.id }));
      }
    }, [history, session.state, value.id]);

    let props, text;

    if (value.enabled) {
        props = {  onClick: loginToAccount, color: 'green' };
        text = 'Login as Admin';
    } else {
        props = {  disabled: true };
        text = ' - Account Disabled -';
    }

    return (
        <Button {...props} >
            {text}
        </Button>
    );
};
