import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { LabelProps } from "./types";

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.FORMS.LABEL.COLOR};
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 2px 13px;
  &.disabled {
    color: ${({ theme }) => theme.FORMS.LABEL.DISABLED_COLOR};
  }
`;

const Label: FunctionComponent<LabelProps> = ({ disabled, required, label }): ReactElement => {
  const text = `${label}${required ? ' *' : ''}`;
  const className = disabled ? 'disabled' : '';

  return <StyledLabel className={className}>{text}</StyledLabel>;
};

export default Label;
