import { useCallback, useMemo } from 'react';
import { useFormValidators } from '../form/validation';
import { IFormFieldValidation } from '../../common/Form/FormField';
import { ICreateUser } from '../../types/resources';

export const useCreateUserValidator = (duplicatedEmails: string[]) => {
  const emailValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'required', attributes: null },
    { key: 'isType', attributes: 'string' },
    { key: 'email', attributes: null },
    { key: 'lengthBetween', attributes: [1, 60] },
    { key: 'isUnique', attributes: duplicatedEmails }
  ], [duplicatedEmails]);
  const firstNameValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'required', attributes: null },
    { key: 'isType', attributes: 'string' },
    { key: 'lengthBetween', attributes: [1, 50] },
  ], []);
  const lastNameValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'required', attributes: null },
    { key: 'isType', attributes: 'string' },
    { key: 'lengthBetween', attributes: [1, 50] },
  ], []);
  const jobTitleValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'isType', attributes: 'string' },
    { key: 'lengthBetween', attributes: [1, 100] },
  ], []);
  const departmentValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'isType', attributes: 'string' },
    { key: 'lengthBetween', attributes: [1, 100] },
  ], []);
  const locationValidation = useMemo<IFormFieldValidation[]>(() => [
    { key: 'isType', attributes: 'string' },
    { key: 'lengthBetween', attributes: [1, 100] },
  ], []);

  const emailValidator = useFormValidators(emailValidation);
  const firstNameValidator = useFormValidators(firstNameValidation);
  const lastNameValidator = useFormValidators(lastNameValidation);
  const jobTitleValidator = useFormValidators(jobTitleValidation);
  const departmentValidator = useFormValidators(departmentValidation);
  const locationValidator = useFormValidators(locationValidation);

  const checkCreateUser = useCallback(
    (user: ICreateUser) =>
      emailValidator(user.emailAddress, user) ||
      firstNameValidator(user.firstName, user) ||
      lastNameValidator(user.lastName, user) ||
      jobTitleValidator(user.jobTitle ?? undefined, user) ||
      departmentValidator(user.department ?? undefined, user) ||
      locationValidator(user.jobTitle ?? undefined, user),
    [departmentValidator, emailValidator, firstNameValidator, jobTitleValidator, lastNameValidator, locationValidator]
  );

  return checkCreateUser;
}
