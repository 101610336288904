import styled from "styled-components";
import { StyledButton } from "../../../../common/Buttons/Button";

export const StyledConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 36px 0 50px 0;

  ${StyledButton} {
    margin-right: 16px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;
