import { IIssue } from "../../../../types/resources";
import { IIssueUpdateRowProps } from "./types";

export const prepareUpdateRows = (issueData: IIssue | null) => {
  var allUpdates: IIssueUpdateRowProps[] = [];

  (issueData?.administratorNotes || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Added note',
      user: update.administrator,
      text: update.note,
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.administratorClosures || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Closed issue',
      user: update.administrator,
      text: update.note,
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.administratorReopenings || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Re-opened issue',
      user: update.administrator,
      text: update.note,
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.administratorUserArchivalClosures || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Archived user (issue closed)',
      user: update.administrator,
      text: 'Issue automatically closed on user archival',
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.accountPolicyAutoClosures || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Auto-closed',
      text: 'Issue automatically closed on based on account settings',
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.userGuidanceResolutions || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Issued closed',
      text: 'User marked issue resolved after viewing guidance',
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.userGuidanceAutoResolutions || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Issued closed',
      text: 'System automatically marked issue resolved after user viewed guidance',
      date: update.createdAt
    };
    allUpdates.push(note);
  });
  
  (issueData?.userGuidanceEscalations || []).forEach(update => {
    var note: IIssueUpdateRowProps = {
      id: update.id,
      action: 'Issued escalated',
      user: issueData?.user,
      text: 'User escalated issue after viewing guidance',
      date: update.createdAt
    };
    allUpdates.push(note);
  });

  return allUpdates;
}
