import React, { FunctionComponent } from 'react';

import { ExportDataButton } from '../../../../common/ExportDataButton';
import { useFetchAccountListApi } from '../../../../utils/api/options/accounts';
import { useAccountListExportOptions } from '../../../../utils/export/options/accountList';

export const ExportAccountsButton: FunctionComponent = () => {
  const fetchAccountsApi = useFetchAccountListApi();
  const exportOptions = useAccountListExportOptions();

  return (
    <ExportDataButton
      fileName="Account List"
      apiRequestOptions={fetchAccountsApi}
      exportOptions={exportOptions}
    />
  );
};
