import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';

import { ICreateUserUpload, IDataUpload, ICreateUser } from '../../../../../types/resources';

import { useUserUploadHasError } from '../contexts/UserUploadHasErrorContext';
import { useUserUploadData } from '../contexts/UserUploadDataContext';
import { useDataUploadUpdate } from '../../DataUploadsTable/contexts/DataUploadUpdateContext';

import { useApiRequest } from '../../../../../utils/api/hooks';
import { useDeleteDataUploads, useStartDataUpload } from '../../../../../utils/api/options/dataUploads';
import { useCreateUserUploadApi, useAddRowsToUserUploadApi } from '../../../../../utils/api/options/users';

import { omit } from 'lodash';
import { useUserUploadFileName } from '../contexts/UserUploadFileNameContext';

const BATCH_SIZE = 500;

export const SubmitUsersUpload: FunctionComponent = () => {
  const [users] = useUserUploadData();
  const [hasError] = useUserUploadHasError();
  const fileName = useUserUploadFileName();

  const [currentUserUploadId, setCurrentUserUploadId] = useState<string>('');

  const createUserUploadApi = useCreateUserUploadApi();
  const addUserUploadRowsApi = useAddRowsToUserUploadApi(currentUserUploadId);
  const startUserUploadApi = useStartDataUpload(currentUserUploadId);
  const deleteUserUploadApi = useDeleteDataUploads(currentUserUploadId);

  const { fetch: createUserUpload } = useApiRequest<ICreateUserUpload, IDataUpload>(createUserUploadApi);
  const { fetch: addUserUploadRows } = useApiRequest<ICreateUser[]>(addUserUploadRowsApi);
  const { fetch: startUserUpload } = useApiRequest(startUserUploadApi);
  const { fetch: deleteUserUpload } = useApiRequest(deleteUserUploadApi);

  const onDataUploadChange = useDataUploadUpdate();

  const handleSubmit = useCallback(async () => {
    const [createdUserUpload] = await createUserUpload({
      name: fileName,
      description: `Upload started ${new Date().toLocaleTimeString()}`,
      createNewUsers: true,
      updateExistingUsers: true,
    });

    if (createdUserUpload) {
      setCurrentUserUploadId(createdUserUpload.id);
    }
  }, [createUserUpload, fileName]);

  const uploadUserRows = useCallback(async (batchNo: number = 1) => {
    const result = await addUserUploadRows(
      users
        .map((user) => omit(user, 'id'))
        .filter((_, index) => index >= (batchNo - 1) * BATCH_SIZE && index < batchNo * BATCH_SIZE)
    );

    if (!result) {
      await deleteUserUpload();
    } else if (users.length >= batchNo * BATCH_SIZE) {
      await uploadUserRows(batchNo + 1);
    } else {
      await startUserUpload();
      onDataUploadChange();
    }

  }, [addUserUploadRows, deleteUserUpload, onDataUploadChange, startUserUpload, users]);

  useEffect(() => {
    if (currentUserUploadId) {
      uploadUserRows();
    }
  }, [currentUserUploadId, uploadUserRows]);

  return (
    <Button disabled={!users.length || hasError} color="green" onClick={handleSubmit}>Begin upload</Button>
  );
};
