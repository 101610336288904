import React, { useCallback, FunctionComponent } from "react";
import styled from "styled-components";
import Error from "./Error";
import Label from "./Label";
import { TextareaProps } from "./types";

export const StyledTextarea = styled.textarea<{ error: string | undefined}>`
  border: 1px solid ${({ theme }) => theme.FORMS.INPUT.BORDER_COLOR};
  border-radius: 10px;
  color: ${({ theme }) => theme.FORMS.INPUT.COLOR};
  font-family: ${({ theme }) => theme.FONT};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  outline: none;
  padding: 8px 12px 6px;
  margin-bottom: ${({ error }) => error ? '0' : '16px'};
  width: 100%;
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.FORMS.INPUT.PLACEHOLDER_COLOR};
  }
  &:focus {
    border-color: ${({ theme }) => theme.FORMS.INPUT.ACTIVE_BORDER_COLOR};
    box-shadow: 0px 4px 9px -3px ${({ theme }) => theme.FORMS.SHADOW};
  }
  &.has-error {
    border-color: ${({ theme }) => theme.FORMS.INPUT.ERRORED_BORDER_COLOR};
    box-shadow: none;
    color: ${({ theme }) => theme.FORMS.INPUT.ERRORED_COLOR};
  }
  &[disabled] {
    border-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BORDER};
    background-color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_BG};
    color: ${({ theme }) => theme.FORMS.INPUT.DISABLED_COLOR};
  }
`;

const StyledTextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InputRow = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const Textarea: FunctionComponent<TextareaProps> = (props) => {
  const { disabled, error, label, onChange, placeholder, rows, value } = props;

  const handleChange = useCallback((event) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <StyledTextareaWrapper>
      { label && <Label {...props} />}
      <InputRow>
        <StyledTextarea
          className={error ? 'has-error' : ''}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          rows={rows ?? 5}
        />
      </InputRow>
      { error && <Error msg={error} />}
    </StyledTextareaWrapper>
  );
};

export default Textarea;
