import React, { FunctionComponent, ReactElement, useMemo } from "react";
import Accordion from "../../../common/Accordion/Accordion";
import { QUESTION_RESPONSE_TYPE, SURVEY_QUESTION_TYPE } from "../../../types/enums";
import { IQuestionResponse } from "../../../types/resources";
import { categorizeAnswers } from "../../../utils/questionResponseIssue/questionCategories";
import AssessmentQACategory from "./AssessmentQACategory";
import { AssessmentQATableProps } from "./types";

const answersFilter = (answer: IQuestionResponse) =>
  answer.status === QUESTION_RESPONSE_TYPE.answered && answer.surveyQuestion.questionType === SURVEY_QUESTION_TYPE.user_input

const answersSort = (answer1: IQuestionResponse, answer2: IQuestionResponse) => answer1.surveySequence - answer2.surveySequence

const AssessmentQATable: FunctionComponent<AssessmentQATableProps> = ({ assessmentQuestionAnswers }): ReactElement => {
  const filteredAnswers = useMemo(() => (assessmentQuestionAnswers || []).filter(answersFilter), [assessmentQuestionAnswers]);
  const sortedAnswers = useMemo(() => [...filteredAnswers].sort(answersSort), [filteredAnswers]);
  const questionCategories = useMemo(() => categorizeAnswers(sortedAnswers), [sortedAnswers]);

  return (
    <Accordion>
      {questionCategories.map(questionCategory => <AssessmentQACategory key={questionCategory.id} questionCategory={questionCategory} />)}
    </Accordion>
  );
};

export default AssessmentQATable;
