import React, { useCallback, useEffect, useMemo, useState, FunctionComponent } from "react";
import Modal from "../../../../common/Modal/Modal";
import { canBeInvited, hasActiveAssessment } from "../../../../utils/user";
import { ISurveyTemplate } from "../../../../types/resources";
import { useSurveyTemplateListApi, useInviteUserToAssessmentApi } from '../../../../utils/api/options/assessments';
import { useApiRequest } from '../../../../utils/api/hooks';
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import { InviteUsersModalProps, InviteUsersModalStep } from "./types";
import Warning from "./Warning";
import SelectAssessmentType from "./SelectAssessmentType";
import Confirm from "./Confirm";
import { getModalTitle, getInviteUserToAssessmentPayload } from "./utils";

const InviteUsersModal: FunctionComponent<InviteUsersModalProps> = ({ isOpen, onCancel, onOk, users, newUser }) => {
  const [step, setStep] = useState<InviteUsersModalStep>('selectAssessmentType');
  const [survey, setSurvey] = useState<ISurveyTemplate>();

  const fetchSurveyTemplatesApi = useSurveyTemplateListApi();
  const inviteUserToAssessmentApi = useInviteUserToAssessmentApi();

  const { fetch: fetchSurveyTemplates, data: surveyTemplates } = useApiRequest<never, ISurveyTemplate[]>(fetchSurveyTemplatesApi);
  const { fetch: inviteUserToAssessment } = useApiRequest(inviteUserToAssessmentApi);

  useEffect(() => {
    fetchSurveyTemplates();
  }, [fetchSurveyTemplates]);

  useEffect(() => {
    const showWarning = users.filter(hasActiveAssessment).length > 0;
    setStep(showWarning ? 'warning' : 'selectAssessmentType');
  }, [users]);

  const filteredUsers = useMemo(() => users.filter(canBeInvited), [users]);
  const title = getModalTitle(step, filteredUsers.length > 1, newUser);

  const selectAssessmentType = (selectedSurvey: ISurveyTemplate) => {
    setSurvey(selectedSurvey);
    setStep('confirm');
  };

  const handleOnCancel = step === 'processing' ? undefined : onCancel;

  const invite = useCallback(async () => {
    if (!survey) {
      setStep('failure');
      return;
    }

    setStep('processing');
    const payload = getInviteUserToAssessmentPayload(filteredUsers, survey);
    const [result, error] = await inviteUserToAssessment(payload); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }, [filteredUsers, inviteUserToAssessment, survey]);

  const error = step === 'failure';
  const successMsg = `User${filteredUsers.length > 1 ? 's' : ''} successfuly invited.`;

  return (
    <Modal onCancel={handleOnCancel} isOpen={isOpen} title={title} step={step} error={error}>
      {step === 'warning' && <Warning onCancel={onCancel} onOk={() => setStep('selectAssessmentType')} />}
      {step === 'selectAssessmentType' && <SelectAssessmentType surveyTemplates={surveyTemplates || []} users={filteredUsers} onCancel={onCancel} onOk={selectAssessmentType} newUser={newUser} />}
      {step === 'confirm' && survey && <Confirm survey={survey} users={filteredUsers} onCancel={() => setStep('selectAssessmentType')} onOk={invite} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text={successMsg} />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default InviteUsersModal;
