import React, { useCallback, useMemo, useState, FunctionComponent, ReactElement } from "react";
import Modal from "../../../../common/Modal/Modal";
import { STEP_TITLE_MAP_COMMON } from "../../../../common/Modal/utils";
import { useApiRequest } from "../../../../utils/api/hooks";
import { useCancelAssessmentApi } from "../../../../utils/api/options/assessments";
import { canBeCancelled } from "../../../../utils/assessment";
import Failure from "../Common/Failure";
import Processing from "../Common/Processing";
import Success from "../Common/Success";
import Confirm from "./Confirm";
import { CancelAssessmentsModalProps, CancelAssessmentsModalStep } from "./types";

const MODAL_TITLES: {[ key in CancelAssessmentsModalStep]: string} = {
  ...STEP_TITLE_MAP_COMMON,
  confirm: 'Cancel assessments',
  processing: 'Canceling assessments'
}

const CancelAssessmentsModal: FunctionComponent<CancelAssessmentsModalProps> = ({ assessments, isOpen, onCancel, onOk }): ReactElement => {
  const [step, setStep] = useState<CancelAssessmentsModalStep>('confirm');
  const cancelAssessmentApi = useCancelAssessmentApi();
  const { fetch: cancelAssessment } = useApiRequest(cancelAssessmentApi);
  const filteredAssessments = useMemo(() => assessments.filter(canBeCancelled), [assessments]);

  const handleCancel = useMemo(() => {
    switch(step) {
      case 'processing': return undefined;
      case 'success': return onOk;
      default: return onCancel;
    }
  }, [onCancel, onOk, step])

  const sendReminder = useCallback(async () => {
    setStep('processing');
    const payload = filteredAssessments.map(assessments => ({ id: assessments.id }))
    const [result, error] = await cancelAssessment(payload); // eslint-disable-line @typescript-eslint/no-unused-vars

    if (error) {
      setStep('failure');
      return;
    }

    setStep('success');
  }, [filteredAssessments, cancelAssessment]);

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} title={MODAL_TITLES[step]}>
      {step === 'confirm' && <Confirm assessments={assessments} onCancel={onCancel} onOk={sendReminder} />}
      {step === 'processing' && <Processing />}
      {step === 'success' && <Success onOk={onOk} text={`Assessment${filteredAssessments.length > 1 ? 's' : ''} successfuly cancelled.`} />}
      {step === 'failure' && <Failure onOk={onCancel} />}
    </Modal>
  );
};

export default CancelAssessmentsModal;
