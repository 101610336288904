import React, { useCallback, FunctionComponent } from 'react';
import { Table, Checkbox } from 'semantic-ui-react';

import { useTableSelection } from '../Context/TableSelectionContext';
import { TableHeaderCellWrapper } from './TableHeaderCellWrapper';
import { useTableSortBy } from '../Context/TableSortByContext';
import { useTableConfig } from '../Context/TableConfigContext';
import { useTableFlags } from '../Context/TableFlagsContext';

export interface ITableHeaderProps {
  sticky?: boolean;
}

export const TableHeader: FunctionComponent<ITableHeaderProps> = ({ sticky }) => {
  const config = useTableConfig();
  const { sortable, selectable } = useTableFlags();
  const { selectAll, areAllSelected } = useTableSelection();
  const { sort, sortBy } = useTableSortBy();

  const sortByColumnKey = useCallback((key?: string | string[]) => !key || !sort ? undefined : () => {
    const foundSort = sort.current.find((s) => s.key === (Array.isArray(key) ? key[0] : key));

    if (foundSort) {
      sortBy(foundSort.direction === 'asc' ? (
        Array.isArray(key) ?
          key.map((k, index) => index === 0 ? ({ key: k, direction: 'desc' }) : ({ key: k, direction: 'asc' })) :
          { key, direction: 'desc' }
      ) : []);
    } else {
      sortBy(
        Array.isArray(key) ?
          key.map((k) => ({ key: k, direction: 'asc' })) :
          { key, direction: 'asc' }
      );
    }
  }, [sort, sortBy]);
  const isColumnSorted = useCallback((key?: string) => {
    if (!key || !sort) {
      return undefined;
    }

    const foundSort = sort.current.find((s) => s.key === key);
    if (foundSort) {
      return foundSort.direction === 'desc' ? 'descending' : 'ascending';
    }

    return undefined;
  }, [sort]);
  
  const handleCheckboxClick = useCallback(() => { selectAll(); }, [selectAll]);

  return (
    <Table.Header>
      <Table.Row>
        {selectable && (
          <TableHeaderCellWrapper sticky={sticky}>
            <Checkbox checked={areAllSelected} onClick={handleCheckboxClick} />
          </TableHeaderCellWrapper>
        )}
        {config.map(({ header, sortKey, cellProps }, index) => (
          <TableHeaderCellWrapper
            key={`table-header-${index}`}
            sticky={sticky}
            sortable={!!sortable && !!sortKey}
            onClick={sortByColumnKey(sortKey)}
            sorted={isColumnSorted(Array.isArray(sortKey) ? sortKey[0] : sortKey)}
            {...cellProps}
          >
            {header}
          </TableHeaderCellWrapper>
        ))}
      </Table.Row>
    </Table.Header>
  );
};
