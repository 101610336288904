import React, { FunctionComponent, ReactElement, useContext } from "react";
import styled, { DefaultTheme, ThemeContext } from "styled-components";
import Box, { StyledBox } from "../../../../common/Box/Box";
import Icon from "../../../../common/Icons/Icon";
import { DashboardCardBoxProps, DashboardCardBoxTheme } from "../types";

const StyledDashboardCardBox = styled.div<{boxColor: string, shadow: string}>`
  margin-bottom: 18px;
  position: relative;

  ${StyledBox} {
    border-color: ${({ color }) => color};
    box-shadow:  0px 5px 12px -3px ${({ shadow }) => shadow};
    display: block;
    padding: 10px 12px 9px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledExclamationIcon = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.DASHBOARD.COLOR};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 2px;
`;

const getBoxColors = (theme: DefaultTheme, color?: DashboardCardBoxTheme): [string, string, string] => {
  const colors = theme.DASHBOARD

  switch (color) {
    case 'error': return [colors.ERROR.COLOR, colors.ERROR.SHADOW, colors.ERROR.ICON];
    case 'warning': return [colors.WARNING.COLOR, colors.WARNING.SHADOW, colors.WARNING.ICON];
    default: return [colors.DEFAULT.COLOR, colors.DEFAULT.SHADOW, colors.DEFAULT.ICON];
  }
}

const DashboardCardBox: FunctionComponent<DashboardCardBoxProps> = ({ children, icon, theme, title }): ReactElement => {
  const appTheme = useContext(ThemeContext)
  const [boxColor, boxShadow, iconColor] = getBoxColors(appTheme, theme)
  const showExclamationIcon = theme === 'error' || theme === 'warning'

  return (
    <StyledDashboardCardBox boxColor={boxColor} shadow={boxShadow}>
      <Box border shadow>
        {showExclamationIcon && <StyledExclamationIcon><Icon color={iconColor} name="warning" size={16} ></Icon></StyledExclamationIcon>}
        {icon && <Icon color={iconColor} name={icon} size={24} ></Icon>}
        {title && <StyledTitle>{title}</StyledTitle>}
        {children}
      </Box>
    </StyledDashboardCardBox>
  );
};

export default DashboardCardBox;
