import { useMemo } from 'react';
import { IAccount, IAccountType } from '../../../types/resources';
import { ITableConfig } from '../../../common/Table/Context/TableConfigContext';
import { textWrapperCell } from './textWrapperCell';
import { LoginToAdminButton } from '../../../pages/backend/AccountList/LoginToAdminButton';
import { formatDateField } from '../../date';
import { AccountEditWrapper } from '../../../pages/backend/AccountList/AccountListTable/wrappers/AccountEdit';
import { GetAssessmentsReport } from '../../../pages/backend/AccountList/GetAccountAssessmentReport';

type ITypeMap = {
  [key in IAccountType]: string
}
export const TYPE_MAP: ITypeMap = {
  'won_customer': 'Customer',
  'trial_customer': 'Trial',
  'lost_customer': 'Lost Customer',
  'lost_trial_customer': 'Lost Trial',
};
export const translateAccountType = (type: IAccountType): string => TYPE_MAP[type];

export const ACCOUNT_FEATURES = {
  'admin.authentication.sso.saml': 'Admin SSO',
  'assessment.authentication.sso.saml': 'Assessment SSO',
}

export const translateFeatures = (features, joiner = "\n") => features.map(feature => ACCOUNT_FEATURES[feature]).join(joiner);

export const useAccountListTable = () => useMemo<ITableConfig<IAccount>[]>(() => [
  {
    header: 'Name',
    sortKey: 'name',
    Cell: textWrapperCell('name'),
  },
  {
    header: 'Active Users',
    sortKey: 'activeUserCount',
    Cell: textWrapperCell('activeUserCount'),
  },
  {
    header: 'Administrators',
    sortKey: 'totalAdministrators',
    Cell: textWrapperCell('totalAdministrators'),
  },
  {
    header: 'Last Admin Login',
    sortKey: 'lastAdminLogin',
    Cell: textWrapperCell('lastAdminLogin', undefined, formatDateField),
  },
  {
    header: 'Code',
    sortKey: 'accountCode',
    Cell: textWrapperCell('accountCode'),
  },
  {
    header: 'Created On',
    sortKey: 'createdAt',
    Cell: textWrapperCell('createdAt', undefined, formatDateField),
    cellProps: { textAlign: 'center' },
  },
  {
    header: 'Type',
    sortKey: 'accountType',
    Cell: textWrapperCell('accountType', undefined, translateAccountType),
  },
  {
    header: 'Managed',
    sortKey: 'isManaged',
    Cell: textWrapperCell('isManaged', undefined, isManaged => isManaged ? 'Yes' : '-'),
  },
  {
    header: 'Features',
    sortKey: 'enabledCapabilities',
    Cell: textWrapperCell('enabledCapabilities', undefined, translateFeatures),
  },
  {
    header: 'Login',
    Cell: LoginToAdminButton,
  },
  {
    header: 'Get Assessment Report',
    Cell: GetAssessmentsReport,
  },
  {
    header: 'Actions',
    Cell: AccountEditWrapper,
  },
], []);
