import React, { FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTableConfig } from '../../Context/TableConfigContext';

export interface ITableEmptyRowProps {
  emptyMessage?: string;
}

const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3vh;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
`;

export const TableEmptyRow: FunctionComponent<ITableEmptyRowProps> = ({ emptyMessage }) => {
  const config = useTableConfig();

  return (
    <Table.Row>
      <Table.Cell colSpan={config.length}>
        <NoDataContainer>{emptyMessage || 'Nothing found'}</NoDataContainer>
      </Table.Cell>
    </Table.Row>
  );
};