import React, { FunctionComponent, ReactElement, useContext } from "react";
import { ThemeContext } from "styled-components";
import Card from "../../../../common/Card/Card";
import Icon from "../../../../common/Icons/Icon";
import Tooltip from "../../../../common/Tooltip/Tooltip";
import Button from "../../../../common/Buttons/Button";
import { StyledCardContent, StyledEntry, StyledEntryLabel, StyledEntryValue, StyledRow, StyledTitle } from "./StyledAssessementCard";
import { FollowUpCardProps } from "../types";
import { canFollowUp } from "../../../../utils/assessment";
import { getManualAssessmentStatus } from "../../../../utils/surveyResponse";

const FollowUpCard: FunctionComponent<FollowUpCardProps> = ({ assessmentDetails, onOpenFollowUpModal }): ReactElement => {
  const { INFO_BADGE } = useContext(ThemeContext)
  const status = getManualAssessmentStatus(assessmentDetails.manualAssessment?.status)
  const info = "Once an employee has completed their online self-assessment they may present risks that they are not able to manage themselves. This area allows you to invite employees to a face to face follow-up with someone in your organisation or an external consultant. This follow-up could be a call, video call or a face to face assessment. It will track the employee's progress through the follow-up statuses."
  const buttonLabel = assessmentDetails.manualAssessment?.status ? 'Manage follow-up' : 'Create follow-up'

  return (
    <Card width="33%">
      <StyledCardContent>
        <StyledRow>
          <StyledTitle>Follow up</StyledTitle>
          <Tooltip text={info} icon trigger="click">
            <Icon name="info" size={16} color={INFO_BADGE.COLOR} />
          </Tooltip>
        </StyledRow>
        <StyledRow>
          <StyledEntry>
            <StyledEntryLabel>Follow-up status</StyledEntryLabel>
            <StyledEntryValue>{status}</StyledEntryValue>
          </StyledEntry>
          <Button
            disabled={!canFollowUp(assessmentDetails)}
            label={buttonLabel}
            theme="SUCCESS_PRIMARY" 
            icon="usersTalking" 
            onClick={() => onOpenFollowUpModal(assessmentDetails)} 
          />
        </StyledRow>
      </StyledCardContent>
    </Card>
  );
};

export default FollowUpCard;
