import React, { FunctionComponent } from 'react';
import { Message } from 'semantic-ui-react';

import { useUserUploadHasError } from '../contexts/UserUploadHasErrorContext';

export const ErrorNotification: FunctionComponent = () => {
  const [hasError, errorMessage] = useUserUploadHasError();

  return hasError ? (
    <Message color="red" negative floating>
      {errorMessage}
    </Message>
  ) : null;
};
