import React, { FunctionComponent, ReactElement } from "react";
import ActionCard from "../../../../common/ActionCard/ActionCard";
import Button from "../../../../common/Buttons/Button";
import { canBeCancelled, canBeReminded } from "../../../../utils/assessment";
import { SelfAssessmentActionsCardProps } from "../types";

const SelfAssessmentActionsCard: FunctionComponent<SelfAssessmentActionsCardProps> = ({ assessmentDetails, onOpenCancelAssessmentsModal, onOpenResendInviteModal }): ReactElement => {
  return (
    <ActionCard
      cardWidth="33%"
      heading="Self-assessment actions"
    >
      <Button disabled={!canBeReminded(assessmentDetails)} label="Resend invite link" theme="SUCCESS_PRIMARY" icon="refresh" onClick={() => onOpenResendInviteModal(assessmentDetails)} />
      <Button disabled={!canBeCancelled(assessmentDetails)} label="Cancel assessment" theme="DANGER_SECONDARY" icon="cancel" onClick={() => onOpenCancelAssessmentsModal([assessmentDetails])} />
    </ActionCard>
  );
};

export default SelfAssessmentActionsCard;
