import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Button, { StyledButton } from "../../common/Buttons/Button";
import useFormField from "../../common/Forms/useFormField";
import InputText from "../../common/Forms/InputText";
import Textarea from "../../common/Forms/Textarea";
import { lengthBetweenValidation, requiredValidation } from "../../common/Forms/validation";
import { ContactFormProps } from "./types";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const StyledInput = styled.div`
  height: 74px;
  width: 464px;
`;

const StyledInfo = styled.div`
  margin: 0 10px 23px 0;
  text-align: right;
  width: 100%;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;

  ${StyledButton} {
    width: 166px;
    max-width: none;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ContactForm: FunctionComponent<ContactFormProps> = ({ initialValues, onCancel, onSubmit, cancelLabel, submitLabel }): ReactElement => {
  const [subject, setSubject, clearSubject, validateSubject, subjectError] = useFormField<string>(initialValues.subject, [requiredValidation, lengthBetweenValidation(1, 500)]);
  const [message, setMessage, clearMessage, validateMessage, messageError] = useFormField<string>(initialValues.message, [requiredValidation, lengthBetweenValidation(1, 5000)]);

  const resetForm = () => {
    clearSubject();
    clearMessage();
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    const errors = [validateSubject(), validateMessage()];

    if (errors.filter(e => !!e).length === 0) {
      onSubmit({ subject, message });
    }
  };

  return (
    <>
      <StyledForm>
        <StyledInput>
          <InputText placeholder="Subject" label="Subject" required value={subject} onChange={setSubject} error={subjectError} />
        </StyledInput>
        <Textarea placeholder="Write here your message" label="Your message" required value={message} onChange={setMessage} error={messageError} />
      </StyledForm>
      <StyledInfo>* required</StyledInfo>
      <StyledButtons>
        <Button label={cancelLabel} theme="SUCCESS_SECONDARY" onClick={handleCancel}/>
        <Button icon="ok" label={submitLabel} theme="SUCCESS_PRIMARY" onClick={handleSubmit} />
      </StyledButtons>
    </>
  );
};

export default ContactForm;
