import React, { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import Box, { StyledBox } from "../Box/Box";
import { CardProps } from "./types";

export const CardsRow = styled.div`
  margin: 0 -9px 18px;
`;

const StyledCard = styled.div<{ cardWidth?: string }>`
  display: inline-block;
  padding: 0 9px;
  min-width: ${({ cardWidth }) => cardWidth ? cardWidth : 'none'};
  width: ${({ cardWidth }) => cardWidth ? cardWidth : 'auto'};

  > ${StyledBox} {
    overflow: hidden;
  }
`;

const Card: FunctionComponent<CardProps> = ({ width, children }): ReactElement => {
  return (
    <StyledCard cardWidth={width}>
      <Box shadow>
        { children }
      </Box>
    </StyledCard>
  );
};

export default Card;
