import React, { FunctionComponent, useCallback } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { useOpenAccountUpdateModal } from '../../modals/AccountUpdateModal/AccountUpdateModalContext';
import { useOnAccountUpdate } from '../AccountListTable/contexts/AccountUpdateContext';

export const AddAccountButton: FunctionComponent = () => {
  const openAddAccountModal = useOpenAccountUpdateModal();
  const onAccountAdd = useOnAccountUpdate();

  const addNewAccount = useCallback(() => {
    openAddAccountModal({}, onAccountAdd);
  }, [openAddAccountModal, onAccountAdd]);

  return (
    <Popup
      content="Add new account"
      position="left center"
      mouseEnterDelay={500}
      mouseLeaveDelay={500}
      inverted
      trigger={(
        <Button onClick={addNewAccount} icon basic>
          Add new account
        </Button>
      )}
    />
  );
};
